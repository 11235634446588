import { Project } from '@hover/breaker-react';
import * as Sentry from '@sentry/react';
import { SplitSdk } from '@splitsoftware/splitio-react';

import * as flags from 'src/lib/FeatureFlag/flags';
import { getSplitApiKey } from 'src/utils/EnvUtils';

import { SplitFlagsToTrafficType } from './utils';

interface Client extends SplitIO.IClient {
  _getTreatment?: (
    splitName: string,
    attributes?: SplitIO.Attributes,
  ) => SplitIO.Treatment;
}

interface Status {
  isInitializing: boolean;
  isReady: boolean;
}

export type TrafficType = 'user' | 'org';

interface Clients {
  [key: string]: {
    client: Client;
    status: Status;
  };
}

interface DefaultAttributes {
  partnerId?: string;
}

export class Split {
  factory: SplitIO.IBrowserSDK | undefined = undefined;

  clients: Clients = {};

  config: SplitIO.IBrowserSettings = {
    core: {
      authorizationKey: getSplitApiKey(),
      key: 'anonymous',
      trafficType: 'org',
    },
  };

  defaultAttributes: DefaultAttributes = {};

  private initializePromise: Promise<Split> | null = null;

  async init({
    orgId,
    userId,
    config,
    defaultAttributes,
  }: {
    orgId: number;
    userId: string;
    config?: SplitIO.IBrowserSettings;
    defaultAttributes: DefaultAttributes;
  }) {
    if (this.initializePromise) return this.initializePromise;

    this.defaultAttributes = defaultAttributes;

    this.initConfig({ orgId, config });
    this.factory = SplitSdk(this.config);
    const initialStatus = { isInitializing: true, isReady: false };

    this.clients.org = {
      client: this.factory.client(),
      status: initialStatus,
    };

    this.clients.user = {
      client: this.factory.client(userId, 'user'),
      status: initialStatus,
    };

    const userPromise: Promise<Split> = new Promise((resolve) => {
      const { client, status } = this.clients.user;

      return client?.on(client.Event.SDK_READY, () => {
        status.isInitializing = false;
        status.isReady = true;
        resolve(this);
      });
    });
    const orgPromise: Promise<Split> = new Promise((resolve) => {
      const { client, status } = this.clients.org;

      return client?.on(client.Event.SDK_READY, () => {
        status.isInitializing = false;
        status.isReady = true;
        resolve(this);
      });
    });
    this.initializePromise = Promise.all([userPromise, orgPromise]).then(
      () => this,
    );
    return this.initializePromise;
  }

  initConfig({
    orgId,
    config,
  }: {
    orgId: number;
    config?: SplitIO.IBrowserSettings;
  }) {
    if (config) {
      // if passed config directly (eg. in tests), override config
      this.config = {
        ...this.config,
        ...config,
      };
    }
    this.config.core.key = orgId.toString();
    if (this.config.core.authorizationKey === 'localhost') {
      this.config.features = window.SPLIT_OVERRIDES ?? {}; // window.SPLIT_OVERRIDES used in browser tests
    }
  }

  convertSplitsToBreakerProject() {
    const splitProject: Project = {
      flags: [],
      key: 'Split',
      name: 'Split',
    };
    // TODO: handle non-binary treatments

    splitProject.flags = Object.values(flags).map((flag) => ({
      key: flag,
      name: flag.replace(/_/g, '-'),
      description: flag,
      enabled: Object.values(this.clients).some(
        ({ client }) => client.getTreatment(flag) === 'on',
      ),
    }));

    return splitProject;
  }

  getClient(trafficType: TrafficType) {
    return (this.clients[trafficType] ?? {}).client ?? {};
  }

  getFactory() {
    return this.factory;
  }

  getStatus(trafficType: TrafficType) {
    return (this.clients[trafficType] ?? {}).status ?? {};
  }

  isEnabled(flagName: string) {
    // Default traffic type to "user" type; eventually this will
    // be the only traffic type.
    const trafficType = SplitFlagsToTrafficType[flagName] ?? 'user';
    const client = this.getClient(trafficType);
    const { isReady } = this.getStatus(trafficType);
    // Create a custom attributes object containing the logged-in
    // user's orgId.
    const customAttributes: SplitIO.Attributes = {
      // TS string conversion needed from Split SplitIO.SplitKey type.
      orgId: this.config.core.key.toString(),
    };

    if (this.defaultAttributes?.partnerId) {
      customAttributes.partnerId = this.defaultAttributes.partnerId;
    }

    if (!isReady) return false;

    // Provide the orgId to the getTreatment() method via the custom
    // attributes, so orgId can be used in Split targeting rules.
    const treatment = client?.getTreatment?.(flagName, customAttributes);

    if (!treatment) {
      Sentry.captureMessage(
        `isEnabled expects valid flagName. Received: ${flagName}`,
      );
      return false;
    }
    switch (treatment) {
      case 'on':
        return true;
      case 'off':
        return false;
      case 'control':
        // https://help.split.io/hc/en-us/articles/360020528072-Control-treatment
        return false;
      default:
        Sentry.captureMessage(
          `isEnabled expects "on" or "off". Received: ${treatment}`,
        );
        return false;
    }
  }
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default new Split();
