import { useEffect } from 'react';

import { wasteFactorRoof } from 'src/api/types/wasteFactorRoof';
import { useLazyQueryEhi } from 'src/hooks';

import { WASTE_FACTOR_ROOF } from '../apis/queries';
import { JobMeasurements } from '../types';

export function useGetWasteFactor(jobMeasurements?: JobMeasurements | null) {
  const [getWasteFactor, queryResult] = useLazyQueryEhi<wasteFactorRoof>(
    WASTE_FACTOR_ROOF,
    {},
  );

  useEffect(() => {
    const roof = jobMeasurements?.roof;
    if (roof) {
      const roofAttributes = {
        hipsLinearFeet: roof.hip_total,
        rakesLinearFeet: roof.rake_total,
        roofSquareFeet: roof.roof_total,
        stepFlashingLinearFeet: roof.step_flashing_total,
        valleysLinearFeet: roof.valley_total,
      };
      getWasteFactor({
        variables: {
          roofAttributes,
        },
      });
    }
  }, [jobMeasurements, getWasteFactor]);

  return queryResult;
}
