import { useCallback, useState } from 'react';

import { Button } from '@hover/blueprint';
import { useDispatch, useSelector } from 'react-redux';

import { BottomBarLayout } from 'src/features/exteriorEstimator/components/EstimationTool/BottomBar/BottomBarLayout';
import { useEstimate } from 'src/features/exteriorEstimator/hooks';
import { estimatorActions } from 'src/features/exteriorEstimator/redux/actions';
import {
  getTemplates,
  getSelectedTemplateIds,
} from 'src/features/exteriorEstimator/redux/sagas/selectors';
import { generateEstimatorWithTemplatesUrl } from 'src/features/exteriorEstimator/utils/miscUtils';
import {
  COMMERCE_ORDERING_ONLY_FLOW,
  isEnabled,
  COMMERCE_PROJECT_SCOPE,
} from 'src/lib/FeatureFlag';
import { getMaterialListFeature, getOrgSettings } from 'src/redux/selectors';

interface Props {
  children: React.ReactNode;
  setIsInitializing: (value: boolean) => void;
}

export const BottomBarSelectTemplates: React.FC<Props> = (props: Props) => {
  const { children, setIsInitializing } = props;
  const templates = useSelector(getTemplates);
  const selectedTemplateIds = useSelector(getSelectedTemplateIds);
  const showOrderingFlow = useSelector(getOrgSettings)?.showOrderingFlow;
  const showOrderingVersion =
    isEnabled(COMMERCE_ORDERING_ONLY_FLOW) &&
    isEnabled(COMMERCE_PROJECT_SCOPE) &&
    showOrderingFlow;
  const materialListFeatureEnabled = useSelector(getMaterialListFeature);
  const showOrderOrMaterialListVersion =
    showOrderingVersion || materialListFeatureEnabled;

  const [isNextDisabled, setIsNextDisabled] = useState<boolean>(false);

  const { setupEstimator } = useEstimate();
  const dispatch = useDispatch();

  const handleDeselectAll = useCallback(() => {
    dispatch(estimatorActions.deselectAllTemplates());
  }, [dispatch]);

  const handleNextClick = useCallback(async () => {
    setIsNextDisabled(true);
    window.location.href = generateEstimatorWithTemplatesUrl(
      window.location.href,
      selectedTemplateIds || [],
    );

    if (!selectedTemplateIds || !templates) return;
    await setupEstimator(
      selectedTemplateIds,
      templates,
      setIsInitializing,
      !!showOrderOrMaterialListVersion,
    );
  }, [
    setupEstimator,
    selectedTemplateIds,
    templates,
    setIsInitializing,
    showOrderOrMaterialListVersion,
  ]);

  const needTemplateSelection =
    !selectedTemplateIds || selectedTemplateIds.length === 0;
  const shouldDisableNextAction = needTemplateSelection;

  return (
    <BottomBarLayout>
      <Button
        fill="outline"
        shape="pill"
        size="large"
        onClick={handleDeselectAll}
        data-test-id="resetAllTemplates"
        isDisabled={needTemplateSelection}
      >
        Reset all
      </Button>
      {children}
      <Button
        shape="pill"
        size="large"
        onClick={handleNextClick}
        isDisabled={shouldDisableNextAction}
        isLoading={isNextDisabled}
        data-test-id="estimatorNextButton"
      >
        Next
      </Button>
    </BottomBarLayout>
  );
};
