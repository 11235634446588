import {
  Body,
  Box,
  Center,
  Heading,
  Image,
  LinkBox,
  Link,
  Icon,
  LinkOverlay,
  SimpleGrid,
} from '@hover/blueprint';
import { iArrowRight } from '@hover/icons';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  AccessLevel,
  SettingsSection,
} from 'src/features/settings/components/Settings';
import { useEffectOnMount } from 'src/hooks';
import { getIsJobManager, getIsOrgAdmin } from 'src/redux/selectors';

const Section = ({ logo, url, title, description, color }: SettingsSection) => {
  const history = useHistory();

  return (
    <LinkBox
      _hover={{
        transform: 'scale(1.03)',
        shadow: 'distance300',
      }}
      as="article"
      borderRadius={500}
      cursor="pointer"
      data-group
      flexDir="column"
      overflow="hidden"
      shadow="distance100"
      transition="all 0.1s ease-in"
      onClick={() => {
        history.push(url);
      }}
    >
      {logo && (
        <Center
          paddingX="500"
          paddingY="600"
          width="100%"
          background={`${color}.100`}
        >
          <Image
            _groupHover={{ filter: 'saturate(100%)' }}
            alt="logo"
            filter="saturate(75%)"
            height="600"
            src={logo}
          />
        </Center>
      )}
      <Box flexDirection="column" padding="500">
        <Link
          href={`/ehi/#${url}`}
          color={`${color}.600`}
          textTransform="uppercase"
          fontWeight="bodyBold"
          textDecoration="none"
          _hover={{ textDecoration: 'underline', color: 'primary700' }}
        >
          <LinkOverlay>{title}</LinkOverlay>
        </Link>
        <Body minHeight="70px">{description}</Body>
        <Link href={`/ehi/#${url}`} _hover={{ textDecoration: 'underline' }}>
          <Box flexDirection="row" alignItems="center">
            More
            <Icon icon={iArrowRight} color="primary500" />
          </Box>
        </Link>
      </Box>
    </LinkBox>
  );
};

export const Dashboard: React.FC<{
  sections: SettingsSection[] | null;
  handleTabsChange: (section: number | null) => void;
}> = ({ sections, handleTabsChange }) => {
  const isOrgAdmin = useSelector(getIsOrgAdmin);
  const isJobManager = useSelector(getIsJobManager);

  useEffectOnMount(() => {
    handleTabsChange(null);
  });
  if (!sections) return null;

  return (
    <Box flexDirection="column">
      <Heading size={500}>Manage Workflows</Heading>
      <Body>
        Customize preferences for your organization and teams to maximize the
        benefits of using HOVER.
      </Body>
      <SimpleGrid columns={3} gap="600" minChildWidth="20em" mt="300">
        {sections.map((section) => {
          // hides admin access level tiles depending on user acl access
          if (
            (section.accessLevel === AccessLevel.ADMIN && !isOrgAdmin) ||
            (section.accessLevel === AccessLevel.JOB_MANAGER &&
              !isOrgAdmin &&
              !isJobManager)
          ) {
            return null;
          }
          return <Section key={section.url} {...section} />;
        })}
      </SimpleGrid>
    </Box>
  );
};
