import HoverHeader from '@hover/header';
import { useSelector } from 'react-redux';

import appSettings from 'src/appSettings';
import { getIsOrgAdmin } from 'src/redux/selectors';

/**
 * use this instead of importing @hover/header directly
 */
export const EHIHoverHeader: React.FC = ({ children, ...props }) => {
  const isOrgAdmin = useSelector(getIsOrgAdmin);

  const additionalMenuItems = isOrgAdmin
    ? [
        {
          label: 'Manage workflows',
          url: `${window.location.origin}/ehi/#/workflows`,
        },
      ]
    : [];

  if (isOrgAdmin) {
    additionalMenuItems.push({
      label: 'Checklist templates',
      url: `${window.location.origin}/ehi/#/workflows/inspection_templates`,
    });
  }

  return (
    <HoverHeader
      apiServer={appSettings.HYPERION_API_SERVER}
      additionalMenuItems={additionalMenuItems}
      {...props}
    >
      {children}
    </HoverHeader>
  );
};
