import {
  ORDERING_AUTO_ATTACH_PDF,
  UNIT_COST_IN_LINE_EDIT_CHANGE_LOG,
  QUANTITY_TAKEOFF,
  DIRECT_TO_PRODUCTION,
} from 'src/lib/FeatureFlag/flags';
import { TrafficType } from 'src/utils/SplitConfig';

export const SplitFlagsToTrafficType: { [key: string]: TrafficType } = {
  // *** DO NOT ADD NEW FLAGS TO THIS SECTION: ALL NEW FLAGS SHOULD BE "user" TYPE. ***
  [ORDERING_AUTO_ATTACH_PDF]: 'user',
  [UNIT_COST_IN_LINE_EDIT_CHANGE_LOG]: 'user',
  [QUANTITY_TAKEOFF]: 'user',
  [DIRECT_TO_PRODUCTION]: 'user',
};
