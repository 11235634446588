import { SIDING_ARGUMENT_MAP } from 'src/features/exteriorEstimator/constants/questionArgumentMappings';
import {
  Input,
  Page,
  QuestionId,
  QuestionResponses,
  PristineMeasurement,
  QuestionAnswer,
  PlainMeasurements,
} from 'src/features/exteriorEstimator/types';
import { LineSegmentCalculator } from 'src/features/exteriorEstimator/utils/LineSegmentCalculator';
import { PartialRoofUtils } from 'src/features/exteriorEstimator/utils/PartialRoofUtils';
import {
  getPageForCategory,
  getQuestionById,
} from 'src/features/exteriorEstimator/utils/questionsUtils';
import { Measurements } from 'src/types/EstimationMeasurementTypes';

// returns ROOF/SIDING partial selection QuestionResponses, reset back to their defaultValue or true
// occurs when toggling back to FULL roof after making partial selections
export const resetPartialQuestions = (category: string, pages: Page[]) => {
  const partialQuestionPage = getPageForCategory(pages, category);
  if (!partialQuestionPage) return {};
  const { questions: partialFacetQuestions } = partialQuestionPage;
  const newAnswers = partialFacetQuestions?.reduce(
    (answers: QuestionResponses, question) => {
      // eslint-disable-next-line no-param-reassign
      answers[question.id] = question.questionDefaultValue ?? true;
      return answers;
    },
    {},
  );
  return newAnswers;
};

// returns only the measurement QuestionResponses reset back to their initial values
// occurs if partial selection has changed a measurement value but now we want to revert the changes
export const resetToPristineMeasurements = (
  pristineMeasurements: PristineMeasurement[],
) => {
  const newAnswers = pristineMeasurements.reduce(
    (answers: QuestionResponses, item) => {
      // eslint-disable-next-line no-param-reassign
      answers[item.questionId] = item.answer;
      return answers;
    },
    {},
  );
  return newAnswers;
};

// returns new partial siding total QuestionResponse based on partial selections
export const updatePartialSidingCalculationsDeprecated = (
  questionId: QuestionId,
  answer: QuestionAnswer,
  sidingTotalQuestion: Input,
  questionResponses: QuestionResponses,
  pages: Page[],
) => {
  const sidingSelectionQuestion = getQuestionById(pages, questionId);
  if (!sidingSelectionQuestion) return {};

  const currentSidingTotal = questionResponses[
    sidingTotalQuestion.id
  ] as number;
  const delta = answer
    ? (sidingSelectionQuestion.total as number)
    : Number(sidingSelectionQuestion.total) * -1;

  if (!currentSidingTotal || !delta) return {};

  return {
    questionId: sidingTotalQuestion.id,
    answer: Math.max(currentSidingTotal + delta, 0),
  };
};

// returns updated roofTotal and pitch QuestionResponses based on a roof facet selection
export const updatePartialRoofCalculations = (
  questionId: QuestionId,
  answer: QuestionAnswer,
  pages: Page[],
  questionResponses: QuestionResponses,
  measurementQuestions: Input[],
) => {
  const question = getQuestionById(pages, questionId);
  if (!question) return {}; // TS
  const { pitchQuestion, roofTotalQuestion } =
    PartialRoofUtils.getPitchQuestionsForPitch(pages, question.pitch ?? '');

  const currentPitchValue = Number(questionResponses[pitchQuestion?.id ?? -1]);
  const pitchChange = answer ? question.area : Number(question.area) * -1;
  const newPitchValue = Math.max(
    Number(currentPitchValue) + Number(pitchChange),
    0,
  );
  if (!pitchQuestion) {
    // no measurement question found for this particular pitch
    return {};
  }
  const newAnswers = {
    ...questionResponses,
    [pitchQuestion.id]: newPitchValue,
  };
  const roofTotalFromPitches = measurementQuestions.reduce((sum, q) => {
    if (PartialRoofUtils.isPitch(q)) {
      const response = newAnswers[q.id];
      return sum + (Number(response) || 0);
    }
    return sum;
  }, 0);

  return {
    [pitchQuestion.id]: newPitchValue,
    ...(!!roofTotalQuestion && {
      [roofTotalQuestion.id]: roofTotalFromPitches,
    }),
  };
};

// returns updated totals and/or edge QuestionResponses based on a partial selection
export const getUpdatedLineSegmentValues = ({
  facetLabel,
  answer,
  answers,
  plainMeasurements,
  fullMeasurements,
  lineSegmentInputs,
  type,
  questionResponses,
  sidingTotalQuestion,
}: {
  facetLabel?: string | null;
  answer?: boolean | null;
  answers?: QuestionResponses | null;
  lineSegmentInputs: Input[];
  type: 'SIDING' | 'ROOF';
  plainMeasurements: PlainMeasurements;
  fullMeasurements: Measurements;
  questionResponses: QuestionResponses;
  sidingTotalQuestion?: Input | null | undefined;
}) => {
  let calculator;
  const newAnswers: QuestionResponses = {};
  if (facetLabel) {
    calculator = new LineSegmentCalculator({
      pristinePlainMeasurements: plainMeasurements,
      estimationJson: fullMeasurements,
      questionResponses: {
        ...questionResponses,
        [facetLabel]: answer,
      },
    });
  } else if (answers) {
    calculator = new LineSegmentCalculator({
      pristinePlainMeasurements: plainMeasurements,
      estimationJson: fullMeasurements,
      questionResponses: {
        ...questionResponses,
        ...answers,
      },
    });
  }
  if (!calculator) return {};

  const { calculatedEdgeTotals, selectedAreaWithTrim } = calculator;

  if (type === 'SIDING' && sidingTotalQuestion) {
    newAnswers[sidingTotalQuestion.id] = selectedAreaWithTrim;
  }

  const edgeAnswers: QuestionResponses = {};
  Object.entries(calculatedEdgeTotals).forEach(
    ([lineSegmentArgument, value]) => {
      // lineSegmentArgument = ridge_total | ridge_count | etc...

      // see if line segment maps to a specfic input / question
      let lineSegmentInput;
      if (type === 'SIDING') {
        lineSegmentInput = lineSegmentInputs.find(
          (question) =>
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            SIDING_ARGUMENT_MAP[question.argument ?? ''] ===
            lineSegmentArgument,
        );
      } else if (type === 'ROOF') {
        lineSegmentInput = lineSegmentInputs.find(
          (question) => question.argument === lineSegmentArgument,
        );
      }

      if (lineSegmentInput) {
        edgeAnswers[lineSegmentInput.id] = parseFloat(value.toFixed(2));
      }
    },
  );
  return { ...newAnswers, ...edgeAnswers };
};
