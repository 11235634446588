import { useMemo, useEffect } from 'react';

import {
  Button,
  Modal,
  Heading,
  Body,
  Tooltip,
  Box,
  Spinner,
} from '@hover/blueprint';
import { Wrap } from '@hover/blueprint/chakra';
import { iInfo } from '@hover/icons';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import {
  toggleSelectedTemplate,
  unselectTemplates,
} from 'src/features/exteriorEstimator/redux/actions/templatesActions';
import {
  getOriginalSelectedTemplates,
  getSelectedTemplates,
  getTemplates,
} from 'src/features/exteriorEstimator/redux/sagas/selectors';
import { Template } from 'src/features/exteriorEstimator/types';
import { useMaterialListFeature } from 'src/features/project/hooks/useMaterialListFeature';
import { useTracking, usePrevious } from 'src/hooks';
import { getUserTrackingProps, getTradeTypesSorted } from 'src/redux/selectors';
import { EventNames } from 'src/types/actionTypes';

type Props = {
  isLoading: boolean;
  isOpen: boolean;
  onClose: () => void;
  refetchProductionList: any;
};

const ModalFooter = styled.div`
  margin-top: ${({ theme }) => theme.utils.pxToRem(100)}rem;
  justify-content: right;
  display: flex;
`;

const sortTemplatesByOrder = (a: Template, b: Template) => {
  if (!a.sortOrder || !b.sortOrder || a.sortOrder > b.sortOrder) {
    return 1;
  }
  if (a.sortOrder === b.sortOrder) {
    return 0;
  }

  return -1;
};

export const MaterialListTemplateSelectionModal = ({
  isLoading,
  isOpen,
  onClose,
  refetchProductionList,
}: Props) => {
  const dispatch = useDispatch();

  const tradeTypes = useSelector(getTradeTypesSorted);
  const templates = useSelector(getTemplates);
  const templateTradeTypeEnums = new Set(
    templates?.map((template) => template.tradeType),
  );
  const templateTradeTypes = tradeTypes.filter((type) =>
    templateTradeTypeEnums.has(type.tradeTypeEnumValue),
  );

  const selectedTemplates = useSelector(getSelectedTemplates);
  const originalSelectedTemplates = useSelector(getOriginalSelectedTemplates);
  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();

  const { recreateEstimateGroupWithTemplates, isRecreatingMaterialList } =
    useMaterialListFeature();
  const prevIsRecreatingMaterialList = usePrevious(isRecreatingMaterialList);

  const selectedTemplatesIds = useMemo(() => {
    if (!selectedTemplates) {
      return [];
    }

    return selectedTemplates.map((template: Template) => template.id);
  }, [selectedTemplates]);

  const isTemplateSelected = (template: Template) =>
    selectedTemplatesIds.includes(template.id);

  const hasChanged = useMemo(() => {
    if (!selectedTemplates || !originalSelectedTemplates) {
      return false;
    }

    return (
      JSON.stringify(originalSelectedTemplates) !==
      JSON.stringify(selectedTemplates)
    );
  }, [originalSelectedTemplates, selectedTemplates]);

  const handleTemplateChange = (template: Template) => () => {
    if (!selectedTemplates) {
      return;
    }

    if (isTemplateSelected(template)) {
      if (selectedTemplates.length === 1) {
        return;
      }

      selectedTemplates.splice(
        selectedTemplates.findIndex((element) => element.id === template.id),
        1,
      );
    }

    typewriter.buttonPressed({
      page_or_screen_name:
        EventNames.project.scope.templateSelectModal.selection,
      button_text: template.name || '',
      primary_cta: true,
      feature: 'material-list',
      ...commonTrackingProps,
    });

    const newSelectedTemplates = selectedTemplates.filter(
      (oldTemplate: Template) => template.tradeType !== oldTemplate.tradeType,
    );

    newSelectedTemplates.push(template);

    // unselected template from tradeType
    const selectedTemplateOnTradeType = selectedTemplates.find(
      (selectedTemplate: Template) =>
        selectedTemplate.tradeType === template.tradeType,
    );

    if (selectedTemplateOnTradeType) {
      dispatch(unselectTemplates({ ids: [selectedTemplateOnTradeType.id] }));
    }

    dispatch(toggleSelectedTemplate({ id: template.id }));
  };

  const handleUpdateMaterialList = () => {
    if (!selectedTemplates) {
      return;
    }
    typewriter.buttonPressed({
      page_or_screen_name: EventNames.project.scope.templateSelectModal.confirm,
      button_text: 'Update material list',
      primary_cta: true,
      feature: 'material-list',
      ...commonTrackingProps,
    });
    recreateEstimateGroupWithTemplates();
  };

  const handleShowMaterialList = () => {
    onClose();
  };

  const handleCancel = () => {
    typewriter.buttonPressed({
      page_or_screen_name: EventNames.project.scope.templateSelectModal.cancel,
      button_text: 'cancel',
      primary_cta: false,
      feature: 'material-list',
      ...commonTrackingProps,
    });
    onClose();
  };

  useEffect(() => {
    const isDoneRecreatingMaterialList =
      isRecreatingMaterialList === false &&
      prevIsRecreatingMaterialList === true;
    if (isDoneRecreatingMaterialList) {
      refetchProductionList();
      onClose();
    }
  }, [isRecreatingMaterialList, prevIsRecreatingMaterialList]);

  useEffect(() => {
    if (isOpen) {
      typewriter.pageViewed({
        page_or_screen_name: EventNames.project.scope.templateSelectModal.page,
        feature: 'material-list',
        ...commonTrackingProps,
      });
    }
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      isCentered
      onClose={() => {
        onClose();
      }}
    >
      {isLoading || isRecreatingMaterialList ? (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Spinner
            size="huge"
            data-test-id="ShowTemplateSelectionModal-Loader"
          />
          <Body>Preparing material list</Body>
        </Box>
      ) : (
        <div data-test-id="ShowTemplateSelectionModal-Content">
          <Heading size={200} color="green.600">
            MATERIAL LIST
          </Heading>

          <Heading>Template selection</Heading>

          <Box flexDir="column">
            {templateTradeTypes.map((tradeType) => (
              <Box key={tradeType.tradeTypeEnumValue} flexDir="column">
                <Body size={500} isBold>
                  {tradeType.tradeTypeName.toUpperCase()}
                </Body>
                <Wrap>
                  {templates &&
                    templates
                      .filter(
                        (template: Template) =>
                          template.tradeType === tradeType.tradeTypeEnumValue,
                      )
                      .sort(sortTemplatesByOrder)
                      .map((template) => (
                        <Tooltip key={template.id} label={template.description}>
                          <Button
                            fill={
                              isTemplateSelected(template) ? 'solid' : 'outline'
                            }
                            shape="box"
                            iconAfter={iInfo}
                            onClick={handleTemplateChange(template)}
                          >
                            {template.name}
                          </Button>
                        </Tooltip>
                      ))}
                </Wrap>
              </Box>
            ))}
          </Box>

          <ModalFooter>
            {hasChanged && (
              <Button fill="outline" mr={4} onClick={handleCancel}>
                Cancel
              </Button>
            )}
            <Button
              onClick={
                hasChanged ? handleUpdateMaterialList : handleShowMaterialList
              }
            >
              {hasChanged ? 'Update material list' : 'Show material list'}
            </Button>
          </ModalFooter>
        </div>
      )}
    </Modal>
  );
};
