import React from 'react';

import { Box, Body, Heading, Button, Badge } from '@hover/blueprint';
import { iEdit } from '@hover/icons';
import { useSelector } from 'react-redux';

import type {
  projectManagementProductionList_projectManagementProductionList_estimateGroup_salesOpportunity_job as Job,
  projectManagementProductionList_projectManagementProductionList as ProjectManagementProductionList,
} from 'src/api/types/projectManagementProductionList';
import {
  VariationMatchingToggle,
  MatchingGroups,
} from 'src/components/VariationMatchingToggle';
import { useTracking } from 'src/hooks';
import { getMaterialListFeature } from 'src/redux/selectors';
import { EventNames } from 'src/types/actionTypes';

import { ProjectScopeHelpDrawer } from './ProjectScopeHelpDrawer';
import { ProjectScopeTipsDrawer } from './ProjectScopeTipsDrawer';

type Props = {
  job: Job;
  productionList: ProjectManagementProductionList;
};

export const ProjectScopeHeader = ({ job, productionList }: Props) => {
  const materialListFeatureEnabled = useSelector(getMaterialListFeature);
  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();

  return (
    <Box justifyContent="space-between">
      {materialListFeatureEnabled ? (
        <Box flexDirection="row" alignItems="center">
          <Body
            fontWeight="700"
            fontSize={300}
            marginRight={400}
            marginBottom={400}
          >
            Material List
          </Body>
          <Badge colorScheme="warning">BETA</Badge>
        </Box>
      ) : (
        <Box flexDirection="column">
          <Body margin="0">Production console</Body>
          <Heading size={500} data-test-id="ProjectScope-JobName">
            {job?.name}
          </Heading>
        </Box>
      )}
      <Box alignItems="center">
        <VariationMatchingToggle
          type={MatchingGroups.PRODUCTION_LIST}
          id={productionList.id}
          enableVariationMatching={productionList.enableVariationMatching}
        />
        {materialListFeatureEnabled && (
          <>
            <ProjectScopeHelpDrawer />
            <ProjectScopeTipsDrawer />
            <Button
              fill="outline"
              data-test-id="customizeMaterialList"
              iconBefore={iEdit}
              onClick={() => {
                typewriter.buttonPressed({
                  page_or_screen_name:
                    EventNames.project.scope.customizeMaterialListPressed,
                  button_text: 'Customize Material List',
                  primary_cta: false,
                  feature: 'material-list',
                  ...commonTrackingProps,
                });
                window.location.href = `/ehi/#/estimator/questions/select_templates?jobId=${job.id}`;
              }}
            >
              Customize Material List
            </Button>
          </>
        )}
      </Box>
    </Box>
  );
};
