import { useState } from 'react';

/* eslint-disable no-alert */
import { useMutation } from '@apollo/client';
import { IconButton } from '@hover/blueprint';
import { iTrash } from '@hover/icons';

import { DELETE_TEMPLATE_SECTION } from 'src/features/settings/api/mutations/inspectionChecklist';
import { GET_INSPECTION_CHECKLIST_TEMPLATE } from 'src/features/settings/api/queries/inspectionChecklist';
import { ConfirmDeleteModal } from 'src/features/settings/components/common/ConfirmDeleteModal';

interface TemplateSectionDeleteIconProps {
  templateId: string;
  templateSectionId: string;
}

export const TemplateSectionDeleteIcon = ({
  templateId,
  templateSectionId,
}: TemplateSectionDeleteIconProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [deleteTemplateSection, { loading }] = useMutation(
    DELETE_TEMPLATE_SECTION,
    {
      variables: {
        id: templateSectionId,
      },
      refetchQueries: [
        {
          query: GET_INSPECTION_CHECKLIST_TEMPLATE,
          variables: { id: templateId },
        },
      ],
    },
  );

  const showConfirmationModal = () => {
    setIsOpen(true);
  };

  const hideConfirmationModal = () => {
    setIsOpen(false);
  };

  const deleteSection = async () => {
    await deleteTemplateSection();
    hideConfirmationModal();
  };

  return (
    <>
      <IconButton
        label="DeleteTemplateSection"
        color="neutral"
        icon={iTrash}
        fill="minimal"
        ml={100}
        onClick={showConfirmationModal}
      />
      <ConfirmDeleteModal
        isOpen={isOpen}
        isLoading={loading}
        message="Are you sure you want to delete this section? All questions in this section will be deleted."
        onCancel={hideConfirmationModal}
        onConfirm={deleteSection}
      />
    </>
  );
};
