import React from 'react';

import { Box } from '@hover/blueprint';
import { useSelector } from 'react-redux';

import type {
  projectManagementProductionList_projectManagementProductionList_estimateGroup as EstimateGroup,
  projectManagementProductionList_projectManagementProductionList as ProjectManagementProductionList,
} from 'src/api/types/projectManagementProductionList';
import { Financials } from 'src/features/project/components/ProjectScope/ProjectDetails/Financials';
import { FinancialsTeaser } from 'src/features/project/components/ProjectScope/ProjectDetails/FinancialsTeaser';
import { Project } from 'src/features/project/components/ProjectScope/ProjectDetails/Project';
import { Property } from 'src/features/project/components/ProjectScope/ProjectDetails/Property';
import { getOrgSettings } from 'src/redux/selectors';

interface Props {
  estimateGroup: EstimateGroup;
  productionList: ProjectManagementProductionList;
}

export const ProjectDetails: React.FC<Props> = React.memo(
  ({ productionList, estimateGroup }) => {
    const showOrderingFlow = useSelector(getOrgSettings)?.showOrderingFlow;

    return (
      <Box justifyContent="space-between" flex="1">
        <Property estimateGroup={estimateGroup} />
        <Project productionList={productionList} />
        {showOrderingFlow ? (
          <FinancialsTeaser />
        ) : (
          <Financials productionList={productionList} />
        )}
      </Box>
    );
  },
);
