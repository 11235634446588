import { useCallback, useState } from 'react';

import { useMutation } from '@apollo/client';
import {
  Body,
  Box,
  Button,
  Heading,
  LoadingOverlay,
  Menu,
  MenuItem,
  MenuButton,
  MenuDivider,
} from '@hover/blueprint';
import { MenuList } from '@hover/blueprint/chakra';
import { remove, cloneDeep, isEmpty } from 'lodash';
import { useForm, FormProvider, FieldValues } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { estimationConfigTemplate_estimationConfigTemplate_templateGroups_lineItems as LineItem } from 'src/api/types/estimationConfigTemplate';
import { estimationConfigTemplateUpdate as EstimationConfigTemplateUpdateType } from 'src/api/types/estimationConfigTemplateUpdate';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from 'src/components/blueprint/Breadcrumb';
import {
  GET_ESTIMATION_TEMPLATE,
  ESTIMATION_CONFIG_TEMPLATE_UPDATE,
} from 'src/features/settings/api/queries/templates';
import { CancelEditModal } from 'src/features/settings/components/common/CancelEditModal';
import { CreateTemplateGroupModal } from 'src/features/settings/components/Templates/CreateTemplateGroupModal';
import type { TemplateType } from 'src/features/settings/components/Templates/TemplateEdit/types';
import { buildEstimationConfigTemplateUpdateInput } from 'src/features/settings/utils/templates';
import {
  useEffectOnMount,
  useLocalStorage,
  useToastEhi,
  ToastStatusEnum,
  useTracking,
} from 'src/hooks';
import { getUserTrackingProps } from 'src/redux/selectors';
import { EventNames } from 'src/types/actionTypes';

import { TemplateEditForm } from './TemplateEditForm';

type TemplateEditProps = {
  template: TemplateType;
};

type TemplateDefaultValues = {
  name: string;
  active: boolean;
  templateLineItems: string[];
};

export const TemplateEdit = ({ template }: TemplateEditProps) => {
  const toast = useToastEhi();
  const history = useHistory();

  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const cachedTemplatePath = `templates.'${template.id}'`;
  // first check if a cached template exists in local storage
  const [cachedTemplate] = useLocalStorage<TemplateType>(
    cachedTemplatePath,
    {} as TemplateType,
  );
  const [
    localTemplate,
    setLocalTemplate,
    isLocalTemplatePersisted,
    removeLocalTemplate,
  ] = useLocalStorage(cachedTemplatePath, template, {
    // if the cached template is older than the template returned from the query
    // then discard the cached version and use the new version
    forceReplace:
      cachedTemplate?.updatedAt?.localeCompare(template?.updatedAt) < 0,
  });

  useEffectOnMount(() => {
    typewriter.pageViewed({
      page_or_screen_name: `${
        EventNames.settings.template.page
      }-restoreEditsFromCache: ${!isEmpty(cachedTemplate)}`,
      ...commonTrackingProps,
    });
  });

  const defaultValues: TemplateDefaultValues = {
    name: localTemplate.name,
    active: localTemplate.active,
    templateLineItems: [],
  };
  const { getValues, setValue, watch, ...methods } = useForm({
    mode: 'onChange',
    shouldUnregister: false,
    defaultValues,
  });

  const lineItemsSelected = !!watch('templateLineItems').length;

  const onTemplateUpdateError = () => {
    toast({
      id: 'template-update-error-toast',
      description: 'Changes not saved. Try again',
      status: ToastStatusEnum.ERROR,
    });
  };

  const onTemplateUpdateCompleted = (
    data: EstimationConfigTemplateUpdateType,
  ) => {
    if (
      data.estimationConfigTemplateUpdate?.errors &&
      data.estimationConfigTemplateUpdate?.errors.length > 0
    ) {
      onTemplateUpdateError();
    } else {
      removeLocalTemplate();
      toast({
        id: 'template-update-complete-toast',
        description: 'Changes successfully saved',
        status: ToastStatusEnum.SUCCESS,
      });
    }
  };

  const [estimationConfigTemplateUpdate, { loading: templateUpdateLoading }] =
    useMutation<EstimationConfigTemplateUpdateType>(
      ESTIMATION_CONFIG_TEMPLATE_UPDATE,
      {
        onCompleted: onTemplateUpdateCompleted,
        refetchQueries: [
          {
            query: GET_ESTIMATION_TEMPLATE,
            variables: { id: localTemplate.id },
          },
        ],
        onError: onTemplateUpdateError,
      },
    );

  const onTemplateUpdate = () => {
    estimationConfigTemplateUpdate({
      variables: {
        id: localTemplate.id,
        templateAttributes:
          buildEstimationConfigTemplateUpdateInput(localTemplate),
      },
    });
    typewriter.buttonPressed({
      page_or_screen_name: EventNames.settings.template.page,
      primary_cta: true,
      button_text: 'Save',
      item_type: 'Template',
      backend_id_value: localTemplate.id.toString(),
      ...commonTrackingProps,
    });
  };

  const handleGroupAssign = useCallback(
    (selectedTemplateGroupIndex: number) => {
      const selectedLineItemIds = getValues('templateLineItems');
      const newTemplateGroups = cloneDeep(localTemplate.templateGroups);

      let lineItemsToMove: LineItem[] = [];

      // get and remove selected line items from template
      newTemplateGroups.forEach((templateGroup) => {
        if (templateGroup.lineItems) {
          const removedItems = remove(templateGroup.lineItems, (lineItem) =>
            selectedLineItemIds.includes(lineItem.id.toString()),
          );
          lineItemsToMove = [...lineItemsToMove, ...removedItems];
        }
      });

      // add selected line items to new group
      const selectedTemplateGroup =
        newTemplateGroups[selectedTemplateGroupIndex];

      // update selected template group line items
      if (selectedTemplateGroup?.lineItems) {
        selectedTemplateGroup.lineItems = [
          ...selectedTemplateGroup.lineItems,
          ...lineItemsToMove,
        ];
      }

      setLocalTemplate({ ...localTemplate, templateGroups: newTemplateGroups });
      setValue('templateLineItems', []);

      typewriter.buttonPressed({
        page_or_screen_name: EventNames.settings.template.page,
        primary_cta: false,
        button_text: selectedTemplateGroup.name,
        backend_id_value: selectedLineItemIds.join(','),
        button_location: 'Assign to group',
        ...commonTrackingProps,
      });
    },
    [getValues, localTemplate, setLocalTemplate, setValue, commonTrackingProps],
  );

  const redirectToTemplateList = () => {
    history.push('/workflows/template_management');
  };

  const handleCancel = () => {
    typewriter.buttonPressed({
      page_or_screen_name: EventNames.settings.template.page,
      primary_cta: false,
      button_text: 'Cancel',
      backend_id_value: localTemplate.id.toString(),
      item_type: 'Template',
      ...commonTrackingProps,
    });

    if (isLocalTemplatePersisted) {
      setIsCancelModalOpen(true);
    } else {
      redirectToTemplateList();
    }
  };

  const handleCancelEdit = () => {
    setIsCancelModalOpen(false);
  };

  const handleLeaveEdit = () => {
    removeLocalTemplate();
    redirectToTemplateList();
  };

  const [isCreateTemplateGroupModalOpen, setIsCreateTemplateGroupModalOpen] =
    useState<boolean>(false);

  const openTemplateGroupModal = () => {
    setIsCreateTemplateGroupModalOpen(true);
  };

  const closeTemplateGroupModal = () => {
    setIsCreateTemplateGroupModalOpen(false);
  };

  const createGroupAddSelectedLineItems = (data: FieldValues) => {
    const newTemplateGroups = cloneDeep(localTemplate.templateGroups);

    const selectedLineItemIds = getValues('templateLineItems');

    let lineItemsToMove: LineItem[] = [];

    // get and remove selected line items from template
    newTemplateGroups.forEach((templateGroup) => {
      if (templateGroup.lineItems) {
        const removedItems = remove(templateGroup.lineItems, (lineItem) =>
          selectedLineItemIds.includes(lineItem.id.toString()),
        );
        lineItemsToMove = [...lineItemsToMove, ...removedItems];
      }
    });

    const getMaxSortOrder = Math.max(
      ...newTemplateGroups
        .filter((group) => !group.defaultGroup)
        .map((group) => group.sortOrder),
      0,
    );

    setLocalTemplate({
      ...localTemplate,
      templateGroups: [
        ...newTemplateGroups,
        {
          id: '',
          createdAt: null,
          defaultGroup: false,
          description: data.description,
          lineItems: lineItemsToMove,
          name: data.name,
          sortOrder: getMaxSortOrder + 1,
        },
      ],
    });
    setValue('templateLineItems', []);

    closeTemplateGroupModal();
  };

  const handleMenuClicked = () => {
    typewriter.buttonPressed({
      page_or_screen_name: EventNames.settings.template.page,
      primary_cta: false,
      button_text: 'Assign item to group',
      ...commonTrackingProps,
    });
  };

  return (
    <>
      <LoadingOverlay isLoading={templateUpdateLoading} />
      <CreateTemplateGroupModal
        isOpen={isCreateTemplateGroupModalOpen}
        onClose={closeTemplateGroupModal}
        onSave={createGroupAddSelectedLineItems}
        currentGroup={null}
      />
      <Box
        flexDirection="column"
        maxWidth="900px"
        height="calc(100vh - 66px - 80px)" // header height is 66px; Settings.tsx paddingY={700}
      >
        <Breadcrumb>
          <BreadcrumbItem>
            <BreadcrumbLink
              trackEventName={EventNames.settings.templates.page}
              label="Templates"
              to="/workflows/template_management"
            />
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink isCurrentPage label={localTemplate.name} />
          </BreadcrumbItem>
        </Breadcrumb>
        <FormProvider
          getValues={getValues}
          setValue={setValue}
          watch={watch}
          {...methods}
        >
          <Box
            flexDirection="column"
            width={1}
            data-test-id="templateEdit"
            height={1}
          >
            <Box flexDirection="row" justifyContent="space-between" my={400}>
              <Box>
                <Heading size={500}>{localTemplate.name}</Heading>
              </Box>
              <Menu
                trigger={
                  <MenuButton
                    data-test-id="assignToGroupButton"
                    disabled={!lineItemsSelected}
                    onClick={handleMenuClicked}
                  >
                    Assign items to group
                  </MenuButton>
                }
              >
                <MenuList maxHeight="300px" sx={{ overflow: 'scroll' }}>
                  {localTemplate?.templateGroups.map(
                    (templateGroup, selectedTemplateGroupIndex) => (
                      <MenuItem
                        key={templateGroup.sortOrder}
                        onClick={() =>
                          handleGroupAssign(selectedTemplateGroupIndex)
                        }
                      >
                        {templateGroup.name}
                      </MenuItem>
                    ),
                  )}
                  <MenuDivider />
                  <MenuItem onClick={openTemplateGroupModal}>
                    + Create new group
                  </MenuItem>
                </MenuList>
              </Menu>
            </Box>
            <Box flexDirection="column" height={1} overflow="scroll">
              <TemplateEditForm
                template={localTemplate}
                setTemplate={setLocalTemplate}
              />
            </Box>
            <Box as="footer" paddingTop={400} alignItems="center" gap={400}>
              <Button
                isDisabled={!isLocalTemplatePersisted}
                onClick={onTemplateUpdate}
                data-test-id="templateEdit-Save"
              >
                Save
              </Button>
              <Button
                fill="outline"
                onClick={handleCancel}
                data-test-id="templateEdit-Cancel"
              >
                Cancel
              </Button>
              <Body
                as="span"
                data-test-id="templateEdit-lastSaved"
                color="neutral600"
                size={400}
              >
                {isLocalTemplatePersisted
                  ? 'Unsaved changes in template'
                  : `Last change made on ${new Date(
                      template.updatedAt,
                    ).toLocaleString()}`}
              </Body>
            </Box>
          </Box>
        </FormProvider>
      </Box>
      <CancelEditModal
        isOpen={isCancelModalOpen}
        onCancel={handleCancelEdit}
        onLeave={handleLeaveEdit}
        itemType="Template"
        itemId={localTemplate.id.toString()}
      />
    </>
  );
};
