import { uniqBy } from 'lodash';
import { createReducer } from 'typesafe-actions';

import { estimatorActions } from 'src/features/exteriorEstimator/redux/actions';
import { initialState } from 'src/features/exteriorEstimator/redux/initialState';

export const templatesReducer = createReducer(initialState)
  .handleAction(estimatorActions.getTemplatesEnd, (state, action) => {
    const templates = uniqBy(action.payload.templates, 'id');
    return {
      ...state,
      config: {
        ...state.config,
        templates,
      },
    };
  })
  .handleAction(estimatorActions.initializeTemplates, (state) => {
    return {
      ...state,
      config: {
        ...state.config,
        templates: [],
      },
    };
  })
  .handleAction(
    estimatorActions.initializeSelectedTemplates,
    (state, { payload: { templateIds } }) => {
      return {
        ...state,
        inputs: {
          ...state.inputs,
          originalSelectedTemplates: templateIds || [],
          selectedTemplates: templateIds || [],
        },
      };
    },
  )
  .handleAction(estimatorActions.toggleSelectedTemplate, (state, action) => {
    const selectedTemplatesClone = state.inputs.selectedTemplates
      ? [...state.inputs.selectedTemplates]
      : [];
    const { id } = action.payload;
    const index = selectedTemplatesClone.indexOf(id);
    if (index !== -1) {
      selectedTemplatesClone.splice(index, 1);
    } else {
      selectedTemplatesClone.push(id);
    }
    return {
      ...state,
      inputs: {
        ...state.inputs,
        selectedTemplates: selectedTemplatesClone,
      },
    };
  })
  .handleAction(estimatorActions.unselectTemplates, (state, action) => {
    const selectedTemplatesClone = state.inputs.selectedTemplates
      ? [...state.inputs.selectedTemplates]
      : [];
    const { ids } = action.payload;
    const newSelectedTemplates = selectedTemplatesClone.filter(
      (id) => !ids.includes(id),
    );
    return {
      ...state,
      inputs: {
        ...state.inputs,
        selectedTemplates: newSelectedTemplates,
      },
    };
  })
  .handleAction(estimatorActions.deselectAllTemplates, (state) => {
    return {
      ...state,
      inputs: {
        ...state.inputs,
        selectedTemplates: [],
      },
    };
  });
