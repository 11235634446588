import { Icon, Box, Body, Image, Link } from '@hover/blueprint';
import { hTriangleRuler, iBox, iInfo } from '@hover/icons';

import type { projectManagementProductionList_projectManagementProductionList_estimateGroup as EstimateGroup } from 'src/api/types/projectManagementProductionList';
import appSettings from 'src/appSettings';
import { RoofOutline } from 'src/components/RoofOutline';
import {
  ProjectDetailsPanel,
  SectionHeading,
  LinkBody,
} from 'src/features/project/components/ProjectScope/ProjectDetails/components';

interface Props {
  estimateGroup: EstimateGroup;
}

export const Property: React.FC<Props> = ({ estimateGroup }) => {
  const { job } = estimateGroup?.salesOpportunity;

  const jobImageUrl = job?.bestFrontImage?.imageSmallUrl;

  return (
    <ProjectDetailsPanel data-test-id="propertySection">
      <SectionHeading marginBottom={300}>Property</SectionHeading>
      <Body size={500} color="neutral700" mt={0} mb={400}>
        {`${job.locationLine1 ?? ''} ${job.locationLine2 ?? ''} ${
          job.locationCity ?? ''
        } ${job.locationRegion ?? ''} ${job.locationPostalCode ?? ''}`}
      </Body>
      <Box flexDirection="row" flex="1">
        <Box data-test-id="propertyImage" flex="0 0 auto" alignItems="start">
          {!jobImageUrl ? (
            <RoofOutline
              jobId={job?.id}
              version="top"
              maxWidth="168px"
              flexShrink={1}
            />
          ) : (
            <Image
              src={jobImageUrl}
              style={{ objectFit: 'contain' }}
              borderRadius="4px"
              alt="Property Image"
              flex="1"
            />
          )}
        </Box>
        <Box flex="1" justifyContent="center">
          <Box flexDirection="column" paddingLeft={300}>
            <Link
              size={200}
              data-test-id="measurements-link"
              href={`${appSettings.HYPERION_API_SERVER}/api/v2/jobs/${job?.id}/measurements.pdf`}
              target="_blank"
            >
              <Box paddingY={100}>
                <Icon icon={hTriangleRuler} />
                <LinkBody size={200}>Measurements</LinkBody>
              </Box>
            </Link>
            <Link
              size={200}
              data-test-id="3d-link"
              href={`${appSettings.HYPERION_API_SERVER}/3d/${job?.id}`}
              target="_blank"
            >
              <Box paddingY={100}>
                <Icon icon={iBox} />
                <LinkBody size={200}>3D</LinkBody>
              </Box>
            </Link>
            <Link
              size={200}
              data-test-id="details-link"
              href={`${appSettings.HYPERION_API_SERVER}/ui/#/property/${job?.id}`}
              target="_blank"
            >
              <Box paddingY={100}>
                <Icon icon={iInfo} />
                <LinkBody size={200}>Details</LinkBody>
              </Box>
            </Link>
          </Box>
        </Box>
      </Box>
    </ProjectDetailsPanel>
  );
};
