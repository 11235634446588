import { useEffect } from 'react';

import {
  Body,
  Editable,
  EditableInput,
  EditablePreview,
  Input,
  Td,
  Tooltip,
} from '@hover/blueprint';
import { get } from 'lodash';
import { useFormContext } from 'react-hook-form';

import { projectManagementProductionList_projectManagementProductionList_listItems as ListItem } from 'src/api/types/projectManagementProductionList';
import { FormattedNumberCellRenderer } from 'src/features/project/components/common/CellRenderers';
import { formattedCost } from 'src/features/project/util/utils';

import { useProjectScopeTracker } from '../../hooks/useProjectScopeTracker';

type ListItemUnitCostColumnProps = {
  listItem: ListItem;
  isDisabled: boolean;
  quantityUnit: string;
  enableInlineEditingV2: boolean;
  jobId: number;
  onUpdate: (inputLabel: string) => void;
};

export const ListItemUnitCostColumn = ({
  listItem,
  quantityUnit,
  jobId,
  onUpdate,
  enableInlineEditingV2,
}: ListItemUnitCostColumnProps) => {
  const errorMessage = 'Unit cost is invalid';
  const { trackInlineEditingInputPressed } = useProjectScopeTracker({ jobId });

  const {
    setValue,
    register,
    getValues,
    formState: { errors: formErrors },
  } = useFormContext();
  const formValue = getValues().unitCost;

  if (!enableInlineEditingV2) {
    <Td isNumeric>{formattedCost(listItem.unitCost)}</Td>;
  }

  return (
    <Td isNumeric>
      <Editable
        defaultValue={listItem.unitCost?.toString()}
        isPreviewFocusable
        selectAllOnFocus
        onSubmit={() => {
          onUpdate('Unit Cost');
        }}
        submitOnBlur={!get(formErrors, 'unitCost')} // Prevents submission of invalid value, when validation error.
        onCancel={(prev) => {
          // If input is canceled on blur due to validation error, then reset the previous value.
          setValue(
            'unitCost',
            parseFloat(prev), // convert display string back to number type.
            {
              shouldValidate: true,
              shouldDirty: true,
            },
          );
        }}
        size="tiny"
        display="inline-block"
        width="fit-content"
      >
        <Tooltip label="Click to edit" placement="top">
          <EditablePreview as={FormattedNumberCellRenderer} value={formValue} />
        </Tooltip>
        <Tooltip
          label={get(formErrors, 'unitCost.message')}
          placement="top"
          background="danger300"
        >
          <Input
            as={EditableInput}
            size="tiny"
            maxWidth="60px"
            minWidth="60px"
            // isRequired
            type="text"
            data-test-id="EditMaterialUnitCost"
            {...register('unitCost', {
              valueAsNumber: true,
              min: { value: 0, message: errorMessage },
              required: errorMessage,
              validate: {
                numberValidator: (unitCostValue) => {
                  return Number.isFinite(unitCostValue) && unitCostValue >= 0
                    ? true
                    : errorMessage;
                },
              },
            })}
            isInvalid={!!get(formErrors, 'unitCost')}
            onChange={() => {
              trackInlineEditingInputPressed('Unit Cost');
            }}
            onKeyDown={(event) => {
              const targetElement = event.target as HTMLInputElement;
              if (event.key === 'Enter' && targetElement?.value.length > 0) {
                targetElement.blur();
              }
            }}
          />
        </Tooltip>
      </Editable>
      <Body
        as="span"
        display="inline-block"
        size={400}
        minWidth="36px"
        width="36px"
        textAlign="left"
      >
        {quantityUnit}
      </Body>
    </Td>
  );
};
