import { Box, Link, Icon } from '@hover/blueprint';
import { iDownload, iExternalLink } from '@hover/icons';
import { isNil } from 'lodash';

import type { distributionOrderConfirmation_distributionOrder as DistributionOrder } from 'src/api/types/distributionOrderConfirmation';
import { buildPdfFileName } from 'src/features/project/util/utils';
import { downloadPdf } from 'src/lib/pdfDownload';

interface SubmittedOrderActionsProps {
  distributionOrder: DistributionOrder;
  jobId: string;
  reviewOrderColor: string;
}

export const SubmittedOrderActions = ({
  jobId,
  distributionOrder,
  reviewOrderColor,
}: SubmittedOrderActionsProps) => {
  const downloadOrderPDF = () => {
    if (isNil(distributionOrder.pdf)) {
      return;
    }

    const name = buildPdfFileName({
      jobId,
      filename: distributionOrder?.pdf?.filename,
    });

    downloadPdf({
      url: distributionOrder?.pdf?.url,
      name,
    });
  };

  return (
    <Box display="flex" flexDirection="row">
      {!isNil(distributionOrder.url) ? (
        <Box mt={300}>
          <Link
            target="_blank"
            href={distributionOrder.url}
            lineHeight={300}
            color={reviewOrderColor}
            display="flex"
          >
            <Icon icon={iExternalLink} size="small" color="primary600" />
            Review Order
          </Link>
        </Box>
      ) : null}

      {!isNil(distributionOrder?.pdf?.url) ? (
        // This link should act as a button
        <Box mt={300}>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link
            href="javascript: void(0);" // eslint-disable-line
            onClick={downloadOrderPDF}
            color="primary600"
            flexDirection="row"
            lineHeight={300}
            ml={3}
            display="flex"
          >
            <Icon icon={iDownload} />
            Download as PDF
          </Link>
        </Box>
      ) : null}
    </Box>
  );
};
