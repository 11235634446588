import { forEach } from 'lodash';

import {
  AugmentedFacade,
  AugmentedEdge,
  AugmentedPlainMeasurements,
  PlainMeasurements,
} from 'src/features/exteriorEstimator/types';
import { Measurements } from 'src/types/EstimationMeasurementTypes';

export interface WindowsForFacade {
  [key: string]: string[];
}

export interface EdgesForFacade {
  [facadeDisplayLabel: string]: AugmentedEdge[];
}

export interface FacadesForEdge {
  [edgeId: string]: AugmentedFacade[];
}

export interface EdgeTotals {
  // ie ridge_total, ridge_count
  [key: string]: number;
}

export interface EdgeTotalsByTypeForFacade {
  [facadeDisplayLabel: string]: EdgeTotals;
}

export interface EdgesByType {
  [edgeType: string]: AugmentedEdge[]; // edgeType should be an enum (rake, ridge, valley, etc)
}

export interface EdgesForFacadesByType {
  [facadeDisplayLabel: string]: EdgesByType;
}

export class PartialsMeasurementsMap {
  private rawMeasurements: PlainMeasurements | null = null;

  public estimationJson: Measurements;

  private augmentedMeasurements: AugmentedPlainMeasurements | null = null;

  /**
   * @example
   * edgesForFacade['RF-1']
   * // array of edges
   */
  public edgesForFacade: EdgesForFacade = {};

  /**
   * @example
   * facadesForEdge['E-1']
   * // array of facades
   */
  public facadesForEdge: FacadesForEdge = {};

  /**
   * @example
   * edgeTotalsForFacade['RF-1']
   * {
   *   rake_total: 348.0338102558,
   *   rake_count: 2,
   *   ridge_total: 468.8603444867,
   *   ridge_count: 1
   * }
   *
   */
  public edgeTotalsForFacade: EdgeTotalsByTypeForFacade = {};

  /**
   * @example
   * edgesForFacadesByType['RF-1']['rake']
   * [
   *   { type: 'rake', length: 5, ... },
   *   { type: 'rake', length: 10, ... },
   * ]
   */
  public edgesForFacadesByType: EdgesForFacadesByType = {};

  /**
   * @example
   * edgeTotals
   * {
   *   rake_total: 348.0338102558,
   *   rake_count: 2,
   *   ridge_total: 468.8603444867,
   *   ridge_count: 1
   * }
   */
  public edgeTotals: EdgeTotals = {};

  public windowsForFacade: WindowsForFacade = {};

  constructor(
    partialMeasurements: PlainMeasurements,
    estimationJson: Measurements,
  ) {
    this.rawMeasurements = partialMeasurements;
    this.estimationJson = estimationJson;
    this.augmentedMeasurements = this.augmentMeasurements();
    this.edgesForFacade = this.createEdgesForFacade();
    this.facadesForEdge = this.createFacadesForEdge();
    this.edgeTotalsForFacade = this.createEdgeTotalsForFacade();
    this.edgesForFacadesByType = this.createEdgesForFacadesByType();
    this.edgeTotals = this.createEdgeTotals();
    this.windowsForFacade = this.createWindowsForFacade();
  }

  private createWindowsForFacade() {
    const map: { [key: string]: string[] } = {};
    Object.values(this.estimationJson?.facades ?? {}).forEach((facesArray) => {
      facesArray.forEach((face) => {
        if (face.facade) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          map[face.facade] = face.openings?.labels?.filter(
            (label) => label?.split('-').shift() === 'W',
          );
        }
      });
    });

    return map;
  }

  private augmentMeasurements() {
    if (!this.rawMeasurements) return null;

    return {
      ...this.rawMeasurements,
      edges: this.augmentEdges(),
      facades: this.augmentFacadesWithEdgeDisplayLabels(),
    };
  }

  /**
   * adds `type` and `id` to edges
   */
  private augmentEdges() {
    const { edges, facades = {} } = { ...this.rawMeasurements };
    return Object.entries(edges ?? []).reduce<
      AugmentedPlainMeasurements['edges']
    >((_edges, [id, edge]) => {
      const result = { ..._edges };
      Object.values(facades).forEach((facade) => {
        // you can break out of a lodash forEach loop by returning false
        forEach(facade.edges, (_edge) => {
          if (_edge.id === Number(id)) {
            result[id] = {
              ..._edge,
              ...edge,
            };
            return false;
          }
          return true;
        });
      });
      return result;
    }, {});
  }

  /**
   * adds `display_label`, `length`, `points` to the `edge` objects in `facade.edges` array
   */
  private augmentFacadesWithEdgeDisplayLabels() {
    const { edges = {} } = { ...this.rawMeasurements };

    return Object.entries(this.rawMeasurements?.facades ?? []).reduce<
      AugmentedPlainMeasurements['facades']
    >((_map, [facadeId, facade]) => {
      const map = { ..._map };
      map[facadeId] = {
        ...facade,
        edges: facade.edges.map((edge, i) => ({
          ...facade.edges[i],
          ...edges[edge.id],
        })),
      };
      return map;
    }, {});
  }

  private createEdgesForFacade() {
    return Object.values(
      this.augmentedMeasurements?.facades ?? [],
    ).reduce<EdgesForFacade>((_edgesForFacade, facade) => {
      const edgesForFacade = { ..._edgesForFacade };
      edgesForFacade[facade.display_label] = facade.edges;
      return edgesForFacade;
    }, {});
  }

  private createFacadesForEdge() {
    return Object.values(
      this.augmentedMeasurements?.facades ?? [],
    ).reduce<FacadesForEdge>((_facadesForEdge, facade) => {
      const facadesForEdge = { ..._facadesForEdge };

      facade.edges.forEach((edge) => {
        const { display_label: edgeLabel } = edge;
        if (facadesForEdge[edgeLabel]) {
          facadesForEdge[edgeLabel].push(facade);
        } else {
          facadesForEdge[edgeLabel] = [facade];
        }
      });
      return facadesForEdge;
    }, {});
  }

  private createEdgeTotalsForFacade() {
    return Object.entries(
      this.edgesForFacade,
    ).reduce<EdgeTotalsByTypeForFacade>(
      (_edgeTotalsForFacade, [facadeDisplayLabel, edges]) => {
        const edgeTotalsForFacade = { ..._edgeTotalsForFacade };
        const edgeTotals = edges.reduce<EdgeTotals>(
          (_map, { type, length }) => {
            const map = { ..._map };
            const typeCountKey = `${type}_count`; // ie ridge_count
            const typeTotalKey = `${type}_total`; // ie ridge_total
            if (map[typeCountKey]) {
              map[typeTotalKey] += length;
              map[typeCountKey] += 1;
            } else {
              map[typeTotalKey] = length;
              map[typeCountKey] = 1;
            }
            return map;
          },
          {},
        );
        edgeTotalsForFacade[facadeDisplayLabel] = edgeTotals;
        return edgeTotalsForFacade;
      },
      {},
    );
  }

  private createEdgesForFacadesByType() {
    return Object.entries(this.edgesForFacade).reduce<EdgesForFacadesByType>(
      (_edgesForFacadesByType, [facadeDisplayLabel, edges]) => {
        const edgesForFacadesByType = { ..._edgesForFacadesByType };
        edgesForFacadesByType[facadeDisplayLabel] = edges.reduce<EdgesByType>(
          (_edgesByType, edge) => {
            const edgesByType = { ..._edgesByType };
            if (!edgesByType[edge.type]) {
              edgesByType[edge.type] = [edge];
            } else {
              edgesByType[edge.type].push(edge);
            }
            return edgesByType;
          },
          {},
        );
        return edgesForFacadesByType;
      },
      {},
    );
  }

  private createEdgeTotals() {
    return Object.values(
      this.augmentedMeasurements?.edges ?? [],
    ).reduce<EdgeTotals>((_map, { type, length }) => {
      const map = { ..._map };
      const typeCountKey = `${type}_count`; // ie ridge_count
      const typeTotalKey = `${type}_total`; // ie ridge_total
      if (map[typeCountKey]) {
        map[typeTotalKey] += length / 12;
        map[typeCountKey] += 1;
      } else {
        map[typeTotalKey] = length / 12;
        map[typeCountKey] = 1;
      }
      return map;
    }, {});
  }
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default PartialsMeasurementsMap;
