import { gql } from '@apollo/client';

export const distributorFields = gql`
  fragment DistributorFields on ProductCatalogDistributor {
    id
    authenticationConnectionStrategy
    capabilities(distributionApprovedBranchId: $branchId) {
      supportsDeliveryType
      supportsDeliveryDate
      supportsDeliveryMethod
      supportsDeliveryTime
      supportsDeliveryContact
      deliveryTypes {
        id
        deliveryType
        label
        sortOrder
      }
      deliveryMethods {
        id
        deliveryMethod
        label
        sortOrder
      }
      deliveryTimes {
        id
        deliveryTime
        label
        sortOrder
      }
      requiresOrderJobAccount
    }
    name
    displayName
    logo {
      url
    }
    url
    oauthAuthorizationUrl
  }
`;

export const distributorCapabilities = gql`
  fragment distributorCapabilities on ProductCatalogDistributorCapabilities {
    supportsDeliveryMethod
    supportsDeliveryDate
    supportsDeliveryTime
    supportsDeliveryType
    deliveryMethods {
      id
      label
      sortOrder
      deliveryMethod
    }
    deliveryTimes {
      id
      label
      sortOrder
      deliveryTime
    }
    deliveryTypes {
      id
      label
      sortOrder
      deliveryType
    }
    requiresOrderJobAccount
  }
`;

export const vendorBaseFields = gql`
  fragment VendorBaseFields on Vendor {
    id
    vendorName
    accountId
    branch {
      id
    }
    distributorId
    distributor {
      capabilities {
        ...distributorCapabilities
      }
      supportsShowingOrderCheckResults
      id
      identifier
      supportsProductCatalog
      supportsProductOrdering
      url
      logo {
        redirectUrl
      }
      displayName
      websiteDisplayName
    }
  }
  ${distributorCapabilities}
`;

export const listItemBaseFields = gql`
  fragment ListItemBaseFields on ListItem {
    id
    type
    name
    measurement
    wasteFactor
    quantity
    quantityUnits
    calculatedQuantity
    unitCost
    taxPercent
    pretaxCost
    isManualMeasurement
    externalProductId
    externalVariationId
    skuChangedByUser
    userSetCustomColor
    userSetCustomSku
    userSetCustomUnitCost
    sortOrder
    notes
    color
    createdAt
    tradeType
    sku
    vendor {
      ...VendorBaseFields
    }
  }
  ${vendorBaseFields}
`;

export const productionListBaseFields = gql`
  fragment ProductionListBaseFields on ProductionList {
    id
    contractPreDiscountPrice
    contractTotalCost
    contractTotalPrice
    materialPretaxCost
    materialSalesTax
    materialTotalCost
    laborTotalCost
    otherTotalCost
    profitAmount
    profitMargin
    totalCost
    totalPrice
    totalDiscount
    materialTotal
    laborTotal
    otherTotal
  }
`;

export const unitsQuery = gql`
  fragment UnitsQuery on ListItem {
    measurementUnits
    quantityUnits
  }
`;

const productionListItemsFields = gql`
  fragment ProductionListItemsFields on ListItem {
    calculatedQuantity
    color
    createdAt
    distributionOrders {
      id
      url
      purchaseOrderNumber
      state
    }
    externalVariationId
    id
    lineItemId
    measurement
    measurementUnits
    name
    pretaxCost
    productCatalogProductId
    product {
      id
      name
      variations(filterByOrg: true, orgId: $orgId) {
        id
        name
      }
    }
    quantity
    quantityUnits
    requiresProductVariationSelection
    sortOrder
    sku
    totalCost
    templateListItemGroupListItems {
      id
      name
    }
    tradeType
    type
    unitCost
    userSetCustomColor
    userSetCustomSku
    userSetCustomUnitCost
    wasteFactor
  }
`;

export const GET_PRODUCTION_LIST = gql`
  query projectManagementProductionList($orgId: ID!, $jobId: ID!) {
    projectManagementProductionList(orgId: $orgId, jobId: $jobId) {
      id
      enableVariationMatching
      contractPreDiscountPrice
      totalDiscount
      contractTotalPrice
      profitMargin
      profitAmount
      materialTotalCost
      laborTotalCost
      otherTotalCost
      totalCost
      estimateGroup {
        id
        totalCost
        materialTotalCost
        createdAt
        estimates {
          active
          discounts {
            id
          }
          template {
            name
            tradeType
          }
        }
        user {
          name
        }
        salesOpportunity {
          id
          job {
            id
            name
            locationLine1
            locationLine2
            locationPostalCode
            locationRegion
            locationCity
            locationCountry
            bestFrontImage {
              imageSmallUrl
            }
          }
        }
        proposalDocumentsCount
      }
      listItems {
        ...ProductionListItemsFields
      }
    }
  }
  ${productionListItemsFields}
`;

export const ORDER_CHECK_CREATE_AND_SUBMIT_TO_DISTRIBUTOR = gql`
  mutation projectManagementOrderCreateAndSubmitToDistributor(
    $orgId: ID!
    $orderAttributes: ProjectManagementOrderCreateInput!
  ) {
    projectManagementOrderCreateAndSubmitToDistributor(
      orgId: $orgId
      orderAttributes: $orderAttributes
    ) {
      errors {
        attribute
        errors
      }
      order {
        id
        distributionOrder {
          id
        }
      }
    }
  }
`;

export const ORDER_CHECK_CREATE = gql`
  mutation distributionOrderCheckCreate(
    $orgId: ID!
    $attributes: DistributionOrderCheckCreateInput!
  ) {
    distributionOrderCheckCreate(orgId: $orgId, attributes: $attributes) {
      errors {
        attribute
        errors
      }
      order {
        errors {
          attribute
          errors
        }
        id
      }
    }
  }
`;

export const ORDER_CHECK = gql`
  query distributionOrderCheck($id: ID!) {
    distributionOrderCheck(id: $id) {
      id
      orderSessionId
      checkComplete
      salesTax
      pretaxCost
      totalCost
      errors {
        attribute
        errors
      }
      submittable
      lineItems {
        checkResult
        errors {
          attribute
          errors
        }
        id
        measurement
        measurementUnits
        productId
        productName
        price
        quantity
        quantityUnits
        requiresProductVariationSelection
        sortOrder
        sku
        pretaxCost
        tradeType
        unitCost
        userSetCustomSku
        userSetCustomVariationName
        variationId
        variationName
        clientIdentifier
      }
    }
  }
`;

export const GET_DISTRIBUTORS = gql`
  query productCatalogConfigOrgDistributors($orgId: ID!) {
    productCatalogConfigOrgDistributors(orgId: $orgId) {
      distributor {
        id
        name
        displayName
        logo {
          url
        }
        url
        oauthAuthorizationUrl
      }
    }
  }
`;

// query productCatalogDistributorCapabilities($id: ID!, $branchId: ID) { // TODO: when `branchId` arg is available.
export const DISTRIBUTOR_CAPABILITIES = gql`
  query productCatalogDistributorCapabilities($id: ID!, $branchId: ID) {
    productCatalogDistributor(id: $id) {
      ...DistributorFields
    }
  }
  ${distributorFields}
`;

export const GET_DISTRIBUTION_APPROVED_BRANCHES = gql`
  query distributionApprovedBranchesQuery($distributorId: ID!, $orgId: ID!) {
    distributionApprovedBranches(distributorId: $distributorId, orgId: $orgId) {
      id
      name
      orgId
      jobAccounts {
        id
        displayName
      }
      default
      defaultJobAccount {
        id
        displayName
      }
      distributionBranchId
    }
  }
`;

export const GET_DISTRIBUTION_ORDER = gql`
  query distributionOrderQuery($id: ID!) {
    distributionOrder(id: $id) {
      id
      state
      failureReason
      notes
      purchaseOrderNumber
    }
  }
`;

export const DISTRIBUTION_ORDER_CONFIRMATION = gql`
  query distributionOrderConfirmation($id: ID!) {
    distributionOrder(id: $id) {
      failureReason
      id
      state
      url
      notes
      shippingAddressCity
      shippingAddressCountry
      shippingAddressLine1
      shippingAddressLine2
      shippingAddressPostalCode
      shippingAddressState
      purchaseOrderNumber
      pdf {
        filename
        url
      }
      distributor {
        identifier
        authenticationConnectionStrategy
        displayName
        logo {
          url
        }
      }
      branch {
        name
      }
      jobAccount {
        id
        displayName
      }
      orderContactName
      orderContactEmail
      orderContactPhoneNumber
      deliveryContactName
      deliveryContactEmail
      deliveryContactPhoneNumber
      externalIdentifier
      confirmationNumber
      createdAt
      deliveryDate
      deliveryTime
      deliveryType
      deliveryMethod
    }
  }
`;

export const GET_PRODUCT = gql`
  query productCatalogProductForEditItem(
    $id: ID!
    $orgId: ID!
    $distributorIds: [ID!]
    $filterVariationsByOrg: Boolean
  ) {
    productCatalogProduct(id: $id, distributorIds: $distributorIds) {
      id
      name
      variations(filterByOrg: $filterVariationsByOrg, orgId: $orgId) {
        id
        name
      }
    }
  }
`;

export const PRODUCT_CATALOG_DISTRIBUTOR_VARIATIONS_ATTRIBUTES = gql`
  query productCatalogDistributorVariationsAttributesForOrder(
    $distributorId: ID!
    $accountId: String
    $distributionBranchId: ID
    $distributionJobAccountId: ID!
    $lineItemsAttributes: [DistributionVariationAttributesInput!]!
    $orgId: ID!
  ) {
    productCatalogDistributorVariationsAttributes(
      distributorId: $distributorId
      accountId: $accountId
      lineItemsAttributes: $lineItemsAttributes
      distributionBranchId: $distributionBranchId
      distributionJobAccountId: $distributionJobAccountId
      orgId: $orgId
    ) {
      sku
      unitCost
      clientIdentifier
    }
  }
`;

export const ORDER_DOCUMENT_CREATE = gql`
  mutation projectOrderDocumentCreate(
    $orderDocumentAttributes: OrderDocumentCreate!
    $purchaseOrderNumber: String
    $externalIdentifier: String
    $listItemIds: [ID!]
  ) {
    projectManagementOrderDocumentCreate(
      orderDocumentAttributes: $orderDocumentAttributes
      purchaseOrderNumber: $purchaseOrderNumber
      externalIdentifier: $externalIdentifier
      listItemIds: $listItemIds
    ) {
      orderDocument {
        id
        state
        pdf {
          url
          filename
          contentType
          byteSize
          checksum
        }
      }
    }
  }
`;

export const PROJECT_ORDER_DOCUMENTS = gql`
  query projectOrderDocuments(
    $productionListId: ID!
    $orderDocumentIds: [ID!]
  ) {
    projectManagementOrderDocuments(
      productionListId: $productionListId
      orderDocumentIds: $orderDocumentIds
    ) {
      id
      createdAt
      state
      stateDeprecated
      order {
        purchaseOrderNumber
        vendor {
          vendorName
        }
      }
      pdf {
        url
        filename
        contentType
        byteSize
        checksum
      }
    }
  }
`;

export const TOGGLE_VARIATIONS_MATCHING = gql`
  mutation projectManagementProductionListUpdate(
    $productionListId: ID!
    $productionListAttributes: ProjectManagementProductionListUpdateInput!
  ) {
    projectManagementProductionListUpdate(
      productionListId: $productionListId
      productionListAttributes: $productionListAttributes
    ) {
      productionList {
        enableVariationMatching
      }
      errors {
        attribute
        errors
      }
    }
  }
`;

export const CREATE_LIST_ITEM = gql`
  mutation projectManagementListItemCreate(
    $productionListId: Int!
    $listItemAttributes: ListItemCreate!
  ) {
    projectManagementListItemCreate(
      productionListId: $productionListId
      listItemAttributes: $listItemAttributes
    ) {
      listItem {
        ...ListItemBaseFields
        productionList {
          ...ProductionListBaseFields
        }
      }
      matchedListItems {
        id
        name
      }
      errors {
        attribute
        errors
      }
    }
  }
  ${listItemBaseFields}
  ${productionListBaseFields}
`;

export const UPDATE_LIST_ITEM = gql`
  mutation projectManagementListItemUpdate(
    $listItemId: Int!
    $listItemAttributes: ListItemUpdate!
  ) {
    projectManagementListItemUpdate(
      listItemId: $listItemId
      listItemAttributes: $listItemAttributes
    ) {
      listItem {
        ...ListItemBaseFields
        ...UnitsQuery
        productionList {
          ...ProductionListBaseFields
        }
      }
      matchedListItems {
        id
        name
      }
      errors {
        attribute
        errors
      }
    }
  }
  ${listItemBaseFields}
  ${unitsQuery}
  ${productionListBaseFields}
  ${distributorCapabilities}
`;

export const UPDATE_PRODUCTION_LIST_ITEMS = gql`
  mutation projectManagementProductionListItemsUpdate(
    $productionListId: ID!
    $productionListAttributes: ProjectManagementProductionListUpdateInput!
    $orgId: ID!
  ) {
    projectManagementProductionListUpdate(
      productionListId: $productionListId
      productionListAttributes: $productionListAttributes
    ) {
      productionList {
        listItems {
          ...ProductionListItemsFields
        }
      }
      errors {
        attribute
        errors
      }
    }
  }
  ${productionListItemsFields}
`;

export const PRODUCT_SEARCH = gql`
  query productCatalogProductSearch(
    $searchTerm: String!
    $orgId: ID!
    $distributorId: ID
    $distributionBranchId: ID
    $filterByOrg: Boolean
    $addMatchesFromApprovedBranches: Boolean
  ) {
    productCatalogProductSearch(
      orgId: $orgId
      searchTerm: $searchTerm
      distributorId: $distributorId
      distributionBranchId: $distributionBranchId
      filterByOrg: $filterByOrg
      addMatchesFromApprovedBranches: $addMatchesFromApprovedBranches
    ) {
      id
      name
      variations(orgId: $orgId, filterByOrg: $filterByOrg) {
        id
        name
        externalVariationIdentifiers {
          distributorId
        }
      }
    }
  }
`;

export const ESTIMATION_QUANTITY_UNITS = gql`
  query estimationQuantityUnits {
    estimationQuantityUnits {
      value
      abbreviation
    }
  }
`;

export const CREATE_ORG_SETUP = gql`
  mutation projectEstimationConfigOrgSetupCreate($orgId: ID!) {
    estimationConfigOrgSetupCreate(orgId: $orgId) {
      orgSetup {
        id
        state
        targetOrgId
        failureReason
      }
      errors {
        attribute
        errors
      }
    }
  }
`;

export const GET_ORG_SETUP = gql`
  query projectEstimationConfigOrgSetup($orgSetupId: ID!) {
    estimationConfigOrgSetup(orgSetupId: $orgSetupId) {
      id
      state
      targetOrgId
      failureReason
    }
  }
`;

export const GET_TEMPLATES = gql`
  query projectEstimationConfigTemplates($orgId: ID!, $after: String) {
    estimationConfigTemplates(orgId: $orgId, after: $after, first: 20) {
      nodes {
        id
        name
        orgId
        description
        tradeType
        sortOrder
        showPricesInEstimation
      }
      edges {
        node {
          id
          name
          description
          orgId
          tradeType
          sortOrder
          showPricesInEstimation
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

export const CREATE_ESTIMATE_GROUP = gql`
  mutation projectEstimationEstimateGroupCreate(
    $estimateGroupAttributes: EstimationEstimateGroupCreate!
    $tradeTypeAttributes: [EstimationEstimateGroupCreateTradeTypeAttributeInput!]
    $templateIds: [ID!]
    $inputs: [EstimationEstimateGroupCreateInput!]!
    $markAsSoldAndActivate: Boolean = false
    $customLineItems: [EstimationEstimateGroupCreateCustomLineItem!]
  ) {
    estimationEstimateGroupCreate(
      estimateGroupAttributes: $estimateGroupAttributes
      tradeTypeAttributes: $tradeTypeAttributes
      templateIds: $templateIds
      inputs: $inputs
      markAsSoldAndActivate: $markAsSoldAndActivate
      customLineItems: $customLineItems
    ) {
      estimateGroup {
        id
        state
      }
      errors {
        attribute
        errors
      }
    }
  }
`;

export const GET_ESTIMATE_GROUP = gql`
  query projectEstimationEstimateGroup($id: ID!) {
    estimationEstimateGroup(id: $id) {
      id
      failureReason
      state
    }
  }
`;
