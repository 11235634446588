import * as Sentry from '@sentry/react';
import { isEmpty } from 'lodash';

import { HDFMeasurements } from 'src/features/exteriorEstimator/types/HdfMeasurements';
import { JobMeasurements } from 'src/features/exteriorEstimator/types/jobMeasurements';

export class HdfEstimatorComparator {
  public hdf: HDFMeasurements;

  public jobMeasurements: JobMeasurements;

  public orgId: string;

  public jobId: number;

  constructor(
    hdf: HDFMeasurements,
    jobMeasurements: JobMeasurements,
    orgId: string,
    jobId: number,
  ) {
    this.hdf = hdf;
    this.jobMeasurements = jobMeasurements;
    this.orgId = orgId;
    this.jobId = jobId;
  }

  public compare() {
    Object.entries(this.jobMeasurements).forEach(([key, value]) => {
      if (key === 'roof') {
        Object.entries(this.jobMeasurements.roof as any).forEach(
          ([roofKey, roofValue]) => {
            if (roofKey === 'pitch') {
              if (!this.jobMeasurements.roof) return;
              const hdfPitch = this.hdf.roof.pitch;
              const estimatorPitch = this.jobMeasurements.roof.pitch;
              this.comparePitch(hdfPitch, estimatorPitch);
            } else {
              this.compareFields(roofKey, roofValue as any);
            }
          },
        );
      } else if (key === 'sides') {
        if (!this.jobMeasurements.sides || isEmpty(this.jobMeasurements.sides))
          return;
        const hdfSides = this.hdf.sides;
        const estimationSides = this.jobMeasurements.sides;
        this.compareSides(hdfSides, estimationSides);
      } else {
        this.compareFields(key, value);
      }
    });
  }

  private compareSides(hdfSides: any, estimationSides: any) {
    // sides are equal if the totals for each elevation are the same
    const areSidesEqual =
      hdfSides?.front?.total === estimationSides?.front?.total &&
      hdfSides?.back?.total === estimationSides?.back?.total &&
      hdfSides?.left?.total === estimationSides?.left?.total &&
      hdfSides?.right?.total === estimationSides?.right?.total;
    if (!areSidesEqual) {
      console.log(
        'mismatching sides',
        JSON.stringify(hdfSides),
        JSON.stringify(estimationSides),
      );
      Sentry.captureMessage('HDF/Estimation_json mismatch: sides mismatch', {
        level: 'info',
        tags: { domain: 'hdf' },
        contexts: {
          estimator: {
            field: 'sides',
            estimationValue: JSON.stringify(estimationSides),
            hdfValue: JSON.stringify(hdfSides),
            orgId: this.orgId,
            jobId: this.jobId,
          },
        },
      });
    }
  }

  private comparePitch(hdfPitches: any, estimatorPitches: any) {
    // pitches are considered the same if they have the same facet, label, pitch, and area
    estimatorPitches.forEach((estimatorPitch: any) => {
      const derivedEstimatorPitch = {
        facet: estimatorPitch.facet,
        label: estimatorPitch.label,
        area: estimatorPitch.area,
        pitch: estimatorPitch.pitch.toString(),
      };
      const matchingHdfPitch = hdfPitches.find(
        (hdfPitch: any) =>
          hdfPitch.facet === derivedEstimatorPitch.facet &&
          hdfPitch.label === derivedEstimatorPitch.label &&
          hdfPitch.area === derivedEstimatorPitch.area &&
          hdfPitch.pitch === derivedEstimatorPitch.pitch,
      );
      if (!matchingHdfPitch) {
        console.log(
          'mismatching pitches',
          JSON.stringify(derivedEstimatorPitch),
          JSON.stringify(hdfPitches),
        );
        Sentry.captureMessage(
          'HDF/Estimation_json mismatch: roof pitches mismatch',
          {
            level: 'info',
            tags: { domain: 'hdf' },
            contexts: {
              estimator: {
                field: 'roof.pitch',
                estimationValue: JSON.stringify(estimatorPitches),
                hdfValue: JSON.stringify(hdfPitches),
                orgId: this.orgId,
                jobId: this.jobId,
              },
            },
          },
        );
      }
    });
  }

  private compareFields(key: string, value: number) {
    const hdfValue = (this.hdf as any)[key] ?? (this.hdf.roof as any)[key];
    if (hdfValue) {
      // percent difference is |expected - actual|/ expected. the estimation json number is expected, actual is the HDF version.
      const percentDifference = (Math.abs(value - hdfValue) / value) * 100;
      if (percentDifference > 5) {
        console.log(
          'mismatching key: ',
          key,
          `estimation_json gives: ${value}`,
          `HDF gives ${hdfValue}`,
          '%diff:',
          percentDifference,
        );
        Sentry.captureMessage('HDF/Estimation_json mismatch', {
          level: 'info',
          tags: { domain: 'hdf' },
          contexts: {
            estimator: {
              field: key,
              estimationValue: value,
              hdfValue,
              orgId: this.orgId,
              jobId: this.jobId,
            },
          },
        });
      }
    }
  }
}
