import {
  MEASUREMENT_NAME,
  ROOF_LINE_SEGMENT_ADJUSTMENT,
  ROOF_FACET_SELECTION,
  PARTIAL_SIDING_SELECTION,
  WASTE_FACTOR_NAME,
  SIDING_LINE_SEGMENT_ADJUSTMENT,
} from '../constants/questionCategories';
import { QuestionResponses, Input } from '../types';
import { getQuestionDefaultValue } from './questionsUtils';

export class QuestionResponsesGenerator {
  static getInitialResponses(questions: Input[]) {
    const emptyQuestionResponses: QuestionResponses = {};
    questions.forEach((question) => {
      if (question.inputCategory.name === ROOF_LINE_SEGMENT_ADJUSTMENT) return;
      if (question.inputCategory.name === SIDING_LINE_SEGMENT_ADJUSTMENT)
        return;
      emptyQuestionResponses[question.id] = getQuestionDefaultValue(question);
      if (question.inputCategory.name === MEASUREMENT_NAME) {
        // populate it with its value
        emptyQuestionResponses[question.id] = question.answer;
      }

      // set default values of roof_facet_selection to be selected
      if (question.inputCategory.name === ROOF_FACET_SELECTION) {
        emptyQuestionResponses[question.id] = true;
      }

      // set default values of siding_facet_selection to be selected
      if (question.inputCategory.name === PARTIAL_SIDING_SELECTION) {
        emptyQuestionResponses[question.id] = question.questionDefaultValue;
      }

      if (question.inputCategory.name === WASTE_FACTOR_NAME) {
        emptyQuestionResponses[question.id] = 10;
      }
    });
    return emptyQuestionResponses;
  }
}
