import { Alert, Box, Body, Label } from '@hover/blueprint';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { FormattedNumber } from 'src/components/FormattedNumber';
import { Question } from 'src/features/exteriorEstimator/components/EstimationTool/QuestionView/Question/Question';
import { UpdateAnswer } from 'src/features/exteriorEstimator/redux/actions/answerActions';
import { getPristineMeasurements } from 'src/features/exteriorEstimator/redux/sagas/selectors';
import { Input } from 'src/features/exteriorEstimator/types';
import { getPristineMeasurement } from 'src/features/exteriorEstimator/utils/measurementsUtils';
import { useTracking } from 'src/hooks';
import { getUserTrackingProps } from 'src/redux/selectors';
import { RootState } from 'src/types/reduxStore';
import { jobProps } from 'src/utils/trackingUtils';
import { UNITS_MAP } from 'src/utils/unitsMap';

const BoxWithBorderTop = styled(Box)`
  border-top: solid 1px ${({ theme }) => theme.colors.neutral300};
`;

export const mapStateToProps = (state: RootState) => ({
  pristineMeasurements: getPristineMeasurements(state),
  jobDetails: state.exteriorEstimator.job.jobDetails,
  userTrackingProps: getUserTrackingProps(state),
  category: state.exteriorEstimator.inputs.currentQuestionCategory,
});

interface OwnProps {
  questions: Input[];
  updateAnswer: (updateProps: UpdateAnswer) => void;
  measurementsWarning?: string;
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps;

export const LineSegmentsPageInputsComponent: React.FC<Props> = (props) => {
  const {
    questions,
    pristineMeasurements,
    jobDetails,
    userTrackingProps,
    category,
    updateAnswer,
    measurementsWarning,
  } = props;
  const { useTypewriter } = useTracking();
  const typewriter = useTypewriter();

  const updateAnswerCallback = (updateProps: UpdateAnswer) => {
    updateAnswer(updateProps);
    typewriter.textInput({
      input_value: updateProps.answer?.toString() || '',
      input_label: updateProps.questionId as string,
      page_or_screen_name: category,
      ...jobProps(jobDetails),
      ...userTrackingProps,
    });
  };

  return (
    <>
      {measurementsWarning && (
        <Alert data-test-id="PartialLineSegmentUpdatedWarning">
          {measurementsWarning}
        </Alert>
      )}
      {questions.map((question) => {
        const { name, measurementUnits } = question;
        const value = getPristineMeasurement(
          pristineMeasurements,
          Number(question.id),
        );

        return (
          <BoxWithBorderTop
            width={1}
            key={name}
            flexDirection="column"
            justifyContent="space-between"
            alignItems="flex-end"
            paddingY={300}
          >
            <Box width={1} justifyContent="space-between" alignItems="center">
              <Label
                size={400}
                marginRight={300}
                htmlFor={name ?? ''}
                color="secondary700"
              >
                {name}
              </Label>
              <Box alignItems="center">
                <Question
                  htmlId={name ?? ''}
                  question={question}
                  hideText
                  updateAnswer={updateAnswerCallback}
                />
                <Body
                  width={50}
                  size={300}
                  color="neutral700"
                  marginLeft={200}
                  marginTop={0}
                  marginBottom={0}
                >
                  {measurementUnits ? UNITS_MAP[measurementUnits] : ''}
                </Body>
              </Box>
            </Box>

            <Body size={300} color="neutral700" marginY={0} marginRight={400}>
              <FormattedNumber value={Number(value)} format="0,0[.]00" />*
            </Body>
          </BoxWithBorderTop>
        );
      })}
    </>
  );
};

export const LineSegmentsPageInputs = connect(mapStateToProps)(
  LineSegmentsPageInputsComponent,
);
