import { memo } from 'react';

import {
  Box,
  Link,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Checkbox,
} from '@hover/blueprint';
import { useDispatch } from 'react-redux';

import { LineItemTypeEnum, TradeTypeEnum } from 'src/api/graphql-global-types';
import { estimationQuantityUnits_estimationQuantityUnits as estimationQuantityUnit } from 'src/api/types/estimationQuantityUnits';
import type { productCatalogConfigOrgDistributors_productCatalogConfigOrgDistributors as Distributor } from 'src/api/types/productCatalogConfigOrgDistributors';
import { projectManagementProductionList_projectManagementProductionList_listItems as ListItem } from 'src/api/types/projectManagementProductionList';
import {
  toggleSelectAllTradeItems,
  removeLineItem,
} from 'src/features/project/redux/actions';
import { byDistributionOrdersSortOrderReverseCreatedAtComparator } from 'src/features/project/util/utils';
import { sentenceCase } from 'src/utils/Formatters';

import { ProductionListLineItemMemoized } from '../ProductionListLineItem';

type Props = {
  jobId: number;
  orgId: string;
  lineItemType: LineItemTypeEnum;
  tradeType: TradeTypeEnum;
  selectedItemsIds: string[] | null;
  items: ListItem[];
  productionListId: number;
  distributors?: Distributor[];
  enableInlineEditingV2: boolean;
  quantityUnits: estimationQuantityUnit[];
  setEditingListItem: (listItem: ListItem) => void;
};

export const ProjectScopeTradeList = ({
  orgId,
  jobId,
  lineItemType,
  tradeType,
  selectedItemsIds,
  items,
  productionListId,
  distributors,
  quantityUnits,
  enableInlineEditingV2,
  setEditingListItem,
}: Props) => {
  const dispatch = useDispatch();

  const handleToggleTypeSelection = () => {
    dispatch(toggleSelectAllTradeItems(lineItemType, tradeType, items));
  };

  const handleLineItemDelete = (listItem: ListItem) => {
    dispatch(removeLineItem(lineItemType, tradeType, listItem.id));
  };

  return (
    <Box>
      {/* Anchor link for direct access via section tags */}
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <Link
        id={`${lineItemType}.${tradeType}`}
        sx={{
          top: '-80px',
          display: 'block',
          position: 'relative',
          visibility: 'hidden',
        }}
      />
      <Box
        className="tradeTypeContainer"
        key={tradeType}
        _notFirst={{ marginTop: 300 }}
        flexDirection="column"
      >
        <Table size="small" sx={{ border: 'none' }}>
          <Tbody sx={{ border: 'none' }}>
            <Tr
              data-test-id={`ListItemTradeRow-${tradeType}`}
              verticalAlign="baseline"
            >
              <Td sx={{ width: '48px' }} paddingBottom={0}>
                <Checkbox
                  value={`${lineItemType}:${tradeType}`}
                  aria-label={tradeType}
                  id={tradeType}
                  key={tradeType}
                  data-test-id="checkboxMaterialItemGroup"
                  flex="0"
                  isChecked={
                    (selectedItemsIds &&
                      selectedItemsIds.length === items.length) ||
                    false
                  }
                  isIndeterminate={
                    (selectedItemsIds &&
                      selectedItemsIds.length > 0 &&
                      selectedItemsIds.length < items.length) ||
                    false
                  }
                  onChange={handleToggleTypeSelection}
                />
              </Td>
              <Td fontWeight="bold" fontSize={400} paddingBottom={0}>
                {sentenceCase(tradeType)}
              </Td>
            </Tr>
          </Tbody>
        </Table>
        <Table size="small" sx={{ tableLayout: 'fixed' }} marginBottom={500}>
          <Thead>
            <Tr>
              <Th sx={{ width: '80px' }} paddingLeft={700} />
              <Th>Name</Th>
              {lineItemType === LineItemTypeEnum.MATERIAL && (
                <Th>Color/Variation</Th>
              )}
              <Th sx={{ width: '12%' }}>Measurements</Th>
              <Th sx={{ width: '8%' }}>Quantity</Th>
              <Th isNumeric sx={{ width: '8%' }}>
                Unit Cost ($)
              </Th>
              <Th isNumeric sx={{ width: '8%' }}>
                Total ($)
              </Th>
              <Th sx={{ width: '12%' }} paddingLeft={700}>
                Orders
              </Th>
              <Th sx={{ width: '80px' }} />
            </Tr>
          </Thead>
          <Tbody data-test-id="ListItemTable" borderBottom={0}>
            {items
              .sort(byDistributionOrdersSortOrderReverseCreatedAtComparator)
              .map((listItem: ListItem) => (
                <ProductionListLineItemMemoized
                  key={listItem.id}
                  lineItemType={lineItemType}
                  tradeType={tradeType}
                  jobId={jobId}
                  orgId={orgId}
                  listItem={listItem}
                  selected={
                    (selectedItemsIds &&
                      selectedItemsIds.includes(listItem.id.toString())) ||
                    false
                  }
                  setEditingListItem={setEditingListItem}
                  distributors={distributors}
                  productionListId={productionListId}
                  enableInlineEditingV2={enableInlineEditingV2}
                  quantityUnits={quantityUnits}
                  onDelete={handleLineItemDelete}
                />
              ))}
          </Tbody>
        </Table>
      </Box>
    </Box>
  );
};

export const ProjectScopeTradeListMemoized = memo(ProjectScopeTradeList);
