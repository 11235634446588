import React, { useEffect } from 'react';

import {
  Editable,
  EditableInput,
  EditablePreview,
  Td,
  Tooltip,
} from '@hover/blueprint';
import { get, isNil } from 'lodash';
import { useFormContext } from 'react-hook-form';

import { projectManagementProductionList_projectManagementProductionList_listItems as ListItem } from 'src/api/types/projectManagementProductionList';
import { CUSTOM_VARIANT_COLOR } from 'src/features/project/components/ProjectScope/EditableVariationSelection';

import { VariationCellRenderer } from '../../../common/CellRenderers';
import { EditableVariationSelect } from './Inputs/EditableVariationSelect';

interface ListItemVariationColumnProps {
  listItem: ListItem;
  isDisabled: boolean;
  enableInlineEditingV2: boolean;
  isUpdating: boolean;
  jobId: number;
  onUpdate: (inputLabel: string) => void;
}

export const ListItemVariationColumn = ({
  listItem,
  isDisabled,
  isUpdating,
  enableInlineEditingV2,
  jobId,
  onUpdate,
}: ListItemVariationColumnProps) => {
  const {
    setValue,
    getValues,
    reset,
    formState: { errors: formErrors },
  } = useFormContext();
  const formValue = getValues().color || '';

  if (!enableInlineEditingV2) {
    return <VariationCellRenderer item={listItem} />;
  }

  return (
    <Td>
      <Editable
        isDisabled={isDisabled}
        selectAllOnFocus={
          listItem.externalVariationId === CUSTOM_VARIANT_COLOR.name
        }
        onSubmit={() => {
          onUpdate('Variation Name');
        }}
        onCancel={(prev) => {
          // If canceling from a custom variation with validation error, then reset.
          if (
            get(formErrors, 'color') &&
            (listItem.externalVariationId === CUSTOM_VARIANT_COLOR.name ||
              listItem.userSetCustomColor)
          ) {
            // Find prev variation selection.
            const foundVariation = (
              listItem as unknown as ListItem
            )?.product?.variations?.find(
              (variation) => variation.name === prev,
            );

            if (!isNil(foundVariation)) {
              // Reset to previous selected variation.
              // If input is canceled on blur due to validation error, then reset the previous value.
              const resetLineItem = {
                ...getValues(),
                ...{ externalVariationId: foundVariation.id, color: prev },
              };

              reset(resetLineItem, {
                keepValues: false,
                keepDefaultValues: false,
              });

              return;
            }

            // Reset to previous unselected variation.
            setValue('externalVariationId', '', {
              shouldValidate: true,
              shouldDirty: true,
            });

            setValue(
              'color',
              prev, // convert display string back to number type.
              {
                shouldValidate: true,
                shouldDirty: true,
              },
            );
          }
        }}
        size="tiny"
        value={formValue}
      >
        <Tooltip label="Click to edit" placement="top">
          <EditablePreview
            fontSize="inherit"
            display="flex"
            as={VariationCellRenderer}
            item={listItem as ListItem}
          />
        </Tooltip>
        <EditableInput
          as={EditableVariationSelect}
          paddingX={0}
          paddingY="11px"
          border="none"
          fontSize={200}
          listItem={listItem as ListItem}
          isUpdating={isUpdating}
          jobId={jobId}
        />
      </Editable>
    </Td>
  );
};
