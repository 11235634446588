import { useState, useEffect } from 'react';

import { useMutation } from '@apollo/client';
import { Box, Button, Modal, Field, TextInput } from '@hover/blueprint';

import {
  CREATE_TEMPLATE_SECTION,
  UPDATE_TEMPLATE_SECTION,
} from 'src/features/settings/api/mutations/inspectionChecklist';
import { GET_INSPECTION_CHECKLIST_TEMPLATE } from 'src/features/settings/api/queries/inspectionChecklist';

interface TemplateSectionCreateOrUpdateProps {
  name?: string;
  templateId: string;
  templateSectionId?: string;
}

export const TemplateSectionCreateOrUpdate: React.FC<
  TemplateSectionCreateOrUpdateProps
> = ({ name = '', templateId, templateSectionId, children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sectionName, setSectionName] = useState(name);

  useEffect(() => {
    if (isModalOpen) {
      setSectionName(name);
    }
  }, [isModalOpen, name]);

  const [createTemplateSection, { loading: createLoading }] = useMutation(
    CREATE_TEMPLATE_SECTION,
    {
      variables: {
        checklistTemplateSectionAttributes: {
          templateId,
          name: sectionName,
          sortOrder: 0,
        },
      },
      refetchQueries: [
        {
          query: GET_INSPECTION_CHECKLIST_TEMPLATE,
          variables: { id: templateId },
        },
      ],
    },
  );

  const [updateTemplateSection, { loading: updateLoading }] = useMutation(
    UPDATE_TEMPLATE_SECTION,
    {
      variables: {
        id: templateSectionId,
        checklistTemplateSectionAttributes: {
          name: sectionName,
          sortOrder: 0,
        },
      },
    },
  );

  const loading = createLoading || updateLoading;
  const hasError = sectionName === '';

  const onCancel = () => {
    setIsModalOpen(false);
    setSectionName('');
  };

  const createSection = async () => {
    if (loading) return;

    await (templateSectionId
      ? updateTemplateSection()
      : createTemplateSection());

    onCancel();
  };

  return (
    <Box>
      <Box onClick={() => setIsModalOpen(true)} width="100%">
        {children}
      </Box>
      <Modal
        isOpen={isModalOpen}
        onClose={onCancel}
        header={`${templateSectionId ? 'Edit' : 'New'} Section`}
        footer={
          <>
            <Button fill="outline" size="large" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              size="large"
              isLoading={loading}
              onClick={createSection}
              isDisabled={hasError}
            >
              {templateSectionId ? 'Edit' : 'Add'}
            </Button>
          </>
        }
      >
        <Field label="Section name (required)" name="section_text">
          <TextInput
            value={sectionName}
            onChange={({ target: { value } }) => setSectionName(value)}
            isRequired
          />
        </Field>
      </Modal>
    </Box>
  );
};
