import {
  Body,
  Box,
  Checkbox,
  Label,
  SimpleGrid,
  TileCheckbox,
  Tooltip,
  Icon,
} from '@hover/blueprint';
import { iInfo } from '@hover/icons';
import { lowerCase, toString } from 'lodash';
import { useSelector } from 'react-redux';

import { TradeTypeEnum } from 'src/api/graphql-global-types';
import {
  ROOF_FACET_SELECTION_3D,
  SIDING_FACET_SELECTION_3D,
} from 'src/features/exteriorEstimator/constants/questionCategories';
import { FACADE_TYPES } from 'src/features/exteriorEstimator/constants/sidingConstants';
import {
  getQuestionResponses,
  getRoofTotalQuestion,
  getJob,
  getPages,
  getSidingTotalQuestion,
  getRoofFacetInputs,
  getBrickTotalQuestion,
  getStoneTotalQuestion,
} from 'src/features/exteriorEstimator/redux/sagas/selectors';
import { Input } from 'src/features/exteriorEstimator/types';
import { QuestionResponses } from 'src/features/exteriorEstimator/types/Questions';
import { PartialRoofUtils } from 'src/features/exteriorEstimator/utils/PartialRoofUtils';
import PartialSidingUtils from 'src/features/exteriorEstimator/utils/PartialSidingUtils';
import { getUpdatedLineSegmentValues } from 'src/features/exteriorEstimator/utils/questionAnswerUtils';
import { useTracking } from 'src/hooks';
import {
  COMMERCE_BRICK_AND_STONE_ESTIMATION,
  isEnabled,
} from 'src/lib/FeatureFlag';
import { jobProps } from 'src/utils/trackingUtils';

type Props = {
  lineSegmentInputs: Input[];
  questions: Input[];
  trade: TradeTypeEnum.SIDING | TradeTypeEnum.ROOF;
  grouping: string;
  updateAnswers: (responses: QuestionResponses) => void;
};

export const TilesByType: React.FC<Props> = ({
  lineSegmentInputs,
  grouping,
  questions,
  updateAnswers,
  trade,
}) => {
  const questionResponses = useSelector(getQuestionResponses);
  const job = useSelector(getJob);

  const pages = useSelector(getPages);
  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();
  const sidingTotalQuestion = useSelector(getSidingTotalQuestion);
  const brickTotalQuestion = useSelector(getBrickTotalQuestion);
  const stoneTotalQuestion = useSelector(getStoneTotalQuestion);
  const roofTotalQuestion = useSelector(getRoofTotalQuestion);
  const roofFacetInputs = useSelector(getRoofFacetInputs);

  const { pitchQuestion } = PartialRoofUtils.getPitchQuestionsForPitch(
    pages,
    grouping,
  );

  const { plainMeasurements, fullMeasurements, jobDetails } = job;

  if (!plainMeasurements || !fullMeasurements) return null;

  const allChecked = questions.every(
    (question) => !!questionResponses[question.id],
  );

  const isIndeterminate =
    !allChecked &&
    questions.some((question) => !!questionResponses[question.id]);

  const handleSelectAllNone = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;

    const page =
      trade === TradeTypeEnum.ROOF
        ? ROOF_FACET_SELECTION_3D
        : SIDING_FACET_SELECTION_3D;

    typewriter.checkboxSelected({
      page_or_screen_name: page,
      backend_id_value: grouping,
      options: `Select All ${trade.toLowerCase()} Partials of Same Material Type`,
      selection: checked ? `all ${grouping}` : `none ${grouping}`,
      primary_cta: false,
      ...jobProps(jobDetails),
      ...commonTrackingProps,
    });

    let answers: QuestionResponses = {};

    // update all the facet selection input answers
    questions.forEach(({ id }) => {
      answers[id] = checked;
    });

    // update line segment input answers
    answers = {
      ...answers,
      ...getUpdatedLineSegmentValues({
        answers,
        type: trade,
        lineSegmentInputs,
        plainMeasurements,
        fullMeasurements,
        sidingTotalQuestion,
        questionResponses,
      }),
    };

    if (trade === 'ROOF') {
      // update roof total input answer
      if (roofTotalQuestion) {
        answers[roofTotalQuestion.id] = PartialRoofUtils.getRoofTotalArea(
          { ...questionResponses, ...answers },
          roofFacetInputs,
        );
      }

      if (pitchQuestion) {
        // update pitch total input answer
        const pitchTotalAnswer = PartialRoofUtils.getTotalAreaForPitch(
          roofFacetInputs,
          grouping,
          answers,
        );

        answers[pitchQuestion.id] = pitchTotalAnswer;
      }
    }
    if (isEnabled(COMMERCE_BRICK_AND_STONE_ESTIMATION)) {
      if (lowerCase(grouping) === 'brick' && brickTotalQuestion) {
        const brickTotalAnswer = checked
          ? PartialSidingUtils.getCombinedAreaForSelectedFacets(questions)
          : 0;
        answers[brickTotalQuestion.id] = brickTotalAnswer;
      }

      if (lowerCase(grouping) === 'stone' && stoneTotalQuestion) {
        const stoneTotalAnswer = checked
          ? PartialSidingUtils.getCombinedAreaForSelectedFacets(questions)
          : 0;
        answers[stoneTotalQuestion.id] = stoneTotalAnswer;
      }
    }

    // put in redux
    updateAnswers(answers);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id: facetLabel, checked } = e.target;
    const question = questions?.find((q) => toString(q.id) === facetLabel);
    const selectedFacets = questions.filter((q) => {
      return {
        ...questionResponses,
        [facetLabel]: checked,
      }[q.id];
    });

    // update line segment input answers
    const answers = getUpdatedLineSegmentValues({
      facetLabel,
      answer: !!checked,
      type: trade,
      lineSegmentInputs,
      plainMeasurements,
      fullMeasurements,
      sidingTotalQuestion,
      questionResponses,
    });

    if (trade === 'ROOF') {
      // update roof total input answer
      if (roofTotalQuestion) {
        const roofTotalAnswer = PartialRoofUtils.getRoofTotalArea(
          {
            ...questionResponses,
            ...answers,
            [facetLabel]: checked,
          },
          roofFacetInputs,
        );
        answers[roofTotalQuestion.id] = roofTotalAnswer;
      }
      // update pitch total input answer
      const pitchTotalAnswer = PartialRoofUtils.getTotalAreaForPitch(
        roofFacetInputs,
        grouping,
        {
          ...questionResponses,
          [facetLabel]: checked,
        },
      );
      answers[pitchQuestion?.id as number] = pitchTotalAnswer;
    }

    if (isEnabled(COMMERCE_BRICK_AND_STONE_ESTIMATION)) {
      if (lowerCase(grouping) === 'brick' && brickTotalQuestion) {
        const brickTotalAnswer =
          PartialSidingUtils.getCombinedAreaForSelectedFacets(selectedFacets);
        answers[brickTotalQuestion.id] = brickTotalAnswer;
      }

      if (lowerCase(grouping) === 'stone' && stoneTotalQuestion) {
        const stoneTotalAnswer =
          PartialSidingUtils.getCombinedAreaForSelectedFacets(selectedFacets);
        answers[stoneTotalQuestion.id] = stoneTotalAnswer;
      }
    }

    // put in redux
    updateAnswers({ ...answers, [facetLabel]: checked });

    typewriter.checkboxSelected({
      page_or_screen_name: {
        [TradeTypeEnum.SIDING]: SIDING_FACET_SELECTION_3D,
        [TradeTypeEnum.ROOF]: ROOF_FACET_SELECTION_3D,
      }[trade],
      options: `Select Individual ${
        {
          [TradeTypeEnum.SIDING]: 'Siding',
          [TradeTypeEnum.ROOF]: 'Roof',
        }[trade]
      } Partial`,
      selection: `${String(question?.name)} ${
        checked ? 'selected' : 'unselected'
      }`,
      primary_cta: false,
      ...jobProps(jobDetails),
      ...commonTrackingProps,
    });
  };

  return (
    <Box key={grouping} flexDirection="column" flex={1} width={1} as="section">
      <Box justifyContent="space-between">
        <Box alignItems="center">
          <Body>
            {
              {
                [TradeTypeEnum.SIDING]: grouping,
                [TradeTypeEnum.ROOF]: `Pitch ${grouping}/12`,
              }[trade]
            }
          </Body>
          {(grouping === FACADE_TYPES.UN || grouping === FACADE_TYPES.SI) && (
            <Tooltip
              backgroundColor="primary.900"
              hasArrow
              placement="top-start"
              mb={1}
              label={
                <Box
                  color="neutral.0"
                  style={{ fontSize: '0.875rem', padding: '16px 8px' }}
                >
                  {
                    {
                      [FACADE_TYPES.UN]: (
                        <>
                          Unknown surfaces that are touching siding have been
                          selected by default. <br />
                          <br />
                          Unknown surfaces are areas HOVER can&apos;t identify
                          because we don&apos;t have enough information.
                        </>
                      ),
                      [FACADE_TYPES.SI]: (
                        <>
                          + siding square feet
                          <br />+ siding trim square feet
                          <br />+ siding openings trim square feet
                          <br />+ openings square feet for openings with an area
                          of less than 33 square feet
                          <br />
                        </>
                      ),
                    }[grouping]
                  }
                </Box>
              }
            >
              <Icon icon={iInfo} marginLeft={200} bottom="2px" />
            </Tooltip>
          )}
        </Box>
        <Label box flexDirection="row" alignItems="center">
          <Checkbox
            id={grouping}
            isChecked={allChecked}
            isIndeterminate={isIndeterminate}
            onChange={handleSelectAllNone}
            marginRight={300}
          />
          <Box>Select all</Box>
        </Label>
      </Box>
      <SimpleGrid minChildWidth="164px" width={1} gap={400} role="group">
        {questions?.map((question) => (
          <TileCheckbox
            id={toString(question.id)}
            aria-label={question.name ?? ''}
            isChecked={!!questionResponses[question.id]}
            key={question.id}
            onChange={handleChange}
            description={
              {
                [TradeTypeEnum.SIDING]: `${question.combinedArea} ft\u00B2`,
                [TradeTypeEnum.ROOF]: `${question.area} ft\u00B2`,
              }[trade]
            }
          >
            {question.name}
          </TileCheckbox>
        ))}
      </SimpleGrid>
    </Box>
  );
};
