/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ACLTemplateOptions {
  ADMIN = 'ADMIN',
  JOB_MANAGER = 'JOB_MANAGER',
  PERSONAL = 'PERSONAL',
  PRO_PLUS = 'PRO_PLUS',
}

export enum DistributionAuthenticationConnectionStrategyEnum {
  CUSTOMER_AUTHORIZATION_CODE = 'CUSTOMER_AUTHORIZATION_CODE',
  OAUTH = 'OAUTH',
}

export enum DistributionDeliveryMethodEnum {
  BRANCH_PICK_UP = 'BRANCH_PICK_UP',
  DELIVER_TO_ADDRESS = 'DELIVER_TO_ADDRESS',
  ON_HOLD = 'ON_HOLD',
}

export enum DistributionDeliveryTimeEnum {
  AFTERNOON = 'AFTERNOON',
  ANYTIME = 'ANYTIME',
  FIRST_STOP = 'FIRST_STOP',
  MORNING = 'MORNING',
  SPECIAL_REQUEST = 'SPECIAL_REQUEST',
}

export enum DistributionDeliveryTypeEnum {
  GROUND_DROP = 'GROUND_DROP',
  ROOF_EDGE = 'ROOF_EDGE',
  ROOF_LOAD = 'ROOF_LOAD',
}

export enum DistributionOrderCheckLineItemCheckResultEnum {
  ERROR = 'ERROR',
  FAILURE = 'FAILURE',
  SUCCESS = 'SUCCESS',
}

export enum DistributionOrderStateEnum {
  canceled = 'canceled',
  check_failed = 'check_failed',
  check_success = 'check_success',
  checking = 'checking',
  created = 'created',
  failed = 'failed',
  pending_submitted = 'pending_submitted',
  queued = 'queued',
  submitted = 'submitted',
}

export enum EstimationConfigInputInputTypeEnum {
  BOOLEAN = 'BOOLEAN',
  NUMBER = 'NUMBER',
  SELECT = 'SELECT',
  STRING = 'STRING',
}

export enum EstimationConfigInputMeasurementUnitEnum {
  CUBIC_FEET = 'CUBIC_FEET',
  EACH = 'EACH',
  FORMULA_UNITS = 'FORMULA_UNITS',
  HOURS = 'HOURS',
  LINEAR_FEET = 'LINEAR_FEET',
  LINEAR_INCHES = 'LINEAR_INCHES',
  PIECES = 'PIECES',
  SELLING_UNITS = 'SELLING_UNITS',
  SQUARES = 'SQUARES',
  SQUARE_FEET = 'SQUARE_FEET',
}

export enum EstimationDiscountDiscountTypeEnum {
  DOLLARS = 'DOLLARS',
  PERCENTAGE = 'PERCENTAGE',
}

export enum EstimationEstimateGroupStateEnum {
  COMPLETE = 'COMPLETE',
  CREATED = 'CREATED',
  FAILED = 'FAILED',
  GENERATING = 'GENERATING',
  QUEUED = 'QUEUED',
}

export enum FeatureAccessIdentifier {
  ANALYTICS_ORG = 'ANALYTICS_ORG',
  ANALYTICS_PARTNER = 'ANALYTICS_PARTNER',
  BLUEPRINTS = 'BLUEPRINTS',
  COMPLETE_WITH_PAYMENT_EMAIL_WITH_PDF_RECEIPT = 'COMPLETE_WITH_PAYMENT_EMAIL_WITH_PDF_RECEIPT',
  DESIGN_VIEW_MEASUREMENTS_TOOL = 'DESIGN_VIEW_MEASUREMENTS_TOOL',
  EHI_PROJECT_MANAGEMENT_PRODUCT = 'EHI_PROJECT_MANAGEMENT_PRODUCT',
  EMAIL_SEND_RECEIPT = 'EMAIL_SEND_RECEIPT',
  EMAIL_SHOW_RECEIPT_AGGREGATE_DISCOUNTS_AND_BASE_PRICE_SUBSIDY = 'EMAIL_SHOW_RECEIPT_AGGREGATE_DISCOUNTS_AND_BASE_PRICE_SUBSIDY',
  EMAIL_SHOW_RECEIPT_BASE_PRICE_SUBSIDY = 'EMAIL_SHOW_RECEIPT_BASE_PRICE_SUBSIDY',
  EXPEDITE_COMPLETE_JOBS_AUTOMATICALLY = 'EXPEDITE_COMPLETE_JOBS_AUTOMATICALLY',
  EXPEDITE_ROOF_ONLY_JOBS_AUTOMATICALLY = 'EXPEDITE_ROOF_ONLY_JOBS_AUTOMATICALLY',
  EXPORT_ADVANCED_JOB = 'EXPORT_ADVANCED_JOB',
  EXPORT_ESX = 'EXPORT_ESX',
  GALLERY_AND_SCREENSHOT_MODE = 'GALLERY_AND_SCREENSHOT_MODE',
  GUTTERS_AND_DOWNSPOUTS = 'GUTTERS_AND_DOWNSPOUTS',
  HI_DEF_RENDERING = 'HI_DEF_RENDERING',
  HOVER_NOW = 'HOVER_NOW',
  INTEGRATIONS = 'INTEGRATIONS',
  LEAD_AUTO_ASSIGN = 'LEAD_AUTO_ASSIGN',
  LEAD_HOMEOWNER_CAPTURE = 'LEAD_HOMEOWNER_CAPTURE',
  LOAN_CALL_TO_ACTION = 'LOAN_CALL_TO_ACTION',
  MATERIAL_ORDERING = 'MATERIAL_ORDERING',
  MATERIAL_ORDERING_BEACON = 'MATERIAL_ORDERING_BEACON',
  MEASUREMENTS_CO_BRAND = 'MEASUREMENTS_CO_BRAND',
  MEASUREMENTS_FILES_ACCESS = 'MEASUREMENTS_FILES_ACCESS',
  ON_DEMAND_EXPEDITED_DELIVERABLES = 'ON_DEMAND_EXPEDITED_DELIVERABLES',
  ORG_JOBS_DAILY_ZIP_EXPORT = 'ORG_JOBS_DAILY_ZIP_EXPORT',
  OUT_OF_THE_BOX_MATERIAL_LISTS = 'OUT_OF_THE_BOX_MATERIAL_LISTS',
  PROSPECT = 'PROSPECT',
  SALES_ESTIMATION = 'SALES_ESTIMATION',
  SEQUENTIAL_CAPTURE = 'SEQUENTIAL_CAPTURE',
  SINGLE_SIGN_ON = 'SINGLE_SIGN_ON',
  TEAM_MANAGEMENT_ADVANCED = 'TEAM_MANAGEMENT_ADVANCED',
  TEAM_MANAGEMENT_BASIC = 'TEAM_MANAGEMENT_BASIC',
  UPGRADE_TO_PRO = 'UPGRADE_TO_PRO',
}

export enum InspectionChecklistQuestionSupportsImageUploadsEnum {
  NO = 'NO',
  OPTIONAL = 'OPTIONAL',
}

export enum InspectionConfigChecklistQuestionTypeEnum {
  BOOLEAN = 'BOOLEAN',
  NUMBER = 'NUMBER',
  SELECT = 'SELECT',
  STRING = 'STRING',
}

export enum InspectionConfigChecklistTemplateStateEnum {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
  INACTIVE = 'INACTIVE',
}

export enum JobModelStates {
  CANCELLED = 'CANCELLED',
  COMPLETE = 'COMPLETE',
  FAILED = 'FAILED',
  PAYING = 'PAYING',
  PROCESSING = 'PROCESSING',
  PROCESSING_UPLOAD = 'PROCESSING_UPLOAD',
  PROCESSING_UPLOAD_FOR_IMPROVEMENTS = 'PROCESSING_UPLOAD_FOR_IMPROVEMENTS',
  REQUESTING_CORRECTIONS = 'REQUESTING_CORRECTIONS',
  REQUESTING_IMPROVEMENTS = 'REQUESTING_IMPROVEMENTS',
  RETRIEVING = 'RETRIEVING',
  SUBMITTING = 'SUBMITTING',
  UPLOADING = 'UPLOADING',
  WAITING_APPROVAL = 'WAITING_APPROVAL',
  WAITLISTED = 'WAITLISTED',
  WORKING = 'WORKING',
}

export enum LineItemTypeEnum {
  LABOR = 'LABOR',
  MATERIAL = 'MATERIAL',
  OTHER = 'OTHER',
}

export enum MeasurementUnit {
  CUBIC_FEET = 'CUBIC_FEET',
  LINEAR_FEET = 'LINEAR_FEET',
  SQUARES = 'SQUARES',
  SQUARE_FEET = 'SQUARE_FEET',
}

export enum ProductCatalogDistributorIdentifierEnum {
  ABC = 'ABC',
  BEACON = 'BEACON',
  SRS = 'SRS',
  WIMSATT = 'WIMSATT',
}

export enum ProductCatalogExternalVariationIdentifierKindEnum {
  DISTRIBUTOR_JSON = 'DISTRIBUTOR_JSON',
  MANUFACTURER_PRODUCT_ID = 'MANUFACTURER_PRODUCT_ID',
}

export enum ProjectManagementConfigProposalTemplatePageTypeEnum {
  HTML = 'HTML',
  LIST_ITEMS = 'LIST_ITEMS',
}

export enum ProjectManagementProposalDocumentStateEnum {
  COMPLETE = 'COMPLETE',
  FAILED = 'FAILED',
  GENERATING = 'GENERATING',
  QUEUED = 'QUEUED',
  REQUESTED = 'REQUESTED',
}

export enum ProjectManagementSignatureRequestRequestTypeEnum {
  EMAIL = 'EMAIL',
  EMBED = 'EMBED',
}

export enum ProjectManagementSignatureRequestStateEnum {
  CANCELLED = 'CANCELLED',
  COMPLETE = 'COMPLETE',
  CREATED = 'CREATED',
  DECLINED = 'DECLINED',
  FAILED = 'FAILED',
  PARTIALLY_SIGNED = 'PARTIALLY_SIGNED',
  QUEUED = 'QUEUED',
  REQUESTED = 'REQUESTED',
  SIGNED = 'SIGNED',
}

export enum QuantityUnit {
  BAGS = 'BAGS',
  BOXES = 'BOXES',
  BUNDLES = 'BUNDLES',
  CANS = 'CANS',
  CARTONS = 'CARTONS',
  CUBIC_FEET = 'CUBIC_FEET',
  EACH = 'EACH',
  GALLONS = 'GALLONS',
  HOURS = 'HOURS',
  LINEAR_FEET = 'LINEAR_FEET',
  PACKAGES = 'PACKAGES',
  PAILS = 'PAILS',
  PAIRS = 'PAIRS',
  PIECES = 'PIECES',
  POUNDS = 'POUNDS',
  ROLLS = 'ROLLS',
  SHEETS = 'SHEETS',
  SQUARES = 'SQUARES',
  SQUARE_FEET = 'SQUARE_FEET',
  TUBES = 'TUBES',
}

export enum QuantityUnitsEnum {
  BAGS = 'BAGS',
  BOXES = 'BOXES',
  BUNDLES = 'BUNDLES',
  CANS = 'CANS',
  CARTONS = 'CARTONS',
  CUBIC_FEET = 'CUBIC_FEET',
  EACH = 'EACH',
  GALLONS = 'GALLONS',
  HOURS = 'HOURS',
  LINEAR_FEET = 'LINEAR_FEET',
  PACKAGES = 'PACKAGES',
  PAILS = 'PAILS',
  PAIRS = 'PAIRS',
  PIECES = 'PIECES',
  POUNDS = 'POUNDS',
  ROLLS = 'ROLLS',
  SHEETS = 'SHEETS',
  SQUARES = 'SQUARES',
  SQUARE_FEET = 'SQUARE_FEET',
  TUBES = 'TUBES',
}

export enum RemoteService {
  TINY_MCE = 'TINY_MCE',
}

export enum SalesPresentationPresentationStateEnum {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
  INACTIVE = 'INACTIVE',
}

export enum TradeTypeCategoryEnum {
  GENERIC = 'GENERIC',
  ROOF = 'ROOF',
  SIDING = 'SIDING',
}

export enum TradeTypeEnum {
  ACCENT_SIDING = 'ACCENT_SIDING',
  ADJUSTER_ROOF = 'ADJUSTER_ROOF',
  ALL = 'ALL',
  CLADDING = 'CLADDING',
  CLADDING_2 = 'CLADDING_2',
  DECKING = 'DECKING',
  DEDUCTIBILITY = 'DEDUCTIBILITY',
  DEDUCTIBLE = 'DEDUCTIBLE',
  DOOR = 'DOOR',
  ENGINEERED_WOOD_SIDING = 'ENGINEERED_WOOD_SIDING',
  FASCIA = 'FASCIA',
  FIBER_CEMENT_SIDING = 'FIBER_CEMENT_SIDING',
  GUTTERS = 'GUTTERS',
  HIP_AND_RIDGE = 'HIP_AND_RIDGE',
  ICE_AND_WATER = 'ICE_AND_WATER',
  INSULATION = 'INSULATION',
  INSURANCE_ROOF = 'INSURANCE_ROOF',
  METAL_ROOF = 'METAL_ROOF',
  PACKAGES = 'PACKAGES',
  PAINTING = 'PAINTING',
  RAILING = 'RAILING',
  RETAIL_ROOF = 'RETAIL_ROOF',
  RETAIL_SIDING = 'RETAIL_SIDING',
  ROOF = 'ROOF',
  ROOF_SYSTEMS = 'ROOF_SYSTEMS',
  SHUTTERS = 'SHUTTERS',
  SIDING = 'SIDING',
  SKYLIGHTS = 'SKYLIGHTS',
  SOFFIT = 'SOFFIT',
  TILE_ROOFING = 'TILE_ROOFING',
  TILE_ROOFING_R_AND_R = 'TILE_ROOFING_R_AND_R',
  TRIM = 'TRIM',
  UNDERLAYMENT = 'UNDERLAYMENT',
  UPGRADES = 'UPGRADES',
  VENTILATION = 'VENTILATION',
  VINYL_SIDING = 'VINYL_SIDING',
  WARRANTIES = 'WARRANTIES',
  WINDOWS = 'WINDOWS',
}

/**
 * Arguments for creating a DistributionOrderCheck
 */
export interface DistributionOrderCheckCreateInput {
  distributionApprovedBranchId?: string | null;
  distributionJobAccountId?: string | null;
  distributorId: string;
  lineItemsAttributes: DistributionOrderCheckLineItemCreateInput[];
  orderSessionId?: string | null;
}

/**
 * Arguments for creating a Distribution Order Check Line Item.
 */
export interface DistributionOrderCheckLineItemCreateInput {
  clientIdentifier?: string | null;
  measurement?: number | null;
  measurementUnits?: string | null;
  productId?: string | null;
  productName?: string | null;
  quantity?: number | null;
  quantityUnits?: string | null;
  sku?: string | null;
  sortOrder?: number | null;
  tradeType?: string | null;
  type?: string | null;
  unitCost?: number | null;
  userSetCustomSku?: boolean | null;
  userSetCustomVariationName?: boolean | null;
  variationId?: string | null;
  variationName?: string | null;
}

/**
 * Arguments for querying for a Variation or Product
 */
export interface DistributionVariationAttributesInput {
  clientIdentifier?: string | null;
  productName?: string | null;
  quantityUnits?: string | null;
  sku?: string | null;
  userSetCustomSku?: boolean | null;
  variationId?: string | null;
  variationName?: string | null;
}

/**
 * Arguments for updating an Estimate
 */
export interface EstimateUpdate {
  active?: boolean | null;
}

/**
 * Arguments for updating an EstimationConfig LineItem
 */
export interface EstimationConfigLineItemUpdateInput {
  allowVariationSelection?: boolean | null;
  margin?: number | null;
  name?: string | null;
  netCoveragePerSellingUnit?: number | null;
  productCatalogProductId?: string | null;
  quantityUnits?: QuantityUnitsEnum | null;
  salesTax?: number | null;
  type?: LineItemTypeEnum | null;
  unitCost?: number | null;
  vendorId?: number | null;
}

/**
 * Arguments for creating an EstimationConfig TemplateGroup
 */
export interface EstimationConfigTemplateGroupCreateInput {
  description?: string | null;
  id?: string | null;
  lineItemIds: string[];
  name?: string | null;
  sortOrder?: number | null;
}

/**
 * Arguments for updating an EstimationConfig Template
 */
export interface EstimationConfigTemplateUpdateInput {
  active?: boolean | null;
  name?: string | null;
  templateGroupsAttributes?: EstimationConfigTemplateGroupCreateInput[] | null;
}

/**
 * Arguments for creating a Discount for an Estimate
 */
export interface EstimationDiscountCreate {
  discountType: EstimationDiscountDiscountTypeEnum;
  estimationEstimateId: string;
  name: string;
  value: number;
}

/**
 * Arguments for updating a Discount for an Estimate
 */
export interface EstimationDiscountUpdate {
  discountType: EstimationDiscountDiscountTypeEnum;
  name: string;
  value: number;
}

/**
 * Arguments for creating an Estimate Group
 */
export interface EstimationEstimateGroupCreate {
  facetsAttributes?: EstimationEstimateGroupFacetNestedAttributesInput[] | null;
  jobId: string;
  orgId: string;
}

/**
 * Arguments for specifying custom line items when creating estimates
 */
export interface EstimationEstimateGroupCreateCustomLineItem {
  name: string;
  price: number;
  tradeType?: TradeTypeEnum | null;
  type?: LineItemTypeEnum | null;
}

/**
 * Arguments for specifying formula input values
 */
export interface EstimationEstimateGroupCreateInput {
  id: string;
  value: string;
}

/**
 * Arguments for specifying which trade types use full measurements
 */
export interface EstimationEstimateGroupCreateTradeTypeAttributeInput {
  fullMeasurements: boolean;
  tradeType: TradeTypeEnum;
}

/**
 * Arguments for creating an Estimate Group Facet
 */
export interface EstimationEstimateGroupFacetNestedAttributesInput {
  area?: number | null;
  identifier: string;
}

/**
 * Arguments for updating a Line Item for an Estimate
 */
export interface EstimationLineItemUpdateInput {
  productCatalogVariationId: string;
  productCatalogVariationName: string;
}

export interface FileUploadCreateInput {
  byteSize: number;
  checksum: string;
  contentType: string;
  filename: string;
}

/**
 * Arguments for creating a List Item
 */
export interface ListItemCreate {
  color?: string | null;
  externalProductId?: string | null;
  externalVariationId?: string | null;
  measurement?: number | null;
  measurementUnits?: string | null;
  name: string;
  notes?: string | null;
  productCatalogProductId?: string | null;
  quantity?: number | null;
  quantityUnits?: QuantityUnitsEnum | null;
  sku?: string | null;
  skuChangedByUser?: boolean | null;
  tradeType?: TradeTypeEnum | null;
  type?: LineItemTypeEnum | null;
  unitCost?: number | null;
  userSetCustomColor?: boolean | null;
  userSetCustomSku?: boolean | null;
  userSetCustomUnitCost?: boolean | null;
  vendorId?: number | null;
}

/**
 * Arguments for updating a List Item
 */
export interface ListItemUpdate {
  activeForTemplateLineItemGroup?: boolean | null;
  color?: string | null;
  externalVariationId?: string | null;
  measurement?: number | null;
  measurementUnits?: string | null;
  name?: string | null;
  notes?: string | null;
  productCatalogProductId?: string | null;
  quantity?: number | null;
  quantityUnits?: QuantityUnitsEnum | null;
  sku?: string | null;
  skuChangedByUser?: boolean | null;
  tradeType?: TradeTypeEnum | null;
  type?: LineItemTypeEnum | null;
  unitCost?: number | null;
  userSetCustomColor?: boolean | null;
  userSetCustomSku?: boolean | null;
  userSetCustomUnitCost?: boolean | null;
  vendorId?: number | null;
}

/**
 * Arguments for creating an OrderDocument
 */
export interface OrderDocumentCreate {
  externalIdentifier?: string | null;
  footerHtml?: string | null;
  inputHtml: string;
  notes?: string | null;
  orderId?: string | null;
  productionListId: string;
  vendorId?: string | null;
}

/**
 * Arguments for creating a ProductCatalogMatchGroupCategory
 */
export interface ProductCatalogMatchGroupCategoryNestedAttributesCreateInput {
  productCatalogCategoryId: string;
}

/**
 * Arguments for creating a ProductCatalogMatchGroup
 */
export interface ProductCatalogMatchGroupCreateInput {
  active: boolean;
  description?: string | null;
  matchGroupCategoriesAttributes?:
    | ProductCatalogMatchGroupCategoryNestedAttributesCreateInput[]
    | null;
  name: string;
}

/**
 * Arguments for updating a ProductCatalogMatchGroup
 */
export interface ProductCatalogMatchGroupUpdateInput {
  active?: boolean | null;
  description?: string | null;
  matchGroupCategoriesAttributes?:
    | ProductCatalogMatchGroupCategoryNestedAttributesCreateInput[]
    | null;
  name?: string | null;
}

/**
 * Arguments for creating or deleting ProductCatalogOrgVariations
 */
export interface ProductCatalogOrgVariationNestedInput {
  destroy?: boolean | null;
  id?: string | null;
  variationId?: string | null;
}

/**
 * Arguments for updating a ProjectManagementConfigOrgProposalSetting
 */
export interface ProjectManagementConfigOrgProposalSettingUpdate {
  allowDefaultOverrideIncludeAllEstimateItems?: boolean | null;
  allowDefaultOverrideIncludeItemCost?: boolean | null;
  allowDefaultOverrideIncludeItemQuantities?: boolean | null;
  allowDefaultOverrideIncludeTotalPrice?: boolean | null;
  allowDefaultOverrideIncludeTradePrice?: boolean | null;
  defaultNote?: string | null;
  includeAllEstimateItemsDefault?: boolean | null;
  includeItemCostDefault?: boolean | null;
  includeItemQuantitiesDefault?: boolean | null;
  includeTotalPriceDefault?: boolean | null;
  includeTradePriceDefault?: boolean | null;
  maxSigneeCount?: number | null;
  proposalsEnabled?: boolean | null;
  scopeOfWorkDisclaimer?: string | null;
  scopeOfWorkShowLogo?: boolean | null;
  scopeOfWorkShowTitle?: boolean | null;
  scopeOfWorkTitle?: string | null;
}

/**
 * Arguments for creating a ProjectManagementConfigProposalTemplatePage
 */
export interface ProjectManagementConfigProposalTemplatePageCreateInput {
  content?: string | null;
  showOrgLogo?: boolean | null;
  showTitle?: boolean | null;
  title?: string | null;
  tradeType: TradeTypeEnum;
}

/**
 * Arguments for updating a List Item
 */
export interface ProjectManagementListItemNestedAttributesUpdateInput {
  activeForTemplateLineItemGroup?: boolean | null;
  color?: string | null;
  destroy?: boolean | null;
  externalVariationId?: string | null;
  id?: string | null;
  measurement?: number | null;
  measurementUnits?: string | null;
  name?: string | null;
  notes?: string | null;
  productCatalogProductId?: string | null;
  quantity?: number | null;
  quantityUnits?: QuantityUnitsEnum | null;
  sku?: string | null;
  skuChangedByUser?: boolean | null;
  tradeType?: TradeTypeEnum | null;
  type?: LineItemTypeEnum | null;
  unitCost?: number | null;
  userSetCustomColor?: boolean | null;
  userSetCustomSku?: boolean | null;
  userSetCustomUnitCost?: boolean | null;
  vendorId?: number | null;
}

/**
 * Arguments for creating an Order
 */
export interface ProjectManagementOrderCreateInput {
  deliveryContactEmail?: string | null;
  deliveryContactName?: string | null;
  deliveryContactPhoneNumber?: string | null;
  deliveryDate?: any | null;
  deliveryMethod?: string | null;
  deliveryTime?: string | null;
  deliveryType?: string | null;
  distributionBranchId?: string | null;
  distributionJobAccountId?: string | null;
  distributorId?: string | null;
  externalIdentifier?: string | null;
  listItemIds?: string[] | null;
  notes?: string | null;
  orderContactEmail?: string | null;
  orderContactName?: string | null;
  orderContactPhoneNumber?: string | null;
  orderListItemsAttributes?: ProjectManagementOrderListItemCreateInput[] | null;
  orderSessionId?: string | null;
  productionListId?: string | null;
  purchaseOrderNumber?: string | null;
  shippingAddressCity?: string | null;
  shippingAddressCountry?: string | null;
  shippingAddressFirstName?: string | null;
  shippingAddressLastName?: string | null;
  shippingAddressLine1?: string | null;
  shippingAddressLine2?: string | null;
  shippingAddressPhoneNumber?: string | null;
  shippingAddressPostalCode?: string | null;
  shippingAddressState?: string | null;
}

/**
 * Arguments for creating an Order List Item
 */
export interface ProjectManagementOrderListItemCreateInput {
  externalVariationId?: string | null;
  name?: string | null;
  productCatalogProductId?: string | null;
  projectManagementListItemId: string;
  quantity?: number | null;
  quantityUnits?: QuantityUnitsEnum | null;
  sku?: string | null;
  sortOrder?: number | null;
  type: LineItemTypeEnum;
  unitCost?: number | null;
  userSetCustomSku?: boolean | null;
  variantName?: string | null;
}

/**
 * Arguments for updating a Production List
 */
export interface ProjectManagementProductionListUpdateInput {
  enableVariationMatching?: boolean | null;
  listItemsAttributes?:
    | ProjectManagementListItemNestedAttributesUpdateInput[]
    | null;
}

/**
 * Arguments for creating a ProposalDocument
 */
export interface ProjectManagementProposalDocumentCreate {
  contractorAddressCity?: string | null;
  contractorAddressLine1?: string | null;
  contractorAddressLine2?: string | null;
  contractorAddressPostalCode?: string | null;
  contractorAddressState?: string | null;
  contractorLogoUrl?: string | null;
  contractorName: string;
  contractorPhoneNumber?: string | null;
  contractorRepresentativeEmail: string;
  contractorRepresentativeName: string;
  contractorRepresentativePhoneNumber?: string | null;
  contractorWebsiteUrl?: string | null;
  customerAddressCity?: string | null;
  customerAddressLine1?: string | null;
  customerAddressLine2?: string | null;
  customerAddressPostalCode?: string | null;
  customerAddressState?: string | null;
  customerEmail?: string | null;
  customerName: string;
  customerPhoneNumber?: string | null;
  customersAttributes?:
    | ProjectManagementProposalDocumentCustomerCreateInput[]
    | null;
  estimateGroupId: string;
  includeAllEstimateItems?: boolean | null;
  includeItemCost?: boolean | null;
  includeItemQuantities?: boolean | null;
  includeTotalPrice?: boolean | null;
  includeTradePrice?: boolean | null;
  name: string;
  notes?: string | null;
}

/**
 * Arguments for creating a Customer associated with a Project Management Proposal Document
 */
export interface ProjectManagementProposalDocumentCustomerCreateInput {
  email: string;
  name: string;
  phoneNumber?: string | null;
}

/**
 * Arguments for creating a SignatureRequest
 */
export interface ProjectManagementSignatureRequestCreate {
  proposalDocumentId: string;
  requestType: ProjectManagementSignatureRequestRequestTypeEnum;
}

/**
 * Arguments for updating a Sales Opportunity
 */
export interface SalesOpportunityUpdate {
  soldEstimateGroupId?: string | null;
  soldExternally?: boolean | null;
}

export interface SalesPresentationPresentationCreateInput {
  name: string;
  orgId: string;
  pdf: string;
}

/**
 * Arguments for updating a Sales Presentation
 */
export interface SalesPresentationPresentationUpdateInput {
  id: string;
  name: string;
}

/**
 * Arguments for calculating a roof's recommended waste factor
 */
export interface WasteFactorRoofInput {
  hipsLinearFeet: number;
  rakesLinearFeet: number;
  roofSquareFeet: number;
  stepFlashingLinearFeet: number;
  valleysLinearFeet: number;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
