import { Box, Toggle, Label, Body, Divider } from '@hover/blueprint';
import { useSelector, useDispatch } from 'react-redux';

import { TradeTypeEnum } from 'src/api/graphql-global-types';
import { estimationConfigTemplates_estimationConfigTemplates_nodes as Template } from 'src/api/types/estimationConfigTemplates';
import { TradeAttributes } from 'src/features/exteriorEstimator/components/EstimationTool/CustomQuestionPages/SelectTemplates/SelectTemplates';
import { TemplateSelection } from 'src/features/exteriorEstimator/components/EstimationTool/CustomQuestionPages/SelectTemplates/TemplateSelection';
import {
  toggleSelectedTemplate,
  unselectTemplates,
} from 'src/features/exteriorEstimator/redux/actions/templatesActions';
import {
  getJobDetails,
  getTemplates,
} from 'src/features/exteriorEstimator/redux/sagas/selectors';
import { areAnyTemplatesSelected } from 'src/features/exteriorEstimator/utils/templateSelection';
import { useTracking } from 'src/hooks';
import {
  COMMERCE_ORDERING_ONLY_FLOW,
  isEnabled,
  COMMERCE_PROJECT_SCOPE,
} from 'src/lib/FeatureFlag';
import {
  getTradeTypesSorted,
  getUserTrackingProps,
  getOrgSettings,
  getMaterialListFeature,
} from 'src/redux/selectors';
import { EventNames } from 'src/types/actionTypes';
import { jobProps } from 'src/utils/trackingUtils';

export const isTemplateSelected = (
  template: Template,
  selectedTemplateIds: number[] | null,
) => {
  if (!selectedTemplateIds) return false;
  return selectedTemplateIds.includes(template.id);
};

interface Props {
  trade: TradeTypeEnum;
  tradeAttributes: TradeAttributes;
  handleTradeSelection: (selectedTrade: TradeTypeEnum) => void;
  selectedTemplateIds: number[] | null;
}

export const TradeSection: React.FC<Props> = ({
  trade,
  tradeAttributes,
  handleTradeSelection,
  selectedTemplateIds,
}) => {
  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();
  const dispatch = useDispatch();
  const jobDetails = useSelector(getJobDetails);
  const tradeTypes = useSelector(getTradeTypesSorted);

  const allTemplates = useSelector(getTemplates);
  const showOrderingFlow = useSelector(getOrgSettings)?.showOrderingFlow;
  const showOrderingVersion =
    isEnabled(COMMERCE_ORDERING_ONLY_FLOW) &&
    isEnabled(COMMERCE_PROJECT_SCOPE) &&
    showOrderingFlow;
  const materialListFeatureEnabled = useSelector(getMaterialListFeature);
  const showOrderOrMaterialListVersion =
    showOrderingVersion || materialListFeatureEnabled;

  const trackTemplateSelection = (template: Template) => {
    typewriter.checkboxSelected({
      selection: !isTemplateSelected(template, selectedTemplateIds)
        ? template.name ?? ''
        : 'none',
      page_or_screen_name: EventNames.estimator.selectTemplates.page,
      primary_cta: true,
      options: `templates of trade ${trade}`,
      ...commonTrackingProps,
      ...jobProps(jobDetails),
    });
  };

  const allTemplatesOfTrade = (tradeType: TradeTypeEnum) => {
    if (!allTemplates) return null;
    return allTemplates.filter((template) => template.tradeType === tradeType);
  };

  const handleTradeSelectionLocal = () => {
    handleTradeSelection(trade);
  };

  const handleTemplateSelection = (selectedTemplate: Template) => {
    trackTemplateSelection(selectedTemplate);
    dispatch(toggleSelectedTemplate({ id: selectedTemplate.id }));
    if (showOrderOrMaterialListVersion) {
      // in the ordering flow, or using material list feature, you can only have 1 template selected per trade
      const allOtherTemplateIdsOfTrade = allTemplatesOfTrade(trade)
        ?.filter((template) => template.id !== selectedTemplate.id)
        .map((template) => template.id);

      if (allOtherTemplateIdsOfTrade)
        dispatch(unselectTemplates({ ids: allOtherTemplateIdsOfTrade }));
    }
  };

  const tradeTypeDisplayName = () => {
    const tradeType = tradeTypes.find(
      (_tradeType) => _tradeType.tradeTypeEnumValue === trade,
    );
    return tradeType ? tradeType.tradeTypeName : trade;
  };

  const renderTemplate = (template: Template) => {
    return (
      <Box
        margin={100}
        padding={100}
        key={template.id}
        data-test-id="templateSection"
      >
        <TemplateSelection
          template={template}
          checked={isTemplateSelected(template, selectedTemplateIds)}
          onClickTemplate={() => handleTemplateSelection(template)}
        />
      </Box>
    );
  };

  return (
    <Box key={trade} flexDirection="column" data-test-id="tradeSection">
      <Label
        box
        margin={200}
        flexDirection="row"
        justifyContent="space-between"
      >
        <Body
          element="span"
          size={500}
          fontWeight="bold"
          data-test-id="tradeTypeName"
        >
          {tradeTypeDisplayName()}
        </Body>

        {!materialListFeatureEnabled && (
          <Toggle
            data-test-id={`toggle-selection-${trade}`}
            size="small"
            value={!tradeAttributes.collapsed}
            onChange={handleTradeSelectionLocal}
            disabled={areAnyTemplatesSelected(
              tradeAttributes.templates,
              selectedTemplateIds,
            )}
          />
        )}
      </Label>

      {tradeAttributes.collapsed ? (
        <Divider color="neutral.200" />
      ) : (
        tradeAttributes.templates
          .sort((a, b) => (a.sortOrder ?? 0) - (b.sortOrder ?? 0))
          .map((template: Template) => {
            return renderTemplate(template);
          })
      )}
    </Box>
  );
};
