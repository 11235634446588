import * as Sentry from '@sentry/react';
import { get, isNil, mapValues, omit } from 'lodash';
import numeral from 'numeral';

import {
  TradeTypeEnum,
  QuantityUnitsEnum,
  LineItemTypeEnum,
} from 'src/api/graphql-global-types';
import { distributionApprovedBranchesQuery_distributionApprovedBranches_jobAccounts as JobAccount } from 'src/api/types/distributionApprovedBranchesQuery';
import type { distributionOrderCheck_distributionOrderCheck_lineItems as OrderCheckLineItem } from 'src/api/types/distributionOrderCheck';
import { productCatalogDistributorCapabilities_productCatalogDistributor_capabilities as ProductCatalogDistributorCapabilities } from 'src/api/types/productCatalogDistributorCapabilities';
import {
  productCatalogProductSearch_productCatalogProductSearch as ProductSearchResult,
  productCatalogProductSearchVariables,
} from 'src/api/types/productCatalogProductSearch';
import type { projectManagementProductionList_projectManagementProductionList_listItems as ListItem } from 'src/api/types/projectManagementProductionList';
import { PRODUCT_SEARCH } from 'src/features/project/apis/graphql/queries/queries';
import type { Attributes } from 'src/features/project/components/OrderDetail/OrderDetailContent';
import { CUSTOM_VARIANT_COLOR } from 'src/features/project/components/ProjectScope/EditableVariationSelection';
import {
  ListItemIdsByTypeAndTrade,
  OTHER_TRADE_TYPE,
} from 'src/features/project/types';
import type {
  ListItemsByTrade,
  ListItemsByTypeAndTrade,
  OtherTrade,
  OrderLineItemsByTrade,
} from 'src/features/project/types';
import { GraphqlClient } from 'src/lib/GraphqlClient';
import {
  bySortOrderAndCreatedAtComparator,
  bySortOrderAndReverseCreatedAtComparator,
} from 'src/utils/comparators';

export const formattedCost = (unitCost: number | string) => {
  return numeral(unitCost).format('0.00');
};

export const formattedUnitCost = (
  unitCost: number | string,
  displayUnits: string,
) => {
  if (unitCost.toString() === '0') {
    return 'Price calculated at invoicing';
  }
  return `${formattedCost(unitCost)}${
    !!displayUnits ? '/' : ''
  }${displayUnits}`;
};

export const formattedNumber = (
  value: number | string,
  format?: string | null,
  suffix?: string | null,
) => {
  return `${numeral(value >= 0.000001 ? value : 0).format(
    format || '0,0[.]00',
  )}${!!suffix ? ` ${suffix}` : ''}`;
};

/**
 * Return a mapped data structure of <TradeType>: <Array of items for trade>.
 */
export const getListItemsByTrade = <
  T extends { tradeType: TradeTypeEnum | null },
  R extends Record<TradeTypeEnum | OtherTrade, Array<T>>,
>(
  listItems: Array<T> | null | undefined,
): R => {
  const listItemsByTrade = {} as R;
  if (!isNil(listItems)) {
    listItems.reduce((acc, item: T) => {
      const key = item.tradeType || (OTHER_TRADE_TYPE as TradeTypeEnum);
      if (!acc[key]) {
        if (!!key) {
          acc[key] = [] as T[];
        } else {
          acc[OTHER_TRADE_TYPE] = [] as T[];
        }
      }
      acc[key].push(item);
      return acc;
    }, listItemsByTrade);
  }

  return listItemsByTrade;
};

export const getListItemsByTypeAndTrade = (
  listItems: Array<ListItem> | null | undefined,
): ListItemsByTypeAndTrade => {
  const listItemsByTypeAndTrade = {} as ListItemsByTypeAndTrade;
  // First, group the listItems by type, then for each group, group that list by trade.
  if (!isNil(listItems)) {
    listItems.reduce((acc, item: ListItem) => {
      const key = item.type;
      if (!acc[key]) {
        acc[key] = {} as ListItemsByTrade;
      }
      if (!acc[key][item.tradeType ?? OTHER_TRADE_TYPE]) {
        acc[key][item.tradeType ?? OTHER_TRADE_TYPE] = [];
      }
      acc[key][item.tradeType ?? OTHER_TRADE_TYPE].push(item);
      return acc;
    }, listItemsByTypeAndTrade);
  }

  return listItemsByTypeAndTrade;
};

export const getListItemsByTypeAndTradeIds = (
  listItemsByTypeAndTrade: ListItemsByTypeAndTrade,
) => {
  // Create a state object that maps <LineItemTypeEnum>: <TradeType>: <listItemIds>
  const listItemsByTypeAndTradeIds = Object.entries(
    listItemsByTypeAndTrade,
  ).reduce((acc, [type, listItemsByTrade]) => {
    const values = mapValues(listItemsByTrade, (items) =>
      items.map((listItem) => listItem.id.toString()),
    );
    acc[type as LineItemTypeEnum] = values;
    return acc;
  }, {} as ListItemIdsByTypeAndTrade);

  return listItemsByTypeAndTradeIds;
};

/**
 * Transform flat array of listItems to array of Attributes,
 * for input to order check APIs.
 */

export const getAttributesFromListItem = (
  item: ListItem | OrderCheckLineItem,
  index: number | null,
): Attributes => {
  return {
    measurement: item.measurement,
    measurementUnits: item.measurementUnits,
    productId:
      // eslint-disable-next-line no-underscore-dangle
      item.__typename === 'DistributionOrderCheckLineItem'
        ? item.productId
        : item.productCatalogProductId,
    productName:
      // eslint-disable-next-line no-underscore-dangle
      item.__typename === 'DistributionOrderCheckLineItem'
        ? (item.productName as string)
        : item.name,
    quantity: item.quantity as number,
    quantityUnits: item.quantityUnits,
    requiresProductVariationSelection: item.requiresProductVariationSelection,
    sku: item.sku,
    tradeType: item.tradeType as TradeTypeEnum,
    unitCost: item.unitCost as number,
    userSetCustomSku: item.userSetCustomSku ?? false,
    userSetCustomVariationName:
      // eslint-disable-next-line no-underscore-dangle
      item.__typename === 'DistributionOrderCheckLineItem'
        ? item.userSetCustomVariationName
        : item.userSetCustomColor,
    variationId:
      // eslint-disable-next-line no-underscore-dangle
      item.__typename === 'DistributionOrderCheckLineItem'
        ? item.variationId
        : item.externalVariationId,
    variationName:
      // eslint-disable-next-line no-underscore-dangle
      item.__typename === 'DistributionOrderCheckLineItem'
        ? item.variationName
        : item.color,
    sortOrder: index,
    // TODO:
    // Need to ignore this TS error until GQL schema is fixed to make ListItem id be `ID` - Type 'number' is not assignable to type 'string | null'
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    clientIdentifier:
      // eslint-disable-next-line no-underscore-dangle
      item.__typename === 'DistributionOrderCheckLineItem'
        ? item.clientIdentifier
        : item.id, // id for correlation of distributionOrderLineItem to productionListItem.
  };
};

export const getAttributesFromListItems = (listItems: ListItem[]) => {
  return listItems.map((item: ListItem, index: number): Attributes => {
    return getAttributesFromListItem(item, index);
  });
};

export const omitOrderCheckLineItemAttributes = (
  lineItem: OrderCheckLineItem,
) => {
  return omit(lineItem, [
    '__typename',
    'id',
    'pretaxCost',
    'price',
    'errors',
    'checkResult',
    'product',
    'name',
    'requiresProductVariationSelection',
  ]);
};

export const omitListItemAttributes = (lineItem: ListItem) => {
  return omit(lineItem, [
    '__typename',
    'calculatedQuantity',
    'createdAt',
    'distributionOrders',
    'lineItemId',
    'pretaxCost',
    'product',
    'sortOrder',
    'totalCost',
    'wasteFactor',
    'requiresProductVariationSelection',
  ]);
};

export const omitAttributes = (
  lineItems: (OrderCheckLineItem | ListItem)[],
) => {
  // eslint-disable-next-line no-underscore-dangle
  return lineItems[0].__typename === 'DistributionOrderCheckLineItem'
    ? // Remove the attributes that will be re-calculated/provided by the orderCheck.
      lineItems.map((item) =>
        omitOrderCheckLineItemAttributes(item as OrderCheckLineItem),
      )
    : // Remove the attributes that are not required attributes for ProductionListItem update.
      lineItems.map((item) => omitListItemAttributes(item as ListItem));
};

export const lineItemsWithOmittedAttributes = (
  lineItems: OrderLineItemsByTrade,
) => {
  return (
    Object.values(lineItems)
      .flat()
      // Remove the attributes that will be re-calculated/provided by the orderCheck.
      .map((item) =>
        omit(item, [
          '__typename',
          'id',
          'pretaxCost',
          'price',
          'errors',
          'checkResult',
          'requiresProductVariationSelection',
        ]),
      )
  );
};

// Make any necessary lineItemData transformations prior to saving.
export const getListItemAttributes = (
  lineItemData: Partial<ListItem | OrderCheckLineItem>[],
  lineItems: (ListItem | OrderCheckLineItem)[],
  dirtyValues: { [x: string]: Partial<ListItem> | Partial<OrderCheckLineItem> },
) => {
  const attributes =
    // eslint-disable-next-line no-underscore-dangle
    Object.values(lineItems)[0].__typename === 'DistributionOrderCheckLineItem'
      ? ([...lineItemData] as unknown as OrderCheckLineItem[])
      : ([...lineItemData] as unknown as ListItem[]);
  attributes.forEach(
    (lineItem: ListItem | OrderCheckLineItem, index: number) => {
      const itemIdProp =
        // eslint-disable-next-line no-underscore-dangle
        lineItem.__typename === 'DistributionOrderCheckLineItem'
          ? 'clientIdentifier'
          : 'id';
      const idValue = get(lineItem, itemIdProp);
      const dirtyField = dirtyValues[idValue];
      const attrNames = Object.keys(dirtyField);
      // Make transformations related to custom variation.
      const origLineItem = lineItems[idValue];
      // eslint-disable-next-line no-underscore-dangle
      if (origLineItem.__typename === 'DistributionOrderCheckLineItem') {
        const attrLineItem = lineItem as unknown as OrderCheckLineItem;
        if (
          attrNames.includes('variationId') ||
          attrNames.includes('variationName')
        ) {
          const lineItemTransformed = {
            ...attrLineItem,
          } as unknown as OrderCheckLineItem;
          lineItemTransformed.variationName = (
            lineItems[idValue] as OrderCheckLineItem
          ).variationName;
          // }
          lineItemTransformed.userSetCustomVariationName =
            attrLineItem.variationId === CUSTOM_VARIANT_COLOR.name ||
            (attrNames.includes('variationName') &&
              !attrNames.includes('variationId') &&
              isNil(attrLineItem.variationId));
          lineItemTransformed.variationId =
            attrLineItem.variationId === CUSTOM_VARIANT_COLOR.name ||
            (attrNames.includes('variationName') &&
              !attrNames.includes('variationId') &&
              isNil(attrLineItem.variationId))
              ? null
              : attrLineItem.variationId;
          attributes[index] = lineItemTransformed;
        }
      } else if (
        attrNames.includes('externalVariationId') ||
        attrNames.includes('color')
      ) {
        const attrListItem = lineItem as unknown as ListItem;
        const lineItemTransformed = { ...attrListItem } as ListItem;
        lineItemTransformed.color = (lineItems[idValue] as ListItem).color;
        // }
        lineItemTransformed.userSetCustomColor =
          attrListItem.externalVariationId === CUSTOM_VARIANT_COLOR.name ||
          (attrNames.includes('color') &&
            !attrNames.includes('externalVariationId') &&
            isNil(attrListItem.externalVariationId));
        lineItemTransformed.externalVariationId =
          attrListItem.externalVariationId === CUSTOM_VARIANT_COLOR.name ||
          (attrNames.includes('color') &&
            !attrNames.includes('externalVariationId') &&
            isNil(attrListItem.externalVariationId))
            ? null
            : attrListItem.externalVariationId;
        attributes[index] = lineItemTransformed;
      }
    },
  );
  return attributes;
};

export const getSavedFields = (items: (ListItem | OrderCheckLineItem)[]) => {
  const savedFields = items.map((item) => {
    return omit(item, [
      // 'id',
      'externalVariationId',
      'userSetCustomColor',
      'productId',
      'productCatalogProductId',
    ]);
  });

  return savedFields;
};

export const hasJobAccountForOrder = (
  jobAccount: JobAccount | null,
  distributorCapabilities:
    | ProductCatalogDistributorCapabilities
    | null
    | undefined,
) => {
  const distributorRequiresOrderJobAccount =
    distributorCapabilities?.requiresOrderJobAccount;

  return (
    !distributorRequiresOrderJobAccount || // Some distributors don't require a job account.
    (distributorRequiresOrderJobAccount && !isNil(jobAccount)) // If distributor does require a job account, check it.
  );
};

export const productSearch = async (
  productSearchVariables: productCatalogProductSearchVariables,
) => {
  const { searchTerm, orgId, distributorId } = productSearchVariables;
  if (searchTerm || searchTerm === '') {
    const variables: productCatalogProductSearchVariables = isNil(distributorId)
      ? { searchTerm, orgId, addMatchesFromApprovedBranches: true }
      : productSearchVariables;
    try {
      const {
        data: { productCatalogProductSearch: results },
      } = await GraphqlClient.query({
        query: PRODUCT_SEARCH,
        variables,
      });

      return results;
    } catch (error) {
      Sentry.captureException(error);
    }
  }
  return null;
};

export const getDistributorIcon = (id: string, distributors: any) => {
  const distributor = distributors?.find((d: any) => d.distributor.id === id);
  return get(distributor, 'distributor.logo.url');
};

export const getTypeAheadSuggestionsIcon = (
  result: ProductSearchResult,
  distributors: any,
): string | undefined => {
  const { variations } = result;

  let id: string | null = '';
  // look thru all the externalVariations to see if they all share the same distributor
  // eslint-disable-next-line no-plusplus
  for (let x = 0; x < variations?.length; x++) {
    const variation = variations[x];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < variation.externalVariationIdentifiers?.length; i++) {
      const externalVariation = variation.externalVariationIdentifiers[i];
      if (!id) {
        id = externalVariation.distributorId;
      } else if (id !== externalVariation.distributorId) {
        // if you found a distributorId that is different, break early
        // and return undefined as icon value
        return undefined;
      }
    }
  }

  // else, if externalVariationIdentifiers all have the same distributor, get the distributors icon
  return getDistributorIcon(id || '', distributors);
};

export const convertMeasurementUnitToQuantityUnit = (
  measurementUnit?: string,
) => {
  if (!measurementUnit) return null;
  switch (measurementUnit) {
    case 'ea':
      return QuantityUnitsEnum.EACH;
    case 'hr':
      return QuantityUnitsEnum.HOURS;
    case 'lf':
      return QuantityUnitsEnum.LINEAR_FEET;
    case 'pc':
      return QuantityUnitsEnum.PIECES;
    case 'sqft':
      return QuantityUnitsEnum.SQUARE_FEET;
    case 'cuft':
      return QuantityUnitsEnum.CUBIC_FEET;
    case 'sq':
      return QuantityUnitsEnum.SQUARES;
    case 'bg':
      return QuantityUnitsEnum.BAGS;
    case 'bx':
      return QuantityUnitsEnum.BOXES;
    case 'bdl':
      return QuantityUnitsEnum.BUNDLES;
    case 'rl':
      return QuantityUnitsEnum.ROLLS;
    case 'tb':
      return QuantityUnitsEnum.TUBES;
    default:
      return null;
  }
};

export const byOrders = (a: ListItem, b: ListItem) => {
  // Constrain a lineItem's order state to either no orders (0) or some orders (1),
  // which effectively groups lineItems into these two groupings/categories, with
  // lineItems that have orders sorted first.
  return (
    Math.min(
      b.distributionOrders.filter((o) => o.state === 'submitted').length,
      1,
    ) -
    Math.min(
      a.distributionOrders.filter((o) => o.state === 'submitted').length,
      1,
    )
  );
};

export const byDistributionOrdersSortOrderCreatedAtComparator = (
  a: ListItem,
  b: ListItem,
) => {
  // items associated with orders are shown first, then by sortOrder, and by creationDate
  return byOrders(a, b) || bySortOrderAndCreatedAtComparator(a, b);
};

export const byDistributionOrdersSortOrderReverseCreatedAtComparator = (
  a: ListItem,
  b: ListItem,
) => {
  // items associated with orders are shown first, then by sortOrder, and by creationDate
  return byOrders(a, b) || bySortOrderAndReverseCreatedAtComparator(a, b);
};

export const buildPdfFileName = ({
  jobId,
  filename,
}: {
  jobId: string;
  filename?: string;
}) => {
  let name = `ID${jobId}`;
  if (filename) name = name.concat(`-${filename}`);
  return name;
};

export const invertMap = (map: Map<any, any>) => {
  return new Map(Array.from(map, (entry) => [entry[1], entry[0]]));
};
