import * as React from 'react';

import { Spinner } from '@hover/blueprint';
import {
  SplitTreatments,
  SplitFactory,
  SplitClient,
} from '@splitsoftware/splitio-react';
import { connect } from 'react-redux';

import { RootState } from 'src/types/reduxStore';
import SplitConfig from 'src/utils/SplitConfig';
import { SplitFlagsToTrafficType } from 'src/utils/SplitConfig/utils';

export interface IProps {
  whenOn: React.ReactNode;
  whenOff: React.ReactNode;
  featureName: string;
  attributes?: SplitIO.Attributes;
  showSpinner?: boolean;
}

export const mapStateToProps = (state: RootState) => ({
  userProfile: state.hover.userProfile,
});

type Props = ReturnType<typeof mapStateToProps> & IProps;

const Component: React.FC<Props> = (props) => {
  const renderSpinner = (showSpinner: boolean) => {
    if (!showSpinner) return null;
    return <Spinner />;
  };

  const {
    whenOn,
    whenOff,
    featureName,
    attributes = {},
    showSpinner,
    userProfile,
  } = props;
  const spinner = showSpinner ?? true;
  if (!userProfile) {
    return renderSpinner(spinner);
  }

  const renderContent = (treatmentWithConfig: SplitIO.TreatmentWithConfig) => {
    const { treatment, config } = treatmentWithConfig;
    const configProps = config ? JSON.parse(config) : {};

    if (treatment === 'on') {
      return (
        <>
          {React.isValidElement(whenOn) &&
            React.cloneElement(whenOn, configProps)}
        </>
      );
    }
    return (
      <>
        {React.isValidElement(whenOff) &&
          React.cloneElement(whenOff, configProps)}
      </>
    );
  };

  const renderTreatment = () => {
    return (
      <SplitTreatments
        names={[featureName]}
        attributes={{ ...attributes, ...{ orgId: userProfile.orgs[0].id } }}
      >
        {({ treatments, isReady }) => {
          return isReady
            ? renderContent(treatments[featureName])
            : renderSpinner(spinner);
        }}
      </SplitTreatments>
    );
  };

  const renderSplit = () => {
    const factory = SplitConfig.getFactory();
    const trafficType = SplitFlagsToTrafficType[featureName] ?? 'user';
    if (trafficType === 'user') {
      return (
        <SplitFactory factory={factory}>
          <SplitClient splitKey={userProfile.id.toString()}>
            {renderTreatment()}
          </SplitClient>
        </SplitFactory>
      );
    }
    return <SplitFactory factory={factory}>{renderTreatment()}</SplitFactory>;
  };

  return renderSplit();
};

const ConnectedComponent = connect(mapStateToProps)(Component);

export { ConnectedComponent as SplitToggle };
