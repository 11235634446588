/* eslint-disable camelcase */

export interface JobMeasurements {
  sides?: {
    front: {
      total: number;
      area_per_label: { [key: string]: string };
    };
    back: {
      total: number;
      area_per_label: { [key: string]: string };
    };
    left: {
      total: number;
      area_per_label: { [key: string]: string };
    };
    right: {
      total: number;
      area_per_label: { [key: string]: string };
    };
  };
  roof?: Roof | null;
  window_count_total?: number | null;
  window_ui_total?: number | null;
  siding_zero_waste_area_total?: number | null;
  siding_with_openings_area_total?: number | null;
  siding_facade_area?: number | null;
  siding_unknown_area_total?: number | null;
  brick_with_openings_area?: number | null;
  brick_zero_waste_area?: number | null;
  stucco_with_openings_area?: number | null;
  stucco_zero_waste_area?: number | null;
  metal_with_openings_area?: number | null;
  metal_zero_waste_area?: number | null;
  stone_with_openings_area?: number | null;
  stone_zero_waste_area?: number | null;
  wrap_with_openings_area?: number | null;
  wrap_zero_waste_area?: number | null;
  tudor_with_openings_area?: number | null;
  tudor_zero_waste_area?: number | null;
  unknown_with_openings_area?: number | null;
  unknown_zero_waste_area?: number | null;
  openings_top_length_siding_total?: number | null;
  openings_top_length_other_total?: number | null;
  openings_sill_length_siding_total?: number | null;
  openings_sill_length_other_total?: number | null;
  openings_sides_length_siding_total?: number | null;
  openings_sides_length_other_total?: number | null;
  inside_corner_siding_count_total?: number | null;
  inside_corner_other_count_total?: number | null;
  outside_corner_siding_count_total?: number | null;
  outside_corner_other_count_total?: number | null;
  inside_corner_siding_length_total?: number | null;
  inside_corner_other_length_total?: number | null;
  outside_corner_siding_length_total?: number | null;
  outside_corner_other_length_total?: number | null;
  level_starter_siding_length_total?: number | null;
  level_starter_other_length_total?: number | null;
  sloped_trim_siding_length_total?: number | null;
  sloped_trim_other_length_total?: number | null;
  vertical_trim_siding_length_total?: number | null;
  vertical_trim_other_length_total?: number | null;
  shutter_quantity_siding_total?: number | null;
  shutter_quantity_other_total?: number | null;
  vent_quantity_other_total?: number | null;
  vent_quantity_siding_total?: number | null;
  eaves_fascia_total?: number | null;
  rakes_fascia_total?: number | null;
  level_frieze_board_length_total?: number | null;
  level_frieze_board_avg_depth?: number | null;
  level_frieze_board_soffit_area?: number | null;
  sloped_frieze_board_length_total?: number | null;
  sloped_frieze_board_avg_depth?: number | null;
  sloped_frieze_board_soffit_area?: number | null;
  gutter_length_total?: number | null;
  downspout_length_total?: number | null;
  cornice_returns_length_total?: number | null;
  cornice_returns_count_total?: number | null;
}
export interface Roof {
  pitch?: PitchEntity[] | null;
  roof_total?: number | null;
  roof_count?: number | null;
  ridge_total?: number | null;
  ridge_count?: number | null;
  soffit_total_length?: number | null;
  soffit_total_area?: number | null;
  hip_total?: number | null;
  hip_count?: number | null;
  valley_total?: number | null;
  valley_count?: number | null;
  eave_total?: number | null;
  eave_count?: number | null;
  rake_total?: number | null;
  rake_count?: number | null;
  flashing_total?: number | null;
  flashing_count?: number | null;
  step_flashing_total?: number | null;
  step_flashing_count?: number | null;
}
export interface PitchEntity {
  facet: string;
  area: number | null;
  pitch: number | null;
  label: string;
  id: number;
}

// possibly incomplete
export interface V1Measurements {
  roof_measurement_data: {
    [key: string]: {
      measurements: {
        totals: {
          total_area?: number;
          total_length?: number;
        };
        per_edge: {
          length: number;
        };
        per_face: {
          [key: number]: {
            pitch: number;
            area: number;
            label: string;
            id: number;
            entity_id: number;
          };
        };
      };
    };
  };
}

export interface Edge {
  display_label: string;
  length: number;
  points: number[];
}

export interface PartialEdge {
  id: number;
  type: string;
}

export interface Facade {
  area: number;
  display_label: string;
  edges: PartialEdge[];
  sort_index: number;
  pitch: number;
  original_pitch: number;
  slope_angle: number;
}

export interface FacadeIDs {
  facade_ids?: number[];
}

export interface Group {
  roof: FacadeIDs;
  siding: FacadeIDs;
}

export interface Point {
  display_label: string;
  position: number[];
}

export interface PlainMeasurements {
  edges: { [key: string]: Edge };
  facades: { [key: string]: Facade };
  groups: { [key: string]: Group };
  points: { [key: string]: Point };
}

export interface AugmentedFacade extends Facade {
  edges: AugmentedEdge[];
}

export interface AugmentedEdge extends Edge, PartialEdge {}

export interface AugmentedPlainMeasurements extends PlainMeasurements {
  facades: { [key: string]: AugmentedFacade };
  edges: { [key: string]: AugmentedEdge };
}

export interface GeometryEdge {
  id: string;
  length: number;
  type: string;
  source: string;
}

export interface ClassificiationFacade {
  display_label: string;
  id: string;
  area: number;
  edges: string[];
}

export interface WindowsForFacade {
  [key: string]: string[];
}
