import { isNil } from 'lodash';
import {
  Redirect,
  matchPath,
  RouteProps,
  RouteComponentProps,
} from 'react-router-dom';

import OauthCallback from 'src/components/OauthCallback';
import EstimatesAccess from 'src/features/estimatesAccess/components/index';
import { EstimateGroupDetail } from 'src/features/exteriorEstimator/components/EstimationTool/EstimateGroupDetail/EstimateGroupDetail';
import { EstimateGroupSummary } from 'src/features/exteriorEstimator/components/EstimationTool/EstimateGroupSummary';
import { EstimatesDataWrapper } from 'src/features/exteriorEstimator/components/EstimationTool/Estimates';
import { EstimateGroups } from 'src/features/exteriorEstimator/components/EstimationTool/Estimates/EstimateGroups';
import { EstimationTool } from 'src/features/exteriorEstimator/components/EstimationTool/EstimationTool';
import { QuestionView } from 'src/features/exteriorEstimator/components/EstimationTool/QuestionView';
import { History } from 'src/features/exteriorEstimator/components/History/History';
import { InspectionChecklist } from 'src/features/exteriorEstimator/components/InspectionChecklist/InspectionChecklist';
import { InspectionChecklists } from 'src/features/exteriorEstimator/components/InspectionChecklist/InspectionChecklists';
import { InspectionChecklistShow } from 'src/features/exteriorEstimator/components/InspectionChecklist/InspectionChecklistShow';
import { VariationSelection } from 'src/features/exteriorEstimator/components/VariationSelection/VariationSelection';
import { ProjectPage, ProjectScope } from 'src/features/project/components';
import { OrderConfirm } from 'src/features/project/components/Checkout/Confirmation/OrderConfirm';
import { OrderCheckout } from 'src/features/project/components/Checkout/OrderCheckout';
import { OrderDetail } from 'src/features/project/components/OrderDetail/OrderDetail';
import { Checkout } from 'src/features/projectManagement/components/ProductionView/Checkout';
import OrderSummary from 'src/features/projectManagement/components/ProductionView/OrderSummary';
import { ProductionView } from 'src/features/projectManagement/components/ProductionView/ProductionView';
import { FormBuilder } from 'src/features/settings/components/ChecklistTemplates/FormBuilder';
import { InspectionTemplates } from 'src/features/settings/components/ChecklistTemplates/InspectionTemplates';
import { ColorGroupingRulesList } from 'src/features/settings/components/ColorGroupingRules/ColorGroupingRulesList';
import { ColorGroupingRulesCreateEdit } from 'src/features/settings/components/ColorGroupingRules/CreateEdit';
import { Dashboard as WorkflowsDashboard } from 'src/features/settings/components/Dashboard';
import { LineItemsList } from 'src/features/settings/components/LineItems/LineItemsList';
import { MaterialList } from 'src/features/settings/components/MaterialList';
import { ProductEdit } from 'src/features/settings/components/Product/Edit';
import { ProductShow } from 'src/features/settings/components/Product/Show';
import { Proposal } from 'src/features/settings/components/Proposal';
import { SectionDataContainer as Section } from 'src/features/settings/components/Proposal/Section';
import { SalesPresentation } from 'src/features/settings/components/SalesPresentation';
import {
  Settings,
  AccessLevel,
} from 'src/features/settings/components/Settings';
import { TemplateEditDataWrapper as TemplateEdit } from 'src/features/settings/components/Templates/TemplateEdit';
import { TemplatesList } from 'src/features/settings/components/Templates/TemplatesList';
import { TakeoffCreation } from 'src/features/settings/takeoffs/components/Estimates/TakeoffCreation';
import { TemplatesContainer as TakeoffTemplatesContainer } from 'src/features/settings/takeoffs/components/Templates/TemplatesContainer';

export type RouteState = { showIntercomMessenger?: boolean };
export type RouteConfig = RouteProps & {
  routes?: Array<RouteConfig>;
  state?: RouteState;
  accessLevel?: AccessLevel;
};

/*
  Logic here for what the default value of workflows/ is based on which
  experiments a user is in. At the moment the only requirement is to always go
  to materials but in the future this may change.
 */

// TODO: Add 404 catch all route for prefixed routes
export const ROUTES: Array<RouteConfig> = [
  // workflows (settings)
  {
    path: '/workflows',
    component: Settings,
    routes: [
      {
        path: '/',
        exact: true,
        component: WorkflowsDashboard,
      },
      {
        path: '/materials',
        exact: true,
        component: MaterialList,
        accessLevel: AccessLevel.ADMIN,
      },
      {
        path: '/materials/products/:productId',
        exact: true,
        component: ProductShow,
        accessLevel: AccessLevel.ADMIN,
      },
      {
        path: '/materials/products/:productId/edit',
        component: ProductEdit,
        accessLevel: AccessLevel.ADMIN,
      },
      {
        path: '/proposal',
        component: Proposal,
        accessLevel: AccessLevel.ADMIN,
        exact: true,
      },
      {
        path: '/proposal/section/:sectionId?',
        component: Section,
        accessLevel: AccessLevel.ADMIN,
        exact: true,
      },
      {
        path: '/line_items',
        component: LineItemsList,
        accessLevel: AccessLevel.ADMIN,
        exact: true,
        state: { showIntercomMessenger: true },
      },
      {
        path: '/template_management',
        component: TemplatesList,
        accessLevel: AccessLevel.ADMIN,
        exact: true,
      },
      {
        path: '/template_management/template/:templateId',
        component: TemplateEdit,
        accessLevel: AccessLevel.ADMIN,
        exact: true,
      },
      {
        path: '/templates',
        component: TakeoffTemplatesContainer,
        accessLevel: AccessLevel.ADMIN,
      },
      {
        path: '/color_grouping_rules',
        component: ColorGroupingRulesList,
        accessLevel: AccessLevel.ADMIN,
        exact: true,
      },
      {
        path: '/color_grouping_rules/create',
        component: ColorGroupingRulesCreateEdit,
        accessLevel: AccessLevel.ADMIN,
        exact: true,
      },
      {
        path: '/color_grouping_rules/:groupId/edit',
        component: ColorGroupingRulesCreateEdit,
        accessLevel: AccessLevel.ADMIN,
        exact: true,
      },
      {
        path: '/inspection_templates/:templateId',
        component: FormBuilder,
        accessLevel: AccessLevel.JOB_MANAGER,
      },
      {
        path: '/inspection_templates',
        component: InspectionTemplates,
        accessLevel: AccessLevel.JOB_MANAGER,
      },
      {
        path: '/sales_presentation',
        component: SalesPresentation,
        accessLevel: AccessLevel.PRO_PLUS,
      },
    ],
  },
  {
    path: '/estimates',
    component: EstimatesAccess,
  },
  {
    path: '/project/:jobId',
    component: ProjectPage,
    routes: [
      {
        path: '/', // project/:jobId will eventually route to a dashboard component
        component: (props: RouteComponentProps<any>) => (
          /*
            Temporary redirect from base ProjectScope route to the Project Scope page;
            project/:jobId will eventually route to a dashboard component.
          */
          <Redirect
            to={`/project/${props.match.params.jobId}/scope${props.location.search}`}
          />
        ),
        exact: true,
      },
      {
        path: '/scope',
        component: ProjectScope,
        exact: true,
      },
      {
        path: '/detail/:orderCheckId?',
        component: OrderDetail,
        exact: true,
      },
      {
        path: '/checkout/:orderCheckId',
        component: OrderCheckout,
        exact: true,
      },
      {
        path: '/confirmation/:orderId',
        component: OrderConfirm,
        exact: true,
      },
      {
        path: '/order/:orderId',
        component: OrderConfirm,
        exact: true,
      },
    ],
  },
  {
    path: '/estimator_production/order/checkout',
    component: Checkout,
  },
  {
    path: '/estimator_production/order/summary',
    component: OrderSummary,
  },
  {
    path: '/estimator_production',
    component: ProductionView,
  },
  {
    path: '/estimator',
    component: EstimationTool,
    routes: [
      {
        path: '/welcome',
        component: (props: RouteComponentProps<any>) => (
          /* Legacy: for mobile app */
          <Redirect
            to={`/estimator/questions/select_templates${props.location.search}`}
          />
        ),
      },
      {
        path: '/inspection_checklist',
        component: InspectionChecklistShow,
      },
      {
        path: '/inspection_checklists/:checklistId',
        component: InspectionChecklist,
      },
      {
        path: '/inspection_checklists',
        component: InspectionChecklists,
        exact: true,
      },
      {
        path: '/estimates/history',
        component: History,
      },
      {
        path: '/estimates/:estimateGroupId/details',
        component: EstimateGroupDetail,
        exact: true,
      },
      {
        path: '/estimates/:estimateGroupId/summary',
        component: EstimateGroupSummary,
        exact: true,
      },
      {
        path: '/estimates/:estimateGroupId/estimate/:estimateId/variations',
        component: VariationSelection,
        exact: true,
      },
      {
        path: '/estimates/:estimateGroupId',
        component: EstimateGroups,
        exact: true,
      },
      {
        path: '/estimates/:estimateGroupId',
        component: EstimatesDataWrapper,
        routes: [
          {
            path: '/takeoff',
            component: TakeoffCreation,
            exact: true,
          },
        ],
      },

      {
        path: '/questions/:category',
        component: QuestionView,
      },
    ],
  },
  {
    path: '/oauth/callback/beacon',
    component: OauthCallback,
  },
  {
    path: '/oauth/callback',
    component: OauthCallback,
  },
];

/**
 * Route utility functions
 */

// Flattened memo-ized map of expanded routes to RouteConfig objects.
// Recursively memo-izes expanded routes for lookup.
const flattenRoutes = (
  routeConfigMap: Record<string, RouteConfig>,
  routes: Array<RouteConfig>,
  parentRoute?: RouteConfig,
) => {
  if (!routes) return;

  routes.forEach((route: RouteConfig) => {
    if (!!route.routes) {
      return flattenRoutes(routeConfigMap, route.routes, route);
    }
    // merge route path
    const mergedRoute = {
      ...route,
      ...{ path: `${parentRoute?.path || ''}${route.path}` },
    };
    // recursive function mutates accumulator object during recursion.
    // eslint-disable-next-line no-param-reassign
    routeConfigMap[`${parentRoute?.path || ''}${route.path}`] = mergedRoute;
    return routeConfigMap;
  });
};

// Recursive function wrapper.
export const flattenRouteConfig = (
  routes: Array<RouteConfig>,
): Record<string, RouteConfig> => {
  const routeConfigMap: Record<string, RouteConfig> = {};
  flattenRoutes(routeConfigMap, routes);

  return routeConfigMap;
};
const routeConfigMap = flattenRouteConfig(ROUTES);

// Match a location pathname against memo-ized expanded routes.
// This allows the router location path to be used to identify the
// RouteConfig that generated the location, and allows access
// to the state in the RouteConfig.
export const matchRoute = (
  pathname: string,
  routeMap: Record<string, RouteConfig> = routeConfigMap,
): RouteConfig | null => {
  const matchedRoutes = Object.values(routeMap).filter((route) => {
    const matched = matchPath(pathname, route);
    return !isNil(matched);
  });
  return matchedRoutes[0];
};
