export const ORDERING_AUTO_ATTACH_PDF = 'commerce-ordering-auto-attach-pdf';
export const UNIT_COST_IN_LINE_EDIT_CHANGE_LOG =
  'commerce-unit-cost-in-line-edit-change-log';

export const COMMERCE_PROJECT_SCOPE = 'commerce_project_scope_p1';
export const COMMERCE_PROJECT_SCOPE_INLINE_EDITING =
  'commerce-project-scope-p1-inlineediting';
export const COMMERCE_PROJECT_SCOPE_INLINE_EDITING_V2 =
  'commerce-project-scope-inline-editing-v2';
export const COMMERCE_PRODUCTION_LIST_DELETE_ITEM =
  'commerce-production-list-delete-item';

export const QUANTITY_TAKEOFF = 'growth-quantity-takeoff';
export const DIRECT_TO_PRODUCTION =
  'growth-quantity-takeoff-direct-to-production';
export const COOKIE_MANAGEMENT = 'web-cookie-management';

export const WORKFLOWS_SALES_PRESENTATION_INTEREST =
  'pa-sales-pres-early-access';

export const COMMERCE_ORDERING_ONLY_FLOW = 'commerce-ordering-only-flow';
export const PA_SALES_PRES_PDF_UPLOAD = 'PA_salesPresPdfUpload';
export const COMMERCE_PROJECT_LIST_REQUEST_REFACTOR =
  'commerce-project-list-request-refactor';
export const WORKFLOWS_RR_NAMED_ESTIMATES = 'workflows-rr-named-estimates';
export const COMMERCE_USE_HDF = 'commerce-use-hdf';
export const COMMERCE_MATERIAL_LIST = 'commerce-out-of-the-box-material-list';
export const COMMERCE_BRICK_AND_STONE_ESTIMATION =
  'commerce-brick-and-stone-estimation';
