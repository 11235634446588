import { Box } from '@hover/blueprint';
import { connect } from 'react-redux';

import { Heading } from 'src/features/exteriorEstimator/components/common/EstimatorHeading';
import { MultiTradesWrapper } from 'src/features/exteriorEstimator/components/EstimationTool/MultiTrades/MultiTradesWrapper/MultiTradesWrapper';
import { SIDING_MEASUREMENTS } from 'src/features/exteriorEstimator/constants/sidingConstants';
import { UpdateAnswer } from 'src/features/exteriorEstimator/redux/actions/answerActions';
import { Input } from 'src/features/exteriorEstimator/types/Questions';
import { RootState } from 'src/types/reduxStore';

import { SidingMeasurements } from './SidingMeasurements';
import { SidingQuestions } from './SidingQuestions';

const ADJUST_SIDING_MEASUREMENT_CATEGORY = 'Adjust Siding Measurement';
const QUESTION_ARGUMENTS = new Set(Object.values(SIDING_MEASUREMENTS));

export const mapStateToProps = (state: RootState) => ({
  jobMeasurements: state.exteriorEstimator.job.jobMeasurements,
});

export interface ComponentProps {
  questions: Input[];
  updateAnswer: (updateProps: UpdateAnswer) => void;
}

export type Props = ReturnType<typeof mapStateToProps> & ComponentProps;

const AdjustSidingMeasurementComponent: React.FC<Props> = ({
  jobMeasurements,
  questions,
  updateAnswer,
}) => {
  const editableQuestions: Input[] = [];
  const nonEditableQuestions: Input[] = [];
  questions
    .filter(
      (question) =>
        question?.inputCategory?.name === ADJUST_SIDING_MEASUREMENT_CATEGORY,
    )
    .forEach((question) =>
      QUESTION_ARGUMENTS.has(question.argument ?? '')
        ? nonEditableQuestions.push(question)
        : editableQuestions.push(question),
    );

  return (
    <MultiTradesWrapper>
      <Box>
        <Heading data-test-id="adjustSidingMeasurmentHeader">
          Adjust Siding Measurement
        </Heading>
      </Box>
      <SidingMeasurements
        jobMeasurements={jobMeasurements}
        questions={nonEditableQuestions}
        updateAnswer={updateAnswer}
      />
      <SidingQuestions
        questions={editableQuestions}
        updateAnswer={updateAnswer}
      />
    </MultiTradesWrapper>
  );
};

export const AdjustSidingMeasurement = connect(mapStateToProps)(
  AdjustSidingMeasurementComponent,
);
