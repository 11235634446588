import { find } from 'lodash';

import { TradeTypeEnum } from 'src/api/graphql-global-types';
import { tradeTypes_tradeTypes as TradeType } from 'src/api/types/tradeTypes';
import { InputWithValue } from 'src/features/exteriorEstimator/components/EstimationTool/CustomQuestionPages/WasteFactorPage/WasteFactorSlider';
import { JobMeasurements } from 'src/features/exteriorEstimator/types';
import { isRoofTradeType } from 'src/features/exteriorEstimator/utils/questionsUtils';
import {
  inchesToFeet,
  squaredFeetToRoofSquares,
} from 'src/lib/conversionUtils';

export class WasteFactorCalculator {
  measurements: JobMeasurements;

  measurementQuestionsAndResponses: InputWithValue[];

  tradeTypes: TradeType[];

  constructor(
    measurements: JobMeasurements,
    measurementQuestionsAndResponses: InputWithValue[],
    tradeTypes: TradeType[],
  ) {
    this.measurements = measurements;
    this.measurementQuestionsAndResponses = measurementQuestionsAndResponses;
    this.tradeTypes = tradeTypes;
  }

  private roofCalculator = () => {
    const roofTotal =
      find(this.measurementQuestionsAndResponses, {
        argument: 'roof_total',
      })?.value ??
      this.measurements.roof?.roof_total ??
      0;
    return squaredFeetToRoofSquares(roofTotal);
  };

  private sidingCalculator = () => {
    return (
      find(this.measurementQuestionsAndResponses, {
        argument: 'siding_with_openings_area_total',
      })?.value ??
      this.measurements.siding_with_openings_area_total ??
      0
    );
  };

  private guttersCalculator = () => {
    const downspoutLengthTotal =
      find(this.measurementQuestionsAndResponses, {
        argument: 'downspout_length_total',
      })?.value ??
      this.measurements.downspout_length_total ??
      0;

    const eaveTotal =
      find(this.measurementQuestionsAndResponses, {
        argument: 'eave_total',
      })?.value ??
      this.measurements.roof?.eave_total ??
      0;
    return inchesToFeet(downspoutLengthTotal) + eaveTotal;
  };

  private fasciaCalculator = () => {
    const rakeTotal =
      find(this.measurementQuestionsAndResponses, {
        argument: 'rake_total',
      })?.value ??
      this.measurements.roof?.rake_total ??
      0;
    const eaveTotal =
      find(this.measurementQuestionsAndResponses, {
        argument: 'eave_total',
      })?.value ??
      this.measurements.roof?.eave_total ??
      0;
    return rakeTotal + eaveTotal;
  };

  private soffitCalculator = () => {
    return (
      find(this.measurementQuestionsAndResponses, {
        argument: 'soffit_total_area',
      })?.value ??
      this.measurements.roof?.soffit_total_area ??
      0
    );
  };

  public getTradeTotalAmount(tradeType: TradeTypeEnum) {
    if (isRoofTradeType(tradeType, this.tradeTypes))
      return this.roofCalculator();
    switch (tradeType) {
      case TradeTypeEnum.SIDING: {
        return this.sidingCalculator();
      }
      case TradeTypeEnum.GUTTERS: {
        return this.guttersCalculator();
      }
      case TradeTypeEnum.FASCIA: {
        return this.fasciaCalculator();
      }
      case TradeTypeEnum.SOFFIT: {
        return this.soffitCalculator();
      }
      default:
        return 0;
    }
  }
}
