import { omit, pick } from 'lodash';

import {
  PristineMeasurement,
  V1Measurements,
  Roof,
} from 'src/features/exteriorEstimator/types';
import {
  squaredInchesToSquaredFeet,
  inchesToFeet,
} from 'src/lib/conversionUtils';

// remove fields from raw HDF we don't need
export const getFilteredHdf = (hdf: any) => {
  const filtered = omit(hdf, ['appearance', 'id', 'internal', 'views']);

  // only need geometry.faces
  filtered.geometry = pick(filtered.geometry, 'faces');

  filtered.classification = pick(filtered.classification, [
    'corniceEdges',
    'roofEdges',
    'wallEdges',
    'faces',
    'windows',
  ]);

  filtered.measurements = filtered.measurements.versions.find(
    (v: any) => v.name === 'measurements_v2',
  );

  return filtered;
};

export const isPitch = (argument: string) =>
  argument && argument.startsWith('pitch_area');

export const getPitchTotal = (argument: string, jobMeasurements: Roof) => {
  const argParts = argument.replace('pitch_area_', '').split('_');
  const isGreater = argParts[0] === 'greater';
  const pitchValue = parseInt(argParts[isGreater ? 1 : 0], 10);
  if (!jobMeasurements?.pitch) return;
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line consistent-return
  return jobMeasurements.pitch.reduce((total, { area, pitch }) => {
    if (pitch == null || area == null) return total;
    if (
      (isGreater && pitch > pitchValue) ||
      (!isGreater && pitch === pitchValue)
    ) {
      return total + area;
    }
    return total;
  }, 0);
};

export const getPristineMeasurement = (
  pristineMeasurements: PristineMeasurement[],
  questionId: number,
) => {
  const measurement: PristineMeasurement | undefined =
    pristineMeasurements.find(
      (pristineMeasurement: PristineMeasurement) =>
        pristineMeasurement.questionId === questionId,
    );

  return measurement?.answer ?? null;
};

export const filterJobMeasurements = (apiResponse: {
  data: V1Measurements;
}) => {
  const roofData = apiResponse.data.roof_measurement_data;
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const results: { [key: string]: any } = {};

  Object.keys(roofData).forEach((feature) => {
    const { totals, per_edge: perEdge = {} } = roofData[feature].measurements;
    if (totals.total_area)
      results[`${feature}_total`] = parseFloat(
        squaredInchesToSquaredFeet(totals.total_area).toFixed(2),
      );
    else if (totals.total_length)
      results[`${feature}_total`] = parseFloat(
        inchesToFeet(totals.total_length).toFixed(2),
      );
    else results[`${feature}_total`] = 0;
    results[`${feature}_count`] = Object.keys(perEdge).length;
  });

  results.pitch = Object.values(roofData.roof.measurements.per_face)
    .map((face) => ({
      pitch: face.pitch,
      area: parseFloat(squaredInchesToSquaredFeet(face.area).toFixed(2)),
      label: face.label,
      id: face.entity_id,
    }))
    .filter((a) => !!a.label)
    .sort(
      (a, b) =>
        Number(a.label.split('-').pop()) - Number(b.label.split('-').pop()),
    );

  return { roof: results };
};
