import { isNil } from 'lodash';
import { createSelector } from 'reselect';

import { FeatureAccessIdentifier } from 'src/api/graphql-global-types';
import { getCommonProps } from 'src/lib/tracking';
import { RootState } from 'src/types/reduxStore';

export const getUserProfile = (state: RootState) => state.hover.userProfile;
export const getAuthStatus = (state: RootState) =>
  state.hover.authenticationStatus;
export const getSplitInitStatus = (state: RootState) =>
  state.hover.isSplitInitialized;

export const getUserTrackingProps = createSelector(
  getUserProfile,
  (userProfile) => {
    if (isNil(userProfile)) return {};
    return getCommonProps(userProfile);
  },
);

export const getImpersonator = (state: RootState) => state.hover?.impersonator;

export const getOrgFeatures = (state: RootState) => {
  const orgFeatureAccesses =
    state.hover?.userProfile?.orgs?.[0]?.featureAccesses || [];

  return orgFeatureAccesses;
};

export const getMaterialListFeature = (state: RootState) => {
  const orgFeatureAccesses =
    state.hover?.userProfile?.orgs[0]?.featureAccesses || [];
  const materialListAccess = orgFeatureAccesses.find(
    (feature) =>
      feature.identifier ===
      FeatureAccessIdentifier.OUT_OF_THE_BOX_MATERIAL_LISTS,
  );
  return !!(materialListAccess && materialListAccess.enabled);
};
