import React, { useState } from 'react';

import {
  Tooltip,
  Input,
  Td,
  Menu,
  Link,
  Box,
  Icon,
  MenuGroup,
  MenuItem,
} from '@hover/blueprint';
import {
  Editable,
  EditablePreview,
  EditableInput,
} from '@hover/blueprint/chakra';
import { iChevronDown } from '@hover/icons';
import { get } from 'lodash';
import { useFormContext } from 'react-hook-form';

import type { productCatalogConfigOrgDistributors_productCatalogConfigOrgDistributors as Distributor } from 'src/api/types/productCatalogConfigOrgDistributors';
import { projectManagementProductionList_projectManagementProductionList_listItems as ListItem } from 'src/api/types/projectManagementProductionList';
import { useProjectScopeTracker } from 'src/features/project/components/ProjectScope/hooks/useProjectScopeTracker';

import { EditableProductName } from './Inputs/EditableProductName';

type Props = {
  isMaterial: boolean;
  isDisabled?: boolean;
  enableInlineEditingV2: boolean;
  listItem: ListItem;
  distributors?: Distributor[];
  orgId: string;
  jobId: number;
  onUpdate: (inputLabel: string) => void;
  onSuggestedItemsUpdate: (listItemId: number) => void;
};

export const ListItemNameColumn = ({
  enableInlineEditingV2,
  isMaterial,
  isDisabled = false,
  listItem,
  distributors,
  orgId,
  jobId,
  onUpdate,
  onSuggestedItemsUpdate,
}: Props) => {
  const [name, setName] = useState(listItem.name);
  const [isProductSelected, setIsProductSelected] = useState(false);
  const { trackInlineEditingInputPressed } = useProjectScopeTracker({ jobId });

  const {
    setValue,
    register,
    formState: { errors: formErrors },
  } = useFormContext();

  const handleInputUpdate = () => {
    if (Object.keys(formErrors).length > 0) {
      return;
    }

    onUpdate('Product Name');
  };

  const handleSuggestedItemsClick = (listItemId: number) => {
    onSuggestedItemsUpdate(listItemId);
  };

  if (!enableInlineEditingV2) {
    return <Td>{listItem.name}</Td>;
  }

  if (isMaterial) {
    return (
      <Td>
        <Editable
          size="tiny"
          value={name}
          isDisabled={isDisabled}
          selectAllOnFocus
          onCancel={(prevValue) => {
            if (!isProductSelected) {
              setName(prevValue);
            }

            setIsProductSelected(false);
          }}
        >
          <Tooltip label="Click to edit" placement="top">
            <EditablePreview display="flex" height="auto" />
          </Tooltip>
          <EditableInput
            as={EditableProductName}
            label={name}
            name={name}
            orgId={orgId}
            jobId={jobId}
            listItem={listItem}
            distributors={distributors}
            setName={setName}
            onUpdate={handleInputUpdate}
            setIsProductSelected={setIsProductSelected}
          />
        </Editable>

        {listItem.templateListItemGroupListItems.length > 0 && (
          <Menu
            trigger={
              <Link as="button">
                <Box display="flex" flexDir="row" alignItems="center">
                  Suggested items <Icon icon={iChevronDown} />
                </Box>
              </Link>
            }
          >
            <MenuGroup title="Suggestions">
              {listItem.templateListItemGroupListItems.map((groupListItem) => (
                <MenuItem
                  onClick={() => handleSuggestedItemsClick(groupListItem.id)}
                >
                  {groupListItem.name}
                </MenuItem>
              ))}
            </MenuGroup>
          </Menu>
        )}
      </Td>
    );
  }

  return (
    <Td>
      <Editable
        defaultValue={listItem.name}
        selectAllOnFocus
        isDisabled={isDisabled}
        submitOnBlur={!get(formErrors, 'name.message')} // Prevents submission of invalid value, when validation error.
        onSubmit={() => {
          onUpdate('Name');
        }}
        onCancel={(prev) => {
          // If input is canceled on blur due to validation error, then reset the previous value.
          setValue('name', prev, {
            shouldValidate: true,
            shouldDirty: true,
          });
        }}
        size="tiny"
      >
        <Tooltip label="Click to edit" placement="top">
          <EditablePreview display="flex" height="auto" />
        </Tooltip>
        <Tooltip
          label={get(formErrors, 'name.message')}
          placement="top"
          background="danger300"
        >
          <Input
            as={EditableInput}
            size="tiny"
            {...register('name', {
              required: 'Item name is required',
            })}
            isInvalid={!!get(formErrors, 'name')}
            onChange={() => {
              trackInlineEditingInputPressed('Name');
            }}
          />
        </Tooltip>
      </Editable>
    </Td>
  );
};
