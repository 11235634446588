import { memo, useState, useEffect, useMemo } from 'react';

import { useMutation } from '@apollo/client';
import {
  Box,
  Button,
  Checkbox,
  IconButton,
  Link,
  Spinner,
  Td,
  Tr,
  Dialog,
} from '@hover/blueprint';
import { iEdit, iTrash2 } from '@hover/icons';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import {
  DistributionOrderStateEnum,
  LineItemTypeEnum,
  TradeTypeEnum,
} from 'src/api/graphql-global-types';
import { estimationQuantityUnits_estimationQuantityUnits as estimationQuantityUnit } from 'src/api/types/estimationQuantityUnits';
import type { productCatalogConfigOrgDistributors_productCatalogConfigOrgDistributors as Distributor } from 'src/api/types/productCatalogConfigOrgDistributors';
import type {
  projectManagementProductionList_projectManagementProductionList_listItems as ListItem,
  projectManagementProductionList_projectManagementProductionList_listItems_distributionOrders as Order,
} from 'src/api/types/projectManagementProductionList';
import { messages } from 'src/constants/messages';
import {
  GET_PRODUCTION_LIST,
  UPDATE_PRODUCTION_LIST_ITEMS,
} from 'src/features/project/apis/graphql/queries/queries';
import { toggleSelectedItem } from 'src/features/project/redux/actions';
import {
  formattedCost,
  formattedNumber,
  omitListItemAttributes,
} from 'src/features/project/util/utils';
import { ToastStatusEnum, useToastEhi } from 'src/hooks';
import {
  isEnabled,
  COMMERCE_PRODUCTION_LIST_DELETE_ITEM,
} from 'src/lib/FeatureFlag';
import { getMaterialListFeature } from 'src/redux/selectors';
import {
  adjustedMeasurementWithWasteFactor,
  wasteFactorDisplay,
} from 'src/utils/estimatesUtils';
import { lineItemQuantityUnits } from 'src/utils/unitsMap';

import { ListItemNameColumn } from './ContentTable/Item/ListItemNameColumn';
import { ListItemQuantityColumn } from './ContentTable/Item/ListItemQuantityColumn';
import { ListItemUnitCostColumn } from './ContentTable/Item/ListItemUnitCostColumn';
import { ListItemVariationColumn } from './ContentTable/Item/ListItemVariationColumn';
import { useProjectScopeTracker } from './hooks/useProjectScopeTracker';

interface LineItemProps {
  listItem: ListItem;
  lineItemType: LineItemTypeEnum;
  tradeType: TradeTypeEnum;
  jobId: number;
  orgId: string;
  selected: boolean;
  quantityUnits: estimationQuantityUnit[];
  enableInlineEditingV2: boolean;
  distributors?: Distributor[];
  productionListId: number;
  setEditingListItem: (listItem: ListItem) => void;
  onDelete: (listItem: ListItem) => void;
}

const enum TOAST_IDS {
  GET_QUANTITY_UNITS_TOAST,
  UPDATE_PRODUCTION_LIST_ITEMS_TOAST,
}

export const ProductionListLineItem: React.FC<LineItemProps> = ({
  jobId,
  orgId,
  listItem,
  selected,
  lineItemType,
  tradeType,
  distributors,
  productionListId,
  quantityUnits,
  enableInlineEditingV2,
  setEditingListItem,
  onDelete,
}) => {
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
  const isMaterial = listItem?.type === LineItemTypeEnum.MATERIAL;
  const dispatch = useDispatch();
  const toast = useToastEhi();
  const {
    trackEditItemButton,
    trackOrderLink,
    trackInlineEditingDataUpdated,
    trackDeleteItemCanceled,
    trackDeleteItemConfirmed,
    trackDeleteItem,
  } = useProjectScopeTracker({
    jobId,
  });
  const productionListDelete = isEnabled(COMMERCE_PRODUCTION_LIST_DELETE_ITEM);
  const materialListFeatureEnabled = useSelector(getMaterialListFeature);
  const showDeleteListOrMaterialListVersion =
    productionListDelete || materialListFeatureEnabled;

  const unitsMap: Map<string, string> = useMemo(() => {
    const map = new Map();
    quantityUnits?.forEach((entry: estimationQuantityUnit) => {
      map.set(entry.value, entry.abbreviation);
    });
    return map;
  }, [quantityUnits]);

  const orders: Array<Order> = useMemo(
    () =>
      listItem.distributionOrders.filter((order) => {
        return (
          order.state === DistributionOrderStateEnum.submitted ||
          order.state === DistributionOrderStateEnum.pending_submitted ||
          order.state === DistributionOrderStateEnum.canceled
        );
      }),
    [listItem.distributionOrders],
  );

  const onEdit = () => {
    trackEditItemButton(listItem.type);
    setEditingListItem(listItem);
  };

  const handleToggleSelection = () => {
    dispatch(toggleSelectedItem(lineItemType, tradeType, listItem.id));
  };

  const formDefaultValues = useMemo(
    () => ({
      id: listItem.id,
      name: listItem.name,
      quantityUnits: listItem.quantityUnits,
      quantity: listItem.quantity,
      calculatedQuantity: listItem.calculatedQuantity,
      color: listItem.color,
      externalVariationId: listItem.externalVariationId,
      unitCost: listItem.unitCost,
      totalCost: listItem.totalCost,
      // customVariant: listItem.customVariant,
      sku: listItem.sku,
      productCatalogProductId: listItem.productCatalogProductId,
      product: listItem.product,
      measurement: listItem.measurement,
      measurementUnits: listItem.measurementUnits,
      wasteFactor: listItem.wasteFactor,
      userSetCustomColor: listItem.userSetCustomColor,
      requiresProductVariationSelection:
        listItem.requiresProductVariationSelection,
      type: lineItemType,
      tradeType,
    }),
    // eslint-disable-next-line
    [listItem],
  );

  const methods = useForm({
    defaultValues: formDefaultValues,
  });

  const [updateProductionList, { loading: updatingProductionList }] =
    useMutation(UPDATE_PRODUCTION_LIST_ITEMS, {
      onError: () => {
        toast({
          id: TOAST_IDS.UPDATE_PRODUCTION_LIST_ITEMS_TOAST,
          description: messages.projectScope.errors.query.productionList.update,
          status: ToastStatusEnum.ERROR,
        });
      },
      refetchQueries: [
        {
          query: GET_PRODUCTION_LIST,
          variables: {
            orgId,
            jobId,
          },
        },
      ],
    });

  const handleDelete = () => {
    trackDeleteItem();
    setShowDeleteDialog(true);
  };

  const handleCloseDeleteConfirmationDialog = () => {
    setShowDeleteDialog(false);
  };

  const handleConfirmDelete = () => {
    updateProductionList({
      variables: {
        productionListId,
        productionListAttributes: {
          listItemsAttributes: {
            id: listItem.id,
            destroy: true,
          },
        },
        orgId,
      },
    });

    trackDeleteItemConfirmed();

    if (onDelete) {
      onDelete(listItem);
    }

    handleCloseDeleteConfirmationDialog();
  };

  const handleUpdateProductLineItem = (inputLabel: string) => {
    const listItemsAttributes = omitListItemAttributes(
      methods.getValues() as ListItem,
    );

    // Post update flags
    if (listItem.unitCost !== listItemsAttributes.unitCost) {
      listItemsAttributes.userSetCustomUnitCost = true;
    }

    updateProductionList({
      variables: {
        productionListId,
        productionListAttributes: {
          listItemsAttributes,
        },
        orgId,
      },
    });

    trackInlineEditingDataUpdated(lineItemType, tradeType, inputLabel);
  };

  const handleUpdateProductSuggestedItems = (listItemId: number) => {
    updateProductionList({
      variables: {
        productionListId,
        productionListAttributes: {
          listItemsAttributes: [
            { id: listItemId, activeForTemplateLineItemGroup: true },
          ],
        },
        orgId,
      },
    });
    dispatch(toggleSelectedItem(lineItemType, tradeType, listItem.id));
    dispatch(toggleSelectedItem(lineItemType, tradeType, listItemId));
  };

  const quantityUnitFormValue = methods.getValues().quantityUnits || '';
  const quantityUnit = useMemo(
    () => unitsMap.get(quantityUnitFormValue.toUpperCase())?.toUpperCase(),
    [quantityUnitFormValue, unitsMap],
  ) as string;

  useEffect(() => {
    methods.reset(formDefaultValues);
    // eslint-disable-next-line
  }, [listItem]);

  return (
    <Tr
      key={listItem.id}
      data-test-id={`ListItemRow-${listItem.id}`}
      lineHeight="1rem"
      verticalAlign="baseline"
      borderBottom="1px solid"
      borderColor="neutral.200"
    >
      <FormProvider {...methods}>
        <Td _first={{ paddingLeft: '800' }}>
          {!updatingProductionList && (
            <Checkbox
              marginLeft={1}
              value={listItem.id?.toString()}
              aria-label={listItem.name}
              key={listItem.id}
              data-test-id="checkboxMaterialItem"
              flex="0"
              isChecked={selected}
              onChange={handleToggleSelection}
            />
          )}
          {updatingProductionList && (
            <Spinner data-test-id={`${listItem.id}-EditingLineItemLoader`} />
          )}
        </Td>
        <ListItemNameColumn
          isMaterial={isMaterial}
          isDisabled={updatingProductionList}
          listItem={listItem}
          enableInlineEditingV2={enableInlineEditingV2}
          distributors={distributors}
          orgId={orgId}
          jobId={jobId}
          onUpdate={handleUpdateProductLineItem}
          onSuggestedItemsUpdate={handleUpdateProductSuggestedItems}
        />
        {isMaterial && (
          <ListItemVariationColumn
            listItem={listItem}
            isDisabled={updatingProductionList}
            isUpdating={updatingProductionList}
            enableInlineEditingV2={enableInlineEditingV2}
            onUpdate={handleUpdateProductLineItem}
            jobId={jobId}
          />
        )}
        <Td>
          {!!listItem.measurement && listItem.measurement > 0 && (
            <>
              <Box>
                {formattedNumber(
                  adjustedMeasurementWithWasteFactor(
                    listItem.measurement,
                    listItem.wasteFactor ?? 0,
                  ),
                  null,
                  lineItemQuantityUnits(listItem.measurementUnits),
                )}
              </Box>
              {!!listItem.wasteFactor && (
                <Box fontSize={200} color="neutral.500">
                  {`${formattedNumber(
                    listItem.measurement,
                    null,
                  )} + ${wasteFactorDisplay(listItem.wasteFactor)}`}{' '}
                </Box>
              )}
            </>
          )}
        </Td>
        <ListItemQuantityColumn
          listItem={listItem}
          isDisabled={updatingProductionList}
          unitsMap={unitsMap}
          quantityUnit={quantityUnit}
          jobId={jobId}
          enableInlineEditingV2={enableInlineEditingV2}
          onUpdate={handleUpdateProductLineItem}
        />
        <ListItemUnitCostColumn
          listItem={listItem}
          quantityUnit={quantityUnit}
          isDisabled={updatingProductionList}
          jobId={jobId}
          enableInlineEditingV2={enableInlineEditingV2}
          onUpdate={handleUpdateProductLineItem}
        />
        <Td isNumeric>{formattedCost(listItem.totalCost)}</Td>
        <Td paddingLeft={700}>
          {orders.map((order: Order) => (
            <Box key={order.id}>
              <Link
                as={RouterLink}
                to={`/project/${jobId}/order/${order.id}?orgId=${orgId}`}
                onClick={trackOrderLink}
              >
                {order.purchaseOrderNumber}
              </Link>
            </Box>
          ))}
        </Td>

        <Td>
          {showDeleteListOrMaterialListVersion && (
            <IconButton
              data-test-id={`${listItem.id}-edit-delete`}
              label="delete-item"
              fill="minimal"
              icon={iTrash2}
              onClick={handleDelete}
              fontWeight="normal"
              color="danger"
              top="3px"
            />
          )}

          {!enableInlineEditingV2 && (
            <Button
              data-test-id={`${listItem.id}-edit`}
              fill="minimal"
              iconBefore={iEdit}
              onClick={onEdit}
              paddingX={2}
              fontWeight="normal"
              fontSize={14}
              color="neutral"
              shape="box"
              top="3px"
            >
              Edit
            </Button>
          )}

          {showDeleteDialog && (
            <Dialog
              cancelText="Cancel"
              confirmText="Remove"
              header={`Remove ${listItem.name}`}
              isDestructive
              isOpen
              onCancelClick={() => {
                trackDeleteItemCanceled();
                handleCloseDeleteConfirmationDialog();
              }}
              onClose={() => {
                handleCloseDeleteConfirmationDialog();
              }}
              onConfirmClick={handleConfirmDelete}
            >
              <span data-test-id="deleteDialogContent">
                Remove item from project scope. This action cannot be done.
              </span>
            </Dialog>
          )}
        </Td>
      </FormProvider>
    </Tr>
  );
};

export const ProductionListLineItemMemoized = memo(ProductionListLineItem);
