import * as Sentry from '@sentry/react';

import { EdgeTotals } from './PartialMeasurementsMapHdf';

export class LineSegmentCalculatorComparator {
  public hdfEdgeTotals: EdgeTotals;

  public estimatorEdgeTotals: EdgeTotals;

  constructor(hdfEdgeTotals: EdgeTotals, estimatorEdgeTotals: EdgeTotals) {
    this.hdfEdgeTotals = hdfEdgeTotals;
    this.estimatorEdgeTotals = estimatorEdgeTotals;
  }

  private static percentDifference(expected: number, actual: number) {
    // percent difference is |expected - actual|/ expected. the estimation json number is expected, actual is the HDF version.

    return (Math.abs(expected - actual) / expected) * 100;
  }

  public compare() {
    Object.entries(this.estimatorEdgeTotals).forEach(([key, value]) => {
      if (
        LineSegmentCalculatorComparator.percentDifference(
          value,
          this.hdfEdgeTotals[key],
        ) > 5
      ) {
        console.log(
          'mismatching calculatedEdgeTotal:',
          'hdf:',
          this.hdfEdgeTotals,
          'estimator',
          this.estimatorEdgeTotals,
        );
        Sentry.captureMessage('HDF/siding calculatedEdgeTotal mismatch', {
          level: 'info',
          tags: { domain: 'hdf' },
          contexts: {
            estimator: {
              estimationValue: this.estimatorEdgeTotals,
              hdfValue: this.hdfEdgeTotals,
            },
          },
        });
      }
    });
  }
}
