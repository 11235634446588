import React, { useEffect, useState } from 'react';

import { useMutation } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { get, find } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  EstimationEstimateGroupStateEnum,
  TradeTypeEnum,
} from 'src/api/graphql-global-types';
import { projectEstimationConfigOrgSetupCreate_estimationConfigOrgSetupCreate as ConfigOrgCreate } from 'src/api/types/projectEstimationConfigOrgSetupCreate';
import { projectEstimationConfigTemplates_estimationConfigTemplates_nodes as Template } from 'src/api/types/projectEstimationConfigTemplates';
import { projectEstimationEstimateGroupCreate_estimationEstimateGroupCreate as EstimateGroupCreate } from 'src/api/types/projectEstimationEstimateGroupCreate';
import { messages } from 'src/constants/messages';
import { useEstimate } from 'src/features/exteriorEstimator/hooks';
import {
  initializeSelectedTemplates,
  getTemplatesEnd,
} from 'src/features/exteriorEstimator/redux/actions/templatesActions';
import {
  getQuestionResponses,
  getCleanCustomLineItems,
  getSelectedTemplateIds,
  getSelectedTrades,
  getTemplates,
} from 'src/features/exteriorEstimator/redux/sagas/selectors';
import { getAllSelectedFacetParams } from 'src/features/exteriorEstimator/redux/sagas/selectors/roofQuestionsSelectors';
import { estimateGroupCreateParams } from 'src/features/exteriorEstimator/utils/estimateGroupUtils';
import {
  CREATE_ORG_SETUP,
  GET_ORG_SETUP,
  GET_TEMPLATES,
  CREATE_ESTIMATE_GROUP,
  GET_ESTIMATE_GROUP,
} from 'src/features/project/apis/graphql/queries/queries';
import {
  useLazyQueryEhi,
  useToastEhi,
  ToastStatusEnum,
  usePrevious,
} from 'src/hooks';
import { getOrgIdParam, getTradeTypesSorted } from 'src/redux/selectors';

const enum TOAST_IDS {
  GET_PRODUCTION_LIST_ERROR_TOAST,
  GET_TEMPLATES_ERROR_TOAST,
  CREATE_ESTIMATE_GROUP_ERROR_TOAST,
  CREATE_ORG_SETUP_TOAST,
  GET_AND_POLL_ORG_SETUP_ERROR_TOAST,
  GET_AND_POLL_ESTIMATE_GROUP_ERROR_TOAST,
}

export function useMaterialListFeature() {
  const jobId = Number(get(useParams(), 'jobId'));
  const orgId = useSelector(getOrgIdParam);
  const toast = useToastEhi();
  const dispatch = useDispatch();
  const { setupEstimator, isFetchingQuestions } = useEstimate();
  const questionResponses = useSelector(getQuestionResponses);
  const customLineItems = useSelector(getCleanCustomLineItems);
  const tradeTypes = useSelector(getTradeTypesSorted);
  const selectedTrades = useSelector(getSelectedTrades) as TradeTypeEnum[];
  const facetsAttributes = useSelector(getAllSelectedFacetParams);
  const selectedTemplateIds = useSelector(getSelectedTemplateIds);
  const allTemplates = useSelector(getTemplates);
  const prevIsFetchingQuestions: boolean | undefined =
    usePrevious(isFetchingQuestions);

  const [isRecreatingMaterialList, setIsRecreatingMaterialList] = useState<
    boolean | undefined
  >(undefined);

  const [isSettingUpConfigAndEstimate, setIsSettingUpConfigAndEstimate] =
    useState<boolean | undefined>(undefined);
  const [setupConfigAndEstimateErrored, setSetupConfigAndEstimateErrored] =
    useState<boolean>(false);

  const [createOrgConfigSetup] = useMutation(CREATE_ORG_SETUP, {
    variables: { orgId },
    onCompleted: ({
      estimationConfigOrgSetupCreate,
    }: {
      estimationConfigOrgSetupCreate: ConfigOrgCreate;
    }) => {
      // if the mutation completed...
      const orgSetupId = estimationConfigOrgSetupCreate?.orgSetup?.id;

      // and if there is an orgSetupId, then continue on with the pattern
      if (orgSetupId) {
        fetchAndPollOrgSetup({
          variables: {
            orgSetupId,
          },
          pollInterval: 1500,
        });
      } else {
        // else try just fetching the templates
        fetchTemplatesAndSetupEstimator();
      }
    },
    onError: () => {
      toast({
        id: TOAST_IDS.CREATE_ORG_SETUP_TOAST,
        description:
          messages.projectScope.errors.mutation.productionList.orgConfigSetup,
        status: ToastStatusEnum.ERROR,
      });
    },
  });

  const [
    fetchAndPollOrgSetup,
    {
      data: getOrgSetupData,
      error: getOrgSetupDataError,
      stopPolling: stopPollingGetOrgSetupData,
    },
  ] = useLazyQueryEhi(GET_ORG_SETUP, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    onError: () => {
      toast({
        id: TOAST_IDS.GET_AND_POLL_ORG_SETUP_ERROR_TOAST,
        description:
          messages.projectScope.errors.query.productionList.orgConfig,
        status: ToastStatusEnum.ERROR,
      });
    },
  });

  const [fetchTemplates] = useLazyQueryEhi(GET_TEMPLATES, {
    variables: { orgId },
    onError: () => {
      toast({
        id: TOAST_IDS.GET_TEMPLATES_ERROR_TOAST,
        description:
          messages.projectScope.errors.query.productionList.estimateTemplates,
        status: ToastStatusEnum.ERROR,
      });
    },
  });

  const [createEstimateGroup] = useMutation(CREATE_ESTIMATE_GROUP, {
    onCompleted: ({
      estimationEstimateGroupCreate,
    }: {
      estimationEstimateGroupCreate: EstimateGroupCreate;
    }) => {
      const id = estimationEstimateGroupCreate?.estimateGroup?.id;
      fetchAndPollEstimateGroup({
        variables: {
          id,
          orgId,
        },
        pollInterval: 1500,
      });
    },
    onError: () => {
      toast({
        id: TOAST_IDS.CREATE_ESTIMATE_GROUP_ERROR_TOAST,
        description:
          messages.projectScope.errors.mutation.productionList.estimateGroup,
        status: ToastStatusEnum.ERROR,
      });
    },
  });

  const [
    fetchAndPollEstimateGroup,
    {
      data: getEstimateGroupData,
      error: getEstimateGroupError,
      stopPolling: stopPollingEstimateGroup,
    },
  ] = useLazyQueryEhi(GET_ESTIMATE_GROUP, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    onError: () => {
      toast({
        id: TOAST_IDS.GET_AND_POLL_ESTIMATE_GROUP_ERROR_TOAST,
        description:
          messages.projectScope.errors.query.productionList.estimateGroup,
        status: ToastStatusEnum.ERROR,
      });
    },
  });

  /*
  When setupEstimator gets invoked the isFetchingQuestions state updates to true
  and when it finalizes all the templates inputs + measurements in the redux store 
  the isFetchingQuestions states updates to false. So we listen to that change, and when 
  it is finished updating we send a request to create a estimate group
  */
  useEffect(() => {
    const isDoneFetching =
      prevIsFetchingQuestions === true && isFetchingQuestions === false;

    if (isDoneFetching) {
      const vars = estimateGroupCreateParams({
        jobId,
        orgId,
        selectedTemplates: selectedTemplateIds || [],
        customLineItems,
        questionResponses,
        tradeTypes,
        selectedTrades,
        facetsAttributes,
        showOrderingVersion: true, // mark as sold and activate
      });
      createEstimateGroup({ variables: vars });
    }
  }, [isFetchingQuestions, prevIsFetchingQuestions]);

  /*
  When an orgConfigSetup is made and the request to query and poll orgSetupData has
  begun, check the data returned for a "complete" state. When that occurs, stop polling orgSetup
  and start the process of getting templates and estimator inputs set up 
  */
  useEffect(() => {
    const { state } = getOrgSetupData?.estimationConfigOrgSetup ?? {};
    if (state?.toUpperCase() === 'COMPLETE') {
      stopPollingGetOrgSetupData();
      fetchTemplatesAndSetupEstimator();
    }
    if (!!getOrgSetupDataError) {
      materialListErrored(getOrgSetupDataError);
      stopPollingGetOrgSetupData();
    }
  }, [getOrgSetupData, getOrgSetupDataError, stopPollingGetOrgSetupData]);

  /*
  When an estimate is created from the setup Estimators isFetchingQuestions-useEffect,  
  wait till the estimate state is complete, to stop polling and fetch production list
  */
  useEffect(() => {
    const { state } = getEstimateGroupData?.estimationEstimateGroup ?? {};
    if (state === EstimationEstimateGroupStateEnum.COMPLETE) {
      stopPollingEstimateGroup();
      if (isSettingUpConfigAndEstimate) {
        setIsSettingUpConfigAndEstimate(false); // this is the end of the happy path making first ml
      }
      if (isRecreatingMaterialList) {
        setIsRecreatingMaterialList(false); // this is the end of the happy path recreating ml
      }
    }
    if (
      getEstimateGroupError ||
      state === EstimationEstimateGroupStateEnum.FAILED
    ) {
      materialListErrored(getEstimateGroupError);
      stopPollingEstimateGroup();
    }
  }, [getEstimateGroupData, getEstimateGroupError, stopPollingEstimateGroup]);

  /*
  get the templates for the org, update the redux store with those templates, then select a template
  and update the redux store with the selected template, finally use the estimate hook to finish 
  setting up all the required inputs and measurements for the given template
  */
  const fetchTemplatesAndSetupEstimator = () => {
    fetchTemplates()
      .then(({ data: mlTemplates }) => {
        // with the templates ready, format them and store them in redux
        const theseTemplates = mlTemplates?.estimationConfigTemplates?.edges
          ?.map((edge: any) => edge?.node)
          .filter((t: any) => !!t);
        dispatch(
          getTemplatesEnd({
            templates: theseTemplates as Template[],
          }),
        );
        return theseTemplates || [];
      })
      .then((theseTemplates) => {
        // attempt to use a roofing template with sortOrder 1, if it exists
        // else just use whatever template comes first
        const preferredTemplate = theseTemplates
          .filter((template: Template) => template.tradeType === 'ROOF')
          .find((template: Template) => template.sortOrder === 1);
        const selectedTemplate = preferredTemplate || theseTemplates[0];

        // select a template id, and store it in redux
        const selectedTemplatesIds = [selectedTemplate?.id];
        dispatch(
          initializeSelectedTemplates({
            templateIds: selectedTemplatesIds,
          }),
        );
        // start up the estimator to gather and store needed inputs and measurements inside redux
        setupEstimator(selectedTemplatesIds, theseTemplates, () => true, false);
      })
      .catch((err) => {
        materialListErrored(err);
      });
  };

  /*
  if an error occurs, stop the process of setting up material list, log the error and notify of error state
  */
  const materialListErrored = (err: any) => {
    setSetupConfigAndEstimateErrored(true);
    console.error(err);
    Sentry.captureException(err);
    setIsSettingUpConfigAndEstimate(false);
  };

  /*
  flags the begining of material list setup
  and sends initial request to create an orgConfigSetup
  */
  const setupConfigAndEstimate = () => {
    setIsSettingUpConfigAndEstimate(true);
    createOrgConfigSetup();
  };

  const recreateEstimateGroupWithTemplates = () => {
    const templates = allTemplates || [];
    const selected = selectedTemplateIds || [];
    setIsRecreatingMaterialList(true);
    setupEstimator(selected, templates, () => true, false);
  };

  return {
    setupConfigAndEstimate,
    setupConfigAndEstimateErrored,
    recreateEstimateGroupWithTemplates,
    isSettingUpConfigAndEstimate,
    isRecreatingMaterialList,
  };
}
