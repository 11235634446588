import { useEffect } from 'react';

import {
  Box,
  Editable,
  EditableInput,
  EditablePreview,
  Input,
  Td,
  Tooltip,
} from '@hover/blueprint';
import { get } from 'lodash';
import { useFormContext } from 'react-hook-form';

import { LineItemTypeEnum } from 'src/api/graphql-global-types';
import { projectManagementProductionList_projectManagementProductionList_listItems as ListItem } from 'src/api/types/projectManagementProductionList';
import { formattedNumber } from 'src/features/project/util/utils';

import { useProjectScopeTracker } from '../../hooks/useProjectScopeTracker';
import { EditableUnitsSelection } from './Inputs/EditableUnitsSelection';

type Props = {
  listItem: ListItem;
  isDisabled: boolean;
  unitsMap: Map<string, string>;
  enableInlineEditingV2: boolean;
  quantityUnit: string;
  jobId: number;
  onUpdate: (inputLabel: string) => void;
};

export const ListItemQuantityColumn = ({
  listItem,
  isDisabled,
  unitsMap,
  quantityUnit,
  jobId,
  enableInlineEditingV2,
  onUpdate,
}: Props) => {
  const isMaterial = listItem?.type === LineItemTypeEnum.MATERIAL;
  const quantityErrorMessage = isMaterial
    ? 'Quantity must be whole number greater than zero'
    : 'Quantity must be greater than zero';

  const { trackInlineEditingInputPressed } = useProjectScopeTracker({ jobId });

  const {
    register,
    setValue,
    getValues,
    formState: { errors: formErrors },
  } = useFormContext();

  const quantityFormValue = getValues().quantity;

  if (!enableInlineEditingV2) {
    return (
      <Td>
        <>
          <Box>{`${listItem.quantity} ${quantityUnit}`}</Box>
          {!!listItem.calculatedQuantity && (
            <Box fontSize={200} color="neutral.500">
              {formattedNumber(listItem.calculatedQuantity)}
            </Box>
          )}
        </>
      </Td>
    );
  }

  return (
    <Td>
      <>
        <Box>
          <Editable
            defaultValue={quantityFormValue}
            isPreviewFocusable
            selectAllOnFocus
            isDisabled={isDisabled}
            onSubmit={() => {
              onUpdate('Quantity');
            }}
            onChange={() => {
              trackInlineEditingInputPressed('Quantity');
            }}
            submitOnBlur={!get(formErrors, 'quantity.message')} // Prevents submission of invalid value, when validation error.
            onCancel={(prev) => {
              // When canceling edit (blurring the field) while the field is invalid,
              // cancel any in-progess saving.
              if (!!get(formErrors, 'quantity')) {
                return;
              }

              // If input is canceled on blur due to validation error, then reset the previous value.
              setValue(
                'quantity',
                parseFloat(prev), // convert display string back to number type.
                {
                  shouldValidate: true,
                  shouldDirty: true,
                },
              );
            }}
            size="tiny"
            display="inline-block"
            width="fit-content"
          >
            <Tooltip label="Click to edit" placement="top">
              <EditablePreview
                width="fit-content"
                minWidth="60px"
                textAlign="right"
              />
            </Tooltip>
            <Tooltip
              label={get(formErrors, 'quantity.message')}
              placement="top"
              background="danger300"
            >
              <Input
                data-test-id="AddMaterial-quantity"
                as={EditableInput}
                size="tiny"
                // isRequired
                isInvalid={!!get(formErrors, 'quantity')}
                maxWidth="60px"
                minWidth="60px"
                textAlign="right"
                type="text"
                {...register('quantity', {
                  valueAsNumber: true,
                  validate: {
                    numberValidator: (quantityValue) => {
                      if (isMaterial) {
                        return Number.isInteger(quantityValue)
                          ? true
                          : quantityErrorMessage;
                      }
                      return Number.isFinite(quantityValue)
                        ? true
                        : quantityErrorMessage;
                    },
                    quantityValidator: (quantityValue) => {
                      return quantityValue > 0 ? true : quantityErrorMessage;
                    },
                  },
                })}
              />
            </Tooltip>
          </Editable>

          <Editable
            value={quantityUnit}
            isPreviewFocusable
            selectAllOnFocus={false}
            size="tiny"
            isDisabled={isDisabled}
            display="inline-block"
            width="fit-content"
            onSubmit={() => {
              onUpdate('Quantity Unit');
            }}
          >
            <Tooltip label="Click to edit" placement="top">
              <EditablePreview />
            </Tooltip>
            <EditableInput
              as={EditableUnitsSelection}
              units={Array.from(unitsMap, ([id, name]) => ({
                id,
                name: name.toUpperCase(),
              }))}
              listItem={listItem as unknown as ListItem}
              jobId={jobId}
              minWidth="56px"
            />
          </Editable>
        </Box>
        {!!listItem.calculatedQuantity && (
          <Box>
            <Box
              display="inline"
              fontSize={200}
              color="neutral.500"
              paddingX={200}
              width="fit-content"
              minWidth="60px"
              textAlign="right"
            >
              {formattedNumber(listItem.calculatedQuantity)}
            </Box>
          </Box>
        )}
      </>
    </Td>
  );
};
