/**
 * This client was automatically generated by Segment Typewriter. ** Do Not Edit **
 */

/**
 * Ajv is a peer dependency for development builds. It's used to apply run-time validation
 * to message payloads before passing them on to the underlying analytics instance.
 *
 * Note that the production bundle does not depend on Ajv.
 *
 * You can install it with: `npm install --save-dev ajv`.
 */
import Ajv from 'ajv';
import * as Segment from './segment';

export interface AccountCreated {
  /**
   * Whether the element pressed was a button or a link.
   */
  button_or_link: string;
  /**
   * The location of the button or link on the page or screen.
   */
  button_or_link_location?: string;
  /**
   * The text on the button or link.
   */
  button_or_link_text?: string;
  /**
   * The name of the page or screen where the button was pressed.
   */
  page_or_screen_name: string;
  /**
   * The platform on which the user signed up. For example, mobile or web.
   */
  platform_type?: string | null;
  /**
   * Whether the button is the primary call to action on the page or screen.
   */
  primary_cta: boolean;
}
export interface AgreedToTosPrivacy {
  /**
   * The location of the checkbox on the page or screen.
   */
  checkbox_location?: string;
  /**
   * The name of the page or screen where the button was pressed.
   */
  page_or_screen_name: string;
  /**
   * Whether the button is the primary call to action on the page or screen.
   */
  primary_cta: boolean;
}
export interface BackButtonPressed {
  /**
   * The location of the button on the page or screen.
   */
  button_location?: string;
  /**
   * The text on the button.
   */
  button_text: string;
  /**
   * The current Design Studio mode
   */
  design_studio_mode?: string;
  /**
   * The name of the page or screen where the button was pressed.
   */
  page_or_screen_name: string;
  /**
   * The Partner if button is embedded with in a Partner specific page context
   */
  partner_id?: number;
  /**
   * Whether the button is the primary call to action on the page or screen.
   */
  primary_cta: boolean;
}
export interface ButtonImpression {
  /**
   * Database object name associated with this event
   */
  backend_id_type?: string;
  /**
   * Database object id associated with this event
   */
  backend_id_value?: string;
  /**
   * The location of the button on the page or screen.
   */
  button_location?: string;
  /**
   * The text on the button.
   */
  button_text: string;
  button_type?: string;
  /**
   * A boolean indicating whether the user is using dense for the current capture
   */
  dense_guidance_capture?: boolean;
  /**
   * A boolean indicating whether dense is enabled for the camera.
   */
  dense_guidance_enabled_for_camera?: boolean;
  /**
   * A string with 3 possible values “disable”, “lite”, “full”. Different dense modes determines what dense features are enabled.
   */
  dense_guidance_mode?: string;
  /**
   * The Design Studio mode the user is currently in
   */
  design_studio_mode?: string;
  /**
   * Name of associated feature
   */
  feature?: string;
  /**
   * The domain object being operated on
   */
  item_type?: string;
  /**
   * The client identifier of the Job the user is capturing.
   */
  job_client_identifier?: string;
  /**
   * The Hyperion Job Id.
   */
  job_id?: number;
  /**
   * The name of the page or screen where the button was pressed.
   */
  page_or_screen_name: string;
  /**
   * The Partner if button is embedded with in a Partner specific page context
   */
  partner_id?: number;
  /**
   * Whether the button is the primary call to action on the page or screen.
   */
  primary_cta: boolean;
  /**
   * Static track id for button
   */
  track_id?: string;
}
export interface ButtonPressed {
  /**
   * Database object name associated with this event
   */
  backend_id_type?: string;
  /**
   * Database object id associated with this event
   */
  backend_id_value?: string;
  /**
   * The location of the button on the page or screen.
   */
  button_location?: string;
  /**
   * The text on the button.
   */
  button_text: string;
  button_type?: string;
  /**
   * A boolean indicating whether the user is using dense for the current capture
   */
  dense_guidance_capture?: boolean;
  /**
   * A boolean indicating whether dense is enabled for the camera.
   */
  dense_guidance_enabled_for_camera?: boolean;
  /**
   * A string with 3 possible values “disable”, “lite”, “full”. Different dense modes determines what dense features are enabled.
   */
  dense_guidance_mode?: string;
  /**
   * The Design Studio mode the user is currently in
   */
  design_studio_mode?: string;
  /**
   * Name of associated feature
   */
  feature?: string;
  /**
   * The domain object being operated on
   */
  item_type?: string;
  /**
   * The client identifier of the Job the user is capturing.
   */
  job_client_identifier?: string;
  /**
   * The Hyperion Job Id.
   */
  job_id?: number;
  /**
   * The name of the page or screen where the button was pressed.
   */
  page_or_screen_name: string;
  /**
   * The Partner if button is embedded with in a Partner specific page context
   */
  partner_id?: number;
  /**
   * Whether the button is the primary call to action on the page or screen.
   */
  primary_cta: boolean;
  /**
   * Static track id for button
   */
  track_id?: string;
}
export interface CaptureStarted {
  /**
   * The location of the button on the page or screen.
   */
  button_location?: string;
  /**
   * The text on the button.
   */
  button_text: string;
  /**
   * The client identifier of the Job the user is capturing.
   */
  job_client_identifier: string;
  /**
	 * The name of the page or screen where the button was pressed.

	 */
  page_or_screen_name: string;
  /**
   * Whether the button is the primary call to action on the page or screen.
   */
  primary_cta: boolean;
}
export interface CaptureSubmitted {
  /**
	 * The location of the button on the page or screen.

	 */
  button_location?: string;
  /**
	 * The text on the button.

	 */
  button_text: string;
  /**
   * The client identifier of the Job the user is capturing.
   */
  job_client_identifier: string;
  /**
	 * The name of the page or screen where the button was pressed.

	 */
  page_or_screen_name: string;
  /**
   * Whether the button is the primary call to action on the page or screen.
   */
  primary_cta: boolean;
  /**
   * Progressive Uploads was enabled for the capture.
   */
  progressive_upload?: boolean;
}
export interface CheckboxSelected {
  /**
   * Database object name associated with this event
   */
  backend_id_type?: string;
  /**
   * Database object value associated with this event
   */
  backend_id_value?: string;
  /**
   * The Hyperion Job Id
   */
  job_id?: number;
  /**
   * The description of the set of options
   */
  options: string;
  /**
   * Where does this checkbox appear
   */
  page_or_screen_name?: string;
  /**
   * Is this field the primary CTA
   */
  primary_cta?: boolean;
  /**
   * The thing being selected
   */
  selection: string;
  /**
   * Id of the user
   */
  user_id?: number;
}
export interface CheckboxToggled {
  /**
   * The location of the button on the page or screen.
   */
  button_location?: string;
  /**
   * Value of checkbox
   */
  checked: boolean;
  /**
   * A boolean indicating whether the user is using dense for the current capture
   */
  dense_guidance_capture?: boolean;
  /**
   * A boolean indicating whether dense is enabled for the camera.
   */
  dense_guidance_enabled_for_camera?: boolean;
  /**
   * A string with 3 possible values “disable”, “lite”, “full”. Different dense modes determines what dense features are enabled.
   */
  dense_guidance_mode?: string;
  /**
   * The Design Studio mode the user is currently in
   */
  design_studio_mode?: string;
  /**
   * Name of associated feature
   */
  feature?: string;
  /**
   * The client identifier of the Job the user is capturing.
   */
  job_client_identifier?: string;
  /**
   * The Hyperion Job Id.
   */
  job_id?: number;
  /**
   * The name of the page or screen where the button was pressed.
   */
  page_or_screen_name: string;
  /**
   * The Partner if button is embedded with in a Partner specific page context
   */
  partner_id?: number;
  /**
   * Whether the button is the primary call to action on the page or screen.
   */
  primary_cta: boolean;
  /**
   * Static track id for button
   */
  track_id?: string;
}
export interface DataSaved {
  /**
   * The backend id type of the item represented by the saved data.
   */
  backend_id_type?: string;
  /**
   * The backend id value of the item represented by the saved data.
   */
  backend_id_value?: string;
  /**
   * The feature that the saved data is associated with.
   */
  feature?: string;
  /**
   * the label of input used to save data (see inputPressed event for parallel field)
   */
  input_label?: string;
  /**
   * The item/object type that the saved data is associated with.
   */
  item_type?: string;
  /**
   * The page or screen where the save occurred.
   */
  page_or_screen_name?: string;
  /**
   * The parent item/object id that the saved data is associated with. This could be a `job`, `estimate`, `sales_opportunity`, etc.
   */
  parent_id?: number;
  /**
   * The data payload of the saved data.
   */
  saved_data?: Record<string, any>;
}
export interface DenseCaptureStarted {
  /**
   * A boolean indicating whether the user is using dense for the current capture
   */
  dense_guidance_capture: boolean;
  /**
   * A boolean indicating whether dense is enabled for the camera.
   */
  dense_guidance_enabled_for_camera: boolean;
  /**
   * A string with 3 possible values “disable”, “lite”, “full”. Different dense modes determines what dense features are enabled.
   */
  dense_guidance_mode: string;
  /**
   * The client identifier of the Job the user is capturing.
   */
  job_client_identifier?: string;
  /**
	 * The name of the page or screen where the button was pressed.

	 */
  page_or_screen_name: string;
}
export interface ElementDragged {
  job_id?: number;
  name: string;
  new_position: number;
  old_position: number;
}
export interface Error {
  /**
   * the error message displayed to users
   */
  message?: string;
  /**
   * HTTP status code e.g. 401
   */
  status?: number;
  /**
   * what kind of error
   */
  type: string;
}
export interface FeatureUpvoted {
  /**
   * The name of the feature the user upvoted.
   */
  feature: string;
  /**
   * The Hyperion Job Id.
   */
  job_id?: number;
  /**
   * The name of the page or screen where the feature was upvoted.
   */
  page_or_screen_name: string;
}
export interface HomeownerIdentified {
  /**
   * The location of the button on the page or screen.
   */
  button_location?: string;
  /**
   * The text on the button.
   */
  button_text: string;
  /**
   * The name of the page or screen where the button was pressed.
   */
  page_or_screen_name: string;
  /**
   * Whether the button is the primary call to action on the page or screen.
   */
  primary_cta: boolean;
}
export interface InputPressed {
  /**
   * Name of associated feature
   */
  feature?: string;
  /**
   * The label of the input
   */
  input_label?: string;
  /**
   * The Hyperion org_id
   */
  org_id?: number;
  /**
   * The Hyperion user id
   */
  user_id?: number;
}
export interface InsuranceIdentified {
  /**
   * The location of the button on the page or screen.
   */
  button_location?: string;
  /**
   * The text on the button.
   */
  button_text: string;
  /**
   * The name of the page or screen where the button was pressed.
   */
  page_or_screen_name: string;
  /**
   * Whether the button is the primary call to action on the page or screen.
   */
  primary_cta: boolean;
}
export interface LinkPressed {
  /**
   * Database object name associated with this event
   */
  backend_id_type?: string;
  /**
   * Database object id associated with this event
   */
  backend_id_value?: string;
  /**
   * The Design Studio mode the user is currently in
   */
  design_studio_mode?: string;
  /**
   * Name of associated feature
   */
  feature?: string;
  /**
   * The Hyperion job id
   */
  job_id?: number;
  /**
   * The location of the link on the page or screen.
   */
  link_location?: string;
  /**
   * The text on the link.
   */
  link_text: string;
  /**
   * The name of the page or screen where the link was pressed.
   */
  page_or_screen_name: string;
  /**
   * The Partner if link is embedded with in a Partner specific page context
   */
  partner_id?: number;
  /**
   * Whether the link is the primary call to action on the page or screen.
   */
  primary_cta: boolean;
  /**
   * Static id for tracking link
   */
  track_id?: string;
}
export interface LoginSsoStarted {
  /**
   * Whether the element pressed was a button or a link.
   */
  button_or_link: string;
  /**
   * The location of the button or link on the page or screen.
   */
  button_or_link_location?: string;
  /**
   * The text on the button.
   */
  button_text: string;
  /**
   * The name of the page or screen where the button was pressed.
   */
  page_or_screen_name: string;
  /**
   * Whether the button is the primary call to action on the page or screen.
   */
  primary_cta: boolean;
}
export interface LoginStarted {
  /**
   * Whether the element pressed was a button or a link.
   */
  button_or_link: string;
  /**
   * The location of the button on the page or screen.
   */
  button_or_link_location?: string;
  /**
   * The text on the button.
   */
  button_text: string;
  /**
   * The name of the page or screen where the button was pressed.
   */
  page_or_screen_name: string;
  /**
   * Whether the button is the primary call to action on the page or screen.
   */
  primary_cta: boolean;
}
export interface ModalViewed {
  ''?: Record<string, any>;
  /**
   * A boolean indicating whether the user is using dense for the current capture
   */
  dense_guidance_capture?: boolean;
  /**
   * A boolean indicating whether dense is enabled for the camera.
   */
  dense_guidance_enabled_for_camera?: boolean;
  /**
   * A string with 3 possible values “disable”, “lite”, “full”. Different dense modes determines what dense features are enabled.
   */
  dense_guidance_mode?: string;
  /**
   * Name of associated feature
   */
  feature?: string;
  /**
   * The client identifier of the Job the user is capturing.
   */
  job_client_identifier?: string;
  /**
   * The name of the modal.
   */
  name: string;
}
export interface ModelComponentSelected {
  /**
   * Database object name associated with this event
   */
  backend_id_type?: string;
  /**
   * Database object value associated with this event
   */
  backend_id_value?: string;
  /**
   * The Hyperion Job Id
   */
  job_id?: number;
  /**
   * Where does this model component appear
   */
  page_or_screen_name?: string;
  /**
   * Is this field the primary cta
   */
  primary_cta?: boolean;
  /**
   * Id of the user
   */
  user_id?: number;
}
export interface ModelInteracted {
  /**
   * The Design Studio mode the user is currently in
   */
  design_studio_mode?: string;
  /**
   * If Design Studio is embedded in an iframe, this value will show if the model interacted event happened while the iframe is in fullscreen mode
   */
  fullscreen_iframe?: boolean;
  /**
   * If Design Studio is embedded in an iframe, this is the "location" of the iframe (e.g. hover_property-details-page, james-hardie_homepage, unknown, etc...). This property needs to be manually set as a URL param in the iframe src url.
   */
  iframe_location?: string;
  /**
   * The type of interaction the user made. Possible values are "rotate", "pan", or "zoom".
   */
  interaction_type: string;
  /**
   * The Hyperion Job Id.
   */
  job_id?: number;
}
export interface ModuleViewed {
  /**
   * The name of the associated feature
   */
  feature?: string;
  /**
   * The Hyperion Job ID
   */
  job_id?: number;
  /**
   * User's org ID
   */
  org_id?: number;
  /**
   * The name of the page or screen where the button was pressed.
   */
  page_or_screen_name?: string;
  /**
   * Static track id for module viewed
   */
  track_id?: string;
  /**
   * The Hyperion Job ID
   */
  user_id?: number;
}
export interface OptionSelected {
  /**
   * Database object name associated with this event
   */
  backend_id_type?: string;
  /**
   * Database object value associated with this event
   */
  backend_id_value?: string;
  /**
	 * Name of associated feature

	 */
  feature?: string;
  /**
   * The Hyperion Job Id
   */
  job_id?: number;
  /**
   * The type of frontend selector (radio, dropdown)
   */
  option_type?: string;
  /**
   * The description of the set of options
   */
  options: string;
  /**
   * Where does this select appear
   */
  page_or_screen_name?: string;
  /**
   * Is this field the primary CTA
   */
  primary_cta?: boolean;
  /**
   * The thing being selected
   */
  selection: string;
  /**
   * Id of the user
   */
  user_id?: number;
}
export interface PageViewed {
  /**
   * The Design Studio mode the user is currently in
   */
  design_studio_mode?: string;
  /**
   * Name of associated feature
   */
  feature?: string;
  /**
   * The Hyperion Job Id.
   */
  job_id?: number;
  /**
   * The name of the page.
   */
  page_or_screen_name?: string;
  /**
   * Partner specific page
   */
  partner_id?: number;
}
export interface ProductSelected {
  /**
   * The category of product selected. For example, "Walls", "Roof", "Trim", etc...
   */
  category?: string | null;
  /**
   * The color group from a color selected that was not defined in texture details, "Popular", "Statement", "Reds", etc...
   */
  color_group?: string | null;
  /**
   * The hexadecimal representation of the color selected. For example, "#ffffff", "#ebdec4", "#ddb582", etc...
   */
  color_hex?: string | null;
  /**
   * The name of the color selected. For example generic names "Reds" and "Greens", or branded names "Rustic Barn", "Wintery White", etc...
   */
  color_name?: string | null;
  /**
   * The Design Studio mode the user is currently in
   */
  design_studio_mode?: string;
  /**
   * The parametric frame type for a window selected (will only be present if the product selected is a window). For example "casement" or "slider"...
   */
  frame?: string | null;
  /**
   * The parametric grid type for a window selected (will only be present if the product selected is a window). For example "diamond" or "farmhouse"...
   */
  grid?: string | null;
  /**
   * The Hyperion Job Id.
   */
  job_id?: number;
  /**
   * The name of the page or screen where the product was selected.
   */
  page_or_screen_name: string;
  /**
   * The parameter of the product the user selected. For example, “Roofing Types”, “Style”, etc..
   */
  parameter: string;
  /**
   * The Partner if button is embedded with in a Partner specific page context
   */
  partner_id?: number;
  /**
   * The kind of product the user selected. For example, "Walls", "Roof", "Trim", etc..
   */
  product: string | null;
  /**
   * The type of texture. For example "horizontal", "stone", "asphalt", "brick", etc...
   */
  product_type?: string | null;
  /**
   * The option the user selected. For example, “Metal”, “Standing Seam”, etc..
   */
  selection: any | null;
  /**
   * The color group assigned with the other texture properties. For example "Beiges", "Reds", etc...
   */
  texture_color_group?: string | null;
  /**
   * The id of the texture selected.
   */
  texture_id?: number | null;
  /**
   * The name of texture color, typically a duplicate of texture_color_group
   */
  texture_name?: string | null;
  /**
   * The shape of the texture. For example "Straight Shake", "Staggered Shake", "Scalloped", etc...
   */
  texture_shape?: string | null;
  /**
   * The size of the selected texture. For example `3"`, `4"`, `4.5"`, etc...
   */
  texture_size?: string | null;
  /**
   * The style of the selected texture. For example "Dutch Lap", "Board and Batten", etc...
   */
  texture_style?: string | null;
  /**
   * The type of texture. For example "Cedarmill" or "Smooth"
   */
  texture_type?: string | null;
}
export interface ProfessionalIdentified {
  /**
   * The location of the button on the page or screen.
   */
  button_location?: string;
  /**
   * The text on the button.
   */
  button_text: string;
  /**
   * The name of the page or screen where the button was pressed.
   */
  page_or_screen_name: string;
  /**
   * Whether the button is the primary call to action on the page or screen.
   */
  primary_cta: boolean;
}
export interface PropertyPressed {
  /**
   * The location of the button on the page or screen.
   */
  button_location?: string;
  /**
   * The text on the button.
   */
  button_text: string;
  /**
   * Whether the property is an example job.
   */
  example_job?: boolean;
  /**
   * The name of the page or screen where the button was pressed.
   */
  page_or_screen_name: string;
  /**
   * Whether the button is the primary call to action on the page or screen.
   */
  primary_cta: boolean;
}
export interface RequestFormOpened {
  /**
   * Name of associated feature
   */
  feature?: string;
  /**
   * The ID of the job for which the rendering is for
   */
  job_id?: string;
  /**
   * The Org of the requesting user
   */
  org_id?: string;
  rendering_id?: string;
  /**
   * Static track id for button
   */
  track_id?: string;
}
export interface ScreenViewed {
  /**
   * A boolean indicating whether the user is using dense for the current capture
   */
  dense_guidance_capture?: boolean;
  /**
   * A boolean indicating whether dense is enabled for the camera.
   */
  dense_guidance_enabled_for_camera?: boolean;
  /**
   * A string with 3 possible values “disable”, “lite”, “full”. Different dense modes determines what dense features are enabled.
   */
  dense_guidance_mode?: string;
  /**
   * The client identifier of the Job the user is capturing.
   */
  job_client_identifier?: string;
}
export interface SettingChanged {
  /**
   * The name of the setting.
   */
  setting_text: string;
  /**
   * The value the setting was changed to.
   */
  value: any | null;
}
export interface SignupStarted {
  /**
   * The location of the button on the page or screen.
   */
  button_location?: string;
  /**
   * Whether the element pressed was a button or a link.
   */
  button_or_link: string;
  /**
   * The text on the button.
   */
  button_text: string;
  /**
   * The name of the page or screen where the button was pressed.
   */
  page_or_screen_name: string;
  /**
   * Whether the button is the primary call to action on the page or screen.
   */
  primary_cta: boolean;
}
export interface SupportContacted {
  /**
   * Whether the element pressed was a button or a link.
   */
  button_or_link: string;
  /**
   * The location of the button or link on the page or screen.
   */
  button_or_link_location?: string;
  /**
   * The text on the button or link.
   */
  button_or_link_text: string;
  /**
   * The name of the page or screen where the button was pressed.
   */
  page_or_screen_name: string;
  /**
   * Whether the button is the primary call to action on the page or screen.
   */
  primary_cta: boolean;
}
export interface Swipe {
  /**
   * Name of associated feature
   */
  feature?: string;
  /**
   * The Hyperion job id
   */
  job_id?: number;
  /**
   * The Hyperion org id
   */
  org_id?: number;
  /**
   * The name of the page or screen where the user swiped
   */
  page_or_screen_name: string;
  /**
   * Static track id for swipe
   */
  track_id?: string;
  /**
   * The Hyperion user id
   */
  user_id?: number;
}
export interface TabViewed {
  button_location?: string;
  /**
   * The Design Studio mode the user is currently in
   */
  design_studio_mode?: string;
  /**
   * Name of associated feature
   */
  feature?: string;
  /**
   * The Hyperion Job Id.
   */
  job_id?: number;
  /**
   * The name of the page.
   */
  page_or_screen_name?: string;
  /**
   * Partner specific page
   */
  partner_id?: number;
}
export interface TextInput {
  /**
   * Database object name associated with this event
   */
  backend_id_type?: string;
  /**
   * Database object value associated with this event
   */
  backend_id_value?: string;
  /**
   * The label of the text input.
   */
  input_label?: string;
  /**
   * The location of the input on the page or screen.
   */
  input_location?: string;
  /**
   * The value of the text input.
   */
  input_value: string;
  /**
   * The Hyperion Job Id
   */
  job_id?: number;
  /**
   * Where does this text input appear
   */
  page_or_screen_name?: string;
}

export type ViolationHandler = (
  message: Record<string, any>,
  violations: Ajv.ErrorObject[],
) => void;

/**
 * The default handler that is fired if none is supplied with setTypewriterOptions.
 * This handler will log a warning message to the console.
 */
export const defaultValidationErrorHandler: ViolationHandler = (
  message,
  violations,
) => {
  const msg = JSON.stringify(
    {
      type: 'Typewriter JSON Schema Validation Error',
      description:
        `You made an analytics call (${message.event}) using Typewriter that doesn't match the ` +
        'Tracking Plan spec.',
      errors: violations,
    },
    undefined,
    2,
  );

  console.warn(msg);
};

let onViolation = defaultValidationErrorHandler;

let analytics: () => SegmentAnalytics.AnalyticsJS | undefined = () => {
  return window.analytics;
};

/** Options to customize the runtime behavior of a Typewriter client. */
export interface TypewriterOptions {
  /**
   * Underlying analytics instance where analytics calls are forwarded on to.
   * Defaults to window.analytics.
   */
  analytics?: SegmentAnalytics.AnalyticsJS;
  /**
   * Handler fired when if an event does not match its spec. This handler
   * does not fire in production mode, because it requires inlining the full
   * JSON Schema spec for each event in your Tracking Plan.
   *
   * By default, it will throw errors if NODE_ENV = "test" so that tests will fail
   * if a message does not match the spec. Otherwise, errors will be logged to stderr.
   */
  onViolation?: ViolationHandler;
}

/**
 * Updates the run-time configuration of this Typewriter client.
 *
 * @param {TypewriterOptions} options - the options to upsert
 *
 * @typedef {Object} TypewriterOptions
 * @property {Segment.AnalyticsJS} [analytics] - Underlying analytics instance where analytics
 * 		calls are forwarded on to. Defaults to window.analytics.
 * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
 * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
 * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
 * 		will be logged to stderr.
 */
export function setTypewriterOptions(options: TypewriterOptions) {
  analytics = options.analytics
    ? () => options.analytics || window.analytics
    : analytics;
  onViolation = options.onViolation || onViolation;
}

/**
 * Validates a message against a JSON Schema using Ajv. If the message
 * is invalid, the `onViolation` handler will be called.
 */
function validateAgainstSchema(message: Record<string, any>, schema: object) {
  const ajv = new Ajv({ schemaId: 'auto', allErrors: true, verbose: true });
  ajv.addMetaSchema(require('ajv/lib/refs/json-schema-draft-06.json'));
  ajv.addMetaSchema(require('ajv/lib/refs/json-schema-draft-04.json'));

  if (!ajv.validate(schema, message) && ajv.errors) {
    onViolation(message, ajv.errors);
  }
}

/**
 * Helper to attach metadata on Typewriter to outbound requests.
 * This is used for attribution and debugging by the Segment team.
 */
function withTypewriterContext(message: Segment.Options = {}): Segment.Options {
  return {
    ...message,
    context: {
      ...(message.context || {}),
      typewriter: {
        language: 'typescript',
        version: '7.4.1',
      },
    },
  };
}

/**
 * @typedef AccountCreated
 * @property {string} button_or_link - Whether the element pressed was a button or a link.
 * @property {string} [button_or_link_location] - The location of the button or link on the page or screen.
 * @property {string} [button_or_link_text] - The text on the button or link.
 * @property {string} page_or_screen_name - The name of the page or screen where the button was pressed.
 * @property {string | null} [platform_type] - The platform on which the user signed up. For example, mobile or web.
 * @property {boolean} primary_cta - Whether the button is the primary call to action on the page or screen.
 */
/**
 * @typedef AgreedToTosPrivacy
 * @property {string} [checkbox_location] - The location of the checkbox on the page or screen.
 * @property {string} page_or_screen_name - The name of the page or screen where the button was pressed.
 * @property {boolean} primary_cta - Whether the button is the primary call to action on the page or screen.
 */
/**
 * @typedef BackButtonPressed
 * @property {string} [button_location] - The location of the button on the page or screen.
 * @property {string} button_text - The text on the button.
 * @property {string} [design_studio_mode] - The current Design Studio mode
 * @property {string} page_or_screen_name - The name of the page or screen where the button was pressed.
 * @property {number} [partner_id] - The Partner if button is embedded with in a Partner specific page context
 * @property {boolean} primary_cta - Whether the button is the primary call to action on the page or screen.
 */
/**
 * @typedef ButtonImpression
 * @property {string} [backend_id_type] - Database object name associated with this event
 * @property {string} [backend_id_value] - Database object id associated with this event
 * @property {string} [button_location] - The location of the button on the page or screen.
 * @property {string} button_text - The text on the button.
 * @property {string} [button_type] -
 * @property {boolean} [dense_guidance_capture] - A boolean indicating whether the user is using dense for the current capture
 * @property {boolean} [dense_guidance_enabled_for_camera] - A boolean indicating whether dense is enabled for the camera.
 * @property {string} [dense_guidance_mode] - A string with 3 possible values “disable”, “lite”, “full”. Different dense modes determines what dense features are enabled.
 * @property {string} [design_studio_mode] - The Design Studio mode the user is currently in
 * @property {string} [feature] - Name of associated feature
 * @property {string} [item_type] - The domain object being operated on
 * @property {string} [job_client_identifier] - The client identifier of the Job the user is capturing.
 * @property {number} [job_id] - The Hyperion Job Id.
 * @property {string} page_or_screen_name - The name of the page or screen where the button was pressed.
 * @property {number} [partner_id] - The Partner if button is embedded with in a Partner specific page context
 * @property {boolean} primary_cta - Whether the button is the primary call to action on the page or screen.
 * @property {string} [track_id] - Static track id for button
 */
/**
 * @typedef ButtonPressed
 * @property {string} [backend_id_type] - Database object name associated with this event
 * @property {string} [backend_id_value] - Database object id associated with this event
 * @property {string} [button_location] - The location of the button on the page or screen.
 * @property {string} button_text - The text on the button.
 * @property {string} [button_type] -
 * @property {boolean} [dense_guidance_capture] - A boolean indicating whether the user is using dense for the current capture
 * @property {boolean} [dense_guidance_enabled_for_camera] - A boolean indicating whether dense is enabled for the camera.
 * @property {string} [dense_guidance_mode] - A string with 3 possible values “disable”, “lite”, “full”. Different dense modes determines what dense features are enabled.
 * @property {string} [design_studio_mode] - The Design Studio mode the user is currently in
 * @property {string} [feature] - Name of associated feature
 * @property {string} [item_type] - The domain object being operated on
 * @property {string} [job_client_identifier] - The client identifier of the Job the user is capturing.
 * @property {number} [job_id] - The Hyperion Job Id.
 * @property {string} page_or_screen_name - The name of the page or screen where the button was pressed.
 * @property {number} [partner_id] - The Partner if button is embedded with in a Partner specific page context
 * @property {boolean} primary_cta - Whether the button is the primary call to action on the page or screen.
 * @property {string} [track_id] - Static track id for button
 */
/**
 * @typedef CaptureStarted
 * @property {string} [button_location] - The location of the button on the page or screen.
 * @property {string} button_text - The text on the button.
 * @property {string} job_client_identifier - The client identifier of the Job the user is capturing.
 * @property {string} page_or_screen_name - The name of the page or screen where the button was pressed.

 * @property {boolean} primary_cta - Whether the button is the primary call to action on the page or screen.
 */
/**
 * @typedef CaptureSubmitted
 * @property {string} [button_location] - The location of the button on the page or screen.

 * @property {string} button_text - The text on the button.

 * @property {string} job_client_identifier - The client identifier of the Job the user is capturing.
 * @property {string} page_or_screen_name - The name of the page or screen where the button was pressed.

 * @property {boolean} primary_cta - Whether the button is the primary call to action on the page or screen.
 * @property {boolean} [progressive_upload] - Progressive Uploads was enabled for the capture.
 */
/**
 * @typedef CheckboxSelected
 * @property {string} [backend_id_type] - Database object name associated with this event
 * @property {string} [backend_id_value] - Database object value associated with this event
 * @property {number} [job_id] - The Hyperion Job Id
 * @property {string} options - The description of the set of options
 * @property {string} [page_or_screen_name] - Where does this checkbox appear
 * @property {boolean} [primary_cta] - Is this field the primary CTA
 * @property {string} selection - The thing being selected
 * @property {number} [user_id] - Id of the user
 */
/**
 * @typedef CheckboxToggled
 * @property {string} [button_location] - The location of the button on the page or screen.
 * @property {boolean} checked - Value of checkbox
 * @property {boolean} [dense_guidance_capture] - A boolean indicating whether the user is using dense for the current capture
 * @property {boolean} [dense_guidance_enabled_for_camera] - A boolean indicating whether dense is enabled for the camera.
 * @property {string} [dense_guidance_mode] - A string with 3 possible values “disable”, “lite”, “full”. Different dense modes determines what dense features are enabled.
 * @property {string} [design_studio_mode] - The Design Studio mode the user is currently in
 * @property {string} [feature] - Name of associated feature
 * @property {string} [job_client_identifier] - The client identifier of the Job the user is capturing.
 * @property {number} [job_id] - The Hyperion Job Id.
 * @property {string} page_or_screen_name - The name of the page or screen where the button was pressed.
 * @property {number} [partner_id] - The Partner if button is embedded with in a Partner specific page context
 * @property {boolean} primary_cta - Whether the button is the primary call to action on the page or screen.
 * @property {string} [track_id] - Static track id for button
 */
/**
 * @typedef DataSaved
 * @property {string} [backend_id_type] - The backend id type of the item represented by the saved data.
 * @property {string} [backend_id_value] - The backend id value of the item represented by the saved data.
 * @property {string} [feature] - The feature that the saved data is associated with.
 * @property {string} [input_label] - the label of input used to save data (see inputPressed event for parallel field)
 * @property {string} [item_type] - The item/object type that the saved data is associated with.
 * @property {string} [page_or_screen_name] - The page or screen where the save occurred.
 * @property {number} [parent_id] - The parent item/object id that the saved data is associated with. This could be a `job`, `estimate`, `sales_opportunity`, etc.
 * @property {Record<string, any>} [saved_data] - The data payload of the saved data.
 */
/**
 * @typedef DenseCaptureStarted
 * @property {boolean} dense_guidance_capture - A boolean indicating whether the user is using dense for the current capture
 * @property {boolean} dense_guidance_enabled_for_camera - A boolean indicating whether dense is enabled for the camera.
 * @property {string} dense_guidance_mode - A string with 3 possible values “disable”, “lite”, “full”. Different dense modes determines what dense features are enabled.
 * @property {string} [job_client_identifier] - The client identifier of the Job the user is capturing.
 * @property {string} page_or_screen_name - The name of the page or screen where the button was pressed.

 */
/**
 * @typedef ElementDragged
 * @property {number} [job_id] -
 * @property {string} name -
 * @property {number} new_position -
 * @property {number} old_position -
 */
/**
 * @typedef Error
 * @property {string} [message] - the error message displayed to users
 * @property {number} [status] - HTTP status code e.g. 401
 * @property {string} type - what kind of error
 */
/**
 * @typedef FeatureUpvoted
 * @property {string} feature - The name of the feature the user upvoted.
 * @property {number} [job_id] - The Hyperion Job Id.
 * @property {string} page_or_screen_name - The name of the page or screen where the feature was upvoted.
 */
/**
 * @typedef HomeownerIdentified
 * @property {string} [button_location] - The location of the button on the page or screen.
 * @property {string} button_text - The text on the button.
 * @property {string} page_or_screen_name - The name of the page or screen where the button was pressed.
 * @property {boolean} primary_cta - Whether the button is the primary call to action on the page or screen.
 */
/**
 * @typedef InputPressed
 * @property {string} [feature] - Name of associated feature
 * @property {string} [input_label] - The label of the input
 * @property {number} [org_id] - The Hyperion org_id
 * @property {number} [user_id] - The Hyperion user id
 */
/**
 * @typedef InsuranceIdentified
 * @property {string} [button_location] - The location of the button on the page or screen.
 * @property {string} button_text - The text on the button.
 * @property {string} page_or_screen_name - The name of the page or screen where the button was pressed.
 * @property {boolean} primary_cta - Whether the button is the primary call to action on the page or screen.
 */
/**
 * @typedef LinkPressed
 * @property {string} [backend_id_type] - Database object name associated with this event
 * @property {string} [backend_id_value] - Database object id associated with this event
 * @property {string} [design_studio_mode] - The Design Studio mode the user is currently in
 * @property {string} [feature] - Name of associated feature
 * @property {number} [job_id] - The Hyperion job id
 * @property {string} [link_location] - The location of the link on the page or screen.
 * @property {string} link_text - The text on the link.
 * @property {string} page_or_screen_name - The name of the page or screen where the link was pressed.
 * @property {number} [partner_id] - The Partner if link is embedded with in a Partner specific page context
 * @property {boolean} primary_cta - Whether the link is the primary call to action on the page or screen.
 * @property {string} [track_id] - Static id for tracking link
 */
/**
 * @typedef LoginSsoStarted
 * @property {string} button_or_link - Whether the element pressed was a button or a link.
 * @property {string} [button_or_link_location] - The location of the button or link on the page or screen.
 * @property {string} button_text - The text on the button.
 * @property {string} page_or_screen_name - The name of the page or screen where the button was pressed.
 * @property {boolean} primary_cta - Whether the button is the primary call to action on the page or screen.
 */
/**
 * @typedef LoginStarted
 * @property {string} button_or_link - Whether the element pressed was a button or a link.
 * @property {string} [button_or_link_location] - The location of the button on the page or screen.
 * @property {string} button_text - The text on the button.
 * @property {string} page_or_screen_name - The name of the page or screen where the button was pressed.
 * @property {boolean} primary_cta - Whether the button is the primary call to action on the page or screen.
 */
/**
 * @typedef ModalViewed
 * @property {Record<string, any>} [] -
 * @property {boolean} [dense_guidance_capture] - A boolean indicating whether the user is using dense for the current capture
 * @property {boolean} [dense_guidance_enabled_for_camera] - A boolean indicating whether dense is enabled for the camera.
 * @property {string} [dense_guidance_mode] - A string with 3 possible values “disable”, “lite”, “full”. Different dense modes determines what dense features are enabled.
 * @property {string} [feature] - Name of associated feature
 * @property {string} [job_client_identifier] - The client identifier of the Job the user is capturing.
 * @property {string} name - The name of the modal.
 */
/**
 * @typedef ModelComponentSelected
 * @property {string} [backend_id_type] - Database object name associated with this event
 * @property {string} [backend_id_value] - Database object value associated with this event
 * @property {number} [job_id] - The Hyperion Job Id
 * @property {string} [page_or_screen_name] - Where does this model component appear
 * @property {boolean} [primary_cta] - Is this field the primary cta
 * @property {number} [user_id] - Id of the user
 */
/**
 * @typedef ModelInteracted
 * @property {string} [design_studio_mode] - The Design Studio mode the user is currently in
 * @property {boolean} [fullscreen_iframe] - If Design Studio is embedded in an iframe, this value will show if the model interacted event happened while the iframe is in fullscreen mode
 * @property {string} [iframe_location] - If Design Studio is embedded in an iframe, this is the "location" of the iframe (e.g. hover_property-details-page, james-hardie_homepage, unknown, etc...). This property needs to be manually set as a URL param in the iframe src url.
 * @property {string} interaction_type - The type of interaction the user made. Possible values are "rotate", "pan", or "zoom".
 * @property {number} [job_id] - The Hyperion Job Id.
 */
/**
 * @typedef ModuleViewed
 * @property {string} [feature] - The name of the associated feature
 * @property {number} [job_id] - The Hyperion Job ID
 * @property {number} [org_id] - User's org ID
 * @property {string} [page_or_screen_name] - The name of the page or screen where the button was pressed.
 * @property {string} [track_id] - Static track id for module viewed
 * @property {number} [user_id] - The Hyperion Job ID
 */
/**
 * @typedef OptionSelected
 * @property {string} [backend_id_type] - Database object name associated with this event
 * @property {string} [backend_id_value] - Database object value associated with this event
 * @property {string} [feature] - Name of associated feature

 * @property {number} [job_id] - The Hyperion Job Id
 * @property {string} [option_type] - The type of frontend selector (radio, dropdown)
 * @property {string} options - The description of the set of options
 * @property {string} [page_or_screen_name] - Where does this select appear
 * @property {boolean} [primary_cta] - Is this field the primary CTA
 * @property {string} selection - The thing being selected
 * @property {number} [user_id] - Id of the user
 */
/**
 * @typedef PageViewed
 * @property {string} [design_studio_mode] - The Design Studio mode the user is currently in
 * @property {string} [feature] - Name of associated feature
 * @property {number} [job_id] - The Hyperion Job Id.
 * @property {string} [page_or_screen_name] - The name of the page.
 * @property {number} [partner_id] - Partner specific page
 */
/**
 * @typedef ProductSelected
 * @property {string | null} [category] - The category of product selected. For example, "Walls", "Roof", "Trim", etc...
 * @property {string | null} [color_group] - The color group from a color selected that was not defined in texture details, "Popular", "Statement", "Reds", etc...
 * @property {string | null} [color_hex] - The hexadecimal representation of the color selected. For example, "#ffffff", "#ebdec4", "#ddb582", etc...
 * @property {string | null} [color_name] - The name of the color selected. For example generic names "Reds" and "Greens", or branded names "Rustic Barn", "Wintery White", etc...
 * @property {string} [design_studio_mode] - The Design Studio mode the user is currently in
 * @property {string | null} [frame] - The parametric frame type for a window selected (will only be present if the product selected is a window). For example "casement" or "slider"...
 * @property {string | null} [grid] - The parametric grid type for a window selected (will only be present if the product selected is a window). For example "diamond" or "farmhouse"...
 * @property {number} [job_id] - The Hyperion Job Id.
 * @property {string} page_or_screen_name - The name of the page or screen where the product was selected.
 * @property {string} parameter - The parameter of the product the user selected. For example, “Roofing Types”, “Style”, etc..
 * @property {number} [partner_id] - The Partner if button is embedded with in a Partner specific page context
 * @property {string | null} product - The kind of product the user selected. For example, "Walls", "Roof", "Trim", etc..
 * @property {string | null} [product_type] - The type of texture. For example "horizontal", "stone", "asphalt", "brick", etc...
 * @property {any | null} selection - The option the user selected. For example, “Metal”, “Standing Seam”, etc..
 * @property {string | null} [texture_color_group] - The color group assigned with the other texture properties. For example "Beiges", "Reds", etc...
 * @property {number | null} [texture_id] - The id of the texture selected.
 * @property {string | null} [texture_name] - The name of texture color, typically a duplicate of texture_color_group
 * @property {string | null} [texture_shape] - The shape of the texture. For example "Straight Shake", "Staggered Shake", "Scalloped", etc...
 * @property {string | null} [texture_size] - The size of the selected texture. For example `3"`, `4"`, `4.5"`, etc...
 * @property {string | null} [texture_style] - The style of the selected texture. For example "Dutch Lap", "Board and Batten", etc...
 * @property {string | null} [texture_type] - The type of texture. For example "Cedarmill" or "Smooth"
 */
/**
 * @typedef ProfessionalIdentified
 * @property {string} [button_location] - The location of the button on the page or screen.
 * @property {string} button_text - The text on the button.
 * @property {string} page_or_screen_name - The name of the page or screen where the button was pressed.
 * @property {boolean} primary_cta - Whether the button is the primary call to action on the page or screen.
 */
/**
 * @typedef PropertyPressed
 * @property {string} [button_location] - The location of the button on the page or screen.
 * @property {string} button_text - The text on the button.
 * @property {boolean} [example_job] - Whether the property is an example job.
 * @property {string} page_or_screen_name - The name of the page or screen where the button was pressed.
 * @property {boolean} primary_cta - Whether the button is the primary call to action on the page or screen.
 */
/**
 * @typedef RequestFormOpened
 * @property {string} [feature] - Name of associated feature
 * @property {string} [job_id] - The ID of the job for which the rendering is for
 * @property {string} [org_id] - The Org of the requesting user
 * @property {string} [rendering_id] -
 * @property {string} [track_id] - Static track id for button
 */
/**
 * @typedef ScreenViewed
 * @property {boolean} [dense_guidance_capture] - A boolean indicating whether the user is using dense for the current capture
 * @property {boolean} [dense_guidance_enabled_for_camera] - A boolean indicating whether dense is enabled for the camera.
 * @property {string} [dense_guidance_mode] - A string with 3 possible values “disable”, “lite”, “full”. Different dense modes determines what dense features are enabled.
 * @property {string} [job_client_identifier] - The client identifier of the Job the user is capturing.
 */
/**
 * @typedef SettingChanged
 * @property {string} setting_text - The name of the setting.
 * @property {any | null} value - The value the setting was changed to.
 */
/**
 * @typedef SignupStarted
 * @property {string} [button_location] - The location of the button on the page or screen.
 * @property {string} button_or_link - Whether the element pressed was a button or a link.
 * @property {string} button_text - The text on the button.
 * @property {string} page_or_screen_name - The name of the page or screen where the button was pressed.
 * @property {boolean} primary_cta - Whether the button is the primary call to action on the page or screen.
 */
/**
 * @typedef SupportContacted
 * @property {string} button_or_link - Whether the element pressed was a button or a link.
 * @property {string} [button_or_link_location] - The location of the button or link on the page or screen.
 * @property {string} button_or_link_text - The text on the button or link.
 * @property {string} page_or_screen_name - The name of the page or screen where the button was pressed.
 * @property {boolean} primary_cta - Whether the button is the primary call to action on the page or screen.
 */
/**
 * @typedef Swipe
 * @property {string} [feature] - Name of associated feature
 * @property {number} [job_id] - The Hyperion job id
 * @property {number} [org_id] - The Hyperion org id
 * @property {string} page_or_screen_name - The name of the page or screen where the user swiped
 * @property {string} [track_id] - Static track id for swipe
 * @property {number} [user_id] - The Hyperion user id
 */
/**
 * @typedef TabViewed
 * @property {string} [button_location] -
 * @property {string} [design_studio_mode] - The Design Studio mode the user is currently in
 * @property {string} [feature] - Name of associated feature
 * @property {number} [job_id] - The Hyperion Job Id.
 * @property {string} [page_or_screen_name] - The name of the page.
 * @property {number} [partner_id] - Partner specific page
 */
/**
 * @typedef TextInput
 * @property {string} [backend_id_type] - Database object name associated with this event
 * @property {string} [backend_id_value] - Database object value associated with this event
 * @property {string} [input_label] - The label of the text input.
 * @property {string} [input_location] - The location of the input on the page or screen.
 * @property {string} input_value - The value of the text input.
 * @property {number} [job_id] - The Hyperion Job Id
 * @property {string} [page_or_screen_name] - Where does this text input appear
 */

/**
 * The user created an account.
 *
 * @param {AccountCreated} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function accountCreated(
  props: AccountCreated,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'The user created an account.',
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          button_or_link: {
            description: 'Whether the element pressed was a button or a link.',
            enum: ['button', 'link'],
            id: '/properties/properties/properties/button_or_link',
            type: 'string',
          },
          button_or_link_location: {
            description:
              'The location of the button or link on the page or screen.',
            id: '/properties/properties/properties/button_or_link_location',
            pattern: '',
            type: 'string',
          },
          button_or_link_text: {
            description: 'The text on the button or link.',
            id: '/properties/properties/properties/button_or_link_text',
            type: 'string',
          },
          page_or_screen_name: {
            description:
              'The name of the page or screen where the button was pressed.',
            id: '/properties/properties/properties/page_or_screen_name',
            type: 'string',
          },
          platform_type: {
            description:
              'The platform on which the user signed up. For example, mobile or web.',
            enum: ['mobile', null, 'web'],
            id: '/properties/properties/properties/platform_type',
            type: ['string', 'null'],
          },
          primary_cta: {
            description:
              'Whether the button is the primary call to action on the page or screen.',
            id: '/properties/properties/properties/primary_cta',
            type: 'boolean',
          },
        },
        required: ['button_or_link', 'page_or_screen_name', 'primary_cta'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Account Created',
    type: 'object',
  };
  const message = {
    event: 'Account Created',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Account Created',
      props || {},
      withTypewriterContext(options),
      callback,
    );
  }
}
/**
 * The user agreed to the terms of service and privacy policy.
 *
 * @param {AgreedToTosPrivacy} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function agreedToTosPrivacy(
  props: AgreedToTosPrivacy,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'The user agreed to the terms of service and privacy policy.',
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          checkbox_location: {
            description: 'The location of the checkbox on the page or screen.',
            type: 'string',
          },
          page_or_screen_name: {
            description:
              'The name of the page or screen where the button was pressed.',
            type: 'string',
          },
          primary_cta: {
            description:
              'Whether the button is the primary call to action on the page or screen.',
            type: 'boolean',
          },
        },
        required: ['primary_cta', 'page_or_screen_name'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Agreed to TOS_Privacy',
    type: 'object',
  };
  const message = {
    event: 'Agreed to TOS_Privacy',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Agreed to TOS_Privacy',
      props || {},
      withTypewriterContext(options),
      callback,
    );
  }
}
/**
 * The user pressed the back button.
 *
 * @param {BackButtonPressed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function backButtonPressed(
  props: BackButtonPressed,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'The user pressed the back button.',
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          button_location: {
            description: 'The location of the button on the page or screen.',
            id: '/properties/properties/properties/button_location',
            pattern: '',
            type: 'string',
          },
          button_text: {
            description: 'The text on the button.',
            id: '/properties/properties/properties/button_text',
            type: 'string',
          },
          design_studio_mode: {
            description: 'The current Design Studio mode',
            enum: ['default', 'guest', 'proView', 'share'],
            id: '/properties/properties/properties/design_studio_mode',
            type: 'string',
          },
          page_or_screen_name: {
            description:
              'The name of the page or screen where the button was pressed.',
            id: '/properties/properties/properties/page_or_screen_name',
            type: 'string',
          },
          partner_id: {
            description:
              'The Partner if button is embedded with in a Partner specific page context',
            id: '/properties/properties/properties/partner_id',
            type: 'integer',
          },
          primary_cta: {
            description:
              'Whether the button is the primary call to action on the page or screen.',
            id: '/properties/properties/properties/primary_cta',
            type: 'boolean',
          },
        },
        required: ['primary_cta', 'page_or_screen_name', 'button_text'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Back Button Pressed',
    type: 'object',
  };
  const message = {
    event: 'Back Button Pressed',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Back Button Pressed',
      props || {},
      withTypewriterContext(options),
      callback,
    );
  }
}
/**
 * The user pressed a button. This is the generic event used for all button presses, other than the ones that have their own dedicated event name.
 *
 * @param {ButtonImpression} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function buttonImpression(
  props: ButtonImpression,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'The user pressed a button. This is the generic event used for all button presses, other than the ones that have their own dedicated event name.',
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          backend_id_type: {
            description: 'Database object name associated with this event',
            id: '/properties/properties/properties/backend_id_type',
            type: 'string',
          },
          backend_id_value: {
            description: 'Database object id associated with this event',
            id: '/properties/properties/properties/backend_id_value',
            type: 'string',
          },
          button_location: {
            description: 'The location of the button on the page or screen.',
            id: '/properties/properties/properties/button_location',
            pattern: '',
            type: 'string',
          },
          button_text: {
            description: 'The text on the button.',
            id: '/properties/properties/properties/button_text',
            type: 'string',
          },
          button_type: {
            description: '',
            id: '/properties/properties/properties/button_type',
            type: 'string',
          },
          dense_guidance_capture: {
            description:
              'A boolean indicating whether the user is using dense for the current capture',
            id: '/properties/properties/properties/dense_guidance_capture',
            type: 'boolean',
          },
          dense_guidance_enabled_for_camera: {
            description:
              'A boolean indicating whether dense is enabled for the camera.',
            id: '/properties/properties/properties/dense_guidance_enabled_for_camera',
            type: 'boolean',
          },
          dense_guidance_mode: {
            description:
              'A string with 3 possible values “disable”, “lite”, “full”. Different dense modes determines what dense features are enabled.',
            enum: ['disable', 'full', 'lite'],
            id: '/properties/properties/properties/dense_guidance_mode',
            type: 'string',
          },
          design_studio_mode: {
            description: 'The Design Studio mode the user is currently in',
            enum: ['default', 'guest', 'proView', 'share'],
            id: '/properties/properties/properties/design_studio_mode',
            type: 'string',
          },
          feature: {
            description: 'Name of associated feature',
            id: '/properties/properties/properties/feature',
            type: 'string',
          },
          item_type: {
            description: 'The domain object being operated on',
            id: '/properties/properties/properties/item_type',
            type: 'string',
          },
          job_client_identifier: {
            description:
              'The client identifier of the Job the user is capturing.',
            id: '/properties/properties/properties/job_client_identifier',
            type: 'string',
          },
          job_id: {
            description: 'The Hyperion Job Id.',
            id: '/properties/properties/properties/job_id',
            type: 'integer',
          },
          page_or_screen_name: {
            description:
              'The name of the page or screen where the button was pressed.',
            id: '/properties/properties/properties/page_or_screen_name',
            type: 'string',
          },
          partner_id: {
            description:
              'The Partner if button is embedded with in a Partner specific page context',
            id: '/properties/properties/properties/partner_id',
            type: 'integer',
          },
          primary_cta: {
            description:
              'Whether the button is the primary call to action on the page or screen.',
            id: '/properties/properties/properties/primary_cta',
            type: 'boolean',
          },
          track_id: {
            description: 'Static track id for button',
            id: '/properties/properties/properties/track_id',
            type: 'string',
          },
        },
        required: ['button_text', 'page_or_screen_name', 'primary_cta'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Button Impression',
    type: 'object',
  };
  const message = {
    event: 'Button Impression',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Button Impression',
      props || {},
      withTypewriterContext(options),
      callback,
    );
  }
}
/**
 * The user pressed a button. This is the generic event used for all button presses, other than the ones that have their own dedicated event name.
 *
 * @param {ButtonPressed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function buttonPressed(
  props: ButtonPressed,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'The user pressed a button. This is the generic event used for all button presses, other than the ones that have their own dedicated event name.',
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          backend_id_type: {
            description: 'Database object name associated with this event',
            id: '/properties/properties/properties/backend_id_type',
            type: 'string',
          },
          backend_id_value: {
            description: 'Database object id associated with this event',
            id: '/properties/properties/properties/backend_id_value',
            type: 'string',
          },
          button_location: {
            description: 'The location of the button on the page or screen.',
            id: '/properties/properties/properties/button_location',
            pattern: '',
            type: 'string',
          },
          button_text: {
            description: 'The text on the button.',
            id: '/properties/properties/properties/button_text',
            type: 'string',
          },
          button_type: {
            description: '',
            id: '/properties/properties/properties/button_type',
            type: 'string',
          },
          dense_guidance_capture: {
            description:
              'A boolean indicating whether the user is using dense for the current capture',
            id: '/properties/properties/properties/dense_guidance_capture',
            type: 'boolean',
          },
          dense_guidance_enabled_for_camera: {
            description:
              'A boolean indicating whether dense is enabled for the camera.',
            id: '/properties/properties/properties/dense_guidance_enabled_for_camera',
            type: 'boolean',
          },
          dense_guidance_mode: {
            description:
              'A string with 3 possible values “disable”, “lite”, “full”. Different dense modes determines what dense features are enabled.',
            enum: ['disable', 'full', 'lite'],
            id: '/properties/properties/properties/dense_guidance_mode',
            type: 'string',
          },
          design_studio_mode: {
            description: 'The Design Studio mode the user is currently in',
            enum: ['default', 'guest', 'proView', 'share'],
            id: '/properties/properties/properties/design_studio_mode',
            type: 'string',
          },
          feature: {
            description: 'Name of associated feature',
            id: '/properties/properties/properties/feature',
            type: 'string',
          },
          item_type: {
            description: 'The domain object being operated on',
            id: '/properties/properties/properties/item_type',
            type: 'string',
          },
          job_client_identifier: {
            description:
              'The client identifier of the Job the user is capturing.',
            id: '/properties/properties/properties/job_client_identifier',
            type: 'string',
          },
          job_id: {
            description: 'The Hyperion Job Id.',
            id: '/properties/properties/properties/job_id',
            type: 'integer',
          },
          page_or_screen_name: {
            description:
              'The name of the page or screen where the button was pressed.',
            id: '/properties/properties/properties/page_or_screen_name',
            type: 'string',
          },
          partner_id: {
            description:
              'The Partner if button is embedded with in a Partner specific page context',
            id: '/properties/properties/properties/partner_id',
            type: 'integer',
          },
          primary_cta: {
            description:
              'Whether the button is the primary call to action on the page or screen.',
            id: '/properties/properties/properties/primary_cta',
            type: 'boolean',
          },
          track_id: {
            description: 'Static track id for button',
            id: '/properties/properties/properties/track_id',
            type: 'string',
          },
        },
        required: ['button_text', 'page_or_screen_name', 'primary_cta'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Button Pressed',
    type: 'object',
  };
  const message = {
    event: 'Button Pressed',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Button Pressed',
      props || {},
      withTypewriterContext(options),
      callback,
    );
  }
}
/**
 * The user started a capture.
 *
 * @param {CaptureStarted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function captureStarted(
  props: CaptureStarted,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'The user started a capture.',
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          button_location: {
            description: 'The location of the button on the page or screen.',
            id: '/properties/properties/properties/button_location',
            type: 'string',
          },
          button_text: {
            description: 'The text on the button.',
            id: '/properties/properties/properties/button_text',
            type: 'string',
          },
          job_client_identifier: {
            description:
              'The client identifier of the Job the user is capturing.',
            id: '/properties/properties/properties/job_client_identifier',
            type: 'string',
          },
          page_or_screen_name: {
            description:
              'The name of the page or screen where the button was pressed.\n',
            id: '/properties/properties/properties/page_or_screen_name',
            type: 'string',
          },
          primary_cta: {
            description:
              'Whether the button is the primary call to action on the page or screen.',
            id: '/properties/properties/properties/primary_cta',
            type: 'boolean',
          },
        },
        required: [
          'button_text',
          'page_or_screen_name',
          'primary_cta',
          'job_client_identifier',
        ],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Capture Started',
    type: 'object',
  };
  const message = {
    event: 'Capture Started',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Capture Started',
      props || {},
      withTypewriterContext(options),
      callback,
    );
  }
}
/**
 * The user submitted a capture.
 *
 * @param {CaptureSubmitted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function captureSubmitted(
  props: CaptureSubmitted,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'The user submitted a capture.',
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          button_location: {
            description: 'The location of the button on the page or screen.\n',
            id: '/properties/properties/properties/button_location',
            type: 'string',
          },
          button_text: {
            description: 'The text on the button.\n',
            id: '/properties/properties/properties/button_text',
            type: 'string',
          },
          job_client_identifier: {
            description:
              'The client identifier of the Job the user is capturing.',
            id: '/properties/properties/properties/job_client_identifier',
            type: 'string',
          },
          page_or_screen_name: {
            description:
              'The name of the page or screen where the button was pressed.\n',
            id: '/properties/properties/properties/page_or_screen_name',
            type: 'string',
          },
          primary_cta: {
            description:
              'Whether the button is the primary call to action on the page or screen.',
            id: '/properties/properties/properties/primary_cta',
            type: 'boolean',
          },
          progressive_upload: {
            description: 'Progressive Uploads was enabled for the capture.',
            id: '/properties/properties/properties/progressive_upload',
            type: 'boolean',
          },
        },
        required: [
          'primary_cta',
          'job_client_identifier',
          'button_text',
          'page_or_screen_name',
        ],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Capture Submitted',
    type: 'object',
  };
  const message = {
    event: 'Capture Submitted',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Capture Submitted',
      props || {},
      withTypewriterContext(options),
      callback,
    );
  }
}
/**
 * The user selected a checkbox.
 *
 * @param {CheckboxSelected} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function checkboxSelected(
  props: CheckboxSelected,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'The user selected a checkbox.',
    properties: {
      context: {},
      properties: {
        properties: {
          backend_id_type: {
            description: 'Database object name associated with this event',
            id: '/properties/properties/properties/backend_id_type',
            type: 'string',
          },
          backend_id_value: {
            description: 'Database object value associated with this event',
            id: '/properties/properties/properties/backend_id_value',
            type: 'string',
          },
          job_id: {
            description: 'The Hyperion Job Id',
            id: '/properties/properties/properties/job_id',
            type: 'integer',
          },
          options: {
            description: 'The description of the set of options',
            id: '/properties/properties/properties/options',
            type: 'string',
          },
          page_or_screen_name: {
            description: 'Where does this checkbox appear',
            id: '/properties/properties/properties/page_or_screen_name',
            type: 'string',
          },
          primary_cta: {
            description: 'Is this field the primary CTA',
            id: '/properties/properties/properties/primary_cta',
            type: 'boolean',
          },
          selection: {
            description: 'The thing being selected',
            id: '/properties/properties/properties/selection',
            type: 'string',
          },
          user_id: {
            description: 'Id of the user',
            id: '/properties/properties/properties/user_id',
            type: 'number',
          },
        },
        required: ['options', 'selection'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Checkbox Selected',
    type: 'object',
  };
  const message = {
    event: 'Checkbox Selected',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Checkbox Selected',
      props || {},
      withTypewriterContext(options),
      callback,
    );
  }
}
/**
 * The user toggled a checkbox. This is the generic event used for all checkbox toggles, other than the ones that have their own dedicated event name.
 *
 * @param {CheckboxToggled} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function checkboxToggled(
  props: CheckboxToggled,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'The user toggled a checkbox. This is the generic event used for all checkbox toggles, other than the ones that have their own dedicated event name.',
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          button_location: {
            description: 'The location of the button on the page or screen.',
            id: '/properties/properties/properties/button_location',
            pattern: '',
            type: 'string',
          },
          checked: {
            description: 'Value of checkbox',
            id: '/properties/properties/properties/checked',
            type: 'boolean',
          },
          dense_guidance_capture: {
            description:
              'A boolean indicating whether the user is using dense for the current capture',
            id: '/properties/properties/properties/dense_guidance_capture',
            type: 'boolean',
          },
          dense_guidance_enabled_for_camera: {
            description:
              'A boolean indicating whether dense is enabled for the camera.',
            id: '/properties/properties/properties/dense_guidance_enabled_for_camera',
            type: 'boolean',
          },
          dense_guidance_mode: {
            description:
              'A string with 3 possible values “disable”, “lite”, “full”. Different dense modes determines what dense features are enabled.',
            enum: ['disable', 'full', 'lite'],
            id: '/properties/properties/properties/dense_guidance_mode',
            type: 'string',
          },
          design_studio_mode: {
            description: 'The Design Studio mode the user is currently in',
            enum: ['default', 'guest', 'proView', 'share'],
            id: '/properties/properties/properties/design_studio_mode',
            type: 'string',
          },
          feature: {
            description: 'Name of associated feature',
            id: '/properties/properties/properties/feature',
            type: 'string',
          },
          job_client_identifier: {
            description:
              'The client identifier of the Job the user is capturing.',
            id: '/properties/properties/properties/job_client_identifier',
            type: 'string',
          },
          job_id: {
            description: 'The Hyperion Job Id.',
            id: '/properties/properties/properties/job_id',
            type: 'integer',
          },
          page_or_screen_name: {
            description:
              'The name of the page or screen where the button was pressed.',
            id: '/properties/properties/properties/page_or_screen_name',
            type: 'string',
          },
          partner_id: {
            description:
              'The Partner if button is embedded with in a Partner specific page context',
            id: '/properties/properties/properties/partner_id',
            type: 'integer',
          },
          primary_cta: {
            description:
              'Whether the button is the primary call to action on the page or screen.',
            id: '/properties/properties/properties/primary_cta',
            type: 'boolean',
          },
          track_id: {
            description: 'Static track id for button',
            id: '/properties/properties/properties/track_id',
            type: 'string',
          },
        },
        required: ['primary_cta', 'checked', 'page_or_screen_name'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Checkbox Toggled',
    type: 'object',
  };
  const message = {
    event: 'Checkbox Toggled',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Checkbox Toggled',
      props || {},
      withTypewriterContext(options),
      callback,
    );
  }
}
/**
 * Data was saved in a programmatic manner, without being directly triggered by a user interaction.
 *
 * @param {DataSaved} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function dataSaved(
  props?: DataSaved,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Data was saved in a programmatic manner, without being directly triggered by a user interaction.',
    properties: {
      context: {},
      properties: {
        properties: {
          backend_id_type: {
            description:
              'The backend id type of the item represented by the saved data.',
            id: '/properties/properties/properties/backend_id_type',
            type: 'string',
          },
          backend_id_value: {
            description:
              'The backend id value of the item represented by the saved data.',
            id: '/properties/properties/properties/backend_id_value',
            type: 'string',
          },
          feature: {
            description: 'The feature that the saved data is associated with.',
            id: '/properties/properties/properties/feature',
            type: 'string',
          },
          input_label: {
            description:
              'the label of input used to save data (see inputPressed event for parallel field)',
            id: '/properties/properties/properties/input_label',
            type: 'string',
          },
          item_type: {
            description:
              'The item/object type that the saved data is associated with.',
            id: '/properties/properties/properties/item_type',
            type: 'string',
          },
          page_or_screen_name: {
            description: 'The page or screen where the save occurred.',
            id: '/properties/properties/properties/page_or_screen_name',
            type: 'string',
          },
          parent_id: {
            description:
              'The parent item/object id that the saved data is associated with. This could be a `job`, `estimate`, `sales_opportunity`, etc.',
            id: '/properties/properties/properties/parent_id',
            type: 'integer',
          },
          saved_data: {
            description: 'The data payload of the saved data.',
            id: '/properties/properties/properties/saved_data',
            type: 'object',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Data Saved',
    type: 'object',
  };
  const message = {
    event: 'Data Saved',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Data Saved',
      props || {},
      withTypewriterContext(options),
      callback,
    );
  }
}
/**
 * The user started a Dense capture.
 *
 * @param {DenseCaptureStarted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function denseCaptureStarted(
  props: DenseCaptureStarted,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'The user started a Dense capture.',
    properties: {
      context: {},
      properties: {
        properties: {
          dense_guidance_capture: {
            description:
              'A boolean indicating whether the user is using dense for the current capture',
            id: '/properties/properties/properties/dense_guidance_capture',
            type: 'boolean',
          },
          dense_guidance_enabled_for_camera: {
            description:
              'A boolean indicating whether dense is enabled for the camera.',
            id: '/properties/properties/properties/dense_guidance_enabled_for_camera',
            type: 'boolean',
          },
          dense_guidance_mode: {
            description:
              'A string with 3 possible values “disable”, “lite”, “full”. Different dense modes determines what dense features are enabled.',
            enum: ['disable', 'full', 'lite'],
            id: '/properties/properties/properties/dense_guidance_mode',
            type: 'string',
          },
          job_client_identifier: {
            description:
              'The client identifier of the Job the user is capturing.',
            id: '/properties/properties/properties/job_client_identifier',
            type: 'string',
          },
          page_or_screen_name: {
            description:
              'The name of the page or screen where the button was pressed.\n',
            id: '/properties/properties/properties/page_or_screen_name',
            type: 'string',
          },
        },
        required: [
          'page_or_screen_name',
          'dense_guidance_capture',
          'dense_guidance_enabled_for_camera',
          'dense_guidance_mode',
        ],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Dense Capture Started',
    type: 'object',
  };
  const message = {
    event: 'Dense Capture Started',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Dense Capture Started',
      props || {},
      withTypewriterContext(options),
      callback,
    );
  }
}
/**
 * The user dragged an element
 *
 * @param {ElementDragged} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function elementDragged(
  props: ElementDragged,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'The user dragged an element',
    properties: {
      context: {},
      properties: {
        properties: {
          job_id: {
            description: '',
            id: '/properties/properties/properties/job_id',
            type: 'integer',
          },
          name: {
            description: '',
            id: '/properties/properties/properties/name',
            type: 'string',
          },
          new_position: {
            description: '',
            id: '/properties/properties/properties/new_position',
            type: 'integer',
          },
          old_position: {
            description: '',
            id: '/properties/properties/properties/old_position',
            type: 'integer',
          },
        },
        required: ['new_position', 'old_position', 'name'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Element Dragged',
    type: 'object',
  };
  const message = {
    event: 'Element Dragged',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Element Dragged',
      props || {},
      withTypewriterContext(options),
      callback,
    );
  }
}
/**
 * The user encountered an error
 *
 * @param {Error} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function error(
  props: Error,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'The user encountered an error',
    properties: {
      context: {},
      properties: {
        properties: {
          message: {
            description: 'the error message displayed to users',
            id: '/properties/properties/properties/message',
            type: 'string',
          },
          status: {
            description: 'HTTP status code e.g. 401',
            id: '/properties/properties/properties/status',
            type: 'number',
          },
          type: {
            description: 'what kind of error',
            enum: ['API', 'AUTHORIZATION'],
            id: '/properties/properties/properties/type',
            type: 'string',
          },
        },
        required: ['type'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Error',
    type: 'object',
  };
  const message = {
    event: 'Error',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track('Error', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * The user upvoted a potential new feature in Design Studio.
 *
 * @param {FeatureUpvoted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function featureUpvoted(
  props: FeatureUpvoted,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'The user upvoted a potential new feature in Design Studio.',
    properties: {
      context: {},
      properties: {
        properties: {
          feature: {
            description: 'The name of the feature the user upvoted.',
            type: 'string',
          },
          job_id: {
            description: 'The Hyperion Job Id.',
            type: 'integer',
          },
          page_or_screen_name: {
            description:
              'The name of the page or screen where the feature was upvoted.',
            type: 'string',
          },
        },
        required: ['page_or_screen_name', 'feature'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Feature Upvoted',
    type: 'object',
  };
  const message = {
    event: 'Feature Upvoted',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Feature Upvoted',
      props || {},
      withTypewriterContext(options),
      callback,
    );
  }
}
/**
 * The user selected the Homeowner option.
 *
 * @param {HomeownerIdentified} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function homeownerIdentified(
  props: HomeownerIdentified,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'The user selected the Homeowner option.',
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          button_location: {
            description: 'The location of the button on the page or screen.',
            pattern: '',
            type: 'string',
          },
          button_text: {
            description: 'The text on the button.',
            type: 'string',
          },
          page_or_screen_name: {
            description:
              'The name of the page or screen where the button was pressed.',
            type: 'string',
          },
          primary_cta: {
            description:
              'Whether the button is the primary call to action on the page or screen.',
            type: 'boolean',
          },
        },
        required: ['page_or_screen_name', 'button_text', 'primary_cta'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Homeowner Identified',
    type: 'object',
  };
  const message = {
    event: 'Homeowner Identified',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Homeowner Identified',
      props || {},
      withTypewriterContext(options),
      callback,
    );
  }
}
/**
 * The user pressed the input
 *
 * @param {InputPressed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function inputPressed(
  props?: InputPressed,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'The user pressed the input',
    properties: {
      context: {},
      properties: {
        properties: {
          feature: {
            description: 'Name of associated feature',
            id: '/properties/properties/properties/feature',
            type: 'string',
          },
          input_label: {
            description: 'The label of the input',
            id: '/properties/properties/properties/input_label',
            type: 'string',
          },
          org_id: {
            description: 'The Hyperion org_id',
            id: '/properties/properties/properties/org_id',
            type: 'integer',
          },
          user_id: {
            description: 'The Hyperion user id',
            id: '/properties/properties/properties/user_id',
            type: 'integer',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Input Pressed',
    type: 'object',
  };
  const message = {
    event: 'Input Pressed',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Input Pressed',
      props || {},
      withTypewriterContext(options),
      callback,
    );
  }
}
/**
 * The user selected the Homeowner option.
 *
 * @param {InsuranceIdentified} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function insuranceIdentified(
  props: InsuranceIdentified,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'The user selected the Homeowner option.',
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          button_location: {
            description: 'The location of the button on the page or screen.',
            id: '/properties/properties/properties/button_location',
            pattern: '',
            type: 'string',
          },
          button_text: {
            description: 'The text on the button.',
            id: '/properties/properties/properties/button_text',
            type: 'string',
          },
          page_or_screen_name: {
            description:
              'The name of the page or screen where the button was pressed.',
            id: '/properties/properties/properties/page_or_screen_name',
            type: 'string',
          },
          primary_cta: {
            description:
              'Whether the button is the primary call to action on the page or screen.',
            id: '/properties/properties/properties/primary_cta',
            type: 'boolean',
          },
        },
        required: ['button_text', 'page_or_screen_name', 'primary_cta'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Insurance Identified',
    type: 'object',
  };
  const message = {
    event: 'Insurance Identified',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Insurance Identified',
      props || {},
      withTypewriterContext(options),
      callback,
    );
  }
}
/**
 * The user pressed a link. This is the generic event used for all link presses, other than the ones that have their own dedicated event name.
 *
 * @param {LinkPressed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function linkPressed(
  props: LinkPressed,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'The user pressed a link. This is the generic event used for all link presses, other than the ones that have their own dedicated event name.',
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          backend_id_type: {
            description: 'Database object name associated with this event',
            id: '/properties/properties/properties/backend_id_type',
            type: 'string',
          },
          backend_id_value: {
            description: 'Database object id associated with this event',
            id: '/properties/properties/properties/backend_id_value',
            type: 'string',
          },
          design_studio_mode: {
            description: 'The Design Studio mode the user is currently in',
            enum: ['default', 'guest', 'proView', 'share'],
            id: '/properties/properties/properties/design_studio_mode',
            type: 'string',
          },
          feature: {
            description: 'Name of associated feature',
            id: '/properties/properties/properties/feature',
            type: 'string',
          },
          job_id: {
            description: 'The Hyperion job id',
            id: '/properties/properties/properties/job_id',
            type: 'integer',
          },
          link_location: {
            description: 'The location of the link on the page or screen.',
            id: '/properties/properties/properties/link_location',
            pattern: '',
            type: 'string',
          },
          link_text: {
            description: 'The text on the link.',
            id: '/properties/properties/properties/link_text',
            type: 'string',
          },
          page_or_screen_name: {
            description:
              'The name of the page or screen where the link was pressed.',
            id: '/properties/properties/properties/page_or_screen_name',
            type: 'string',
          },
          partner_id: {
            description:
              'The Partner if link is embedded with in a Partner specific page context',
            id: '/properties/properties/properties/partner_id',
            type: 'integer',
          },
          primary_cta: {
            description:
              'Whether the link is the primary call to action on the page or screen.',
            id: '/properties/properties/properties/primary_cta',
            type: 'boolean',
          },
          track_id: {
            description: 'Static id for tracking link',
            id: '/properties/properties/properties/track_id',
            type: 'string',
          },
        },
        required: ['page_or_screen_name', 'primary_cta', 'link_text'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Link Pressed',
    type: 'object',
  };
  const message = {
    event: 'Link Pressed',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Link Pressed',
      props || {},
      withTypewriterContext(options),
      callback,
    );
  }
}
/**
 * The user started SSO login.
 *
 * @param {LoginSsoStarted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function loginSsoStarted(
  props: LoginSsoStarted,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'The user started SSO login.',
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          button_or_link: {
            description: 'Whether the element pressed was a button or a link.',
            type: 'string',
          },
          button_or_link_location: {
            description:
              'The location of the button or link on the page or screen.',
            pattern: '',
            type: 'string',
          },
          button_text: {
            description: 'The text on the button.',
            type: 'string',
          },
          page_or_screen_name: {
            description:
              'The name of the page or screen where the button was pressed.',
            type: 'string',
          },
          primary_cta: {
            description:
              'Whether the button is the primary call to action on the page or screen.',
            type: 'boolean',
          },
        },
        required: [
          'button_or_link',
          'page_or_screen_name',
          'button_text',
          'primary_cta',
        ],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Login SSO Started',
    type: 'object',
  };
  const message = {
    event: 'Login SSO Started',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Login SSO Started',
      props || {},
      withTypewriterContext(options),
      callback,
    );
  }
}
/**
 * The user started login.
 *
 * @param {LoginStarted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function loginStarted(
  props: LoginStarted,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'The user started login.',
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          button_or_link: {
            description: 'Whether the element pressed was a button or a link.',
            type: 'string',
          },
          button_or_link_location: {
            description: 'The location of the button on the page or screen.',
            pattern: '',
            type: 'string',
          },
          button_text: {
            description: 'The text on the button.',
            type: 'string',
          },
          page_or_screen_name: {
            description:
              'The name of the page or screen where the button was pressed.',
            type: 'string',
          },
          primary_cta: {
            description:
              'Whether the button is the primary call to action on the page or screen.',
            type: 'boolean',
          },
        },
        required: [
          'button_text',
          'primary_cta',
          'button_or_link',
          'page_or_screen_name',
        ],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Login Started',
    type: 'object',
  };
  const message = {
    event: 'Login Started',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Login Started',
      props || {},
      withTypewriterContext(options),
      callback,
    );
  }
}
/**
 * The user viewed a modal.
 *
 * @param {ModalViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function modalViewed(
  props: ModalViewed,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'The user viewed a modal.',
    properties: {
      context: {},
      properties: {
        properties: {
          '': {
            description: '',
            id: '/properties/properties/properties/',
            type: 'object',
          },
          dense_guidance_capture: {
            description:
              'A boolean indicating whether the user is using dense for the current capture',
            id: '/properties/properties/properties/dense_guidance_capture',
            type: 'boolean',
          },
          dense_guidance_enabled_for_camera: {
            description:
              'A boolean indicating whether dense is enabled for the camera.',
            id: '/properties/properties/properties/dense_guidance_enabled_for_camera',
            type: 'boolean',
          },
          dense_guidance_mode: {
            description:
              'A string with 3 possible values “disable”, “lite”, “full”. Different dense modes determines what dense features are enabled.',
            enum: ['disable', 'full', 'lite'],
            id: '/properties/properties/properties/dense_guidance_mode',
            type: 'string',
          },
          feature: {
            description: 'Name of associated feature',
            id: '/properties/properties/properties/feature',
            type: 'string',
          },
          job_client_identifier: {
            description:
              'The client identifier of the Job the user is capturing.',
            id: '/properties/properties/properties/job_client_identifier',
            type: 'string',
          },
          name: {
            description: 'The name of the modal.',
            id: '/properties/properties/properties/name',
            type: 'string',
          },
        },
        required: ['name'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Modal Viewed',
    type: 'object',
  };
  const message = {
    event: 'Modal Viewed',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Modal Viewed',
      props || {},
      withTypewriterContext(options),
      callback,
    );
  }
}
/**
 * The user clicked on a facet of the 3d model.
 *
 * @param {ModelComponentSelected} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function modelComponentSelected(
  props?: ModelComponentSelected,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'The user clicked on a facet of the 3d model.',
    properties: {
      context: {},
      properties: {
        properties: {
          backend_id_type: {
            description: 'Database object name associated with this event',
            id: '/properties/properties/properties/backend_id_type',
            type: 'string',
          },
          backend_id_value: {
            description: 'Database object value associated with this event',
            id: '/properties/properties/properties/backend_id_value',
            type: 'string',
          },
          job_id: {
            description: 'The Hyperion Job Id',
            id: '/properties/properties/properties/job_id',
            type: 'integer',
          },
          page_or_screen_name: {
            description: 'Where does this model component appear',
            id: '/properties/properties/properties/page_or_screen_name',
            type: 'string',
          },
          primary_cta: {
            description: 'Is this field the primary cta',
            id: '/properties/properties/properties/primary_cta',
            type: 'boolean',
          },
          user_id: {
            description: 'Id of the user',
            id: '/properties/properties/properties/user_id',
            type: 'number',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Model Component Selected',
    type: 'object',
  };
  const message = {
    event: 'Model Component Selected',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Model Component Selected',
      props || {},
      withTypewriterContext(options),
      callback,
    );
  }
}
/**
 * The user rotated, panned, or zoomed the 3D model in Design Studio.
 *
 * @param {ModelInteracted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function modelInteracted(
  props: ModelInteracted,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'The user rotated, panned, or zoomed the 3D model in Design Studio.',
    properties: {
      context: {},
      properties: {
        properties: {
          design_studio_mode: {
            description: 'The Design Studio mode the user is currently in',
            enum: ['default', 'guest', 'proView', 'share'],
            id: '/properties/properties/properties/design_studio_mode',
            type: 'string',
          },
          fullscreen_iframe: {
            description:
              'If Design Studio is embedded in an iframe, this value will show if the model interacted event happened while the iframe is in fullscreen mode',
            id: '/properties/properties/properties/fullscreen_iframe',
            type: 'boolean',
          },
          iframe_location: {
            description:
              'If Design Studio is embedded in an iframe, this is the "location" of the iframe (e.g. hover_property-details-page, james-hardie_homepage, unknown, etc...). This property needs to be manually set as a URL param in the iframe src url.',
            id: '/properties/properties/properties/iframe_location',
            type: 'string',
          },
          interaction_type: {
            description:
              'The type of interaction the user made. Possible values are "rotate", "pan", or "zoom".',
            enum: ['pan', 'rotate', 'zoom'],
            id: '/properties/properties/properties/interaction_type',
            type: 'string',
          },
          job_id: {
            description: 'The Hyperion Job Id.',
            id: '/properties/properties/properties/job_id',
            type: 'integer',
          },
        },
        required: ['interaction_type'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Model Interacted',
    type: 'object',
  };
  const message = {
    event: 'Model Interacted',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Model Interacted',
      props || {},
      withTypewriterContext(options),
      callback,
    );
  }
}
/**
 * The user has viewed the feature module
 *
 * @param {ModuleViewed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function moduleViewed(
  props?: ModuleViewed,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'The user has viewed the feature module',
    properties: {
      context: {},
      properties: {
        properties: {
          feature: {
            description: 'The name of the associated feature',
            id: '/properties/properties/properties/feature',
            type: 'string',
          },
          job_id: {
            description: 'The Hyperion Job ID',
            id: '/properties/properties/properties/job_id',
            type: 'integer',
          },
          org_id: {
            description: "User's org ID",
            id: '/properties/properties/properties/org_id',
            type: 'integer',
          },
          page_or_screen_name: {
            description:
              'The name of the page or screen where the button was pressed.',
            id: '/properties/properties/properties/page_or_screen_name',
            type: 'string',
          },
          track_id: {
            description: 'Static track id for module viewed',
            id: '/properties/properties/properties/track_id',
            type: 'string',
          },
          user_id: {
            description: 'The Hyperion Job ID',
            id: '/properties/properties/properties/user_id',
            type: 'integer',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Module Viewed',
    type: 'object',
  };
  const message = {
    event: 'Module Viewed',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Module Viewed',
      props || {},
      withTypewriterContext(options),
      callback,
    );
  }
}
/**
 * User selected something from a multiple-choice select element, like a dropdown.
 *
 * @param {OptionSelected} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function optionSelected(
  props: OptionSelected,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'User selected something from a multiple-choice select element, like a dropdown.',
    properties: {
      context: {},
      properties: {
        properties: {
          backend_id_type: {
            description: 'Database object name associated with this event',
            id: '/properties/properties/properties/backend_id_type',
            type: 'string',
          },
          backend_id_value: {
            description: 'Database object value associated with this event',
            id: '/properties/properties/properties/backend_id_value',
            type: 'string',
          },
          feature: {
            description: 'Name of associated feature\n',
            id: '/properties/properties/properties/feature',
            type: 'string',
          },
          job_id: {
            description: 'The Hyperion Job Id',
            id: '/properties/properties/properties/job_id',
            type: 'integer',
          },
          option_type: {
            description: 'The type of frontend selector (radio, dropdown)',
            id: '/properties/properties/properties/option_type',
            type: 'string',
          },
          options: {
            description: 'The description of the set of options',
            id: '/properties/properties/properties/options',
            type: 'string',
          },
          page_or_screen_name: {
            description: 'Where does this select appear',
            id: '/properties/properties/properties/page_or_screen_name',
            type: 'string',
          },
          primary_cta: {
            description: 'Is this field the primary CTA',
            id: '/properties/properties/properties/primary_cta',
            type: 'boolean',
          },
          selection: {
            description: 'The thing being selected',
            id: '/properties/properties/properties/selection',
            type: 'string',
          },
          user_id: {
            description: 'Id of the user',
            id: '/properties/properties/properties/user_id',
            type: 'number',
          },
        },
        required: ['options', 'selection'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Option Selected',
    type: 'object',
  };
  const message = {
    event: 'Option Selected',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Option Selected',
      props || {},
      withTypewriterContext(options),
      callback,
    );
  }
}
/**
 * The user viewed a page. Page calls are automatically converted to this event.
 *
 * @param {PageViewed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function pageViewed(
  props?: PageViewed,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'The user viewed a page. Page calls are automatically converted to this event.',
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          design_studio_mode: {
            description: 'The Design Studio mode the user is currently in',
            enum: ['default', 'guest', 'proView', 'share'],
            id: '/properties/properties/properties/design_studio_mode',
            type: 'string',
          },
          feature: {
            description: 'Name of associated feature',
            id: '/properties/properties/properties/feature',
            type: 'string',
          },
          job_id: {
            description: 'The Hyperion Job Id.',
            id: '/properties/properties/properties/job_id',
            type: 'integer',
          },
          page_or_screen_name: {
            description: 'The name of the page.',
            id: '/properties/properties/properties/page_or_screen_name',
            type: 'string',
          },
          partner_id: {
            description: 'Partner specific page',
            id: '/properties/properties/properties/partner_id',
            type: 'integer',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Page Viewed',
    type: 'object',
  };
  const message = {
    event: 'Page Viewed',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Page Viewed',
      props || {},
      withTypewriterContext(options),
      callback,
    );
  }
}
/**
 * The user made a product selection in Design Studio.
 *
 * @param {ProductSelected} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productSelected(
  props: ProductSelected,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'The user made a product selection in Design Studio.',
    properties: {
      context: {},
      properties: {
        properties: {
          category: {
            description:
              'The category of product selected. For example, "Walls", "Roof", "Trim", etc...',
            id: '/properties/properties/properties/category',
            type: ['string', 'null'],
          },
          color_group: {
            description:
              'The color group from a color selected that was not defined in texture details, "Popular", "Statement", "Reds", etc...',
            id: '/properties/properties/properties/color_group',
            type: ['string', 'null'],
          },
          color_hex: {
            description:
              'The hexadecimal representation of the color selected. For example, "#ffffff", "#ebdec4", "#ddb582", etc...',
            id: '/properties/properties/properties/color_hex',
            type: ['string', 'null'],
          },
          color_name: {
            description:
              'The name of the color selected. For example generic names "Reds" and "Greens", or branded names "Rustic Barn", "Wintery White", etc...',
            id: '/properties/properties/properties/color_name',
            type: ['string', 'null'],
          },
          design_studio_mode: {
            description: 'The Design Studio mode the user is currently in',
            enum: [' share', 'default', 'guest', 'proView'],
            id: '/properties/properties/properties/design_studio_mode',
            type: 'string',
          },
          frame: {
            description:
              'The parametric frame type for a window selected (will only be present if the product selected is a window). For example "casement" or "slider"...',
            id: '/properties/properties/properties/frame',
            type: ['string', 'null'],
          },
          grid: {
            description:
              'The parametric grid type for a window selected (will only be present if the product selected is a window). For example "diamond" or "farmhouse"...',
            id: '/properties/properties/properties/grid',
            type: ['string', 'null'],
          },
          job_id: {
            description: 'The Hyperion Job Id.',
            id: '/properties/properties/properties/job_id',
            type: 'integer',
          },
          page_or_screen_name: {
            description:
              'The name of the page or screen where the product was selected.',
            id: '/properties/properties/properties/page_or_screen_name',
            type: 'string',
          },
          parameter: {
            description:
              'The parameter of the product the user selected. For example, “Roofing Types”, “Style”, etc..',
            id: '/properties/properties/properties/parameter',
            type: 'string',
          },
          partner_id: {
            description:
              'The Partner if button is embedded with in a Partner specific page context',
            id: '/properties/properties/properties/partner_id',
            type: 'integer',
          },
          product: {
            description:
              'The kind of product the user selected. For example, "Walls", "Roof", "Trim", etc..',
            id: '/properties/properties/properties/product',
            type: ['string', 'null'],
          },
          product_type: {
            description:
              'The type of texture. For example "horizontal", "stone", "asphalt", "brick", etc...',
            id: '/properties/properties/properties/product_type',
            type: ['string', 'null'],
          },
          selection: {
            description:
              'The option the user selected. For example, “Metal”, “Standing Seam”, etc..',
            id: '/properties/properties/properties/selection',
          },
          texture_color_group: {
            description:
              'The color group assigned with the other texture properties. For example "Beiges", "Reds", etc...',
            id: '/properties/properties/properties/texture_color_group',
            type: ['string', 'null'],
          },
          texture_id: {
            description: 'The id of the texture selected.',
            id: '/properties/properties/properties/texture_id',
            type: ['number', 'null'],
          },
          texture_name: {
            description:
              'The name of texture color, typically a duplicate of texture_color_group',
            id: '/properties/properties/properties/texture_name',
            type: ['string', 'null'],
          },
          texture_shape: {
            description:
              'The shape of the texture. For example "Straight Shake", "Staggered Shake", "Scalloped", etc...',
            id: '/properties/properties/properties/texture_shape',
            type: ['string', 'null'],
          },
          texture_size: {
            description:
              'The size of the selected texture. For example `3"`, `4"`, `4.5"`, etc...',
            id: '/properties/properties/properties/texture_size',
            type: ['string', 'null'],
          },
          texture_style: {
            description:
              'The style of the selected texture. For example "Dutch Lap", "Board and Batten", etc...',
            id: '/properties/properties/properties/texture_style',
            type: ['string', 'null'],
          },
          texture_type: {
            description:
              'The type of texture. For example "Cedarmill" or "Smooth"',
            id: '/properties/properties/properties/texture_type',
            type: ['string', 'null'],
          },
        },
        required: ['page_or_screen_name', 'selection', 'parameter', 'product'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Product Selected',
    type: 'object',
  };
  const message = {
    event: 'Product Selected',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Product Selected',
      props || {},
      withTypewriterContext(options),
      callback,
    );
  }
}
/**
 * The user selected the Professional option.
 *
 * @param {ProfessionalIdentified} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function professionalIdentified(
  props: ProfessionalIdentified,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'The user selected the Professional option.',
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          button_location: {
            description: 'The location of the button on the page or screen.',
            pattern: '',
            type: 'string',
          },
          button_text: {
            description: 'The text on the button.',
            type: 'string',
          },
          page_or_screen_name: {
            description:
              'The name of the page or screen where the button was pressed.',
            type: 'string',
          },
          primary_cta: {
            description:
              'Whether the button is the primary call to action on the page or screen.',
            type: 'boolean',
          },
        },
        required: ['page_or_screen_name', 'button_text', 'primary_cta'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Professional Identified',
    type: 'object',
  };
  const message = {
    event: 'Professional Identified',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Professional Identified',
      props || {},
      withTypewriterContext(options),
      callback,
    );
  }
}
/**
 * The user pressed a property on the Property List screen.
 *
 * @param {PropertyPressed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function propertyPressed(
  props: PropertyPressed,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'The user pressed a property on the Property List screen.',
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          button_location: {
            description: 'The location of the button on the page or screen.',
            pattern: '',
            type: 'string',
          },
          button_text: {
            description: 'The text on the button.',
            type: 'string',
          },
          example_job: {
            description: 'Whether the property is an example job.',
            type: 'boolean',
          },
          page_or_screen_name: {
            description:
              'The name of the page or screen where the button was pressed.',
            type: 'string',
          },
          primary_cta: {
            description:
              'Whether the button is the primary call to action on the page or screen.',
            type: 'boolean',
          },
        },
        required: ['button_text', 'primary_cta', 'page_or_screen_name'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Property Pressed',
    type: 'object',
  };
  const message = {
    event: 'Property Pressed',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Property Pressed',
      props || {},
      withTypewriterContext(options),
      callback,
    );
  }
}
/**
 * The user opened the intake form for Renderings
 *
 * @param {RequestFormOpened} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function requestFormOpened(
  props?: RequestFormOpened,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'The user opened the intake form for Renderings',
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          feature: {
            description: 'Name of associated feature',
            id: '/properties/properties/properties/feature',
            type: 'string',
          },
          job_id: {
            description: 'The ID of the job for which the rendering is for',
            id: '/properties/properties/properties/job_id',
            type: 'string',
          },
          org_id: {
            description: 'The Org of the requesting user',
            id: '/properties/properties/properties/org_id',
            type: 'string',
          },
          rendering_id: {
            description: '',
            id: '/properties/properties/properties/rendering_id',
            type: 'string',
          },
          track_id: {
            description: 'Static track id for button',
            id: '/properties/properties/properties/track_id',
            type: 'string',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Request Form Opened',
    type: 'object',
  };
  const message = {
    event: 'Request Form Opened',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Request Form Opened',
      props || {},
      withTypewriterContext(options),
      callback,
    );
  }
}
/**
 * The user viewed a screen. Screen calls are automatically converted to this event.
 *
 * @param {ScreenViewed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function screenViewed(
  props?: ScreenViewed,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'The user viewed a screen. Screen calls are automatically converted to this event.',
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          dense_guidance_capture: {
            description:
              'A boolean indicating whether the user is using dense for the current capture',
            id: '/properties/properties/properties/dense_guidance_capture',
            type: 'boolean',
          },
          dense_guidance_enabled_for_camera: {
            description:
              'A boolean indicating whether dense is enabled for the camera.',
            id: '/properties/properties/properties/dense_guidance_enabled_for_camera',
            type: 'boolean',
          },
          dense_guidance_mode: {
            description:
              'A string with 3 possible values “disable”, “lite”, “full”. Different dense modes determines what dense features are enabled.',
            enum: ['disable', 'full', 'lite'],
            id: '/properties/properties/properties/dense_guidance_mode',
            type: 'string',
          },
          job_client_identifier: {
            description:
              'The client identifier of the Job the user is capturing.',
            id: '/properties/properties/properties/job_client_identifier',
            type: 'string',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Screen Viewed',
    type: 'object',
  };
  const message = {
    event: 'Screen Viewed',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Screen Viewed',
      props || {},
      withTypewriterContext(options),
      callback,
    );
  }
}
/**
 * The user changed a setting.
 *
 * @param {SettingChanged} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function settingChanged(
  props: SettingChanged,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'The user changed a setting.',
    properties: {
      context: {},
      properties: {
        properties: {
          setting_text: {
            description: 'The name of the setting.',
            type: 'string',
          },
          value: {
            description: 'The value the setting was changed to.',
          },
        },
        required: ['setting_text', 'value'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Setting Changed',
    type: 'object',
  };
  const message = {
    event: 'Setting Changed',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Setting Changed',
      props || {},
      withTypewriterContext(options),
      callback,
    );
  }
}
/**
 * The user started signup.
 *
 * @param {SignupStarted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function signupStarted(
  props: SignupStarted,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'The user started signup.',
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          button_location: {
            description: 'The location of the button on the page or screen.',
            pattern: '',
            type: 'string',
          },
          button_or_link: {
            description: 'Whether the element pressed was a button or a link.',
            type: 'string',
          },
          button_text: {
            description: 'The text on the button.',
            type: 'string',
          },
          page_or_screen_name: {
            description:
              'The name of the page or screen where the button was pressed.',
            type: 'string',
          },
          primary_cta: {
            description:
              'Whether the button is the primary call to action on the page or screen.',
            type: 'boolean',
          },
        },
        required: [
          'page_or_screen_name',
          'button_text',
          'primary_cta',
          'button_or_link',
        ],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Signup Started',
    type: 'object',
  };
  const message = {
    event: 'Signup Started',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Signup Started',
      props || {},
      withTypewriterContext(options),
      callback,
    );
  }
}
/**
 * The user contacted Support.
 *
 * @param {SupportContacted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function supportContacted(
  props: SupportContacted,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'The user contacted Support.',
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          button_or_link: {
            description: 'Whether the element pressed was a button or a link.',
            type: 'string',
          },
          button_or_link_location: {
            description:
              'The location of the button or link on the page or screen.',
            type: 'string',
          },
          button_or_link_text: {
            description: 'The text on the button or link.',
            type: 'string',
          },
          page_or_screen_name: {
            description:
              'The name of the page or screen where the button was pressed.',
            type: 'string',
          },
          primary_cta: {
            description:
              'Whether the button is the primary call to action on the page or screen.',
            type: 'boolean',
          },
        },
        required: [
          'page_or_screen_name',
          'button_or_link_text',
          'primary_cta',
          'button_or_link',
        ],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Support Contacted',
    type: 'object',
  };
  const message = {
    event: 'Support Contacted',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Support Contacted',
      props || {},
      withTypewriterContext(options),
      callback,
    );
  }
}
/**
 * The user swiped left or right on a screen.
 *
 * @param {Swipe} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function swipe(
  props: Swipe,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'The user swiped left or right on a screen.',
    properties: {
      context: {},
      properties: {
        properties: {
          feature: {
            description: 'Name of associated feature',
            id: '/properties/properties/properties/feature',
            type: 'string',
          },
          job_id: {
            description: 'The Hyperion job id',
            id: '/properties/properties/properties/job_id',
            type: 'integer',
          },
          org_id: {
            description: 'The Hyperion org id',
            id: '/properties/properties/properties/org_id',
            type: 'integer',
          },
          page_or_screen_name: {
            description: 'The name of the page or screen where the user swiped',
            id: '/properties/properties/properties/page_or_screen_name',
            type: 'string',
          },
          track_id: {
            description: 'Static track id for swipe ',
            id: '/properties/properties/properties/track_id',
            type: 'string',
          },
          user_id: {
            description: 'The Hyperion user id',
            id: '/properties/properties/properties/user_id',
            type: 'integer',
          },
        },
        required: ['page_or_screen_name'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Swipe',
    type: 'object',
  };
  const message = {
    event: 'Swipe',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track('Swipe', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * The user viewed a page. Page calls are automatically converted to this event.
 *
 * @param {TabViewed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function tabViewed(
  props?: TabViewed,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'The user viewed a page. Page calls are automatically converted to this event.',
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          button_location: {
            description: '',
            id: '/properties/properties/properties/button_location',
            type: 'string',
          },
          design_studio_mode: {
            description: 'The Design Studio mode the user is currently in',
            enum: ['default', 'guest', 'proView', 'share'],
            id: '/properties/properties/properties/design_studio_mode',
            type: 'string',
          },
          feature: {
            description: 'Name of associated feature',
            id: '/properties/properties/properties/feature',
            type: 'string',
          },
          job_id: {
            description: 'The Hyperion Job Id.',
            id: '/properties/properties/properties/job_id',
            type: 'integer',
          },
          page_or_screen_name: {
            description: 'The name of the page.',
            id: '/properties/properties/properties/page_or_screen_name',
            type: 'string',
          },
          partner_id: {
            description: 'Partner specific page',
            id: '/properties/properties/properties/partner_id',
            type: 'integer',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Tab Viewed',
    type: 'object',
  };
  const message = {
    event: 'Tab Viewed',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Tab Viewed',
      props || {},
      withTypewriterContext(options),
      callback,
    );
  }
}
/**
 * The user updates a text input
 *
 * @param {TextInput} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function textInput(
  props: TextInput,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'The user updates a text input',
    properties: {
      context: {},
      properties: {
        properties: {
          backend_id_type: {
            description: 'Database object name associated with this event',
            id: '/properties/properties/properties/backend_id_type',
            type: 'string',
          },
          backend_id_value: {
            description: 'Database object value associated with this event',
            id: '/properties/properties/properties/backend_id_value',
            type: 'string',
          },
          input_label: {
            description: 'The label of the text input.',
            id: '/properties/properties/properties/input_label',
            type: 'string',
          },
          input_location: {
            description: 'The location of the input on the page or screen.',
            id: '/properties/properties/properties/input_location',
            type: 'string',
          },
          input_value: {
            description: 'The value of the text input.',
            id: '/properties/properties/properties/input_value',
            type: 'string',
          },
          job_id: {
            description: 'The Hyperion Job Id',
            id: '/properties/properties/properties/job_id',
            type: 'number',
          },
          page_or_screen_name: {
            description: 'Where does this text input appear',
            id: '/properties/properties/properties/page_or_screen_name',
            type: 'string',
          },
        },
        required: ['input_value'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Text Input',
    type: 'object',
  };
  const message = {
    event: 'Text Input',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Text Input',
      props || {},
      withTypewriterContext(options),
      callback,
    );
  }
}

const clientAPI = {
  /**
   * Updates the run-time configuration of this Typewriter client.
   *
   * @param {TypewriterOptions} options - the options to upsert
   *
   * @typedef {Object} TypewriterOptions
   * @property {Segment.AnalyticsJS} [analytics] - Underlying analytics instance where analytics
   * 		calls are forwarded on to. Defaults to window.analytics.
   * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
   * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
   * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
   * 		will be logged to stderr.
   */
  setTypewriterOptions,
  /**
   * The user created an account.
   *
   * @param {AccountCreated} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  accountCreated,
  /**
   * The user agreed to the terms of service and privacy policy.
   *
   * @param {AgreedToTosPrivacy} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  agreedToTosPrivacy,
  /**
   * The user pressed the back button.
   *
   * @param {BackButtonPressed} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  backButtonPressed,
  /**
   * The user pressed a button. This is the generic event used for all button presses, other than the ones that have their own dedicated event name.
   *
   * @param {ButtonImpression} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  buttonImpression,
  /**
   * The user pressed a button. This is the generic event used for all button presses, other than the ones that have their own dedicated event name.
   *
   * @param {ButtonPressed} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  buttonPressed,
  /**
   * The user started a capture.
   *
   * @param {CaptureStarted} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  captureStarted,
  /**
   * The user submitted a capture.
   *
   * @param {CaptureSubmitted} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  captureSubmitted,
  /**
   * The user selected a checkbox.
   *
   * @param {CheckboxSelected} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  checkboxSelected,
  /**
   * The user toggled a checkbox. This is the generic event used for all checkbox toggles, other than the ones that have their own dedicated event name.
   *
   * @param {CheckboxToggled} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  checkboxToggled,
  /**
   * Data was saved in a programmatic manner, without being directly triggered by a user interaction.
   *
   * @param {DataSaved} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  dataSaved,
  /**
   * The user started a Dense capture.
   *
   * @param {DenseCaptureStarted} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  denseCaptureStarted,
  /**
   * The user dragged an element
   *
   * @param {ElementDragged} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  elementDragged,
  /**
   * The user encountered an error
   *
   * @param {Error} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  error,
  /**
   * The user upvoted a potential new feature in Design Studio.
   *
   * @param {FeatureUpvoted} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  featureUpvoted,
  /**
   * The user selected the Homeowner option.
   *
   * @param {HomeownerIdentified} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  homeownerIdentified,
  /**
   * The user pressed the input
   *
   * @param {InputPressed} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  inputPressed,
  /**
   * The user selected the Homeowner option.
   *
   * @param {InsuranceIdentified} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  insuranceIdentified,
  /**
   * The user pressed a link. This is the generic event used for all link presses, other than the ones that have their own dedicated event name.
   *
   * @param {LinkPressed} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  linkPressed,
  /**
   * The user started SSO login.
   *
   * @param {LoginSsoStarted} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  loginSsoStarted,
  /**
   * The user started login.
   *
   * @param {LoginStarted} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  loginStarted,
  /**
   * The user viewed a modal.
   *
   * @param {ModalViewed} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  modalViewed,
  /**
   * The user clicked on a facet of the 3d model.
   *
   * @param {ModelComponentSelected} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  modelComponentSelected,
  /**
   * The user rotated, panned, or zoomed the 3D model in Design Studio.
   *
   * @param {ModelInteracted} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  modelInteracted,
  /**
   * The user has viewed the feature module
   *
   * @param {ModuleViewed} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  moduleViewed,
  /**
   * User selected something from a multiple-choice select element, like a dropdown.
   *
   * @param {OptionSelected} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  optionSelected,
  /**
   * The user viewed a page. Page calls are automatically converted to this event.
   *
   * @param {PageViewed} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  pageViewed,
  /**
   * The user made a product selection in Design Studio.
   *
   * @param {ProductSelected} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  productSelected,
  /**
   * The user selected the Professional option.
   *
   * @param {ProfessionalIdentified} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  professionalIdentified,
  /**
   * The user pressed a property on the Property List screen.
   *
   * @param {PropertyPressed} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  propertyPressed,
  /**
   * The user opened the intake form for Renderings
   *
   * @param {RequestFormOpened} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  requestFormOpened,
  /**
   * The user viewed a screen. Screen calls are automatically converted to this event.
   *
   * @param {ScreenViewed} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  screenViewed,
  /**
   * The user changed a setting.
   *
   * @param {SettingChanged} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  settingChanged,
  /**
   * The user started signup.
   *
   * @param {SignupStarted} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  signupStarted,
  /**
   * The user contacted Support.
   *
   * @param {SupportContacted} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  supportContacted,
  /**
   * The user swiped left or right on a screen.
   *
   * @param {Swipe} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  swipe,
  /**
   * The user viewed a page. Page calls are automatically converted to this event.
   *
   * @param {TabViewed} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  tabViewed,
  /**
   * The user updates a text input
   *
   * @param {TextInput} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  textInput,
};

export default new Proxy<typeof clientAPI>(clientAPI, {
  get(target, method) {
    if (typeof method === 'string' && target.hasOwnProperty(method)) {
      return target[method as keyof typeof clientAPI];
    }

    return () => {
      console.warn(`⚠️  You made an analytics call (${String(
        method,
      )}) that can't be found. Either:
    a) Re-generate your typewriter client: \`npx typewriter\`
    b) Add it to your Tracking Plan: https://app.segment.com/hover3d/protocols/tracking-plans/rs_1kCXzJyCn3GUEonojuBe71ZOlYC`);
      const a = analytics();
      if (a) {
        a.track(
          'Unknown Analytics Call Fired',
          {
            method,
          },
          withTypewriterContext(),
        );
      }
    };
  },
});
