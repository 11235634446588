import { useEffect, useState } from 'react';

import { Box, Heading, Body, Button, Modal } from '@hover/blueprint';
import { isEmpty } from 'lodash';
import { useDispatch, connect } from 'react-redux';

import { Heading as EstimatorHeading } from 'src/features/exteriorEstimator/components/common/EstimatorHeading';
import { MultiTradesWrapper } from 'src/features/exteriorEstimator/components/EstimationTool/MultiTrades/MultiTradesWrapper/MultiTradesWrapper';
import { useGetWasteFactor } from 'src/features/exteriorEstimator/hooks/useGetWasteFactor';
import { estimatorActions } from 'src/features/exteriorEstimator/redux/actions';
import { UpdateAnswer } from 'src/features/exteriorEstimator/redux/actions/answerActions';
import {
  getQuestionsForCategory,
  getJobMeasurements,
  getMeasurementQuestionsAndResponses,
} from 'src/features/exteriorEstimator/redux/sagas/selectors';
import { Input } from 'src/features/exteriorEstimator/types/Questions';
import { RootState } from 'src/types/reduxStore';

import { WasteFactorSlider } from './WasteFactorSlider';

interface OwnProps {
  updateAnswer: (updateProps: UpdateAnswer) => void;
}

export const mapStateToProps = (state: RootState) => ({
  questions: getQuestionsForCategory(state),
  jobMeasurements: getJobMeasurements(state),
  measurementQuestionsAndResponses: getMeasurementQuestionsAndResponses(state),
  questionResponses: state.exteriorEstimator.inputs.questionResponses,
});

type Props = ReturnType<typeof mapStateToProps> & OwnProps;

export const WasteFactorComponent: React.FC<Props> = ({
  questions,
  jobMeasurements,
  measurementQuestionsAndResponses,
  questionResponses,
  updateAnswer,
}) => {
  const dispatch = useDispatch();

  const { data: wasteFactorData } = useGetWasteFactor(jobMeasurements);

  useEffect(() => {
    if (wasteFactorData && !isEmpty(wasteFactorData)) {
      const {
        wasteFactorRoof: { predictedWasteFactor },
      } = wasteFactorData;
      dispatch(
        estimatorActions.getPredictedWasteFactorRoof.success(
          predictedWasteFactor,
        ),
      );
    }
  }, [wasteFactorData, dispatch]);

  const [showWasteFactorCalcModal, setShowWasteFactorCalcModal] =
    useState<boolean>(false);

  const closeWasteFactorCalcModal = () => {
    setShowWasteFactorCalcModal(false);
  };

  const openWasteFactorCalcModal = () => {
    setShowWasteFactorCalcModal(true);
  };

  return (
    <MultiTradesWrapper>
      <Box paddingTop={600}>
        <EstimatorHeading>Select Waste Factors</EstimatorHeading>
      </Box>
      {questions &&
        jobMeasurements &&
        questions?.map?.((question: Input) => (
          <WasteFactorSlider
            key={question.id}
            question={question}
            jobMeasurements={jobMeasurements}
            measurementQuestionsAndResponses={measurementQuestionsAndResponses}
            questionResponses={questionResponses}
            openWasteFactorCalcModal={openWasteFactorCalcModal}
            updateAnswer={updateAnswer}
          />
        ))}
      <Modal
        isOpen={showWasteFactorCalcModal}
        data-test-id="waste-factor-calc-modal"
        size="small"
        onClose={closeWasteFactorCalcModal}
        header={<Heading size={500}>Waste Factor Recommendation</Heading>}
      >
        <Box flexDirection="column" alignItems="center">
          <Body size={400} color="neutral600">
            HOVER calculates waste suggestions based on the unique attributes of
            each property (line segments like valleys, hips, etc.) within
            industry norms.
          </Body>
          <Button
            data-test-id="waste-factor-calc-button"
            size="large"
            onClick={closeWasteFactorCalcModal}
          >
            Got it
          </Button>
        </Box>
      </Modal>
    </MultiTradesWrapper>
  );
};

export const WasteFactor = connect(mapStateToProps)(WasteFactorComponent);
