import React, { useMemo } from 'react';

import { Box, Button, Icon } from '@hover/blueprint';
import { iDownload } from '@hover/icons';
import { isNil } from 'lodash';
import { useSelector } from 'react-redux';

import { LineItemTypeEnum } from 'src/api/graphql-global-types';
import type { productCatalogConfigOrgDistributors_productCatalogConfigOrgDistributors as Distributor } from 'src/api/types/productCatalogConfigOrgDistributors';
import { getSelectedListItemIds } from 'src/features/project/redux/selectors/projectSelectors';
import { useTracking } from 'src/hooks';
import { EventNames } from 'src/types/actionTypes';

import { DirectOrderCtaModal } from './DirectOrderCtaModal';
import { useDirectOrderCtaModal } from './hooks/useDirectOrderCtaModal';

type Props = {
  openDownloadPdfModal: (lineItemtype: LineItemTypeEnum) => void;
  selectDistributor: () => void;
  distributors?: Distributor[];
};

export const ProjectScopeFooter: React.FC<Props> = React.memo(
  ({ openDownloadPdfModal, selectDistributor, distributors }) => {
    const { useTypewriter, useCommonTrackingProps } = useTracking();
    const commonTrackingProps = useCommonTrackingProps();
    const typewriter = useTypewriter();

    const selectedListItemIds = useSelector(getSelectedListItemIds);
    const selectedMaterialListItemIds =
      selectedListItemIds && selectedListItemIds[LineItemTypeEnum.MATERIAL];
    const selectedItemsTotal = useMemo(() => {
      return !selectedMaterialListItemIds
        ? 0
        : Object.values(selectedMaterialListItemIds).flat().length;
    }, [selectedMaterialListItemIds]);

    const selectDistributorsOrOpenCtaModal = () => {
      const hasDistributorsIntegrated = distributors?.length;
      if (hasDistributorsIntegrated) {
        selectDistributor();
      } else {
        openDirectOrderCtaModal();
      }
    };

    const {
      showDirectOrderCtaModal,
      openDirectOrderCtaModal,
      closeDirectOrderCtaModal,
    } = useDirectOrderCtaModal();

    return (
      <Box
        sx={{ position: 'sticky', bottom: '0px', zIndex: 1 }}
        height={500}
        backgroundColor="white"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        paddingLeft={800}
        paddingRight={800}
        boxShadow="distance600"
      >
        <DirectOrderCtaModal
          isOpen={showDirectOrderCtaModal}
          onCancel={closeDirectOrderCtaModal}
        />
        <Box>{/* Placeholder for selection state */}</Box>
        <Box display="flex" flexDirection="row">
          <Button
            color="primary"
            fill="outline"
            marginRight={300}
            onClick={() => {
              openDownloadPdfModal(LineItemTypeEnum.MATERIAL);
            }}
            isDisabled={isNil(selectedListItemIds) || selectedItemsTotal === 0}
            data-test-id="ProjectScope-Footer-DownloadMaterialList"
          >
            <Icon icon={iDownload} />
            Download material list
          </Button>
          <Button
            onClick={() => {
              typewriter.buttonPressed({
                page_or_screen_name:
                  EventNames.project.scope.orderMaterialsButtonPressed,
                button_text: 'Order materials',
                primary_cta: false,
                feature: 'material-list',
                ...commonTrackingProps,
              });
              selectDistributorsOrOpenCtaModal();
            }}
            color="primary"
            isDisabled={isNil(selectedListItemIds) || selectedItemsTotal === 0}
            data-test-id="ProjectScope-Footer-OrderMaterials"
          >
            Order materials
          </Button>
        </Box>
      </Box>
    );
  },
);
