import { startsWith } from 'lodash';
import { createReducer } from 'typesafe-actions';

import { estimatorActions } from 'src/features/exteriorEstimator/redux/actions';
import { initialState } from 'src/features/exteriorEstimator/redux/initialState';
import { PartialRoofUtils } from 'src/features/exteriorEstimator/utils/PartialRoofUtils';
import {
  COMMERCE_BRICK_AND_STONE_ESTIMATION,
  isEnabled,
} from 'src/lib/FeatureFlag';

import {
  MEASUREMENT,
  ROOF_FACET_SELECTION_3D,
  ROOF_FACET_SELECTION,
} from '../../constants/questionCategories';
import { Input } from '../../types';
import PartialSidingUtils from '../../utils/PartialSidingUtils';
import { getUpdatedLineSegmentValues } from '../../utils/questionAnswerUtils';

export const questionsReducer = createReducer(initialState)
  .handleAction(estimatorActions.updateAnswer, (state, action) => ({
    ...state,
    inputs: {
      ...state.inputs,
      questionResponses: {
        ...state.inputs.questionResponses,
        [action.payload.questionId]: action.payload.answer,
      },
    },
  }))
  .handleAction(estimatorActions.updateAnswers, (state, action) => {
    return {
      ...state,
      inputs: {
        ...state.inputs,
        questionResponses: {
          ...state.inputs.questionResponses,
          ...action.payload,
        },
      },
    };
  })
  .handleAction(estimatorActions.toggleSelectionAnswer, (state, action) => {
    const {
      questionId,
      type,
      lineSegmentInputs,
      sidingTotalQuestion,
      brickTotalQuestion,
      stoneTotalQuestion,
      brickFacetInputs,
      stoneFacetInputs,
    } = action.payload;

    const { plainMeasurements, fullMeasurements } = state.job;
    const roofTotalQuestion = state.pages.pages
      .find((page) => page.category === MEASUREMENT)
      ?.questions?.find((question) => question.argument === 'roof_total');

    const roofFacetInputs =
      state.pages.pages
        ?.find?.((page) => page.category === ROOF_FACET_SELECTION_3D)
        ?.tabs?.find?.((tab) => tab.category === ROOF_FACET_SELECTION)
        ?.questions ?? [];

    const newQuestionResponses = {
      ...state.inputs.questionResponses,
      [questionId]: !state.inputs.questionResponses[questionId],
    };
    const newRoofTotal = roofFacetInputs.reduce((_acc, question) => {
      let acc = _acc;

      acc += newQuestionResponses[question.id as number]
        ? question?.area ?? 0
        : 0;
      return acc;
    }, 0);

    if (!plainMeasurements || !fullMeasurements) return { ...state };

    const newAnswers = getUpdatedLineSegmentValues({
      facetLabel: questionId,
      answer: !state.inputs.questionResponses[questionId],
      type,
      lineSegmentInputs,
      plainMeasurements,
      fullMeasurements,
      sidingTotalQuestion,
      questionResponses: state.inputs.questionResponses,
    });

    newAnswers[roofTotalQuestion?.id as number] = newRoofTotal;
    const question = roofFacetInputs.find((input) => input.id === questionId);

    const { pitchQuestion } = PartialRoofUtils.getPitchQuestionsForPitch(
      state.pages.pages,
      question?.pitch ?? '',
    );

    newAnswers[pitchQuestion?.id as number] =
      PartialRoofUtils.getTotalAreaForPitch(
        roofFacetInputs,
        question?.pitch ?? '',
        newQuestionResponses,
      );

    if (isEnabled(COMMERCE_BRICK_AND_STONE_ESTIMATION)) {
      // update brick and stone inputs
      if (startsWith(questionId, 'BR-') && brickTotalQuestion) {
        const selectedBrickFacets = brickFacetInputs?.filter(
          (q) => newQuestionResponses[q.id],
        );

        newAnswers[brickTotalQuestion.id] =
          PartialSidingUtils.getCombinedAreaForSelectedFacets(
            selectedBrickFacets as Input[],
          );
      }

      if (startsWith(questionId, 'STO-') && stoneTotalQuestion) {
        const selectedStoneFacets = stoneFacetInputs?.filter(
          (q) => newQuestionResponses[q.id],
        );
        newAnswers[stoneTotalQuestion.id] =
          PartialSidingUtils.getCombinedAreaForSelectedFacets(
            selectedStoneFacets as Input[],
          );
      }
    }

    return {
      ...state,
      inputs: {
        ...state.inputs,
        questionResponses: {
          ...state.inputs.questionResponses,
          [action.payload.questionId]:
            !state.inputs.questionResponses[action.payload.questionId],
          ...newAnswers,
        },
      },
    };
  })
  .handleAction(estimatorActions.setQuestionResponsesEnd, (state, action) => ({
    ...state,
    inputs: {
      ...state.inputs,
      questionResponses: action.payload.emptyQuestionResponses,
    },
  }))
  .handleAction(
    estimatorActions.setCurrentQuestionCategory,
    (state, action) => ({
      ...state,
      inputs: {
        ...state.inputs,
        currentQuestionCategory: action.payload.category,
      },
      currentQuestionCategory: action.payload.category,
    }),
  )
  .handleAction(
    estimatorActions.getCustomLineItems.success,
    (state, action) => ({
      ...state,
      inputs: {
        ...state.inputs,
        customLineItems: action.payload.customLineItems,
      },
    }),
  )
  .handleAction(
    estimatorActions.updateCustomLineItems.success,
    (state, action) => ({
      ...state,
      inputs: {
        ...state.inputs,
        customLineItems: action.payload.customLineItems,
      },
    }),
  );
