import React, { useEffect } from 'react';

import {
  Body,
  Box,
  Button,
  Drawer,
  Heading,
  useDisclosure,
  Link,
} from '@hover/blueprint';
import { iInfo, iExternalLink, iDownload } from '@hover/icons';
import { useSelector } from 'react-redux';

import { useTracking } from 'src/hooks';
import { getUserTrackingProps } from 'src/redux/selectors';
import { EventNames } from 'src/types/actionTypes';

export const ProjectScopeTipsDrawer: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();

  const handleTipsOpenClick = () => {
    onOpen();
    typewriter.buttonPressed({
      page_or_screen_name: EventNames.project.scope.tipsButtonPressed,
      button_text: 'Tips',
      primary_cta: false,
      feature: 'material-list',
      ...commonTrackingProps,
    });
  };

  const handleDownloadSampleClick = () => {
    typewriter.buttonPressed({
      page_or_screen_name:
        EventNames.project.scope.tipsModal.downloadPdfPressed,
      button_text: 'Download material list sample',
      primary_cta: false,
      feature: 'material-list',
      ...commonTrackingProps,
    });
  };

  const handleLearnMoreClick = () => {
    typewriter.linkPressed({
      link_text: 'learn more',
      page_or_screen_name: EventNames.project.scope.tipsModal.learnMorePressed,
      primary_cta: false,
      feature: 'material-list',
      link_location: 'project scope',
      ...commonTrackingProps,
    });
  };

  const handleGetStartedClick = () => {
    typewriter.buttonPressed({
      page_or_screen_name: EventNames.project.scope.tipsModal.getStartedPressed,
      button_text: 'Get started',
      primary_cta: true,
      feature: 'material-list',
      ...commonTrackingProps,
    });
  };

  const handleClose = () => {
    typewriter.buttonPressed({
      page_or_screen_name: EventNames.project.scope.tipsModal.close,
      button_text: 'x',
      primary_cta: false,
      feature: 'material-list',
      ...commonTrackingProps,
    });
    onClose();
  };

  useEffect(() => {
    if (isOpen) {
      typewriter.pageViewed({
        page_or_screen_name: EventNames.project.scope.tipsModal.page,
        feature: 'material-list',
        ...commonTrackingProps,
      });
    }
  }, [isOpen]);

  return (
    <Box marginLeft={200}>
      <Drawer isOpen={isOpen} onClose={handleClose} isClosable>
        <Box flexDir="column" paddingX={600} paddingY={400}>
          <Body color="brandGreen.500" fontWeight={700} fontSize={200}>
            TIPS
          </Body>
          <Heading>Instantly create and share Material Lists</Heading>
          <Body fontSize={200}>
            Generate a list of material quantities needed for siding or roofing
            projects using measurements and industry-specific templates. Edit
            and download the material list for project bids, quotes, or material
            orders.
          </Body>
          <Body fontWeight={700} fontSize={200} color="brandGreen.500">
            HOW TO USE IT?
          </Body>
          <Box flexDirection="row">
            <Body
              minW="50px"
              width="50px"
              height="50px"
              border="1.2px solid black"
              textAlign="center"
              padding="12px"
              borderRadius="50%"
              marginRight={500}
            >
              1
            </Body>
            <Box flexDirection="column">
              <Body fontWeight={700} fontSize={300} marginBottom={0}>
                Select Template and Scope
              </Body>
              <Body fontSize={200} marginTop={100}>
                Select property scope, trade(s) and templates to suit your
                project needs.
              </Body>
            </Box>
          </Box>
          <Box flexDirection="row">
            <Body
              minW="50px"
              width="50px"
              height="50px"
              border="1.2px solid black"
              textAlign="center"
              padding="12px"
              borderRadius="50%"
              marginRight={500}
            >
              2
            </Body>
            <Box flexDirection="column">
              <Body fontWeight={700} fontSize={300} marginBottom={0}>
                Review and Edit
              </Body>
              <Body fontSize={200} marginTop={100}>
                Edit, add, replace, or remove any material list line item(s). Or
                click{' '}
                <Body as="span" fontWeight={700} fontSize={200}>
                  Customize material list
                </Body>{' '}
                to easily modify scope (e.g., partial jobs), waste, install
                preferences, or template.
              </Body>
            </Box>
          </Box>
          <Box flexDirection="row">
            <Body
              minW="50px"
              width="50px"
              height="50px"
              border="1.2px solid black"
              textAlign="center"
              padding="12px"
              borderRadius="50%"
              marginRight={500}
              marginBottom={600}
            >
              3
            </Body>
            <Box flexDirection="column" marginBottom={500}>
              <Body fontWeight={700} fontSize={300} marginBottom={0}>
                Download and Share
              </Body>
              <Body fontSize={200} marginTop={100} marginBottom={100}>
                Select the trades or line items you want to include, download
                and share it.
              </Body>
              <Link
                fill="minimal"
                fontSize={200}
                iconBefore={iDownload}
                justifyContent="start"
                target="_blank"
                href="https://drive.google.com/file/d/1A5Kg6HTqOT5qxrG2MsNu3WbaUoFv-HhD/view?usp=share_link"
                onClick={handleDownloadSampleClick}
              >
                Download a Material List sample
              </Link>
            </Box>
          </Box>
          <Box
            flexDir="column"
            background="warning.100"
            borderRadius="6px"
            padding={500}
          >
            <Body fontSize={300} fontWeight={700} marginY={0}>
              HOW TO SET UP CUSTOM TEMPLATES?
            </Body>
            <Body fontSize={200}>
              Personalize the Material List templates to suit your business
              needs.
            </Body>
            <Box>
              <Button
                as="a"
                color="primary"
                fontSize={200}
                fill="outline"
                target="_blank"
                href="https://hover.qualtrics.com/jfe/form/SV_ahGMn84z1KTF0xg"
                onClick={handleGetStartedClick}
              >
                Get started
              </Button>
              <Link
                colorScheme="primary"
                iconBefore={iExternalLink}
                marginLeft={600}
                fontSize={200}
                target="_blank"
                href="https://help.hover.to/en/collections/3835185-material-list"
                onClick={handleLearnMoreClick}
              >
                Learn more
              </Link>
            </Box>
          </Box>
        </Box>
      </Drawer>
      <Button
        color="primary"
        fill="minimal"
        iconBefore={iInfo}
        onClick={handleTipsOpenClick}
        boxShadow="distance100"
        marginRight={200}
      >
        Tips
      </Button>
    </Box>
  );
};
