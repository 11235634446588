import { facadesSortOrderByType as sidingPrefixes } from 'src/features/exteriorEstimator/constants/SidingPartialSortOrder';
import { EstimationMeasurementsFacade } from 'src/types/EstimationMeasurementTypes';

import {
  SIDING_FACET_SELECTION_3D,
  SIDING_JOB_TYPE,
} from '../constants/questionCategories';
import { FULL_SIDING_VALUE } from '../constants/sidingConstants';
import { QuestionId, QuestionAnswer, Input } from '../types';

export interface CombinedAreaWithTrimForFacade {
  [facade: string]: {
    areaWithOpenings: number;
    trimArea: number;
    openingsTrim: number;
    combinedArea: number;
  };
}

export class PartialSidingUtils {
  // determines if question is for selecting partial siding facets
  // ie. if questionId is prefixed with ['SI', 'UN', 'STC', 'TD', 'STO', 'BR', 'WR']
  static isQuestionPartialSidingSelection(questionId: QuestionId) {
    return (
      typeof questionId === 'string' &&
      sidingPrefixes.includes(questionId.split('-')[0])
    );
  }

  static isSidingPage(category: string) {
    return category === SIDING_FACET_SELECTION_3D;
  }

  // this function sets the default value for the partial siding selection question
  static setDefaultPartialSidingSelection(
    // All siding facades should be selected by default
    // Unknown facades if 'connected to siding' should be selected by default
    // all other facades [Brick (BR-), stucco (ST-) etc.] should default to false
    questionId: QuestionId,
    unknownsConnectedToSidingMap: { [key: string]: boolean | undefined },
  ) {
    const wallType = questionId.toString().split('-')[0];
    const isSiding = wallType === 'SI';
    const isUnkWallConnectedToSiding =
      wallType === 'UN' ? unknownsConnectedToSidingMap[questionId] : false;

    return isSiding || isUnkWallConnectedToSiding;
  }

  static isSetToFull(questionId: QuestionId, answer: QuestionAnswer) {
    return questionId === SIDING_JOB_TYPE && answer === FULL_SIDING_VALUE;
  }

  static getCombinedAreaWithTrimForFacades(
    facades: EstimationMeasurementsFacade[],
  ) {
    // memory placeholders for deduping trims and opening_trims shared among facades
    const trims: { [sortIndex: number]: 1 } = {};
    const openingTrims: { [sortIndex: number]: 1 } = {};

    return facades.reduce<CombinedAreaWithTrimForFacade>((acc, facade) => {
      const areaWithOpenings =
        facade?.area_with_waste_factor_calculation?.with_openings ?? 0;
      const trimArea =
        facade?.trim?.connected_areas?.reduce<number>((sum, next) => {
          if (!trims[next.sort_index]) {
            trims[next.sort_index] = 1;
            return sum + (next?.area_in_sqrft ?? 0);
          }
          return sum;
        }, 0) ?? 0;
      const openingsTrim =
        facade?.openings_trim?.connected_areas?.reduce<number>((sum, next) => {
          if (!openingTrims[next.sort_index]) {
            openingTrims[next.sort_index] = 1;
            return sum + (next?.area_in_sqrft ?? 0);
          }
          return sum;
        }, 0) ?? 0;

      const combinedArea = areaWithOpenings + trimArea + openingsTrim;

      acc[facade.facade] = {
        areaWithOpenings,
        trimArea,
        openingsTrim,
        combinedArea,
      };

      return acc;
    }, {});
  }

  static getCombinedAreaForSelectedFacets = (selectedFacets: Input[]) => {
    return selectedFacets.reduce((_combinedArea, input) => {
      let combinedArea = _combinedArea;
      combinedArea += input?.combinedArea ?? 0;

      return combinedArea;
    }, 0);
  };
}
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default PartialSidingUtils;
