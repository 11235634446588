import { JobMeasurements } from '../types';

export const ROOF_TOTAL = 'roof_total';

export const FEATURES_MAP = {
  roof_total: ROOF_TOTAL,
  roof_count: 'roof_count',
  ridge_total: 'ridge_total',
  ridge_count: 'ridge_count',
  hip_total: 'hip_total',
  hip_count: 'hip_count',
  valley_total: 'valley_total',
  valley_count: 'valley_count',
  eave_total: 'eave_total',
  eave_count: 'eave_count',
  rake_total: 'rake_total',
  rake_count: 'rake_count',
  flashing_total: 'flashing_total',
  flashing_count: 'flashing_count',
  step_flashing_total: 'step_flashing_total',
  step_flashing_count: 'step_flashing_count',
};

// These are all the questions we need to track for updating partial roof selection, facets only
export const PARTIAL_ROOF_AREA_FEATURES_MAP: {
  [key: string]: string;
} = {
  roof_total: ROOF_TOTAL,
  roof_count: 'roof_count',
  pitch_area_0_in_12: 'pitch_area_0_in_12',
  pitch_area_1_in_12: 'pitch_area_1_in_12',
  pitch_area_2_in_12: 'pitch_area_2_in_12',
  pitch_area_3_in_12: 'pitch_area_3_in_12',
  pitch_area_4_in_12: 'pitch_area_4_in_12',
  pitch_area_5_in_12: 'pitch_area_5_in_12',
  pitch_area_6_in_12: 'pitch_area_6_in_12',
  pitch_area_7_in_12: 'pitch_area_7_in_12',
  pitch_area_8_in_12: 'pitch_area_8_in_12',
  pitch_area_9_in_12: 'pitch_area_9_in_12',
  pitch_area_10_in_12: 'pitch_area_10_in_12',
  pitch_area_11_in_12: 'pitch_area_11_in_12',
  pitch_area_12_in_12: 'pitch_area_12_in_12',
  pitch_area_13_in_12: 'pitch_area_13_in_12',
  pitch_area_14_in_12: 'pitch_area_14_in_12',
  pitch_area_15_in_12: 'pitch_area_15_in_12',
  pitch_area_greater_15_in_12: 'pitch_area_greater_15_in_12',
};

// These are all the questions we need to track for line segments for partial roof selection
export const PARTIAL_ROOF_LINE_SEGMENT_FEATURES_MAP = {
  ridge_total: 'ridge_total',
  ridge_count: 'ridge_count',
  hip_total: 'hip_total',
  hip_count: 'hip_count',
  valley_total: 'valley_total',
  valley_count: 'valley_count',
  eave_total: 'eave_total',
  eave_count: 'eave_count',
  rake_total: 'rake_total',
  rake_count: 'rake_count',
  flashing_total: 'flashing_total',
  flashing_count: 'flashing_count',
  step_flashing_total: 'step_flashing_total',
  step_flashing_count: 'step_flashing_count',
};

export const PARTIAL_ROOF_LINE_SEGMENT_FEATURES_QUESTIONS = Object.values(
  PARTIAL_ROOF_LINE_SEGMENT_FEATURES_MAP,
);

export enum PARTIAL_SIDING_LINE_SEGMENT_INPUT_ARGUMENT_ENUM {
  inside_corner_siding_count_total = 'inside_corner_siding_count_total',
  inside_corner_siding_length_total = 'inside_corner_siding_length_total',
  level_starter_siding_length_total = 'level_starter_siding_length_total',
  openings_sides_length_siding_total = 'openings_sides_length_siding_total',
  openings_sill_length_siding_total = 'openings_sill_length_siding_total',
  openings_top_length_siding_total = 'openings_top_length_siding_total',
  outside_corner_siding_count_total = 'outside_corner_siding_count_total',
  outside_corner_siding_length_total = 'outside_corner_siding_length_total',
  shutter_quantity_siding_total = 'shutter_quantity_siding_total',
  sloped_trim_siding_length_total = 'sloped_trim_siding_length_total',
  vertical_trim_siding_length_total = 'vertical_trim_siding_length_total',
  window_count_total = 'window_count_total',
}

export const PARTIAL_SIDING_LINE_SEGMENT_FEATURES_MAP: {
  [key in PARTIAL_SIDING_LINE_SEGMENT_INPUT_ARGUMENT_ENUM]: string;
} = {
  inside_corner_siding_count_total: 'inside_corner_siding_count_total',
  inside_corner_siding_length_total: 'inside_corner_siding_length_total',
  level_starter_siding_length_total: 'level_starter_siding_length_total',
  openings_sides_length_siding_total: 'openings_sides_length_siding_total',
  openings_sill_length_siding_total: 'openings_sill_length_siding_total',
  openings_top_length_siding_total: 'openings_top_length_siding_total',
  outside_corner_siding_count_total: 'outside_corner_siding_count_total',
  outside_corner_siding_length_total: 'outside_corner_siding_length_total',
  shutter_quantity_siding_total: 'shutter_quantity_siding_total',
  sloped_trim_siding_length_total: 'sloped_trim_siding_length_total',
  vertical_trim_siding_length_total: 'vertical_trim_siding_length_total',
  window_count_total: 'window_count_total',
};

export const PARTIAL_SIDING_LINE_SEGMENT_FEATURES_QUESTIONS = Object.values(
  PARTIAL_SIDING_LINE_SEGMENT_FEATURES_MAP,
);

/* 
  - matches input argument to the corresponding siding edge measurements
  - ehi-api defines these differently than the measurements apis
*/
export const SIDING_ARGUMENT_MAP: typeof PARTIAL_SIDING_LINE_SEGMENT_FEATURES_MAP =
  {
    inside_corner_siding_count_total: 'inside_corners_count',
    inside_corner_siding_length_total: 'inside_corners_total',
    level_starter_siding_length_total: 'level_bases_total',
    openings_sides_length_siding_total: 'opening_sides_total',
    openings_sill_length_siding_total: 'opening_bottoms_total',
    openings_top_length_siding_total: 'opening_tops_total',
    outside_corner_siding_count_total: 'outside_corners_count',
    outside_corner_siding_length_total: 'outside_corners_total',
    shutter_quantity_siding_total: 'shutter_quantity_siding_total', // might be wrong
    sloped_trim_siding_length_total: 'sloped_bases_total',
    vertical_trim_siding_length_total: 'perimeter_total',
    window_count_total: 'window_count_total',
  };
