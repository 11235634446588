import { useMemo } from 'react';

import {
  Body,
  Box,
  BoxProps,
  Button,
  Checkbox,
  Heading,
  Icon,
  Link,
  Tag,
} from '@hover/blueprint';
import { iDownload, iPlus } from '@hover/icons';
import { isNil } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { HashLink as RouterLink } from 'react-router-hash-link';

import { LineItemTypeEnum, TradeTypeEnum } from 'src/api/graphql-global-types';
import type {
  ListItemIdsByTrade,
  ListItemsByTrade,
} from 'src/features/project/types';
import { useTracking } from 'src/hooks';
import {
  getMaterialListFeature,
  getUserTrackingProps,
} from 'src/redux/selectors';
import { EventNames } from 'src/types/actionTypes';
import { sentenceCase } from 'src/utils/Formatters';

import { toggleSelectAllTypeItems } from '../../redux/actions';
import { useProjectScopeTracker } from './hooks/useProjectScopeTracker';

type ProjectScopeTypeListControlsProps = {
  jobId: number;
  lineItemType: LineItemTypeEnum;
  selectedListItemIds: ListItemIdsByTrade | null;
  itemsByTrade: ListItemsByTrade;
  selectDistributor: () => void;
  openDownloadPdfModal: (lineItemType: LineItemTypeEnum) => void;
  openAddEditMaterialModal: (type: LineItemTypeEnum) => void;
};

const LineItemTypeDisplayMap = {
  [LineItemTypeEnum.MATERIAL]: 'Materials',
  [LineItemTypeEnum.LABOR]: 'Labor',
  [LineItemTypeEnum.OTHER]: 'Other',
} as const;

export const ProjectScopeTypeListControls: React.FC<
  ProjectScopeTypeListControlsProps & BoxProps
> = ({
  jobId,
  lineItemType,
  selectDistributor,
  selectedListItemIds,
  itemsByTrade,
  openDownloadPdfModal,
  openAddEditMaterialModal,
  ...props
}) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { trackAddItemButton, trackLineItemTypeCheckbox } =
    useProjectScopeTracker({ jobId });
  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();

  const materialListFeatureEnabled = useSelector(getMaterialListFeature);

  const trades = useMemo(() => Object.keys(itemsByTrade), [itemsByTrade]);
  const selectedItemsTotal = useMemo(() => {
    return !selectedListItemIds
      ? 0
      : Object.values(selectedListItemIds).flat().length;
  }, [selectedListItemIds]);

  const itemsByTradeTotal = useMemo(
    () => Object.values(itemsByTrade).flat().length,
    [itemsByTrade],
  );

  const handleToggleSelection = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(toggleSelectAllTypeItems(lineItemType, itemsByTrade));
    // trackLineItemTypeCheckbox(lineItemType, e.target.checked);
  };

  const handleSaveAsTemplateClick = () => {
    typewriter.linkPressed({
      link_text: 'Save as template',
      page_or_screen_name: EventNames.project.scope.saveAsTemplatePressed,
      primary_cta: true,
      feature: 'material-list',
      link_location: 'project scope',
      ...commonTrackingProps,
    });
  };

  return (
    <Box
      id="ProjectScope-controls"
      alignItems="baseline"
      padding={500}
      flexDirection="row"
      justifyContent="space-between"
      backgroundColor="neutral0"
      {...props}
    >
      <Box alignItems="center">
        <Box width="48px" paddingLeft={3} paddingRight={1}>
          <Checkbox
            value={`${lineItemType}`}
            aria-label={lineItemType}
            id={lineItemType}
            key={lineItemType}
            data-test-id="checkboxLineItemTypeGroup"
            flex="0"
            isChecked={selectedItemsTotal === itemsByTradeTotal}
            isIndeterminate={
              selectedItemsTotal > 0 && selectedItemsTotal < itemsByTradeTotal
            }
            onChange={handleToggleSelection}
          />
        </Box>

        <Heading marginBottom={0} paddingLeft={1} size={400}>
          {LineItemTypeDisplayMap[lineItemType]}
        </Heading>

        <Box color="neutral.500" lineHeight={400} marginLeft={400}>
          {selectedItemsTotal === 0 && (
            <Body as="span" color="neutral.500">
              Select materials to order or download as PDF
            </Body>
          )}

          {selectedItemsTotal > 0 &&
            trades.map((tradeType) => (
              <Tag
                key={tradeType}
                colorScheme="dark"
                marginRight={2}
                marginY="2px"
              >
                <Link
                  as={RouterLink}
                  to={{
                    ...location,
                    ...{ hash: `#${lineItemType}.${tradeType}` },
                  }}
                  color="neutral.0"
                  _active={{
                    color: 'neutral.0',
                    textDecorationColor: 'transparent',
                  }}
                  _hover={{
                    color: 'neutral.0',
                    textDecorationColor: 'transparent',
                  }}
                >
                  {`${sentenceCase(tradeType)}
              (${
                selectedListItemIds &&
                selectedListItemIds[tradeType as TradeTypeEnum] &&
                selectedListItemIds[tradeType as TradeTypeEnum].length
              })`}
                </Link>
              </Tag>
            ))}
        </Box>
      </Box>

      <Box>
        {!materialListFeatureEnabled && (
          <>
            {lineItemType === LineItemTypeEnum.MATERIAL && (
              <Button
                data-test-id="ProjectScope-Actions-Order"
                label="Order materials"
                onClick={() => {
                  typewriter.buttonPressed({
                    page_or_screen_name:
                      EventNames.project.scope.orderMaterialsButtonPressed,
                    button_text: 'Order materials',
                    primary_cta: false,
                    ...commonTrackingProps,
                  });
                  selectDistributor();
                }}
                isDisabled={
                  isNil(selectedListItemIds) || selectedItemsTotal === 0
                }
              >
                Order materials
              </Button>
            )}
            <Button
              data-test-id="ProjectScope-Actions-PDF"
              label="Download PDF"
              fill="outline"
              marginLeft={400}
              onClick={() => openDownloadPdfModal(lineItemType)}
              isDisabled={
                isNil(selectedListItemIds) || selectedItemsTotal === 0
              }
            >
              <Icon icon={iDownload} />
              Download PDF
            </Button>
          </>
        )}

        {materialListFeatureEnabled && (
          <Link
            data-test-id="ProjectScope-Actions-SaveAsTemplate"
            colorScheme="primary"
            href="https://hover.qualtrics.com/jfe/form/SV_6mQ0ziNKq6ORDi6?Q_CHL"
            target="_blank"
            onClick={handleSaveAsTemplateClick}
          >
            Save as template
          </Link>
        )}

        <Button
          data-test-id="ProjectScope-Actions-AddItem"
          label={`Add ${lineItemType.toLowerCase()}`}
          aria-label={`add-${lineItemType}-button`}
          fill="outline"
          marginLeft={400}
          onClick={() => {
            trackAddItemButton(lineItemType);
            openAddEditMaterialModal(lineItemType);
          }}
        >
          <Icon icon={iPlus} />
          {`Add ${lineItemType.toLowerCase()}`}
        </Button>
      </Box>
    </Box>
  );
};
