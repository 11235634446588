import {
  HdfSource,
  HDFMeasurementsExtended,
  MeasurementValue,
  RoofPitch,
  HDFMeasurements,
} from 'src/features/exteriorEstimator/types/HdfMeasurements';

/* WIP */

const MEASUREMENT_VALUE_FINAL_PATH = (name: string) =>
  `calculations[name = "${name}"].value.final`;

const MEASUREMENT_BREAKDOWN_VALUE_FINAL_PATH = (
  name: string,
  breakdownName: string,
) =>
  `calculations[name = "${name}"].breakdown[name= "${breakdownName}"].value.final`;

const MEASUREMENT_BREAKDOWN_PATH = (name: string) =>
  `calculations[name = "${name}"].breakdown[0].breakdown`;

const MEASUREMENT_BREAKDOWN_COUNT_PATH = (name: string) =>
  `COUNT(${MEASUREMENT_BREAKDOWN_PATH(name)})`;

/* eslint-disable no-eval */

export class HdfMeasurementParser {
  public measurements: HdfSource;

  public results: HDFMeasurementsExtended;

  constructor(measurements: HdfSource) {
    this.measurements = measurements;
    this.results = {
      roof: {
        roof_total: this.getMeasurement('areas.roof'),
        roof_count: this.getRoofCount('areas.roof'),
        ridge_total: this.getMeasurement('ridge.length'),
        ridge_count: this.getMeasurement('ridge.quantity'),
        hip_total: this.getMeasurement('hip.length'),
        hip_count: this.getMeasurement('hip.quantity'),
        valley_total: this.getMeasurement('valley.length'),
        valley_count: this.getMeasurement('valley.quantity'),
        eave_total: this.getMeasurement('eave.length'),
        eave_count: this.getMeasurement('eave.quantity'),
        rake_total: this.getMeasurement('rake.length'),
        rake_count: this.getMeasurement('rake.quantity'),
        flashing_total: this.getMeasurement('flashing.length'),
        flashing_count: this.getMeasurement('flashing.quantity'),
        step_flashing_total: this.getMeasurement('step_flashing.length'),
        step_flashing_count: this.getMeasurement('step_flashing.quantity'),
        soffit_total_area: this.getMeasurement('soffit.area'),
        soffit_total_length: this.getMeasurement('soffit.length'),
        pitch: this.getRoofPitches('large_roof_pitch.area'),
      },
      // sides
      sides: this.getSides(),
      siding_zero_waste_area_total: this.getMeasurement(
        'waste.siding.zero.area',
      ),
      siding_with_openings_area_total: this.getMeasurement(
        'waste.siding.with_openings_lt_33.zero.area',
      ),
      siding_facade_area: this.getMeasurement('facades.siding.area'),
      siding_unknown_area_total: this.getMeasurement('facades.unknown.area'),
      brick_with_openings_area: this.getMeasurement(
        'waste.brick.with_openings_lt_33.zero.area',
      ),
      brick_zero_waste_area: this.getMeasurement('facades.brick.area'),
      stucco_with_openings_area: this.getMeasurement(
        'waste.stucco.with_openings_lt_33.zero.area',
      ),
      stucco_zero_waste_area: this.getMeasurement('facades.stucco.area'),
      metal_with_openings_area: this.getMeasurement(
        'waste.metal.with_openings_lt_33.zero.area',
      ),
      metal_zero_waste_area: this.getMeasurement('facades.metal.area'),
      stone_with_openings_area: this.getMeasurement(
        'waste.stone.with_openings_lt_33.zero.area',
      ),
      stone_zero_waste_area: this.getMeasurement('facades.stone.area'),
      wrap_with_openings_area: this.getMeasurement(
        'waste.wrap.with_openings_lt_33.zero.area',
      ),
      wrap_zero_waste_area: this.getMeasurement('facades.wrap.area'),
      tudor_with_openings_area: this.getMeasurement(
        'waste.tudor.with_openings_lt_33.zero.area',
      ),
      tudor_zero_waste_area: this.getMeasurement('facades.tudor.area'),
      unknown_with_openings_area: this.getMeasurement(
        'waste.unknown.with_openings_lt_33.zero.area',
      ),
      unknown_zero_waste_area: this.getMeasurement('facades.unknown.area'),
      // windows
      window_count_total: this.getMeasurement('openings.windows.quantity'),
      window_ui_total: this.getMeasurement('openings.windows.united'),

      // facades
      openings_top_length_siding_total: this.getMeasurementInBreakdown(
        'siding.openings.perimeter',
        'tops',
      ),
      openings_top_length_other_total: this.getMeasurementInBreakdown(
        'other.openings.perimeter',
        'tops',
      ),
      openings_sill_length_siding_total: this.getMeasurementInBreakdown(
        'siding.openings.perimeter',
        'sills',
      ),
      openings_sill_length_other_total: this.getMeasurementInBreakdown(
        'other.openings.perimeter',
        'sills',
      ),
      openings_sides_length_siding_total: this.getMeasurementInBreakdown(
        'siding.openings.perimeter',
        'sides',
      ),
      openings_sides_length_other_total: this.getMeasurementInBreakdown(
        'other.openings.perimeter',
        'sides',
      ),
      inside_corner_siding_count_total: this.getMeasurement(
        'siding.corners.inside.quantity',
      ),
      outside_corner_siding_count_total: this.getMeasurement(
        'siding.corners.outside.quantity',
      ),
      outside_corner_other_count_total: this.getMeasurement(
        'other.corners.outside.quantity',
      ),
      inside_corner_siding_length_total: this.getMeasurement(
        'siding.corners.inside.perimeter',
      ),
      outside_corner_siding_length_total: this.getMeasurement(
        'siding.corners.outside.perimeter',
      ),
      outside_corner_other_length_total: this.getMeasurement(
        'other.corners.outside.perimeter',
      ),
      level_starter_siding_length_total: this.getMeasurement(
        'siding.trim.levelstarter',
      ),
      level_starter_other_length_total: this.getMeasurement(
        'other.trim.levelstarter',
      ),
      sloped_trim_siding_length_total:
        this.getMeasurement('siding.trim.sloped'),
      sloped_trim_other_length_total: this.getMeasurement('other.trim.sloped'),
      vertical_trim_siding_length_total: this.getMeasurement(
        'siding.trim.vertical',
      ),
      vertical_trim_other_length_total: this.getMeasurement(
        'other.trim.vertical',
      ),
      // shutters
      shutter_quantity_siding_total: this.getMeasurement(
        'facades.siding.shutters.quantity',
      ),
      shutter_quantity_other_total: this.getMeasurement(
        'facades.other.shutters.quantity',
      ),
      // vents
      vent_quantity_other_total: this.getMeasurement(
        'facades.other.vents.quantity',
      ),
      vent_quantity_siding_total: this.getMeasurement(
        'facades.siding.vents.quantity',
      ),
      // gutters
      gutter_length_total: this.getMeasurementInInches('gutter.length'),
      downspout_length_total: this.getMeasurementInInches('downspout.length'),
      // cornices
      cornice_returns_length_total: this.getMeasurementInInches(
        'cornice_return.length',
      ),
      cornice_returns_count_total: this.getMeasurement(
        'cornice_return.quantity',
      ),
      // fascia
      eaves_fascia_total: this.getMeasurement('eave.length'),
      rakes_fascia_total: this.getMeasurement('rake.length'),
      level_frieze_board_length_total: this.getMeasurement(
        'level_frieze_board.length',
      ),
      level_frieze_board_avg_depth: this.getMeasurementAverage(
        'level_frieze_board.depth',
      ),
      level_frieze_board_soffit_area: this.getMeasurement(
        'level_frieze_board.area',
      ),
      sloped_frieze_board_length_total: this.getMeasurement(
        'sloped_frieze_board.length',
      ),
      sloped_frieze_board_avg_depth: this.getMeasurementAverage(
        'sloped_frieze_board.depth',
      ),
      sloped_frieze_board_soffit_area: this.getMeasurement(
        'sloped_frieze_board.area',
      ),
    };
  }

  public parse() {
    return this.results;
  }

  public getValues(): HDFMeasurements {
    const values: any = {};
    Object.entries(this.results).forEach(([key, val]) => {
      if (key === 'roof') {
        const roofValues: any = {};
        Object.entries(this.results.roof).forEach(([roofKey, roofVal]) => {
          roofValues[roofKey] = roofVal.value;
        });
        values.roof = roofValues;
      } else {
        values[key] = val.value;
      }
    });
    return values;
  }

  /* functions for getting individual measurements */

  // the most generic and simple case, simply read the measurement with the matching name
  private getMeasurement(name: string): MeasurementValue {
    const value = this.getMeasurementFinalValue(name);

    return {
      value,
      sources: [MEASUREMENT_VALUE_FINAL_PATH(name)],
      raw: [this.getMeasurementByNameRaw(name)],
      equation: '',
    };
  }

  // the measurement, multipleid by 12
  private getMeasurementInInches(name: string): MeasurementValue {
    const value = this.getMeasurementFinalValue(name) * 12;

    return {
      value,
      sources: [MEASUREMENT_VALUE_FINAL_PATH(name)],
      raw: [this.getMeasurementByNameRaw(name)],
      equation: '',
    };
  }

  private getMeasurementInBreakdown(
    name: string,
    breakdownName: string,
  ): MeasurementValue {
    const measurement = this.getMeasurementByName(name);
    const breakdown = measurement?.breakdown?.find(
      (b: any) => b.name === breakdownName,
    );
    const value = breakdown?.value?.final ?? 0;

    return {
      value,
      sources: [MEASUREMENT_BREAKDOWN_VALUE_FINAL_PATH(name, breakdownName)],
      raw: [this.getMeasurementByNameRaw(name)],
      equation: '',
    };
  }

  private getMeasurementAverage(name: string): MeasurementValue {
    const length = this.getMeasurementFinalValue(name);
    const count = this.getCountOfMeasurement(name);
    const equation = `${length} / ${count}`;

    return {
      value: eval(equation),
      sources: [
        MEASUREMENT_VALUE_FINAL_PATH(name),
        MEASUREMENT_BREAKDOWN_PATH(name),
      ],
      raw: [this.getMeasurementByNameRaw(name)],
      equation: `${MEASUREMENT_VALUE_FINAL_PATH(
        name,
      )} / ${MEASUREMENT_BREAKDOWN_COUNT_PATH(name)} => ${equation}`,
    };
  }

  private getRoofPitches(name: string) {
    const measurement = this.getMeasurementByName(name);
    const pitches: RoofPitch[] = [];

    measurement?.breakdown[0]?.breakdown.forEach((pitch: any) => {
      const pitchNumber = pitch?.groupBy[0]?.value;
      pitch.breakdown.forEach((pitchBreakdown: any) => {
        pitches.push({
          facet: pitchBreakdown?.breakdown[0]?.sourceId?.name,
          label: pitchBreakdown?.breakdown[0]?.sourceId?.name,
          area: pitchBreakdown?.breakdown[0]?.value?.final,
          pitch: pitchNumber,
        });
      });
    });

    return {
      value: pitches,
      sources: [`calculations[name = "${name}"].breakdown[0].breakdown...`],
      raw: [measurement ?? null],
      equation: 'custom',
    };
  }

  private getSides() {
    const sidings = this.getMeasurementByName(
      'waste.siding.with_openings_lt_33.zero.area',
    )?.breakdown[0]?.breakdown;

    const stucco = this.getMeasurementByName(
      'waste.stucco.with_openings_lt_33.zero.area',
    )?.breakdown[0]?.breakdown;

    const stone = this.getMeasurementByName(
      'waste.stone.with_openings_lt_33.zero.area',
    )?.breakdown[0]?.breakdown;

    const brick = this.getMeasurementByName(
      'waste.brick.with_openings_lt_33.zero.area',
    )?.breakdown[0]?.breakdown;

    const tudor = this.getMeasurementByName(
      'waste.tudor.with_openings_lt_33.zero.area',
    )?.breakdown[0]?.breakdown;

    const wrap = this.getMeasurementByName(
      'waste.wrap.with_openings_lt_33.zero.area',
    )?.breakdown[0]?.breakdown;

    const unknown = this.getMeasurementByName(
      'waste.unknown.with_openings_lt_33.zero.area',
    )?.breakdown[0]?.breakdown;

    const sidingFaces = {
      front: {
        total: 0,
        area_per_label: [],
      },
      back: {
        total: 0,
        area_per_label: [],
      },
      left: {
        total: 0,
        area_per_label: [],
      },
      right: {
        total: 0,
        area_per_label: [],
      },
    };

    [sidings, stucco, stone, brick, tudor, wrap, unknown]
      .filter((m) => m)
      .flat()
      .forEach((siding: any) => {
        const area = siding.value.final; // area from waste factor lt 33 without connected siding

        const classificiation = this.measurements.classification.faces.find(
          (face) => face.id.name === siding.name,
        ); // find the elevation from classification
        const elevation = classificiation?.elevation
          ? classificiation.elevation.toLowerCase()
          : null;

        if (classificiation && elevation) {
          const elevationSection: any =
            sidingFaces[elevation as 'front' | 'back' | 'left' | 'right'];
          // assign it to the correct elevation, add its area to elevation total
          elevationSection.area_per_label.push({ [siding.name]: area });

          elevationSection.total += area;
        }
      });

    return {
      value: sidingFaces,
      sources: [
        'calculations[name = "waste.siding.with_openings_lt_33.zero.area.breakdown[0].breakdown',
        'calculations[name = "waste.stucco.with_openings_lt_33.zero.area.breakdown[0].breakdown',
        'calculations[name = "waste.stone.with_openings_lt_33.zero.area.breakdown[0].breakdown',
        'calculations[name = "waste.brick.with_openings_lt_33.zero.area.breakdown[0].breakdown',
        'calculations[name = "waste.tudor.with_openings_lt_33.zero.area.breakdown[0].breakdown',
        'calculations[name = "waste.wrap.with_openings_lt_33.zero.area.breakdown[0].breakdown',
        'calculations[name = "waste.unknown.with_openings_lt_33.zero.area.breakdown[0].breakdown',
        'classification.faces[id.name] === siding.name',
      ],
      raw: [
        sidings,
        stucco,
        stone,
        brick,
        tudor,
        wrap,
        unknown,
        this.measurements.classification.faces,
      ],
      equation: '',
    };
  }

  private getRoofCount(name: string): MeasurementValue {
    const count = this.getCountOfMeasurement(name);

    return {
      value: count,
      sources: [MEASUREMENT_BREAKDOWN_PATH(name)],
      raw: [this.getMeasurementByNameRaw(name)],
      equation: `${MEASUREMENT_BREAKDOWN_COUNT_PATH(name)}`,
    };
  }

  /* helper methods */

  // given a string, find the calculation in calculations where name === string (the entire object)
  private getMeasurementByName(name: string) {
    return this.measurements.measurements.calculations.find(
      (c) => c.name === name,
    );
  }

  // same as above, but return null if it's not present in the HDF
  private getMeasurementByNameRaw(name: string) {
    return this.getMeasurementByName(name) ?? null;
  }

  // given a string, find the calculation in calculations where name === string, return its value.final or 0 if it does not exist
  private getMeasurementFinalValue(name: string) {
    return this.getMeasurementByName(name)?.value.final ?? 0;
  }

  // given a string, find the calculation in calculations where name === string, and give the count of total objects in the breakdown[0].breakdown of that calculation
  private getCountOfMeasurement(name: string) {
    const measurement = this.getMeasurementByName(name);
    return measurement?.breakdown[0]?.breakdown?.length;
  }
}

/* eslint-enable no-eval */
