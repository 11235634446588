import { useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { Box, Button, Loader } from '@hover/blueprint';
import { iFile } from '@hover/icons';
import { useSelector, useDispatch } from 'react-redux';

import { ProjectManagementProposalDocumentStateEnum } from 'src/api/graphql-global-types';
import { projectManagementProposalDocumentPreviewCreate as ProposalPreviewResp } from 'src/api/types/projectManagementProposalDocumentPreviewCreate';
import { ToastNotification } from 'src/components/ToastNotification';
import {
  CREATE_PROPOSAL_PREVIEW,
  GET_PROPOSAL_PREVIEW,
} from 'src/features/settings/api/queries/proposals';
import type { ViewMode } from 'src/features/settings/components/Proposal/Proposal';
import { useTracking } from 'src/hooks';
import { downloadPdf } from 'src/lib/pdfDownload';
import { mobileDownloadPdf } from 'src/redux/actions';
import { getUserTrackingProps, getUserOrgId } from 'src/redux/selectors';
import { EventNames } from 'src/types/actionTypes';

type Props = {
  viewMode: ViewMode;
};

export const Preview: React.FC<Props> = ({ viewMode }) => {
  const orgId = useSelector(getUserOrgId);
  const dispatch = useDispatch();
  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();

  const [previewId, setPreviewId] = useState<string | null>(null);
  const [showPreviewError, setShowPreviewError] = useState<boolean>(false);

  // clear proposal preview error
  const clearShowPreviewError = () => {
    setShowPreviewError(false);
  };

  // fetch proposal preview
  const {
    loading: loadingProposalPreview,
    data,
    stopPolling,
    error,
  } = useQuery(GET_PROPOSAL_PREVIEW, {
    variables: { id: previewId },
    pollInterval: 1500,
    skip: !previewId,
  });

  const proposalPreview = data?.projectManagementProposalDocumentPreview;

  if (
    error ||
    proposalPreview?.state === ProjectManagementProposalDocumentStateEnum.FAILED
  ) {
    stopPolling();
    setPreviewId(null);
    setShowPreviewError(true);
  }
  if (
    proposalPreview?.state ===
    ProjectManagementProposalDocumentStateEnum.COMPLETE
  ) {
    stopPolling();
    setPreviewId(null);
    dispatch(
      mobileDownloadPdf.request({
        url: proposalPreview?.pdf?.redirectUrl,
        name: proposalPreview?.pdf?.filename,
      }),
    );
    downloadPdf({
      url: proposalPreview?.pdf?.redirectUrl,
      name: proposalPreview?.pdf?.filename,
    });
  }

  // generate proposal preview
  const onCompleted = ({
    projectManagementProposalDocumentPreviewCreate,
  }: ProposalPreviewResp) => {
    const id =
      projectManagementProposalDocumentPreviewCreate?.proposalDocumentPreview
        ?.id;
    if (id) setPreviewId(id);
  };

  const [
    projectManagementProposalDocumentPreviewCreate,
    { loading: creatingProposalPreview },
  ] = useMutation(CREATE_PROPOSAL_PREVIEW, {
    onCompleted,
  });

  const handleClick = () => {
    typewriter.buttonPressed({
      button_text: 'Preview',
      page_or_screen_name: EventNames.settings.proposal.page,
      primary_cta: false,
      ...commonTrackingProps,
    });
    projectManagementProposalDocumentPreviewCreate({
      variables: {
        orgId,
      },
    });
  };

  const isProposalGenerating =
    proposalPreview?.state ===
    ProjectManagementProposalDocumentStateEnum.GENERATING;

  return (
    <Box alignItems="center">
      <ToastNotification
        show={showPreviewError}
        notification="Error downloading proposal preview"
        severity="Error"
        clearError={clearShowPreviewError}
        autohide
      />

      {creatingProposalPreview ||
      loadingProposalPreview ||
      isProposalGenerating ? (
        <Loader />
      ) : (
        <Button
          iconAfter={iFile}
          fill="outline"
          onClick={handleClick}
          isDisabled={viewMode === 'EDIT'}
          data-test-id="preview-proposal-button"
        >
          Preview
        </Button>
      )}
    </Box>
  );
};
