import { useCallback } from 'react';

import { useSelector } from 'react-redux';

import { useTracking } from 'src/hooks';
import { getUserTrackingProps } from 'src/redux/selectors';
import { EventNames } from 'src/types';

export function useProjectScopeTracker({ jobId }: { jobId: number }) {
  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();

  // Segment tracking.
  const trackAddItemButton = useCallback(
    (itemType: string) => {
      typewriter.buttonPressed({
        button_text: `Add ${itemType}`,
        page_or_screen_name: EventNames.project.scope.page,
        job_id: jobId,
        primary_cta: false,
        ...commonTrackingProps,
      });
    },
    // eslint-disable-next-line
    [jobId, commonTrackingProps],
  );

  const trackLineItemTypeCheckbox = useCallback(
    (lineItemType: string, checked: boolean) => {
      typewriter.checkboxSelected({
        selection: checked.toString(),
        options: lineItemType,
        page_or_screen_name: EventNames.project.scope.page,
        ...commonTrackingProps,
      });
    },
    // eslint-disable-next-line
    [commonTrackingProps],
  );

  const trackTradeTypeCheckbox = useCallback(
    (lineItemType: string, tradeType: string, checked: boolean) => {
      typewriter.checkboxSelected({
        selection: checked.toString(),
        options: tradeType,
        page_or_screen_name: EventNames.project.scope.page,
        ...commonTrackingProps,
      });
    },
    // eslint-disable-next-line
    [commonTrackingProps],
  );

  const trackEditItemButton = useCallback(
    (itemType: string) => {
      typewriter.buttonPressed({
        button_text: `Edit ${itemType}`,
        page_or_screen_name: EventNames.project.scope.page,
        job_id: jobId,
        primary_cta: false,
        ...commonTrackingProps,
      });
    },
    // eslint-disable-next-line
    [jobId, commonTrackingProps],
  );

  const trackOrderLink = useCallback(() => {
    typewriter.buttonPressed({
      button_location: 'Orders column',
      button_text: 'Order Confirmation',
      page_or_screen_name: EventNames.project.scope.page,
      job_id: jobId,
      primary_cta: false,
      ...commonTrackingProps,
    });
    // eslint-disable-next-line
  }, [jobId, commonTrackingProps]);

  const trackInlineEditingDataUpdated = useCallback(
    (lineItemType: string, tradeType: string, inputLabel: string) => {
      typewriter.dataSaved({
        feature: 'Inline Editing',
        item_type: `${lineItemType} - ${tradeType}`,
        page_or_screen_name: EventNames.project.scope.page,
        input_label: inputLabel,
        ...commonTrackingProps,
      });
    },
    // eslint-disable-next-line
    [jobId, commonTrackingProps],
  );

  const trackInlineEditingInputPressed = useCallback(
    (inputName: string) => {
      typewriter.inputPressed({
        feature: 'Inline Editing',
        input_label: inputName,
        ...commonTrackingProps,
      });
    },
    // eslint-disable-next-line
    [jobId, commonTrackingProps],
  );
  const trackDeleteItemCanceled = useCallback(() => {
    typewriter.buttonPressed({
      button_location: 'Delete Item',
      button_text: 'Cancel',
      page_or_screen_name: EventNames.project.scope.page,
      job_id: jobId,
      primary_cta: false,
      ...commonTrackingProps,
    });
  }, [jobId, commonTrackingProps]);

  const trackDeleteItemConfirmed = useCallback(() => {
    typewriter.buttonPressed({
      button_location: 'Delete Item',
      button_text: 'Confirm',
      page_or_screen_name: EventNames.project.scope.page,
      job_id: jobId,
      primary_cta: false,
      ...commonTrackingProps,
    });
  }, [jobId, commonTrackingProps]);

  const trackDeleteItem = useCallback(() => {
    typewriter.buttonPressed({
      button_location: 'Delete Item',
      button_text: 'Trash Icon',
      page_or_screen_name: EventNames.project.scope.page,
      job_id: jobId,
      primary_cta: false,
      ...commonTrackingProps,
    });
  }, [jobId, commonTrackingProps]);

  return {
    trackEditItemButton,
    trackOrderLink,
    trackAddItemButton,
    trackLineItemTypeCheckbox,
    trackTradeTypeCheckbox,
    trackInlineEditingDataUpdated,
    trackInlineEditingInputPressed,
    trackDeleteItemCanceled,
    trackDeleteItemConfirmed,
    trackDeleteItem,
  };
}
