import { useEffect } from 'react';

import { isEqual } from 'lodash';
import { useSelector } from 'react-redux';

import { AreaCounter } from 'src/features/exteriorEstimator/components/EstimationTool/BottomBar/AreaCounter';
import { UpdateAnswer } from 'src/features/exteriorEstimator/redux/actions/answerActions';
import {
  getJob,
  getQuestionResponses,
  getSidingTotalArea,
  getSidingTotalQuestion,
  getPartialsMeasurements,
} from 'src/features/exteriorEstimator/redux/sagas/selectors/estimatorSelectors';
import { LineSegmentCalculator } from 'src/features/exteriorEstimator/utils/LineSegmentCalculator';
import { LineSegmentCalculatorComparator } from 'src/features/exteriorEstimator/utils/LineSegmentCalculatorComparator';
import { LineSegmentCalculatorHdf } from 'src/features/exteriorEstimator/utils/LineSegmentCalculatorHdf';

interface OwnProps {
  updateAnswer: (updateProps: UpdateAnswer) => void;
}

export const SelectedPartialSidingAreaCounter = (props: OwnProps) => {
  const sidingTotalQuestion = useSelector(getSidingTotalQuestion);
  const sidingsTotalArea = useSelector(getSidingTotalArea);
  const { plainMeasurements, fullMeasurements } = useSelector(getJob);
  const partialsMeasurements = useSelector(getPartialsMeasurements);
  const questionResponses = useSelector(getQuestionResponses);
  const { updateAnswer } = props;

  useEffect(() => {
    if (!!plainMeasurements && !!fullMeasurements && !!sidingTotalQuestion) {
      const { selectedAreaWithTrim, calculatedEdgeTotals } =
        new LineSegmentCalculator({
          pristinePlainMeasurements: plainMeasurements,
          estimationJson: fullMeasurements,
          questionResponses,
        });

      // only update the store if the calculated value
      // does not match what's already in redux
      if (selectedAreaWithTrim !== sidingsTotalArea) {
        updateAnswer({
          questionId: sidingTotalQuestion?.id,
          answer: selectedAreaWithTrim,
        });
      }
      // remove when COMMERCE_USE_HDF deprecated
      // this will only be true if COMMERCE_USE_HDF is on
      if (!!partialsMeasurements) {
        const { edges, facades, windowsForFacade } = partialsMeasurements;
        const calculator = new LineSegmentCalculatorHdf({
          edges,
          facades,
          windowsForFacade,
          questionResponses,
        });
        new LineSegmentCalculatorComparator(
          calculator.calculatedEdgeTotals,
          calculatedEdgeTotals,
        ).compare();
      }
    }
  }, [
    fullMeasurements,
    plainMeasurements,
    questionResponses,
    sidingTotalQuestion,
    sidingsTotalArea,
    updateAnswer,
    partialsMeasurements,
  ]);

  return <AreaCounter area={sidingsTotalArea} />;
};
