import { breakerClient } from '@hover/breaker-react';
import { throttle, get, compact } from 'lodash';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import typewriter from 'src/lib/telemetry/generated/segment';
import {
  getImpersonator,
  getOrgFeatures,
  getUserTrackingProps,
} from 'src/redux/selectors';

const throttledConsoleInfo = throttle(
  () => console.info('typewriter suppressed because user is an impersonator'),
  1000,
);

/**
 * wrapper for typewriter
 *
 * if a user is being impersonated, will stub out and supress typewriter updates to segment
 * else, does normal typewriter behaviour
 */
export const useTracking = () => {
  const orgFeatureAccesses = useSelector(getOrgFeatures);

  const useTypewriter = () => {
    const currentImpersonator = useSelector(getImpersonator);

    if (currentImpersonator) {
      const stubbedTypewriter = { ...typewriter };
      Object.keys(stubbedTypewriter).forEach((key) => {
        stubbedTypewriter[key as keyof typeof stubbedTypewriter] = () => {
          return throttledConsoleInfo();
        };
      });

      return stubbedTypewriter;
    }
    return typewriter;
  };

  const formatEnabledSplitFlagFeatures = () => {
    const splitFeatureFlagsObject = breakerClient?.getFeatureMap?.() || {};
    const splitFeatureFlagsArray = Object.values(splitFeatureFlagsObject).map(
      (featureFlag) => {
        if (featureFlag.key && featureFlag.enabled) {
          return featureFlag.key;
        }
        return false;
      },
    );
    const enabledSplitFlagFeatures = compact(splitFeatureFlagsArray);
    return enabledSplitFlagFeatures;
  };

  const formatEnabledOrgFeatures = () => {
    const orgFeatures = orgFeatureAccesses?.map((accessor) => {
      if (accessor.enabled && accessor.identifier) {
        return accessor.identifier as string;
      }
      return false;
    });
    const enabledOrgFeatures = compact(orgFeatures);
    return enabledOrgFeatures;
  };

  /**
   * common props that we want to, in normal situations, pass to every event
   */
  const useCommonTrackingProps = () => {
    // add common props
    const commonUserProps = useSelector(getUserTrackingProps);
    // add job ID
    const jobIdFromUrl = Number(get(useParams(), 'jobId'));
    const jobObj = {} as { [k: string]: number };
    if (jobIdFromUrl) {
      jobObj.job_id = jobIdFromUrl;
    }
    // add all enabled features from split and hyperion
    const enabledSplitFlagFeatures = formatEnabledSplitFlagFeatures();
    const enabledOrgFeatures = formatEnabledOrgFeatures();
    const allEnabledFeatures: Array<string> = [
      ...enabledSplitFlagFeatures,
      ...enabledOrgFeatures,
    ];
    const features = {
      features: allEnabledFeatures,
    };

    return { ...commonUserProps, ...jobObj, ...features };
  };

  return { useTypewriter, useCommonTrackingProps };
};
