import { useCallback, useEffect } from 'react';

import { Box, Body } from '@hover/blueprint';
import { uniqBy, groupBy, sortBy } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';

import { EstimateGroupForInputSummary } from 'src/api/types/EstimateGroupForInputSummary';
import { LoaderSpinner } from 'src/components/LoaderSpinner';
import { EstimatorApi } from 'src/features/exteriorEstimator/apis/estimator';
import { GET_ESTIMATE_GROUP_FOR_INPUT_SUMMARY } from 'src/features/exteriorEstimator/apis/queries';
import { EstimatorResponsiveWrapper } from 'src/features/exteriorEstimator/components/common/EstimatorResponsiveWrapper';
import { CustomLineItem } from 'src/features/exteriorEstimator/components/EstimationTool/EstimateGroupSummary/CustomLineItem';
import { InputCategory } from 'src/features/exteriorEstimator/components/EstimationTool/EstimateGroupSummary/InputCategory';
import { SummaryHeader } from 'src/features/exteriorEstimator/components/EstimationTool/EstimateGroupSummary/SummaryHeader';
import { ModelSection } from 'src/features/exteriorEstimator/components/EstimationTool/Machete/ModelSection';
import { SUPERSCRIPT_TWO } from 'src/features/exteriorEstimator/constants/sidingConstants';
import { useGetWasteFactor } from 'src/features/exteriorEstimator/hooks/useGetWasteFactor';
import { estimatorActions } from 'src/features/exteriorEstimator/redux/actions';
import {
  getJobDetailSummary,
  getEstimateGroupIdFromLocation,
  getJobMeasurements,
} from 'src/features/exteriorEstimator/redux/sagas/selectors';
import { getParams } from 'src/features/exteriorEstimator/redux/sagas/selectors/estimatorSelectors';
import { JobMeasurements } from 'src/features/exteriorEstimator/types';
import {
  getSelectedRoofFacetTotal,
  getSelectedSidingFacetTotal,
} from 'src/features/exteriorEstimator/utils/estimateGroupUtils';
import { EstimationMeasurementParser } from 'src/features/exteriorEstimator/utils/EstimationMeasurementParser';
import {
  getRoofTrades,
  getSidingTrades,
} from 'src/features/exteriorEstimator/utils/questionsUtils';
import { useQueryEhi, useTracking } from 'src/hooks';
import {
  getTradeTypesSorted,
  getOrgSettings,
  getUserTrackingProps,
  getVariationsFilter,
} from 'src/redux/selectors';
import { EventNames } from 'src/types/actionTypes';
import { Measurements } from 'src/types/EstimationMeasurementTypes';

import { ProfitMargin } from './ProfitMargin';
import { Timestamp } from './Timestamp';

export const EstimateGroupSummary: React.FC = () => {
  const dispatch = useDispatch();
  const { jobId } = useSelector(getParams);
  const { showEstimationProfitInSummary = false } =
    useSelector(getOrgSettings) ?? {};

  const jobDetailSummary = useSelector(getJobDetailSummary);
  const jobMeasurements = useSelector(getJobMeasurements);
  const tradeTypes = useSelector(getTradeTypesSorted);
  const estimateGroupId = useSelector(getEstimateGroupIdFromLocation);
  const variationsFilter = useSelector(getVariationsFilter);
  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();

  const getMeasurements = useCallback(async () => {
    let fullMeasurements: Measurements;

    try {
      fullMeasurements = (
        (await EstimatorApi.getMeasurementsEstimation(Number(jobId))) as any
      ).data as Measurements;

      dispatch(
        estimatorActions.storeJobMeasurements({
          jobMeasurements: EstimationMeasurementParser.parse(
            fullMeasurements,
          ) as JobMeasurements,
        }),
      );
    } catch (e) {
      // NO-OP
    }
  }, [dispatch, jobId]);

  useEffect(() => {
    if (!jobMeasurements) {
      getMeasurements();
    }
  }, [getMeasurements, jobMeasurements]);

  const { data: wasteFactorData } = useGetWasteFactor(jobMeasurements);

  const { data, loading } = useQueryEhi<EstimateGroupForInputSummary>(
    GET_ESTIMATE_GROUP_FOR_INPUT_SUMMARY,
    {
      variables: {
        id: estimateGroupId,
        ...variationsFilter,
      },
    },
  );

  useEffect(() => {
    if (!jobDetailSummary) return;
    typewriter.pageViewed({
      page_or_screen_name:
        EventNames.estimator.estimateDetailsScreen.inputSummary.page,
      job_id: Number(jobDetailSummary?.id),
      ...commonTrackingProps,
    });
  }, [jobDetailSummary, commonTrackingProps]);

  const estimateGroup = data?.estimationEstimateGroup;

  if (loading) return <LoaderSpinner show />;

  if (!estimateGroup || !estimateGroup?.userAnswers?.length) return null;

  const activeEstimates = estimateGroup.estimates.filter((est) => est.active);

  const customLineItems = activeEstimates
    .map((estimate) =>
      estimate.lineItems?.filter((lineItem) => lineItem.custom),
    )
    .flat();

  const groupedAnswers = groupBy(estimateGroup.userAnswers, (answer) => {
    return answer.inputCategory.name;
  });

  const inputCategories = estimateGroup.userAnswers.map(
    (answer) => answer.inputCategory,
  );

  const sortedUniqInputCategories = sortBy(
    uniqBy(inputCategories, 'id'),
    'sortOrder',
  );

  const roofTrades = getRoofTrades(tradeTypes).map(
    (trade) => trade.tradeTypeEnumValue,
  );
  const sidingTrades = getSidingTrades(tradeTypes).map(
    (trade) => trade.tradeTypeEnumValue,
  );
  const activeEstimatesIncludesRoof = activeEstimates.some((e) =>
    e.tradeType ? roofTrades.includes(e.tradeType) : false,
  );
  const activeEstimatesIncludesSiding = activeEstimates.some((e) =>
    e.tradeType ? sidingTrades.includes(e.tradeType) : false,
  );

  const selectedRoofFacetTotal = getSelectedRoofFacetTotal(estimateGroup);
  const selectedSidingFacetTotal = getSelectedSidingFacetTotal(estimateGroup);

  let facets = estimateGroup?.facets ?? [];
  if (!activeEstimatesIncludesRoof)
    facets = facets.filter((f) => !f.identifier.startsWith('RF'));

  if (!activeEstimatesIncludesSiding)
    facets = facets.filter((f) => f.identifier.startsWith('RF'));

  return (
    <Box data-test-id="EstimateGroupSummary" flexDirection="column">
      <SummaryHeader />
      <Box display="block">
        <EstimatorResponsiveWrapper
          data-test-id="EstimateGroupSummary"
          // this is all to achieve a paddingTop={0}
          padding={0}
          paddingX={{ base: 300, desktop: 500 }}
          paddingBottom={{ base: 300, desktop: 500 }}
        >
          <Box flexDirection="row" justifyContent="space-between" marginY={300}>
            <Box flexDirection="column">
              <Body
                size={500}
                color="neutral800"
                marginY={200}
                fontFamily="Avenir Next"
              >
                {jobDetailSummary.name}
                <br />
                {[
                  jobDetailSummary.locationLine1,
                  jobDetailSummary.locationLine2,
                  jobDetailSummary.locationCity,
                  jobDetailSummary.locationCountry,
                  jobDetailSummary.postalCode,
                ]
                  .filter(Boolean)
                  .join(', ')}
              </Body>
            </Box>
            <Timestamp createdAt={estimateGroup?.createdAt} />
          </Box>
          <Box flexDirection="column" boxShadow="distance500" padding={400}>
            {facets.length > 0 && (
              <>
                <Box flexDirection="column">
                  {selectedRoofFacetTotal && activeEstimatesIncludesRoof && (
                    <Body
                      size={500}
                      color="neutral800"
                      fontFamily="Avenir Next"
                      marginBottom={400}
                      marginY={0}
                    >
                      Selected roof area:&nbsp;
                      {`${selectedRoofFacetTotal} ft${SUPERSCRIPT_TWO}`}
                    </Body>
                  )}
                  {selectedSidingFacetTotal > 0 &&
                    activeEstimatesIncludesSiding && (
                      <Body
                        size={500}
                        color="neutral800"
                        fontFamily="Avenir Next"
                        marginY={0}
                      >
                        Selected siding area:&nbsp;
                        {`${selectedSidingFacetTotal} ft${SUPERSCRIPT_TWO}`}
                      </Body>
                    )}
                </Box>
                <ModelSection facets={facets} widescreen />
              </>
            )}
            <Box
              flexDirection="column"
              width="100%"
              data-test-id="InputCategories"
            >
              <Box flexDirection="column">
                {sortedUniqInputCategories.map((inputCategory) => {
                  const answers = groupedAnswers[inputCategory.name];
                  return (
                    <InputCategory
                      key={inputCategory.id}
                      answers={answers}
                      input={inputCategory}
                      predictedWasteFactor={
                        wasteFactorData?.wasteFactorRoof.predictedWasteFactor
                      }
                    />
                  );
                })}
                {customLineItems && customLineItems.length > 0 && (
                  <CustomLineItem customLineItems={customLineItems} />
                )}
              </Box>
              {showEstimationProfitInSummary && (
                <ProfitMargin estimateGroup={estimateGroup} />
              )}
            </Box>
          </Box>
        </EstimatorResponsiveWrapper>
      </Box>
    </Box>
  );
};
