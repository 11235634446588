import { PureComponent } from 'react';

import { Box } from '@hover/blueprint';
import autobind from 'autobind-decorator';
import { connect } from 'react-redux';

import { withTypewriter } from 'src/components/WithTypewriter';
import { downloadPdf } from 'src/lib/pdfDownload';
import { getUserTrackingProps } from 'src/redux/selectors';
import { EventNames } from 'src/types/actionTypes';
import { RootState } from 'src/types/reduxStore';
import { jobProps } from 'src/utils/trackingUtils';

import {
  getJobDetails,
  getParams,
  getPdf,
} from '../../../redux/selectors/estimatorProductionSelectors';
import {
  formatCreatedAtToHumanFriendly,
  buildPdfFileName,
} from '../../../utils/ProductionListUtils';
import { PdfDownload } from './styled';

export const mapStateToProps = (state: RootState) => ({
  jobDetails: getJobDetails(state),
  jobId: getParams(state).jobId,
  pdf: getPdf(state),
  commonProps: getUserTrackingProps(state),
});

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class OrderDocumentDownloadLink extends PureComponent<any> {
  @autobind
  handleClick() {
    const { orderDocument, jobDetails, jobId, commonProps, typewriter } =
      this.props;

    typewriter.buttonPressed({
      button_text: 'PDF Historical Exports Download Existing',
      page_or_screen_name: EventNames.pmp.pdf.page,
      primary_cta: false,
      ...jobProps(jobDetails),
      ...commonProps,
    });
    const { filename } = orderDocument.pdf;
    const name = buildPdfFileName({
      jobId,
      filename,
    });
    downloadPdf({ name, url: orderDocument.pdf.url });
  }

  public render() {
    const {
      orderDocument: {
        createdAt,
        order: { vendor },
      },
    } = this.props;
    const vendorName = vendor?.vendorName;

    return (
      <Box justifyContent="space-between" padding={200}>
        <Box marginBottom={200}>
          {vendorName} Order Summary <br />
          Generated {formatCreatedAtToHumanFriendly(createdAt)}
        </Box>
        <Box>
          <PdfDownload onClick={this.handleClick}>Download</PdfDownload>
        </Box>
      </Box>
    );
  }
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default connect(mapStateToProps)(
  withTypewriter(OrderDocumentDownloadLink),
);
