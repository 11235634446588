import { useEffect } from 'react';

import { useMutation } from '@apollo/client';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { LoaderSpinner } from 'src/components/LoaderSpinner';
import { ESTIMATOR, ESTIMATOR_PRODUCTION } from 'src/constants/urls';
import {
  SALES_OPPORTUNITY_UPDATE,
  ESTIMATION_ESTIMATE_TOGGLE_ACTIVATION,
} from 'src/features/exteriorEstimator/apis/queries';
import { getEstimateGroup } from 'src/features/exteriorEstimator/redux/sagas/selectors';
import { useSearchParams } from 'src/hooks/useSearchParams';
import { isEnabled, COMMERCE_PROJECT_SCOPE } from 'src/lib/FeatureFlag';
import { getUserOrgId } from 'src/redux/selectors';

export const TakeoffCreation = () => {
  const history = useHistory();
  const { searchParams, updateParams } = useSearchParams();
  const jobId = searchParams.get('jobId');
  const orgId = useSelector(getUserOrgId);
  const estimateGroup = useSelector(getEstimateGroup);
  const [updateSalesOpportunity] = useMutation(SALES_OPPORTUNITY_UPDATE);
  const [updateEstimate] = useMutation(ESTIMATION_ESTIMATE_TOGGLE_ACTIVATION);

  const redirectToProductionConsole = () => {
    if (!jobId) return;

    if (isEnabled(COMMERCE_PROJECT_SCOPE)) {
      history.replace(`/project/${jobId}/scope?orgId=${orgId}`);
    } else {
      updateParams({
        jobId,
        orgId,
      });

      history.replace({
        pathname: ESTIMATOR_PRODUCTION,
        search: searchParams.toString(),
      });
    }
  };

  const generateTakeoff = async () => {
    if (!jobId || !estimateGroup || estimateGroup.estimates.length > 1) {
      history.push({ pathname: ESTIMATOR });
      return;
    }

    if (estimateGroup.salesOpportunity.soldEstimateGroupId) {
      redirectToProductionConsole();
      return;
    }

    // mark single estimate as active
    await updateEstimate({
      variables: {
        id: estimateGroup.estimates[0].id,
        orgId,
        attributes: { active: true },
      },
    });

    // mark sales opportunity as sold externally
    // this will also trigger generation of production list
    await updateSalesOpportunity({
      variables: {
        id: estimateGroup.salesOpportunity.id,
        salesOpportunityAttributes: {
          soldEstimateGroupId: estimateGroup.id,
          soldExternally: true,
        },
      },
    });

    redirectToProductionConsole();
  };

  useEffect(() => {
    generateTakeoff();
  }, []);

  return <LoaderSpinner show />;
};
