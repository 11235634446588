import { gql } from '@apollo/client';

export const errorFields = gql`
  fragment errorFields on Error {
    attribute
    errors
  }
`;

export const proposalFields = gql`
  fragment proposalFields on ProjectManagementProposalDocument {
    customerEmail
    customerName
    createdAt
    totalPrice
    customers {
      id
      fullName
      emailAddress
      phoneNumber
    }
    estimateGroup {
      id
    }
    id
    includeAllEstimateItems
    signatureRequest {
      state
      failureReason
    }
    notes
    pdf {
      filename
      url
      redirectUrl
    }
    state
    updatedAt
    userId
  }
`;

export const estimateFields = gql`
  fragment estimateFields on Estimate {
    id
    active
    name
    tradeType
    basePrice
    discounts {
      discountType
      id
      name
      value
      estimationEstimateId
    }
    jobId
    orgId
    userId
    template {
      id
      name
      tradeType
      sortOrder
    }
    lineItems {
      allowVariationSelection
      custom
      id
      isAuto
      margin
      measurement
      measurementUnits
      name
      price
      quantity
      quantityUnits
      results
      salesTax
      type
      unitCost
      values
      wasteFactor
    }
    fullMeasurements
  }
`;

export const lineItems = gql`
  fragment lineItems on LineItem {
    allowVariationSelection
    custom
    id
    isAuto
    margin
    measurement
    measurementUnits
    name
    price
    product {
      id
      name
      variations(filterByOrg: $filterVariationsByOrg, orgId: $orgId) {
        id
        name
        texture {
          diffuseMapThumbnail {
            redirectUrl
          }
        }
      }
    }
    quantity
    quantityUnits
    results
    salesTax
    productCatalogVariationName
    selectedVariation {
      id
      name
    }
    type
    unitCost
    values
    wasteFactor
  }
`;

export const estimateFieldsForEstimateGroups = gql`
  fragment estimateFieldsForEstimateGroups on Estimate {
    id
    active
    name
    tradeType
    basePrice
    discounts {
      discountType
      id
      name
      value
      estimationEstimateId
    }
    jobId
    orgId
    userId
    template {
      id
      name
      tradeType
      sortOrder
    }
    templateGroups {
      id
      name
      description
      sortOrder
      lineItems {
        id
        name
        price
        quantity
        quantityUnits
        results
        measurementUnits
        wasteFactor
        productCatalogVariationName
        selectedVariation {
          name
        }
      }
    }
    lineItems {
      ...lineItems
    }
    fullMeasurements
  }
  ${lineItems}
`;

export const estimationEstimateFields = gql`
  fragment estimationEstimateFields on EstimationEstimateGroup {
    id
    state
    failureReason
    createdAt
    updatedAt
    orgId
    jobId
    totalPrice
    totalCost
    estimates(active: $active) {
      ...estimateFields
    }
    salesOpportunity {
      id
      soldEstimateGroupId
    }
  }
  ${estimateFields}
`;

export const userAnswers = gql`
  fragment userAnswers on EstimateGroupUserAnswer {
    categoryName
    estimationConfigInput {
      id
      tradeTypes
      tradeTypeCategories
    }
    inputCategory {
      id
      name
      sortOrder
    }
    id
    inputType
    measurementUnits
    question
    userValue
  }
`;

export const ORG_SETTINGS_QUERY = gql`
  query ehiOrgSettings {
    ehiOrgSettings {
      id
      monthlyPaymentApr
      monthlyPaymentDuration
      showOrderingFlow
      showPricesInEstimation
      showEstimateLineItemDetailsToggle
      showEstimationProfitInSummary
      showInspectionChecklist
      createdAt
      updatedAt
    }
  }
`;

export const GET_PRODUCT_CATALOG_ORG_SETTINGS = gql`
  query productCatalogOrgSettings($orgId: ID!) {
    productCatalogOrgSettings(orgId: $orgId) {
      productFilteringEnabled
    }
  }
`;

export const GET_PROJECT_MANAGEMENT_CONFIG_ORG_PROPOSAL_SETTINGS = gql`
  query projectManagementConfigOrgProposalSettingGlobal($orgId: ID!) {
    projectManagementConfigOrgProposalSetting(orgId: $orgId) {
      proposalsEnabled
      id
    }
  }
`;

export const GET_TRADES = gql`
  query tradeTypes {
    tradeTypes {
      tradeTypeCategoryEnumValue
      tradeTypeCategoryName
      tradeTypeEnumValue
      tradeTypeName
    }
  }
`;

export const GET_JOBS = gql`
  query jobs($page: Int, $perPage: Int, $search: String) {
    jobs(page: $page, perPage: $perPage, search: $search) {
      pagination {
        hasNextPage
        currentPage
      }
      results {
        id
        name
        state
        locationLine1
        locationLine2
        locationCity
        locationRegion
        locationCountry
        locationPostalCode
        approvingUser {
          email
          name
        }
        orgId
      }
    }
  }
`;

export const GET_JOBS_WITH_SALES_OPPORTUNITIES = gql`
  query jobsWithSalesOpportunity(
    $page: Int
    $perPage: Int
    $search: String
    $orgId: ID!
  ) {
    jobs(page: $page, perPage: $perPage, search: $search) {
      pagination {
        hasNextPage
        currentPage
      }
      results {
        id
        name
        state
        locationLine1
        locationLine2
        locationCity
        locationRegion
        locationCountry
        locationPostalCode
        approvingUser {
          email
          name
        }
        orgId
        bestFrontImage {
          imageSmallUrl
        }
        salesOpportunity(orgId: $orgId) {
          id
          jobId
          orgId
          soldEstimateGroup {
            id
            state
            createdAt
            user {
              name
              email
            }
            updatedAt
            orgId
            jobId
            totalCost
            totalPrice
            estimates(active: true) {
              name
              tradeType
              userId
              active
              basePrice
              lineItems {
                price
              }
              id
              discounts {
                discountType
                value
              }
            }
            salesOpportunity {
              id
              soldEstimateGroupId
            }
          }
        }
      }
    }
  }
`;

export const JOB_DETAILS = gql`
  query jobDetails($ids: [Int!]) {
    jobs(ids: $ids) {
      results {
        id
        name
        state
        locationLine1
        locationLine2
        locationCity
        locationRegion
        locationCountry
        locationPostalCode
        orderDataJwt
        modelId
        threeDFiles {
          geometryUrl
          metadataUrl
        }
        capturedBy {
          email
          name
          firstName
          lastName
        }
        createdBy {
          email
          name
          firstName
          lastName
        }
        captureRequest {
          id
          capturingUserName
          capturingUserEmail
          capturingUserPhone
        }
      }
    }
  }
`;

export const PROFILE = gql`
  query profile {
    profile {
      impersonator {
        userEmail
        userId
      }
      user {
        id
        createdAt
        email
        intercomSha
        firstName
        lastName
        isTestData
        name
        officePhone
        mobilePhone
        orgs {
          id
          name
          custServCity
          custServLine1
          custServLine2
          custServPostalCode
          custServRegion
          custServPhone
          partner {
            id
            name
          }
          brand {
            id
            logoUrl
          }
          wallet {
            id
            billingAddressCity
            billingAddressRegion
            billingAddressPostalCode
            billingAddressLine1
            billingAddressLine2
            billingEmail
          }
          featureAccesses {
            accessorId
            accessorType
            enabled
            id
            identifier
          }
        }
        aclAccesses {
          id
          orgId
          aclTemplate
        }
      }
    }
  }
`;
