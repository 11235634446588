import { useState, useEffect } from 'react';

import { useQuery } from '@apollo/client';
import { LoadingOverlay } from '@hover/blueprint';
import * as Sentry from '@sentry/react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Switch,
  Route,
  useRouteMatch,
  useLocation,
  RouteProps,
} from 'react-router-dom';

import { EstimationEstimateGroupStateEnum } from 'src/api/graphql-global-types';
import { GET_ESTIMATE_GROUP } from 'src/features/exteriorEstimator/apis/queries';
import { estimatorActions } from 'src/features/exteriorEstimator/redux/actions';
import { getVariationsFilter } from 'src/redux/selectors';

type Props = RouteProps & { routes: RouteProps[] };

export const EstimatesDataWrapper: React.FC<Props> = ({ routes }) => {
  const [success, setSuccess] = useState(false);
  const location = useLocation();
  const match = useRouteMatch();
  const dispatch = useDispatch();

  const { estimateGroupId } = match.params;
  const variationsFilter = useSelector(getVariationsFilter);
  const { loading, data, stopPolling, error } = useQuery(GET_ESTIMATE_GROUP, {
    variables: {
      id: estimateGroupId,
      ...variationsFilter,
    },
    pollInterval: 1500,
  });

  useEffect(() => {
    const { state, failureReason } = data?.estimationEstimateGroup ?? {};
    if (state === EstimationEstimateGroupStateEnum.COMPLETE) {
      stopPolling();
      setSuccess(true);
      dispatch(
        estimatorActions.receiveEstimateGroup(data.estimationEstimateGroup),
      );
    }
    if (error || state === EstimationEstimateGroupStateEnum.FAILED) {
      stopPolling();
      setSuccess(false);
      dispatch(estimatorActions.setError(true));

      // graphQlErrors will already be caught by our client.onError hook
      // so we really only care about the ones that failed for some other reason
      if (failureReason && failureReason !== 'None') {
        Sentry.captureException(error);
      }
    }
  }, [data, dispatch, error, estimateGroupId, stopPolling]);

  if (!success || loading) return <LoadingOverlay isLoading />;
  return (
    <Switch location={location}>
      {routes.map((route) => (
        <Route
          key={`${route.path}`}
          {...route}
          path={`${match.path}${route.path}`}
        />
      ))}
    </Switch>
  );
};
