import { useEffect } from 'react';

import { Box } from '@hover/blueprint';
import { useDispatch } from 'react-redux';
import type { RouteConfig } from 'react-router-config';
import { Switch, Route, useLocation, useRouteMatch } from 'react-router-dom';

import { EHIHoverHeader as HoverHeader } from 'src/components/EHIHoverHeader';
import { updateSelectedListItems } from 'src/features/project/redux/actions';

type Props = { routes: RouteConfig[] };

export const ProjectPage: React.FC<Props> = ({ routes }) => {
  const location = useLocation();
  const match = useRouteMatch();
  const pathsWithoutHeader = ['/detail', '/checkout', '/confirmation'];
  const omitHeader = pathsWithoutHeader.reduce((acc, path) => {
    const matches = location.pathname.includes(path);
    return matches || acc;
  }, false);
  const dispatch = useDispatch();

  useEffect(() => {
    // When leaving the Project Scope pages, which are in the context of a jobId, clear the selected listItems.
    return () => {
      dispatch(updateSelectedListItems(null));
    };
    // Effect is used for un-mount cleanup only.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* Static Project page template elements. */
  const pageComponent = (
    <Box flexDirection="row" flex={1} data-test-id="ProjectScope-Page">
      <Box flex="auto" flexDirection="column" backgroundColor="neutral.100">
        <Switch location={location}>
          {routes.map((route) => (
            <Route
              exact={route.exact}
              path={`${match.path}${route.path}`}
              key={route.path?.toString()}
              render={(props) => {
                const Component = route.component as React.ElementType;
                return Component ? (
                  <Component {...props} routes={route.routes} />
                ) : null;
              }}
            />
          ))}
        </Switch>
      </Box>
    </Box>
  );

  return omitHeader ? (
    <Box display="flex" position="absolute" minHeight="100%" width="100%">
      {pageComponent}
    </Box>
  ) : (
    <HoverHeader>{pageComponent}</HoverHeader>
  );
};
